<template>
  <select class="form-select" :value="value" ref="formselect" @input="$emit('input', $event.target.value)"
    :required="required">
    <slot></slot>
    <option v-for="(elem, index) in options" :key="index" :value="elem.value">{{ elem.text }}</option>
  </select>
</template> 
<script>
import { feather } from '@/main.js';
export default {
  props: {
    value: { required: true },
    required: { type: Boolean },
    options: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      modelSelected: this.selected,
    };
  },
  methods: {
  },
}
</script>