<template>
    <section>
        <div class="row">
            <div class="col-sm-auto col-12">
                <h3 class="my-1">
                    <div class="btn-group">
                        <button type="button" title="Обновить" class="btn btn-default btn-smx" :disabled="isBusy"
                            v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" @click="Refresh()"><i
                                class="nav-icon fas fa-sync-alt"></i></button>
                        <button class="btn btn-outline-success btn-add   btn-smx" @click="ShowCreateProductModal()"
                            v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" title="Добавить продукт">
                            <i class="nav-icon fa fa-plus me-1" aria-hidden="true"></i>Добавить продукт
                        </button>
                    </div>
                </h3>
                <div class="card card-layout">
                    <div class="card-body p-0">
                        <div class="d-flex flex-wrap align-items-baseline justify-content-between p-2" style="gap:5px">
                            <div class="form-check form-switch">
                                <input class="form-check-input " v-model="show_removed" name="removed" type="checkbox"
                                    id="removed_products">
                                <i><b> &laquo;удалённые&raquo; </b>продукты</i>
                            </div>
                            <data-table-filter v-model="filter"></data-table-filter>
                        </div>
                        <b-overlay :show="isBusy" :opacity="0.5">
                            <b-table responsive @filtered="onFiltered" :filter="filter"
                                :filter-included-fields="['name']" :filter-function="filterTable" striped hover
                                class="m-0" head-variant="light" :fields="fields" :items="Products" id="table_products"
                                :currentPage="currentPage" :perPage="perPage" :busy="isBusy && Products.length == 0"
                                show-empty>
                                <template #emptyfiltered>
                                    <div class=" text-center my-2">{{ emptyFilteredText }}</div>
                                </template>
                                <template #empty>
                                    <div v-if="!ProductsGetHaveError" class="text-center my-2">{{ emptyText }}</div>
                                    <div v-else class="text-center my-2" style="color:red">Ошибка загрузки</div>
                                </template>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <strong>Загрузка...</strong>
                                    </div>
                                </template>
                                <!-- <template #table-caption v-if="rows != 0">
                    <div class="d-flex flex-column flex-sm-row text-start align-items-center px-2"
                        :class="{ 'justify-content-end': rows < perPage, 'justify-content-between': rows >= perPage }">
                        <b-pagination v-if="rows >= perPage" v-model="currentPage" :total-rows="rows"
                            :per-page="perPage" class="order-sm-first order-last"
                            aria-controls="table_products" size="sm"></b-pagination>
                        Записи с {{ currentPage * perPage - perPage + 1 }} до {{ currentPage * perPage
                            <= rows ? currentPage * perPage : rows }} из {{ rows }} записей </div>
                </template>-->
                                <template #cell(name)="data">
                                    <span
                                        :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }">{{
                                            data.item.name
                                        }}</span>
                                </template>
                                <template #cell(hardwareName)="data">
                                    <span
                                        :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }">{{
                                            data.item.hardwareName }}</span>
                                </template>
                                <template #cell(change)="data">
                                    <div class="actions justify-content-center">
                                        <button class="btn edit" @click="ShowEditModal(data.item)"
                                            v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                            title="Свойства продукта"><i class="fa fa-pencil fa-1x"></i></button>
                                    </div>
                                </template>
                                <template #cell(delete)="data">
                                    <div class="actions justify-content-center">
                                        <button class="btn delete" v-if="!data.item.isRemoved" title="Удаление продукта"
                                            @click="ShowDeleteModal(data.item)"><i
                                                class="fa fa-trash fa-1x"></i></button>
                                    </div>

                                </template>
                            </b-table>
                            <pagination :total-rows="rows" :perPage.sync="perPage" :currentPage.sync="currentPage"
                                aria-controls="table_products">
                            </pagination>

                        </b-overlay>
                    </div>
                </div>
            </div>

        </div>
        <!-- #region modals -->
        <bs-modal centered id="CreateEditProductModal" :loading="loading" ref="CreateEditProductModal"
            :title="edit ? 'Свойства продукта' : 'Новый продукт'">
            <div class="col-12 mb-6">
                <form ref="CreateOrEditeProductForm" id="CreateOrEditeProductForm" @submit.prevent="CreateOrEdite">
                    <div class="row mb-n7 align-items-center">
                        <div class="col-12 ">
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Наименование</label></div>
                                <div class="col-sm-8 col-12 align-self-center"><input type="text"
                                        placeholder="Дизельное топливо" :disabled="loading" v-model="name"
                                        class="form-control" required /></div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Сокр.
                                        наим. &nbsp; <b-icon icon="question-circle"
                                            v-b-tooltip="{ id: 'svgCircleQuestion', title: 'Значение отображается на экране терминала', placement: 'right', variant: 'info', customClass: 'my-tooltip-class' }"
                                            style="cursor:pointer"></b-icon> </label>
                                </div>
                                <div class="col-sm-8 col-12 align-self-center"><input type="text" placeholder="ДТ"
                                        :disabled="loading" v-model="hardwareName" class="form-control" required />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button form="CreateOrEditeProductForm" :disabled="loading" type="submit"
                        class="btn btn-sm btn-primary  btn-sm text-center align-items-center">
                        <span v-if="!loading">Сохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <bs-modal centered id="DeleteProductModal" ref="DeleteProductModal" title="Удаление продукта">
            <div class="col-12 mb-6">
                <form ref="DeleteProductForm" id="DeleteProductForm" @submit.prevent="Delete()">
                    <div class="row mb-n7 align-items-center">
                        <div class="col-12 ">
                            <p class="px-5 py-3 h4" style="text-align: center;">Вы действительно хотите удалить
                                продукт
                                <br> <strong>"{{ name }}"</strong>?
                            </p>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 d-flex gap-1">

                    <button :disabled="loading" form="DeleteProductForm" type="submit"
                        class="btn btn-sm btn-danger text-center align-items-center">
                        <div v-if="!loading"> <i class="fa fa-trash me-1"></i>
                            <span>да</span>
                        </div>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                    <button :disabled="loading" form="DeleteCardForm" type="button"
                        @click="$refs.DeleteProductModal.hide()"
                        class="btn me-1 btn-primary btn-sm text-center align-items-center">
                        <span>Нет</span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!-- #endregion modals -->

    </section>
</template>
<script>
import {
    textC_alignM,
    textL_alignM
} from '@/helpers/const.js'
import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => {
    window[name] = exported;
});
import {
    feather
} from '@/main.js';
import {
    mapActions
} from 'vuex'
import {
    eventBus
} from '@/main.js'
import {
    mapGetters
} from 'vuex'
export default {
    data() {
        return {
            emptyText: "Нет продуктов",
            show_removed: false,
            emptyFilteredText: "Не найдено",
            filtered_items: [],
            filter: "",
            dismissSecs: 5,
            dismissCountDown: 0,
            fields: [{
                key: 'name',
                label: "Наименование",
                sortable: true,
                thClass: textC_alignM,
                tdСlass: textL_alignM,

            },
            {
                key: 'hardwareName',
                label: "Cокр. наим.",
                sortable: true,
                thClass: textC_alignM,
                tdСlass: textL_alignM,

            },
            //  { key: 'change', sortable: false, label: "", thStyle: { width: "10%" } },
            {
                key: 'change',
                sortable: false,
                label: " ",
                thStyle: {
                    width: "5%"
                },
                class: textC_alignM
            },
            {
                key: 'delete',
                sortable: false,
                label: " ",
                thStyle: {
                    width: "5%"
                },
                class: textC_alignM
            }
            ],
            edit: null,
            id: null,
            name: null,
            hardwareName: null,
            method: null,
            empty_text: "Нет продуктов",
            perPage: 10,
            currentPage: 1,
        }
    },
    computed: {
        Products() {
            if (this.show_removed) return this.AllProducts
            else return this.AllProducts.filter(a => !a.isRemoved)
        },
        rows() {
            if (this.filtered_items.length == 0 && this.filter == "")
                return this.Products.length
            else {
                return this.filtered_items.length;
            }
        },
        ...mapGetters([
            'AllProducts',
            'ProductByGUID',
            'ProductsOnceLoaded',
            'ProductsGetAreLoading',
            'ProductsGetHaveError',
            'ProductsPostAreLoading',
            'ProductsPostHaveError',
            'ProductsPutAreLoading',
            'ProductsPutHaveError',
            'ProductsDeleteAreLoading',
            'ProductsDeleteHaveError',
            'WalletsOnceLoaded',
            'WalletFindByProdictGUID',
        ]),
        loading() {
            switch (this.method) {
                case 'get':
                    return this.ProductsGetAreLoading;
                case 'post':
                    return this.ProductsPostAreLoading;
                case 'put':
                    return this.ProductsPutAreLoading;
                case 'delete':
                    return this.ProductsDeleteAreLoading;
                default:
                    return null
            }
        },
        isBusy() {

            return (this.ProductsGetAreLoading) ? true : false;

        }
    },
    async created() {

    },
    mounted() {
        feather.replace();
    },
    methods: {
        async Refresh() {
            if (!this.WalletsOnceLoaded)
                await this.$store.dispatch(WALLETS_GET_REQUEST);
            this.$store.dispatch(PRODUCTS_GET_REQUEST);

        },
        onFiltered(filteredItems) {
            this.filtered_items = filteredItems;
            this.currentPage = 1;
        },
        clear() {
            this.filter = "";
        },
        filterTable(row, filter) {
            if (filter.length == 0 || filter.length == 1) return true
            //if (row.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 && row.number.toLowerCase()[0] == filter.toLowerCase()[0]) return true;
            //else return false
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        ShowEditModal(element) {
            this.edit = true;
            this.name = element.name;
            this.hardwareName = element.hardwareName;
            this.id = element.id;
            this.$refs['CreateEditProductModal'].show();
        },
        async CreateOrEdite() {
            if (this.edit) {
                this.method = "put"
                let product = JSON.parse(JSON.stringify(this.ProductByGUID(this.id)));
                /*   if (product.name != this.name && product.hardwareName != this.hardwareName) {*/
                product.name = this.name;
                product.hardwareName = this.hardwareName;
                this.$store.dispatch('PRODUCTS_PUT_REQUEST', product)
                    .then(() => this.$refs['CreateEditProductModal'].success())
                    .catch(err => {
                        this.$refs['CreateEditProductModal'].error(err)
                    })
                // }
                /*   else {
                       this.$refs['CreateEditProductModal'].error(err);
                       this.name = null;
                   }*/
            } else {
                this.method = "post"
                this.$store.dispatch('PRODUCTS_POST_REQUEST', {
                    name: this.name,
                    hardwareName: this.hardwareName
                })
                    .then(() => this.$refs['CreateEditProductModal'].success())
                    .catch(err => this.$refs['CreateEditProductModal'].error(err))
            }
        },
        ShowCreateProductModal() {
            this.edit = false;
            this.name = null;
            this.hardwareName = null;
            this.id = null;
            this.$refs['CreateEditProductModal'].show();
        },
        ShowDeleteModal(element) {
            this.id = element.id;
            this.name = element.name;
            this.hardwareName = element.hardwareName;
            this.$refs['DeleteProductModal'].show();
        },
        Delete() {
            this.method = "delete"
            if (this.WalletFindByProdictGUID(this.id) != undefined) {
                this.$refs['DeleteProductModal'].error("Продукт привязан к кошелькам")
                //   this.dismissCountDown = this.dismissSecs
            } else {
                // let product = JSON.parse(JSON.stringify(this.ProductByGUID(this.id)));
                this.$store.dispatch('PRODUCTS_DELETE_REQUEST', this.id)
                    .then(() => {
                        this.$refs['DeleteProductModal'].success()
                        //   product.isRemoved = "true";
                    })
                    .catch(err => this.$refs['DeleteProductModal'].error(err))
            }
        },
    },
}
</script>