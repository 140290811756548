<template>
    <div>
        <div class="content-body content-body-filter" :class="{ 'content-body-filter--open': filterOpen }">
            <!-- #region PageHeadingsStart -->
            <div class="row align-items-center justify-content-between mb-0">
                <h3><i data-feather="list" class="nav-icon"></i>
                    <span class="titlePage"> Отчёт </span>
                    <div class="btn-group">
                        <button type="button" title="Обновить" class="btn btn-default btn-smx" :disabled="isBusy"
                            @click="Refresh()" v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"><i
                                class="nav-icon fas fa-sync-alt"></i></button>

                    </div>
                </h3>
            </div>
            <!-- #endregion Page Headings End -->
            <div class="card card-layout">
                <div class="card-body p-0">
                    <div class="d-flex flex-wrap align-items-baseline justify-content-between px-2  py-1"
                        style="gap:5px">
                        <div>
                            <b-dropdown :disabled="isBusy" tabindex="0"
                                v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" title="Столбцы"
                                variant='light' size="sm" no-caret>
                                <template #button-content>
                                    <i class="fa-sharp fa-solid fa-gear" />
                                    <b-badge class="position-absolute" style="padding: 0; right: 5px; top:0"
                                        variant="danger">{{ SelectedFilsQuantity }}</b-badge>
                                </template>
                                <b-dropdown-text>
                                    <b-form-checkbox-group v-model="selected_field" :options="base_fields"
                                        value-field="key" class="column-checkbox" stacked plain text-field="label"
                                        disabled-field="disabled"></b-form-checkbox-group>
                                </b-dropdown-text>
                            </b-dropdown>
                            <button :disabled="isBusy" class="btn btn-sm btn-light position-relative"
                                v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" title="Фильтры"
                                type="button" @click="ToggleFilter"> <i class="fa-solid fa-filter"></i><b-badge
                                    class="position-absolute" style="padding: 0; right: 5px; top:0" variant="danger">{{
                                        FilterQuantity }}</b-badge></button>

                            <b-dropdown :disabled="isBusy" variant='light'
                                v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" title="Сохранить как"
                                size="sm" toggle-class="text-decoration-none" no-caret>
                                <template #button-content>
                                    <i class="fa-solid fa-download"></i>
                                </template>
                                <b-dropdown-text class="d-flex flex-column">
                                    <div class="d-flex flex-column" style="gap:5px">
                                        <!--   <button type="button" class="btn btn-sm btn-primary align-items-center"
                                            @click="PrepareReport('PDF')" :disabled="isBusy || rows == 0"><b-spinner
                                                small v-if="report_type == 'PDF'"></b-spinner><span v-else>

                                                <i class="far fa-file-pdf"></i> PDF </span></button>-->
                                        <button type="button" class="btn btn-sm btn-primary  align-items-center"
                                            @click="PrepareReport('EXCEL')" :disabled="isBusy || rows == 0"><b-spinner
                                                small v-if="report_type == 'EXCEL'"></b-spinner><span v-else><i
                                                    class="far fa-file-excel"></i> EXCEL
                                            </span></button>
                                        <button type="button" class="btn btn-sm btn-primary align-items-center"
                                            @click="PrepareReport('CSV')" :disabled="isBusy || rows == 0"><b-spinner
                                                small v-if="report_type == 'CSV'"></b-spinner><span v-else><i
                                                    class="fas fa-file-csv"></i> CSV
                                            </span></button>
                                    </div>
                                </b-dropdown-text>
                            </b-dropdown>
                        </div>
                        <h5 class="h5">{{ ComputedDateInterval }} </h5>
                        <data-table-filter v-model="filter"></data-table-filter>
                    </div>
                    <b-overlay :show="isBusy" :opacity="0.5">


                        <b-table @filtered="onFiltered" :filter="filter" ref="table_transactions" sticky-header
                            :filter-included-fields="['cardID', 'terminalID']" class="table-sticky-header-layout m-0"
                            :filter-function="filterTable" striped hover head-variant="light" :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc" :per-page="perPage" responsive :current-page="currentPage"
                            :fields="fields" show-empty :items="items" :style="{ width: '100%' }"
                            id="table_transactions" :busy="isBusy && items.length == 0">
                            <template v-slot:head(totalStartValue)="data">
                                <div>
                                    {{ data.label.split('(')[0] }}
                                    <br>
                                    ({{ data.label.split('(')[1] }}
                                </div>
                            </template>
                            <template v-slot:head(totalEndValue)="data">
                                <div>
                                    {{ data.label.split('(')[0] }}
                                    <br>
                                    ({{ data.label.split('(')[1] }}
                                </div>
                            </template>



                            <template #custom-foot>
                                <b-tr v-if="items.length > 0 && NeedFieldsON">
                                    <b-th style="position: sticky; left: 0;">
                                        Итого на стр.:
                                    </b-th>
                                    <b-th v-for="elem in fields.slice(1)" :key="elem.key">
                                        <div v-if="elem.key == 'requestQuantity'"> {{ totalRequestedQuantityInPage }}
                                        </div>
                                        <div v-if="elem.key == 'quantity'">{{ totalQuantityReleasedInPage }}</div>
                                    </b-th>
                                </b-tr>
                            </template>
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>


                            <template #emptyfiltered>
                                <div class=" text-center my-2">{{ emptyFilteredText }}</div>
                            </template>
                            <template #table-busy>
                                <div class="text-center my-2">
                                    <strong>Загрузка...</strong>
                                </div>
                            </template>
                            <template #empty>
                                <div v-if="!CardsGetHaveError && !WalletsGetHaveError && !OrganizationsGetHaveError && !ProductsGetHaveError"
                                    class="my-2">{{ FilterQuantity == 0 ? `Нет транзакций. Выберите другие
                                    даты`: 'Нет транзакций. Измените фильтр' }}</div>
                                <div v-else class="my-2" style="color:red">Ошибка загрузки</div>
                            </template>
                            <template #cell(terminalID)="data">
                                <div v-if="!TerminalByGUID(data.item.terminalID)?.isRemoved ?? false">
                                    <span class="">{{ TerminalByGUID(data.item.terminalID)?.name }}
                                        <b-icon icon="info-circle"
                                            v-b-tooltip="{ title: `IMEI: \n ${TerminalByGUID(data.item.terminalID)?.imei}`, placement: 'bottom', variant: 'info', }"
                                            style="cursor:pointer">
                                        </b-icon>
                                    </span>
                                </div>
                                <div v-else>
                                    <span class="text-decoration-line-through text-danger">{{
                                        TerminalByGUID(data.item.terminalID)?.name }}
                                    </span>
                                </div>
                            </template>
                            <template #cell(LatLong)="data">

                                <div class="d-flex align-items-center justify-content-center" style="gap:5px"
                                    v-if="data.item.transactionType == 0 && data.item.latitude != null && data.item.longitude != null">
                                    <div>

                                        Широта: {{ data.item.latitude.toFixed(2) }}
                                        <br>
                                        Долгота: {{ data.item.longitude.toFixed(2) }}
                                    </div>
                                    <div> <b-tooltip style="opacity: 1;"
                                            v-if="LatLngNotNull(data.item.latitude, data.item.longitude)"
                                            :target="'map-trigger-' + data.item.id" placement="bottom"  
                                            custom-class="map-tooltip smooth-fade" title="Map Tooltip" variant="light" delay="50"
                                            @show="ToltipShow($event, data.item.latitude, data.item.longitude)">
                                           
                                          
                                            <div v-if="mapIsLoading" style="width: 300px; height: 300px;" class="d-flex align-items-center justify-content-center"><b-spinner>Загрузка</b-spinner>
                                            </div>
                                            <div v-else style="width: 300px; height: 300px; font-size:0.9rem;" class="text-start">
                                                <div class="p-1">{{ geocodingText }}</div>
                                                     <l-map :zoom="zoom"
                                                        :center="GetLatLng(data.item.latitude, data.item.longitude)"
                                                        :options="{ attributionControl: false }">
                                                        <l-tile-layer :url="url" :attribution="attribution"
                                                            :subdomains="subdomains"></l-tile-layer>
                                                        <l-marker :key="data.item.id" :icon="icon"
                                                            :lat-lng="GetLatLng(data.item.latitude, data.item.longitude)">
                                                        </l-marker>
                                                  </l-map>                           
                                             </div>
                                        </b-tooltip>
                                        <b-icon style="cursor: pointer;" v-if="LatLngNotNull(data.item.latitude, data.item.longitude)" icon="map"
                                            :id="'map-trigger-' + data.item.id" class="map-trigger">
                                        </b-icon>
                                    </div>
                                </div><!--="data.item.latitude == null || data.item.longitude == null"-->
                                <span class="d-flex align-items-center justify-content-center" v-else> -
                                </span>

                            </template>
                            <template #cell(requestQuantity)="data">
                                <span v-if="data.item.transactionType == 0">
                                    {{ (data.item.requestQuantity / 100).toFixed(2) }}
                                </span>
                                <span v-else class="d-flex align-items-center justify-content-center">
                                    -
                                </span>
                            </template>
                            <template #cell(quantity)="data">
                                <span v-if="data.item.transactionType < 2">
                                    {{ (data.item.quantity / 100).toFixed(2) }}
                                </span>
                                <span v-else class="d-flex align-items-center justify-content-center">
                                    -
                                </span>
                            </template>
                            <template #cell(carNumber)="data">
                                <span class="d-flex align-items-center justify-content-center"
                                    v-if="data.item.transactionType == 0">
                                    {{ data.item.carNumber }}
                                </span>
                                <span v-else class="d-flex align-items-center justify-content-center">
                                    -
                                </span>
                            </template>
                            <template #cell(totalStartValue)="data">
                                <span v-if="data.item.transactionType == 0">
                                    {{ data.item.totalStartValue }}</span>
                                <span v-else class="d-flex align-items-center justify-content-center"> - </span>
                            </template>
                            <template #cell(totalEndValue)="data">
                                <span v-if="data.item.transactionType == 0">
                                    {{ data.item.totalEndValue }}</span>
                                <span v-else class="d-flex align-items-center justify-content-center"> - </span>
                            </template>
                            <template #cell(user)="data">
                                <span v-if="data.item.transactionType != 2"
                                    :class="{ 'text-decoration-line-through text-danger': CardByGUID(data.item.cardID)?.isRemoved ?? false }">{{
                                        CardByGUID(data.item.cardID)?.holderName }}
                                </span>
                                <span v-else class="d-flex align-items-center justify-content-center">
                                    -
                                </span>
                            </template>

                            <template #cell(pumpID)="data">
                                <span v-if="data.item.transactionType == 0">
                                    {{ data.item.pumpID != null ? data.item.pumpID + 1 : '-' }}</span>
                                <span v-else class="d-flex align-items-center justify-content-center"> - </span>
                            </template>
                            <template #cell(channelNum)="data">
                                <span v-if="data.item.transactionType == 0">
                                    {{ data.item.channelNum != null ? data.item.channelNum + 1 : '-' }}</span>
                                <span v-else class="d-flex align-items-center justify-content-center"> - </span>
                            </template>
                            <template #cell(cardID)="data">
                                <span v-if="data.item.transactionType != 2"
                                    :class="{ 'text-decoration-line-through text-danger': CardByGUID(data.item.cardID)?.isRemoved ?? false }">{{
                                        NumberSpace(CardByGUID(data.item.cardID)?.number) }}




                                    <!--<b-icon icon="info-circle"
                                        v-b-tooltip="{ title: `Держатель:  ${CardByGUID(data.item.cardID)?.holderName}`, placement: 'bottom', variant: 'info', }"
                                        style="cursor:pointer">
                                    </b-icon>-->
                                </span>
                                <span v-else>
                                    {{ NumberSpace(data.item.cardNumber) }}
                                </span>
                            </template>
                            <template #cell(servicedNumber)="data">
                                <span class="d-flex align-items-center justify-content-center"
                                    v-if="data.item.servicedNumber == null">
                                    - </span>
                                <span v-else>{{ data.item.servicedNumber }}</span>
                            </template>
                            <template #cell(walletID)="data">
                                <span class="d-flex align-items-center justify-content-center"
                                    v-if="data.item.transactionType == 2">-</span>
                                <span class="text-decoration-line-through text-danger"
                                    v-else-if="WalletByGUID(data.item.walletID) == null">Неизвестный кошелёк</span>


                                <span
                                    :class="{ 'text-decoration-line-through text-danger': WalletByGUID(data.item.walletID).isRemoved }"
                                    v-else>

                                    {{ ProductByGUID(WalletByGUID(data.item.walletID)?.productID)?.hardwareName }}
                                    <br> ({{ WalletByGUID(data.item.walletID)?.name }})
                                </span>
                            </template>
                            <template #cell(organizationID)="data">
                                <span class="d-flex align-items-center justify-content-center"
                                    v-if="data.item.transactionType == 2">-</span>
                                <span v-else>{{ OrganizationByGUID(data.item.organizationID)?.name ?? '-' }}</span>
                            </template>
                            <template #cell(userID)="data">
                                <span class="d-flex align-items-center justify-content-center"
                                    v-if="data.item.transactionType == 2">-</span>

                                <span v-else>{{ UserByGUID(data.item.userID)?.name ?? '-' }}</span>
                            </template>
                            <template #cell(transactionType)="data">
                                <span v-if="data.item.transactionType === 2" class="badge rounded-pill bg-info">Выпуск
                                    карты</span>
                                <span v-else-if="data.item.transactionType === 1"
                                    class="badge rounded-pill bg-success">Пополнение</span>
                                <span v-else-if="data.item.transactionType === 0"
                                    class="badge rounded-pill bg-danger">Списание</span>
                                <span v-else-if="data.item.transactionType === -1"
                                    class="badge rounded-pill bg-secondary">Возврат</span>
                                <span class="text-danger" v-else> Неизвестный тип:{{ data.item.transactionType }}
                                </span>
                            </template>
                            <template #cell(delete)="data">
                                <button v-if="!data.item.isRemoved" class="btn btn-outline-dark"
                                    @click="ShowDeleteModal(data.item)"
                                    style="width:40px; border-radius:12px !important; height:40px; padding:0;">
                                    <i class="fa fa-trash-o fa-1x" aria-hidden="true"></i>
                                </button>

                            </template>
                            <template #cell(driverID)="data">
                                <span v-if="data.item.transactionType == 0"
                                    :class="{ 'text-decoration-line-through text-danger': DriverByGUID(data.item.driverID)?.isRemoved ?? false }">{{
                                        DriverByGUID(data.item.driverID)?.name ?? '-' }}
                                    <b-icon icon="info-circle"
                                        v-b-tooltip="{ title: `Карта: \n ${DriverByGUID(data.item.driverID)?.number}, Код: ${DriverByGUID(data.item.driverID)?.pin ?? '-'}`, placement: 'bottom', variant: 'info', }"
                                        style="cursor:pointer">
                                    </b-icon>

                                </span>
                                <span class="d-flex align-items-center justify-content-center" v-else>-</span>
                            </template>
                        </b-table>
                        <pagination :total-rows="rows" :perPage.sync="perPage" :currentPage.sync="currentPage"
                            aria-controls="table_cards">
                        </pagination>
                    </b-overlay>
                </div>
            </div>

        </div>
        <transition name="aside-animation" mode="out-in">
            <aside v-if="filterOpen" class="driver-filter">
                <div class="driver-filter--content px-2">
                    <h3 class="h3 text-center py-2">Фильтры отчёта</h3>
                    <form class="driver-filter--content--filter_fields" @submit.prevent="GET_TRANSACTIONS()">
                        <div>
                            Период
                            <bs-datarange @UpdateDT="setDateTime" :start.sync="start" :end.sync="end" :disabled="isBusy"
                                id="transactionendto" required />
                        </div>
                        <div>
                            Топливные карты
                            <multiselect trackBy="number" valueProp="value" :searchable="true" :canClear="true"
                                :native="false" locale="ru" placeholder="Выберите карты" id="card"
                                :close-on-select="false" mode="tags" :hideSelected="false" label="text"
                                @input="change_cards" v-model="filterObject.selected_cards" :options="AllCardsSelect"
                                :searchFilter="CardsFilter" @search-change="searchChange" :disabled="isBusy">
                                <template v-slot:nooptions>Нет элементов</template>
                                <template v-slot:noresults>
                                    <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                        Не найдено</div>
                                </template>
                                <template v-slot:option="{ option }">
                                    <span :class="{ 'text-decoration-line-through text-danger': option.isRemoved }"> {{
                                        option.text }}</span>
                                </template>
                            </multiselect>
                        </div>
                        <div>
                            Терминалы
                            <multiselect trackBy="text" valueProp="value" :searchable="true" locale="ru"
                                placeholder="Выберите терминалы" id="terminal" :canClear="false"
                                :close-on-select="false" mode="tags" :hideSelected="false" label="text"
                                @input="GET_TRANSACTIONS" selectedLabel="" :disabled="isBusy"
                                v-model="filterObject.selected_terminals" :options="AllTerminalsSelect">
                                <template v-slot:nooptions>Нет элементов</template>
                                <template v-slot:noresults>
                                    <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                        Не найдено</div>
                                </template>
                                <template v-slot:option="{ option }">
                                    <span :class="{ 'text-decoration-line-through text-danger': option.isRemoved }"
                                        v-html="option.text"></span>
                                </template>
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                    <div class="multiselect-tag" :class="{
                                        'is-disabled': disabled
                                    }">

                                        <span v-html="option.text" />
                                        <span v-if="!disabled" class="multiselect-tag-remove"
                                            @mousedown.prevent="handleTagRemove(option, $event)">
                                            <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <div>
                            Подразделения
                            <multiselect trackBy="text" valueProp="value" :searchable="true" :canClear="false"
                                locale="ru" placeholder="Выберите подразделения" id="organization"
                                :close-on-select="false" mode="tags" :hideSelected="false" label="text"
                                @input="GET_TRANSACTIONS" selectedLabel="" :disabled="isBusy"
                                v-model="filterObject.selected_organizationsganizations"
                                :options="AllOrganizationsSelect">
                                <template v-slot:nooptions>Нет элементов</template>
                                <template v-slot:noresults>
                                    <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                        Не найдено</div>
                                </template>
                                <template v-slot:option="{ option }">
                                    <span :class="{ 'text-decoration-line-through text-danger': option.isRemoved }"> {{
                                        option.text }}</span>
                                </template>
                            </multiselect>
                        </div>
                        <div>
                            Кошельки
                            <multiselect trackBy="text" valueProp="value" :searchable="true" :canClear="false"
                                locale="ru" placeholder="Выберите кошельки" id="wallets" :close-on-select="false"
                                mode="tags" :hideSelected="false" label="text" @input="GET_TRANSACTIONS"
                                selectedLabel="" v-model="filterObject.selected_wallets" :disabled="isBusy"
                                :options="filterObject.selected_cards.length == 0 ? AllWalletsSelect : WalletsOfCards">
                                <template v-slot:nooptions>{{
                                    AllWalletsSelect.length == 0 ? 'Нет элементов' : `Не найдено кошельков для выбранной
                                    карты`}}</template>
                                <template v-slot:noresults>
                                    <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                        Не найдено</div>
                                </template>
                                <template v-slot:option="{ option }">
                                    <span :class="{ 'text-decoration-line-through text-danger': option.isRemoved }"
                                        v-html="option.text"> </span>
                                </template>
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                    <div class="multiselect-tag" :class="{
                                        'is-disabled': disabled
                                    }">

                                        <span v-html="option.text" />
                                        <span v-if="!disabled" class="multiselect-tag-remove"
                                            @mousedown.prevent="handleTagRemove(option, $event)">
                                            <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <div>
                            Тип транзакции
                            <multiselect trackBy="text" valueProp="value" :searchable="true" :canClear="false"
                                locale="ru" placeholder="Выберите типы транзакций" id="wallets" :close-on-select="false"
                                mode="tags" :hideSelected="false" label="text" @input="GET_TRANSACTIONS"
                                v-model="filterObject.selected_types" :disabled="isBusy"
                                :options="AllTransactionsTypeSelect">

                                <template v-slot:noresults>
                                    <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                        Не найдено</div>
                                </template>
                                <template v-slot:option="{ option }">
                                    <span :class="{ 'text-decoration-line-through text-danger': option.isRemoved }"
                                        v-html="option.text"> </span>
                                </template>
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                    <div class="multiselect-tag" :class="{
                                        'is-disabled': disabled
                                    }">

                                        <span v-html="option.text" />
                                        <span v-if="!disabled" class="multiselect-tag-remove"
                                            @mousedown.prevent="handleTagRemove(option, $event)">
                                            <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </form>
                    <div class="btn-group py-3" role="group"
                        aria-label="Группа кнопок с вложенным раскрывающимся списком">
                        <div class="input-group">

                            <button type="button" class="btn flex-grow-1 btn-sm btn-outline-primary"
                                @click="ToggleFilter">Закрыть
                                фильтр</button>
                            <button type="button" class="btn  btn-sm align-items-center btn-outline-secondary "
                                @click="InitializingFilter">
                                <b-iconstack>
                                    <b-icon stacked icon="filter" variant="danger" />
                                    <b-icon stacked icon="slash-lg" variant="danger"></b-icon>
                                </b-iconstack>

                            </button>
                        </div>

                    </div>
                </div>
            </aside>
        </transition>
    </div>
</template>
<script>
import L from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
import VueLeafletMinimap from 'vue-leaflet-minimap'
import helper from '@/helpers/Reports'
import {
    textC_alignM,
    textL_alignM,
    textR_alignM
} from '@/helpers/const.js'
import {
    mapActions
} from 'vuex'
import {
    feather
} from '@/main.js';
import {
    eventBus
} from '@/main.js'
import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => {
    window[name] = exported;
});
import {
    mapGetters
} from 'vuex'
import axios from 'axios';
export default {
    data() {
        return {

///МИНИ_КАРТА
            mapIsLoading: true,
            icon: L.icon({
                iconUrl: `/assets/images/leaflet_images/marker-icon.png`,
            }),
            geocodingText: '', // Хранит текст геокодирования
            zoom: 12,
            url: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
///

            cardsFilter: "",
            loading_report: false,
            filter: "",
            emptyFilteredText: "Не найдено",
            filtered_items: [],
            //Пагинация 
            perPage: 100,
            report_type: null,
            currentPage: 1,
            sortDesc: true,
            sortBy: 'utc',




            ///Фильтрация таблицы           
            filterOpen: false,

            defaultFilterObject: {
                selected_cards: [],
                selected_terminals: [],
                selected_organizationsganizations: [],
                selected_wallets: [],
                selected_types: [-1, 0, 1]
            },
            filterObject: null,

            defaultStartDate: moment().startOf("day").subtract(6, 'days'),
            defaultEndDate: moment().endOf("day"),
            start: null,
            end: null,


            empty_text: "Введите даты",
            dismissSecs: 5,
            dismissCountDown: 0,
            base_fields: [{
                key: 'index',
                label: "№",
                sortable: false,
                thClass: textC_alignM,
                tdClass: textR_alignM,
                thStyle: {},
                disabled: true,
            },
            {
                key: 'transactionType',
                label: "Тип",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {},
                disabled: false,
            },
            {
                key: 'utc',
                label: "Начало",
                sortable: true,
                class: textC_alignM,
                thStyle: {},
                formatter: value => {
                    return moment(value).locale('ru').format('DD.MM.YYYY/HH:mm:ss');
                },
                disabled: false,
            },
            {
                key: 'utcEnd',
                label: "Конец",
                sortable: true,
                class: textC_alignM,
                thStyle: {},
                formatter: value => {
                    return moment(value).locale('ru').format('DD.MM.YYYY/HH:mm:ss');
                }
                ,
                disabled: false,
            },
            {
                key: 'receiveUTC',
                label: "Получено сервером",
                sortable: true,
                class: textC_alignM,
                thStyle: {},
                formatter: value => {
                    return moment(value).locale('ru').format('DD.MM.YYYY/HH:mm:ss');
                }
                ,
                disabled: false,
            },
            {
                key: 'terminalID',
                label: "Терминал",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {}
                ,
                disabled: false,
            },
            {
                key: 'LatLong',
                label: "Координаты",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {},
                disabled: false,
            },
            {
                key: 'cardID',
                label: "Карта",
                sortable: true,
                class: textC_alignM,
                thStyle: {}
                ,
                disabled: false,
            },
            {
                key: 'servicedNumber',
                label: "Табельный номер",
                sortable: true,
                class: textL_alignM,
                thStyle: {}
                ,
                disabled: false,
            },
            {
                key: 'user',
                label: "Держатель",
                sortable: false,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {},
                disabled: false,
            },
            {
                key: 'driverID',
                label: "Водитель",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textC_alignM,
                thStyle: {},
                disabled: false,
            },
            {
                key: 'carNumber',
                label: "Номер авто",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textC_alignM,
                thStyle: {},
                disabled: false,
            },
            {
                key: 'walletID',
                label: "Кошелёк",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {},
                disabled: false,
            },
            {
                key: 'organizationID',
                label: "Подразделение",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {},
                disabled: false,
            },
            {
                key: 'requestQuantity',
                label: "Запрошено, л",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {},
                disabled: false,

            },
            {
                key: 'quantity',
                label: "Отпущено, л.",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {},
                disabled: false,

            },

            {
                key: 'totalStartValue',
                label: "Сум.сч.(начальный)",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {},
                disabled: false,

            },
            {
                key: 'totalEndValue',
                label: "Сум.сч.(конечный)",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textL_alignM,
                thStyle: {},
                disabled: false,

            },
            {
                key: 'pumpID',
                label: "Номер ТРК",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textC_alignM,
                thStyle: {},
                disabled: false,

            },
            {
                key: 'channelNum',
                label: "Номер рукава",
                sortable: true,
                thClass: textC_alignM,
                tdClass: textC_alignM,
                thStyle: {},
                disabled: false,


            },

            ],
            selected_field: [],
            fields: [],

            edit: null,
            number: null,
            id: null,
            pin: null,
            organizationID: null,
            holderName: null,
            method: null,

            loading_transactions: false,
            items: [],
            emptyText: "Нет транзакций с выбранными фильтрами",
            perper: null,

        }
    },
    computed: {

        SelectedFilsQuantity() {
            return this.fields.length;
        },
        FilterQuantity() {
            var sum = 0;
            for (var item in this.filterObject) {
                if (this.filterObject[item].length > 0) sum += 1
                //if(this.filterObject[elem].length>0) sum+=1;
            }
            return sum;
        },
        Wallets() {
            return this.AllWallets.map(element => {
                var product = this.ProductByGUID(element.productID);
                return Object.assign({}, element, { product: product?.hardwareName ?? null, productIsRemoved: product?.isRemoved ?? true })
            }
            );
        },
        isBusy() {
            return this.loading_transactions || this.loading_report;
        },
        NeedFieldsON() {
            return this.fields?.filter(a => a.key == 'quntity' || a.key == 'requestQuantity')?.length > 0 ?? false;
        },
        ComputedDateInterval() {
            if (this.start == null || this.end == null) return ''
            return `Cводка: ${this.start.format("DD.MM.YYYY HH:mm:ss")} - ${this.end.format("DD.MM.YYYY HH:mm:ss")}`
        },
        totalRequestedQuantityInPage() {

            return this.total(this.$refs.table_transactions.computedItems, 'requestQuantity', 100, true)
        },
        totalQuantityReleasedInPage() {

            return this.total(this.$refs.table_transactions.computedItems, 'quantity', 100, true)
        },

        CaptionText() {
            return `Показано с ${this.currentPage * this.perPage - this.perPage + 1} по ${this.currentPage * this.perPage <= this.rows ? this.currentPage * this.perPage : this.rows} из ${this.rows} записей`
        },
        WalletsOfCards() {
            return this.AllWalletsSelect.filter(wallet => this.filterObject.selected_cards.some(card => { return card === this.WalletByGUID(wallet.value)?.cardID }))
        },
        rows() {
            if (this.filtered_items.length == 0)
                return this.items.length
            else {
                return this.filtered_items.length;
            }
        },
        ...mapGetters([
            'AllCards',
            'AllOrganizations',
            'AllWallets',
            'AllTerminals',
            'CardByGUID',
            'WalletByGUID',
            'UserByGUID',
            'ProductByGUID',
            'OrganizationByGUID',
            'CardsGetHaveError',
            'ProductsGetHaveError',
            'TerminalsGetHaveError',
            'OrganizationsGetHaveError',
            'WalletsGetHaveError',
            'TerminalByGUID',
            'CardsOnceLoaded',
            'OrganizationsOnceLoaded',
            'UsersOnceLoaded',
            'TerminalsOnceLoaded',
            'CardsOnceLoaded',
            'ProductsOnceLoaded', ,
            'DriverByGUID',
            'DriversOnceLoaded',
            'DriverByNumber',
            'AllTransactionsTypeSelect'
        ]),

        DtimeIsNULL() {
            return this.start == null ? true : false
        },
        AllCardsSelect() {
            return this.AllCards.map(element => {
                var text = element.holderName + ' ' + '(' + this.NumberSpace(element.number) + ')'
                return {
                    text,
                    value: element.id,
                    number: element.number,
                    holderName: element.holderName,
                    isRemoved: element.isRemoved,
                }
            })

        },
        AllTerminalsSelect() {
            return this.AllTerminals.map(element => {
                var imei = element.imei != null ? `<br> IMEI: ${element.imei}` : ``
                var text = element.name + imei;
                return {
                    text: text,
                    value: element.id,
                    isRemoved: element.isRemoved,
                }
            })
        },
        AllOrganizationsSelect() {
            return this.AllOrganizations.filter(item => !item.isRemoved).map(element => {
                return {
                    text: element.name,
                    value: element.id,
                    isRemoved: element.isRemoved,
                }
            })
        },
        AllWalletsSelect() {
            return this.Wallets.map(element => {
                var text = element.product + `<br> Наименование: ${element.name}`
                return {
                    text: text,
                    value: element.id,
                    isRemoved: element.isRemoved,
                }
            })
        },
    },
    async created() {
        if (!this.CardsOnceLoaded) {
            await this.$store.dispatch(CARDS_GET_REQUEST);
        }
        if (!this.UsersOnceLoaded) {
            await this.$store.dispatch(USERS_GET_REQUEST);
        }
        if (!this.OrganizationsOnceLoaded) {
            await this.$store.dispatch(ORGANIZATIONS_GET_REQUEST);
        }
        if (!this.WalletsOnceLoaded) {
            await this.$store.dispatch(WALLETS_GET_REQUEST);
        }
        if (!this.TerminalsOnceLoaded) {
            await this.$store.dispatch(TERMINALS_GET_REQUEST);
        }
        if (!this.ProductsOnceLoaded) {
            await this.$store.dispatch(PRODUCTS_GET_REQUEST);
        }
        if (!this.DriversOnceLoaded) {
            await this.$store.dispatch(DRIVERS_GET_REQUEST);
        }
    },
    watch: {
        filterObject: {
            handler: function () {
                this.SetFieldsFilter();
            },
            deep: true
        },
        start() { this.SetFieldsFilter() },
        selected_field: function (new_val) {
            if (new_val.length == 1) {
                //var a= this.base_fields.find(el=>el.key==new_val[0]).disabled=true;
                this.base_fields.find(el => el.key == new_val[0]).disabled = true;
            }
            else {

                this.base_fields.filter(a => a.disabled == true).forEach(b => { if (b.key != 'index') b.disabled = false })
            }
            this.fields = this.base_fields.filter(field => new_val.some(el => el == field.key))
            this.SetFieldsFilter();
        },
        number: function () {
            if (this.number != null) {
                let realNumber = this.number.replace(/\s/gi, '')
                // Generate dashed number
                let dashedNumber = realNumber.match(/.{1,4}/g)
                // Replace the dashed number with the real one
                if (dashedNumber != null) this.number = dashedNumber.join(' ')
            }
        },
    },
    beforeDestroy() {
        eventBus.$off('New_Transaction');
    }
    ,
    mounted() {
        feather.replace();



        this.InitializingFields();
        this.InitializingFilter();

        eventBus.$on('New_Transaction', (message) => {
            this.GET_TRANSACTIONS();
        })

    },
    components: {
        LMap, LTileLayer, LMarker, LPopup
    },

    methods: {
        async ToltipShow(event, lat, lng) {

            if (this.LatLngNotNull(lat, lng)) {
                this.mapIsLoading = true;
                await this.GetGeocodingText(lat, lng);
                this.mapIsLoading = false
            }
            else
                event.preventDefault();

        },

        async GetGeocodingText(lat, lng) {
            var vm = this;

            await axios.get(`https://nominatim.openstreetmap.org/search.php?q=${lat},${lng}&polygon_geojson=1&format=json`).then(
                resp => {
                    vm.geocodingText = resp.data[0]?.display_name
                })
                .catch(() => { vm.geocodingText = "" });


        },
        GetLatLng(lat, lng) {
            return [lat, lng]
        },
        LatLngNotNull(lat, lng) {
            return (lat != 0 && lng != 0) && (lat != null && lng != null);
        },
        SetFieldsFilter() {
            var obj = {

                selected_field: this.selected_field,
                filterObject: this.filterObject,
                //start:  this.start.format("YYYY-MM-DD[T]HH:mm:ss"),
                // end:   this.end.format("YYYY-MM-DD[T]HH:mm:ss"),
            }

            sessionStorage.setItem("SavedFieldsFilter", JSON.stringify(obj));

        },
        GetFieldsFilter() {
            var item = sessionStorage.getItem("SavedFieldsFilter")
            if (item == null && item == undefined) {

                return null
            }
            else {
                var obj = JSON.parse(item);
                return obj;
            }
        },

        async Refresh() {
            if (!this.CardsOnceLoaded) {
                await this.$store.dispatch(CARDS_GET_REQUEST);
            }
            if (!this.UsersOnceLoaded) {
                await this.$store.dispatch(USERS_GET_REQUEST);
            }
            if (!this.OrganizationsOnceLoaded) {
                await this.$store.dispatch(ORGANIZATIONS_GET_REQUEST);
            }
            if (!this.WalletsOnceLoaded) {
                await this.$store.dispatch(WALLETS_GET_REQUEST);
            }
            if (!this.TerminalsOnceLoaded) {
                await this.$store.dispatch(TERMINALS_GET_REQUEST);
            }
            if (!this.ProductsOnceLoaded) {
                await this.$store.dispatch(PRODUCTS_GET_REQUEST);
            }
            if (!this.DriversOnceLoaded) {
                await this.$store.dispatch(exp.DRIVERS_GET_REQUEST);
            }
            this.GET_TRANSACTIONS()
        },
        InitializingFields() {
            this.fields = this.base_fields.filter(a => a.key != "receiveUTC" && a.key != "servicedNumber" && a.key != "LatLong" && a.key != "carNumber" && a.key != "driverID" && a.key != 'totalStartValue' && a.key != 'totalEndValue' && a.key != 'pumpID' && a.key != 'channelNum')
            var item = this.GetFieldsFilter();
            if (item == null || item?.selected_field == null) {
                this.selected_field = this.fields.map(el => el.key);
            }
            else {

                this.selected_field = item.selected_field;
            }
        },
        InitializingFilter() {
            ///Инициализация фильтра
            var item = this.GetFieldsFilter();
            if (item == null || item?.filterObject == null) {
                this.filterObject = Object.assign({}, this.defaultFilterObject);
            }

            else {

                this.filterObject = item.filterObject;
            }
            this.start = this.defaultStartDate;
            this.end = this.defaultEndDate;
            this.GET_TRANSACTIONS();
        },
        ToggleFilter() {
            this.filterOpen = !this.filterOpen
        },
        total(items, key, determinator = undefined, need_fixed = false) {
            var sum = 0;
            for (var i = 0; i < items.length; i++) {
                if (items[i][key] != null) {
                    if (determinator != undefined)
                        sum += items[i][key] / determinator;
                }
            }
            return need_fixed ? sum.toFixed(2) : sum;
        },
        Search(query) {
            return this.AllCardsSelect;
        },
        searchChange(query, select$) {
            this.cardsFilter = query;
        },
        CardsFilter(option, query) {
            if (this.cardsFilter == "" || this.cardsFilter == null) return true;
            else {


                if (option.holderName.toLowerCase().indexOf(this.cardsFilter.toLowerCase()) > -1 && option.holderName.toLowerCase()[0] == this.cardsFilter.toLowerCase()[0]) return true;
                if (option.number.split(' ').join('').toLowerCase().indexOf(this.cardsFilter.split(' ').join('').toLowerCase()) > -1 /*&& row.number.toLowerCase()[0] == filter.toLowerCase()[0]*/) return true;
                return false
            }

        },
        PrepareReport(report_type) {
            this.loading_report = true;
            this.report_type = report_type;
            setTimeout(() => {
                this.GET_REPORT(report_type);
            }, 1000);
        },


        onFiltered(filteredItems) {
            this.filtered_items = filteredItems;
            this.currentPage = 1;
        },
        clear() {
            this.filter = "";
        },
        filterTable(row, filter) {
            if (filter.length == 0 || filter.length == 1) return true
            // if (row.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 && row.number.toLowerCase()[0] == filter.toLowerCase()[0]) return true;
            // if (row.organizationID.toLowerCase().indexOf(filter.toLowerCase()) > -1 && row.imei.toLowerCase()[0] == filter.toLowerCase()[0]) return true;
            else return false
        },
        change_cards() {
            if (this.filterObject.selected_wallets.length == 0 || this.filterObject.selected_cards.length == 0) {
                this.GET_TRANSACTIONS()
            } else {
                this.filterObject.selected_wallets = this.filterObject.selected_wallets.filter(wallet => this.filterObject.selected_cards.some(card => card /*.id*/ === this.WalletByGUID(wallet /*.id*/)?.cardID))
                this.GET_TRANSACTIONS();
            }
        },
        WalletsName(elem) {
            var Wallet = this.WalletByGUID(elem.id);
            return `${this.CardByGUID(Wallet?.cardID)?.holderName ?? ''} [${elem.text} (${this.ProductByGUID(Wallet?.productID)?.hardwareName ??
                ''})]`
        },
        GET_REPORT(type) {

            {
                var need_footer = false;
                var utc, receiveUTC, utcEnd, id, terminal, LatLong, card, wallet, driver, organization, user, carNumber, quantity, requestQuantity, transactionType, totalStartValue, totalEndValue, servicedNumber, pumpID, channelNum;
                var header = this.fields.map(element => {
                    if (element.key == 'quantity' || element.key == 'requestQuantity') need_footer = true;
                    return element.label
                })
                var k = 0;

                var data_item = this.$refs.table_transactions.sortedItems.map(element => {
                    k += 1;
                    if (this.fields.find(el => el.key == "utc") != undefined)
                        utc = moment(element.utc).locale('ru').format('DD.MM.YYYY/HH:mm:ss')
                    else utc = null
                    if (this.fields.find(el => el.key == "utcEnd") != undefined)
                        utcEnd = moment(element.utcEnd).locale('ru').format('DD.MM.YYYY/HH:mm:ss');
                    else utcEnd = null
                    if (this.fields.find(el => el.key == "receiveUTC") != undefined)
                        receiveUTC = moment(element.receiveUTC).locale('ru').format('DD.MM.YYYY/HH:mm:ss');
                    else receiveUTC = null
                    if (this.fields.find(el => el.key == "id") != undefined)
                        id = element.id
                    else id = null
                    if (this.fields.find(el => el.key == "terminalID") != undefined) {
                        var trm = this.TerminalByGUID(element.terminalID);
                        if (trm == null || trm == undefined) {
                            terminal = `-`;
                        }
                        else {
                            if (!trm.isRemoved)
                                terminal = `${trm.name}\nIMEI:${trm.imei}`;
                            else terminal = `${trm.name} (удалён)`
                        }
                    }
                    else terminal = null
                    if (this.fields.find(el => el.key == "LatLong") != undefined) { LatLong = element?.latitude != null && element?.longitude != null && element.transactionType == 0 ? `Широта:${element.latitude.toFixed(2)}, Долгота:${element.longitude.toFixed(2)}` : "-" }
                    else LatLong = null
                    if (this.fields.find(el => el.key == "driverID") != undefined) {
                        if (element.transactionType == 0) {
                            var drv = this.DriverByGUID(element.driverID)
                            if (drv != null && drv != undefined)

                                driver = drv.name + `${drv.isRemoved ? ' (удален)' : ''}` + `, Карта: ${this.NumberSpace(drv.number)}` + `${drv.pin != null ? ', Код: ' + drv.pin : ''}`;
                            else { driver = 'Неизвестный водитель' }
                        }
                        else driver = '-';

                    }
                    else driver = null


                    if (this.fields.find(el => el.key == "cardID") != undefined) {
                        var crd = this.CardByGUID(element.cardID)
                        if (crd != null && crd != undefined)

                            card = this.NumberSpace(crd.number) + `${crd.isRemoved ? ' (удалена)' : ''}`;
                        else if (element.transactionType == 2 && element.cardNumber != null) {
                            card = this.NumberSpace(element.cardNumber);

                        }
                        else
                            card = `Неизвестная карта`
                    }
                    else card = null
                    if (this.fields.find(el => el.key == "servicedNumber") != undefined)
                        servicedNumber = element.servicedNumber ?? "-"
                    else servicedNumber = null
                    if (this.fields.find(el => el.key == "user") != undefined) {
                        if (element.transactionType != 2)
                            user = this.CardByGUID(element.cardID)?.holderName ?? "-";
                        else user = `-`;
                    }
                    else user = null
                    if (this.fields.find(el => el.key == "carNumber") != undefined) {
                        if (element.transactionType == 0)
                            carNumber = element.carNumber != null && element.carNumber != 0 ? element.carNumber : "-";
                        else carNumber = `-`
                    }
                    else carNumber = null

                    if (this.fields.find(el => el.key == "walletID") != undefined) {

                        var wallet = this.Wallets?.find(a => a.id == element.walletID) ?? null;
                        if (wallet != null) {
                            wallet = wallet.product + ` (${wallet.name})` + `${wallet.isRemoved ? ' (удалён)' : ''}`
                        }
                        else if (element.transactionType == 2) wallet = `-`;
                        else wallet = `Неизвестный кошелёк`
                    }
                    else wallet = null
                    if (this.fields.find(el => el.key == "organizationID") != undefined)
                        organization = this.OrganizationByGUID(element.organizationID)?.name ?? "-";
                    else organization = null
                    if (this.fields.find(el => el.key == "quantity") != undefined) {
                        if (element.transactionType < 2)
                            quantity = (element.quantity / 100).toFixed(2).toString() //+ ' л.'
                        else quantity = `-`;
                    }
                    else quantity = null
                    if (this.fields.find(el => el.key == "requestQuantity") != undefined) {
                        if (element.transactionType == 0)
                            requestQuantity = (element.requestQuantity / 100).toFixed(2).toString() //+ ' л.'
                        else requestQuantity = '-';
                    }
                    else requestQuantity = null
                    if (this.fields.find(el => el.key == "transactionType") != undefined) {
                        switch (element.transactionType) {
                            case -1:
                                transactionType = 'Возврат'
                                break;
                            case 0:
                                transactionType = 'Cписание'
                                break;
                            case 1:
                                transactionType = 'Пополнение'
                                break;
                            case 2:
                                transactionType = 'Выпуск карты'
                                break;
                        }
                    }
                    else transactionType = null
                    if (this.fields.find(el => el.key == "totalStartValue") != undefined) {
                        if (element.transactionType == 0)
                            totalStartValue = element.totalStartValue ? element.totalStartValue : '-';
                        else totalStartValue = `-`
                    }
                    else totalStartValue = null
                    if (this.fields.find(el => el.key == "totalEndValue") != undefined) {
                        if (element.transactionType == 0)
                            totalEndValue = element.totalEndValue != null ? element.totalEndValue : '-';
                        else totalEndValue = `-`
                    }
                    else totalEndValue = null
                    if (this.fields.find(el => el.key == "pumpID") != undefined) {
                        if (element.transactionType == 0)
                            pumpID = element.pumpID != null ? parseInt(element.pumpID + 1) : '-';
                        else pumpID = `-`
                    }
                    else pumpID = null
                    if (this.fields.find(el => el.key == "channelNum") != undefined) {
                        if (element.transactionType == 0)
                            channelNum = element.channelNum != null ? parseInt(element.channelNum + 1) : '-';
                        else channelNum = `-`

                    }
                    else channelNum = null
                    return [k, id, transactionType, utc, utcEnd, receiveUTC, terminal, LatLong, card, servicedNumber, driver, user, carNumber, wallet, organization, requestQuantity, quantity, totalStartValue, totalEndValue, pumpID, channelNum]
                })
                for (var i = 0; i < data_item.length; i++) {
                    data_item[i] = data_item[i].filter(a => {
                        return a != null
                    })
                }
                var start_date = this.start.locale('ru').format('DD.MM.YYYY/HH:mm:ss');
                var end_date = this.end.locale('ru').format('DD.MM.YYYY/HH:mm:ss');

                var footer = null
                if (need_footer) {
                    footer = [];
                    for (var i = 0; i < this.fields.length; i++) {
                        if (this.fields[i].key == 'index')
                            footer.push('Итого: ')
                        else if (this.fields[i].key == 'quantity')
                            footer.push(this.total(this.items, 'quantity', 100, true))
                        else if (this.fields[i].key == 'requestQuantity')
                            footer.push(this.total(this.items, 'requestQuantity', 100, true))
                        else footer.push("");

                    }
                }
                switch (type) {
                    case "PDF":
                        if (helper.MAKE_PDF(data_item, header, `Отчёт с ${start_date} по ${end_date}`, footer)) {
                            this.$nextTick(() => { this.loading_report = false; })
                        }
                        break;
                    case "EXCEL":
                        if (helper.MAKE_xlsx(data_item, header, `Отчёт с ${start_date} по ${end_date} `, footer)) {
                            this.$nextTick(() => { this.loading_report = false; })
                        } break;
                    case "CSV":
                        if (helper.MAKE_сsv(data_item, header, `Отчёт с ${start_date} по ${end_date} `, footer)) {
                            this.$nextTick(() => { this.loading_report = false; })
                        }
                        break;
                }
                this.report_type = null;
            }

        },
        NumberSpace(card_number) {
            if (card_number != undefined && card_number != null)
                return card_number.match(/.{1,4}/g).join(' ')
            else return ``
        },
        //Только номер 
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                $event.preventDefault();
            }
        },
        clearSelectedCardsTags() {
            this.filterObject.selected_cards = [];
            this.GET_TRANSACTIONS()
        },
        clearSelectedTerminalsTags() {
            this.filterObject.selected_terminals = [];
            this.GET_TRANSACTIONS()
        },
        clearSelectedWalletsTags() {
            this.filterObject.selected_wallets = [];
            this.GET_TRANSACTIONS()
        },
        clearSelectedOrganizationsTags() {
            this.filterObject.selected_organizationsganizations = [];
            this.GET_TRANSACTIONS()
        },
        async GET_TRANSACTIONS() {
            let body = {
                "terminalIDs": this.filterObject.selected_terminals, //.map(p => p.id),
                "cardIDs": this.filterObject.selected_cards, //.map(p => p.id),
                "walletIDs": this.filterObject.selected_wallets, //.map(p => p.id),
                "organizationIDs": this.filterObject.selected_organizationsganizations, //.map(p => p.id)
                "types": this.filterObject.selected_types,
            }
            this.loading_transactions = true;
            await this.$store.dispatch(TRANSACTIONS_GET_BY_DATETIME_REQUEST, {
                time: {
                    '_start': this.start.format("YYYY-MM-DD[T]HH:mm:ss"),// moment(this.start.slice(0, -1)).utc().format("YYYY-MM-DD[T]HH:mm:ss"),
                    '_end': this.end.format("YYYY-MM-DD[T]HH:mm:ss"),// moment(this.end.slice(0, -1)).utc().format("YYYY-MM-DD[T]HH:mm:ss"),
                },
                body
            }).then(
                el => {
                    this.items = el.data
                    this.loading_transactions = false;
                    this.empty_text = "Не найдено"
                }
            ).catch(err => {
                this.loading_transactions = false;

            })
        },
        setDateTime(e) {
            this.start = e.startDTime;
            this.end = e.endDTime;
            this.GET_TRANSACTIONS();
            //this.empty_text = ""
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
    },
}
</script>
<style>

.smooth-fade .tooltip {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Увеличение времени и плавности перехода */
}

.map-tooltip {
    opacity: 1 !important;
}

.map-tooltip .tooltip-inner {
    max-width: none;
    padding: 0;
}

.driver-filter--content {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: auto;
    flex-direction: column;
}

#table_transactions tfoot {
    position: sticky;
    bottom: 0;
}
</style>