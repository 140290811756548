import { render, staticRenderFns } from "./IssuedCards.vue?vue&type=template&id=15ee71be&"
import script from "./IssuedCards.vue?vue&type=script&lang=js&"
export * from "./IssuedCards.vue?vue&type=script&lang=js&"
import style0 from "./IssuedCards.vue?vue&type=style&index=0&id=15ee71be&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports