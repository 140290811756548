<template>
    <div class="content-body p-0" id="mapContainer" >
        <l-map ref="map" style=" z-index:1"  class=" custom-popup"
            :options="{ attributionControl: false }" :attribution="attribution" :zoom="zoom" :center="center"
            @ready="SetMap()">
            <l-tile-layer :url="url" :attribution="attribution" :subdomains="subdomains" :maxZoom="20"></l-tile-layer>
            <!--  <l-control-attribution position="bottomright"
                prefix='<a href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>'></l-control-attribution>-->
            <l-feature-group ref="features">
                <l-marker-cluster>
                    <l-marker @click=" GetGeocodingText(item)"
                        v-for="item in AllTerminals.filter(a => !a.isRemoved)"
                        v-if="LatLngNotNull(item.latitude, item.longitude)"
                        :icon="item.online ? trk_icon_green : trk_icon_red" :key="item.id"
                        :lat-lng="GetLatLng(item.latitude, item.longitude)" @l-add="$event.target.openPopup()">
                        <l-tooltip :options="{ 'permanent': true, 'offset': [15, 0] }"><b>{{ item.name }}</b></l-tooltip>

                        <l-popup :options="options_popup">
                            <div v-if="!item.online" class="terninal_status_offline">
                                <span v-html="OffTime(item.lastConnectionDateTime)"> </span>
                            </div>
                            <div class="popup__head d-flex align-items-center" style=" width: 90%;     padding: 0.5em 0;">
                                <div class="current_status">
                                    <b-icon v-if="item.online" icon="circle-fill" style="color: #48cb3f;" font-scale="2"
                                        title="Включен">
                                    </b-icon>
                                    <b-icon v-if="!item.online" icon="circle-fill" style="color: #000;" font-scale="2"
                                        title="Выключен"></b-icon>
                                </div>
                                <div class="first__info " style="  padding:0 0.5em; overflow: hidden; min-width: 100%;">
                                    <div class="d-flex align-items-center" style="border-bottom: 1px dotted #fff; gap:10px">
                                        <div style="flex: 0 0 auto; border-right:1px dotted #fff;padding: 0 0.2em;">
                                            {{ item.name }} </div>
                                        <div style="flex: 0 1 auto; text-overflow: ellipsis;
                                    white-space: nowrap;
                                    font-size: 13px;
                                    overflow: hidden;
                                    transition: 0.3s;
                                    " class="current_coordinates">
                                            {{ text_from_coordinates.find(elem => elem.imei == item.imei)?.text }}</div>
                                    </div>
                                    <div style="padding: 0 0.2em;"> IMEI: {{ item.imei }} </div>
                                </div>
                            </div>
                            <div>
                                <div class="popup__center d-flex align-items-start text-center ">
                                    <div style="flex: 1 1 0"><span>Тип
                                            ТРК:</span><br /><span>{{ GetType(item.terminalConfiguration.pumps[0].type)
                                            }}</span>
                                    </div>
                                    <div style="flex: 1 1 45px"><span>Количество
                                            ТРК</span><br /><span>{{ item.terminalConfiguration.pumps.length + ' шт.'
                                            }}</span>
                                    </div>
                                    <div style="flex: 1 1 0"><span>Доза
                                            ПБ</span><br /><span>{{
                                                GetFullTankVolume(item.terminalConfiguration.fullTankVolume)
                                            }}</span>
                                    </div>
                                </div>
                                <div class="popup__organizations d-flex align-items-center text-start" style="">
                                    <div style="flex: 0 0 100px">
                                        Подразделения:
                                    </div>
                                    <div style="flex: 1 1 100%" class="text-start">
                                        <span :class="{ 'text-decoration-line-through text-danger': item.isRemoved }"
                                            v-if="item.organizationIds?.length == 0 || item.organizationIds == null">Нет
                                            прикреплённых подразделений</span>
                                        <span :class="{ 'text-decoration-line-through text-danger': item.isRemoved }"
                                            v-if="item.organizationIds?.length == 1"><span
                                                class="badge rounded-pill bg-light text-dark">{{
                                                    OrganizationByGUID(item.organizationIds[0])?.name }}</span></span>
                                        <span :class="{ 'text-decoration-line-through text-danger': item.isRemoved }"
                                            v-if="item.organizationIds != null && item.organizationIds?.length > 1"><span
                                                class="badge rounded-pill bg-light text-dark">{{
                                                    OrganizationByGUID(item.organizationIds[0])?.name }}</span>
                                            и {{ (Number(item.organizationIds.length) - 1) }} ещё </span>
                                    </div>
                                </div>
                                <div class="popup__blockedcards d-flex align-items-center text-start" style="">
                                    <div style="flex: 0 0 100px">
                                        Блок. карты:
                                    </div>
                                    <div style="flex: 1 1 100%" class="text-start">
                                        <span :class="{ 'text-decoration-line-through text-danger': item.isRemoved }"
                                            v-if="item.blackList?.length == 0 || item.blackList == null">Нет заблокированных
                                            карт</span>
                                        <span :class="{ 'text-decoration-line-through text-danger': item.isRemoved }"
                                            v-if="item.blackList?.length == 1"><span
                                                class="badge rounded-pill bg-light text-dark">{{
                                                    NumberSpace(CardByGUID(item.blackList[0])?.number) }}</span></span>
                                        <span :class="{ 'text-decoration-line-through text-danger': item.isRemoved }"
                                            v-if="item.blackList != null && item.blackList?.length > 1"><span
                                                class="badge rounded-pill bg-light text-dark">{{
                                                    NumberSpace(CardByGUID(item.blackList[0])?.number) }}</span>
                                            и {{ (Number(item.blackList.length) - 1) }} ещё </span>
                                    </div>
                                </div>
                            </div>
                        </l-popup>
                    </l-marker></l-marker-cluster>
            </l-feature-group>
        </l-map>
    </div>
</template>
<script>
import {
    feather
} from '@/main.js';
import {
    latLngBounds,
    latLng,
} from "leaflet";
import {
    mapActions
} from 'vuex'
import {
    eventBus
} from '@/main.js'
import {
    mapGetters
} from 'vuex'
import axios from 'axios'
import {
    textC_alignM,
    textL_alignM
} from '@/helpers/const'
import {
    LMap,
    LFeatureGroup,
    LTileLayer,
    LMarker,
    LTooltip,
    LPopup,
    LControlAttribution
} from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => {
    window[name] = exported;
});
export default {
    components: {
        'l-marker-cluster': Vue2LeafletMarkerCluster,
        LTooltip,
        LMap,
        LFeatureGroup,
        LTileLayer,
        LMarker,
        LPopup,
        LControlAttribution,
    },
    beforeDestroy() {
        clearInterval(this.terminal_interval)
    },
    data() {
        return {
            needBounds: true,
            terminal_interval: null,
            //NEW
            map: null,
            bounds: latLngBounds([54.501707, 36.313740], [54.501707, 36.313740]),
            url: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            //https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
            center: [54.501707, 36.313740 /*54.501833, 36.312913*/],
            zoom: 6,

            options_popup: {
                offset: [140, -5],
                maxWidth: 400,
                minWidth: 350
            },
            trk_icon_red: L.icon({
                iconUrl: `/assets/images/leaflet_images/trk-icon_red.svg`,
                iconSize: [30, 40], // size of the icon
                shadowSize: [25, 45], // size of the shadow
            }),
            trk_icon_green: L.icon({
                iconUrl: `/assets/images/leaflet_images/trk-icon_green.svg`,
                iconSize: [30, 40], // size of the icon
                shadowSize: [25, 45], // size of the shadow
            }),
            text_from_coordinates: [],
            //Тип действия
            action: {
                type: null,
                method: null,
            },
        }
    },
    async created() {
        var vm = this;
        if (!this.TerminalsOnceLoaded) {
            await this.$store.dispatch(TERMINALS_GET_REQUEST);
        }
        if (!this.ProductsOnceLoaded) {
            await this.$store.dispatch(PRODUCTS_GET_REQUEST);
        }
        if (!this.OrganizationsOnceLoaded) {
            await this.$store.dispatch(ORGANIZATIONS_GET_REQUEST);
        }
        if (!this.CardsOnceLoaded) {
            await this.$store.dispatch(CARDS_GET_REQUEST);
        }
        this.terminal_interval = setInterval(() => {
            this.$store.dispatch(TERMINALS_GET_REQUEST);
        }, 60000 * 2);
    },
    computed: {
        ...mapGetters([
            'AllTerminals',
            'TerminalByGUID',
            'TerminalsOnceLoaded',
            'TerminalsGetAreLoading',
            'TerminalsGetHaveError',
            'TerminalsPostAreLoading',
            'TerminalsPostHaveError',
            'TerminalsPutAreLoading',
            'TerminalsPutHaveError',
            'TerminalsDeleteAreLoading',
            'AllOrganizations',
            'OrganizationByGUID',
            'OrganizationsOnceLoaded',
            'OrganizationsOnceLoaded',
            'OrganizationsGetAreLoading',
            'OrganizationsGetHaveError',
            'OrganizationsPostAreLoading',
            'OrganizationsPostHaveError',
            'OrganizationsPutAreLoading',
            'OrganizationsPutHaveError',
            'OrganizationsDeleteAreLoading',
            'OrganizationsTerminals',
            'CardByGUID',
            'AllCards',
            'CardsOnceLoaded',
            'CardsGetAreLoading',
            'CardsGetHaveError',
            'CardsPostAreLoading',
            'CardsPostHaveError',
            'CardsPutAreLoading',
            'CardsPutHaveError',
            'CardsDeleteAreLoading',
            'BlackListCards',
            'WalletByGUID',
            'AllProducts',
            'ProductsOnceLoaded',
            'ProductsGetAreLoading',
            'ProductsGetHaveError',
        ]),
    },
    async mounted() {
        L.Icon.Default.imagePath = '/assets/images/leaflet_images/';

        feather.replace();
        if (this.AllTerminals.length != 0) {
            var coordinats = this.AllTerminals.filter(m => m.latitude > 5 && m.longitude > 5).map(m => {
                return [m.latitude, m.longitude]
            });
            if (coordinats.length != 0 && this.needBounds) {
                this.$refs.map.mapObject.fitBounds(coordinats);
                this.needBounds = false;
            }
            // await this.GetGeocodingText(this.AllTerminals)
        }
    },
    watch: {
        AllTerminals: async function (new_val, old) {
            var coordinats = new_val.filter(m => m.latitude > 5 && m.longitude > 5).map(m => {
                return [m.latitude, m.longitude]
            });
            if (coordinats.length != 0 && this.needBounds) {
                this.$refs.map.mapObject.fitBounds(coordinats)
                this.needBounds = false;

            }
            //  await this.GetGeocodingText(new_val);
        }
    },
    methods: {
        /*    async GetGeocodingText(terminals) {
                var vm = this;
                for (var i = 0; i < terminals.length; i++)
                    if (this.LatLngNotNull(terminals[i].latitude, terminals[i].longitude))
                       
                        await axios.get(`https://nominatim.openstreetmap.org/search.php?q=${terminals[i].latitude},${terminals[i].longitude}&polygon_geojson=1&format=json`).then(
                            resp => {
                                var temp = vm.text_from_coordinates.find(elem => elem.imei == terminals[i].imei);
                                if (temp != undefined) temp.text = resp.data[0]?.display_name
                                else vm.text_from_coordinates.push({
                                    text: resp.data[0]?.display_name,
                                    imei: terminals[i].imei
                                });
                                //  element.text = resp[0].display_name;
                            })
            },*/
        async GetGeocodingText(terminal) {
            var vm = this;
            if (this.LatLngNotNull(terminal.latitude, terminal.longitude))
                /*  var resp
                  resp = [{ "place_id": 181557124, "licence": "Data © OpenStreetMap contributors, ODbL 1.0. https://osm.org/copyright", "osm_type": "way", "osm_id": 314524990, "boundingbox": ["58.1028835", "58.1195925", "36.7115498", "36.7344219"], "lat": "58.1081622", "lon": "36.7255483", "display_name": "Белево, Молоковский муниципальный округ, Тверская область, Центральный федеральный округ, Россия", "class": "highway", "type": "unclassified", "importance": 0.001, "geojson": { "type": "LineString", "coordinates": [[36.7344219, 58.1195925], [36.7339448, 58.1182391], [36.7332924, 58.116732], [36.7326218, 58.1149979], [36.7324603, 58.1147593], [36.7321953, 58.1145647], [36.731844, 58.1143553], [36.7295222, 58.112006], [36.7283174, 58.1109754], [36.7259404, 58.1087014], [36.7255483, 58.1081622], [36.7248756, 58.1075525], [36.7200927, 58.1050059], [36.7153467, 58.1036101], [36.7144025, 58.1033153], [36.7135979, 58.1032303], [36.7125035, 58.1030999], [36.7115498, 58.1028835]] } }]
                  var temp = vm.text_from_coordinates.find(elem => elem.imei == terminals[i].imei);
                  if (temp != undefined) temp.text = resp[0]?.display_name
                  else vm.text_from_coordinates.push({ text:resp[0]?.display_name, imei: terminals[i].imei });*/
                await axios.get(`https://nominatim.openstreetmap.org/search.php?q=${terminal.latitude},${terminal.longitude}&polygon_geojson=1&format=json`).then(
                    resp => {
                        var temp = vm.text_from_coordinates.find(elem => elem.imei == terminal.imei);
                        if (temp != undefined) temp.text = resp.data[0]?.display_name
                        else vm.text_from_coordinates.push({
                            text: resp.data[0]?.display_name,
                            imei: terminal.imei
                        });
                        //  element.text = resp[0].display_name;
                    })
        },
        GetFullTankVolume(fullTankVolume) {
            if (Number(fullTankVolume) == 0) return "-"
            else return fullTankVolume + ' л.'
        },
        LatLngNotNull(lat, lng) {
            return (lat != 0 && lng != 0) && (lat != null && lng != null);
        },
        NumberSpace(card_number) {
            if (card_number != undefined) return card_number.match(/.{1,4}/g).join(' ')
        },
        GetType(TYPE_NUMBER) {
            switch (TYPE_NUMBER) {
                case 0:
                    return "АЗТ 2.0";
                case 1:
                    return "SANKI";
                default:
                    return 'АЗТ 2.0'
            }
        },
        SetMap() {
            this.map = this.$refs.map.mapObject
        },
        OffTime(elem) {
            //window.momentDurationFormatSetup();
            moment.locale('ru');
            var now = moment();
            var before = moment(elem);
            var text = moment.duration(now.diff(before)).format('Y[ лет.] D[ дн.] H[ ч.] m[ м.] s[ сек.]');
            var text1 = "Выкл. " + text + ". c" + before.format(" DD.MM.YY/HH:mm:ss");
            return text1;
        },
        GetLatLng(lat, lng) {
            return [lat, lng]
        }
    },
}
</script>
<style>
.body-map {
    padding: 20px 0px 0px 0px !important;
}

 

#mapContainer {
     width: 100%;
     height: calc(100vh - 80px - 35px)
}

@media screen and (max-width: 767px) {
    .body-map {
        padding: 0px 0px 0px 0px !important;
    }

    .terminal-map {      
        height: calc(100vh - 42px - 35px)

     }
}

/*---------------leaflet_map--------------*/


/*---------------leaflet_map--------------*/
/*Leflet*/
.current_coordinates:hover {
    text-align: justify;
    border-radius: 10px;
    background-color: #eeeeee;
    padding: 5px;
    color: #000;
    top: 5px;
    width: 80% !important;
    box-shadow: rgb(50 50 93 / 25%) 0px 30px 60px -12px, rgb(0 0 0 / 30%) 0px 18px 36px -18px;
    white-space: normal !important;
    position: absolute;
    line-height: 1.2;
    font-size: 13px;
    transition: all;
}

.leaflet-popup-close-button {
    color: #fff !important;
    width: 30px;
}

.terninal_status_offline {
    float: left;
    position: absolute;
    top: -20px;
    left: 4px;
    text-align: center;
    line-height: 20px;
    font-size: 0.8rem;
    text-shadow: 1px 1px 10px #fffda0, -1px -1px 5px #fffda0;
    color: #FF0000;
}

.custom-popup .leaflet-popup-content-wrapper {
    /* width: 350px; */
    color: #fff !important;
    font-size: 14px;
    background: rgb(32 36 66 / 61%);
    box-shadow: 1px 1px 2px rgb(0 0 0 / 10%), -1px -1px 2px rgb(0 0 0 / 10%);
    border: 1px solid #33333350;
    line-height: 24px;
    border-radius: 20px;
}

.custom-popup {
    width: 350px;
}

.custom-popup .leaflet-popup-content-wrapper a {
    color: rgba(255, 255, 255, 0.5);
}

.custom-popup .leaflet-popup-tip-container {
    width: 30px;
    height: 15px;
    left: 15% !important;
}

.leaflet-popup-close-button {
    color: #fff
}

.custom-popup .popup__organizations,
.popup__blockedcards {
    background: #eeeeee;
    gap: 5px;
    font-size: 0.9rem !important;
    padding: 10px;
    margin-top: 0.2em;
    color: #2c3e50;

}

.popup__blockedcards {
    margin-bottom: 0.5em;
}

.popup__blockedcards {
    border-radius: 0px 0px 10px 10px;
}

.custom-popup .leaflet-popup-tip {
    background-color: #2c3e50;
}

.custom-popup .leaflet-popup-content {
    margin: 5px 5px 2px 5px;
    line-height: 1.4;
}

.custom-popup .leaflet-popup-close-button {
    color: #fff
}

.custom-popup .popup__center {
    background: #eeeeee;
    gap: 5px;
    font-size: 0.8rem !important;
    color: #2c3e50;
    padding: 0.5em 0 0.5em 0;
    border-radius: 10px 10px 0 0;
}</style>