<template>
    <div class="modal fade" :class="{ 'fade-scale': modalFullscreen, 'modal-blur-backdrop': blur }" :id="id"
        tabindex="-1">
        <div class="modal-dialog " :style="{ width: width != null ? width + 'px' : '' }"
            :class="{ 'modal-dialog-centered': centered, 'modal-dialog-scrollable': modalDialogScrollable, 'modal-lg': modalLg && !loading_spinner && !div_error && !div_succses, 'modal-fullscreen': modalFullscreen }">
            <div class="modal-content" v-if="value">
                <div class="modal-header" v-if="!hideHeader && !div_succses && !div_error && !loading_spinner">
                    <!-- <div class="d-flex justify-content-between align-items-center">-->
                    <slot name="title" class="me-1" v-if="noDefaultTitle"></slot>
                    <h5 class="h5 modal-title me-1" v-else="!noDefaultTitle">{{ title }}</h5>
                    <slot name="headerActions"></slot>
                    <button class="btn-close" v-if="!noCloseButton" @keydown.esc="hide()" @click="hide()"
                        :disabled="loading" aria-label="Close"></button>
                    <!-- </div>-->
                </div>
                <div class="modal-body">
                    <slot v-if="!div_succses && !div_error && !loading_spinner"></slot>
                    <div v-if="!div_succses && !div_error && haveLoading && loading_spinner">
                        <div class="d-flex justify-content-center align-items-center">
                            <strong class="me-1">Загрузка...</strong>
                            <b-spinner class="ml-auto"></b-spinner>
                        </div>

                    </div>
                    <div v-if="div_succses" class="col-12 mb-6">
                        <div class="row mb-n7 align-items-center">
                            <div class="col-12">
                                <div class="sweet-alert">
                                    <div class="icon success animate" style="/* display: none; */">
                                        <span class="line tip animateSuccessTip"></span>
                                        <span class="line long animateSuccessLong"></span>
                                        <div class="placeholder"></div>
                                        <div class="fix"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="h2 text-center"> {{ success_text }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="div_error" class="col-12 mb-6">
                        <div class="row mb-n7 align-items-center">
                            <div class="col-12 align-self-center justify-self-center">
                                <div class="sweet-alert">
                                    <div class="icon error animateErrorIcon" style="/* display: none; */">
                                        <span class="x-mark animateXMark">
                                            <span class="line left"></span>
                                            <span class="line right"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="h2 text-center">{{ title_error }}</div>
                            </div>
                            <div class="col-12 text-center px-2 mb-1" style="font-size: 20px"> {{ error_text }} </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer justify-content-start" v-if="!hideFooter && !div_succses && !div_error">
                    <slot name="footer">
                        <button class="btn btn-sm btn-danger" data-bs-dismiss="modal">Close</button>
                        <button class="btn btn-sm btn-primary">Save changes</button>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { feather } from '@/main';
import { Alert } from 'bootstrap';
export default {
    methods: {
        LoadingFalse() {
            this.loading_spinner = false;
            this.modal._config.backdrop = this.backdrop;
            this.modal._config.keyboard = this.keyboard;
        },
        show(elem) {
             if (elem) { this.elem = elem } else this.elem = null;
            this.div_succses = false;
            this.div_error = false;
            this.error_text = "";
            this.keyboard = this.staticModal ? false : true;
            this.backdrop = this.staticModal ? 'static' : true;
            this.modal._config.backdrop = this.backdrop;
            this.modal._config.keyboard = this.keyboard;


            if (this.haveLoading) {
                this.loading_spinner = true;
                this.modal._config.backdrop = 'static';
                this.modal._config.keyboard = false;
            }
            else {
                this.loading_spinner = false;
                this.modal._config.backdrop = this.backdrop;
                this.modal._config.keyboard = this.keyboard;

            }
            this.modal.show();
        },
     
        hide() {
            this.modal.hide();
            if (this.elem != null) {
                this.elem.vm.$refs[this.elem.name].show();
            }
        },
        hide_simple() {
            this.modal.hide();
        },
        success(elem) {
            this.success_text=this.default_success_text;
            this.modal._config.backdrop = "static";
            this.modal._config.keyboard = false;
            this.div_succses = true;
            var action = null;
            if (typeof elem == 'object' && elem != null) {
                if (elem.hasOwnProperty("action"))
                    action = elem.action
                    if (elem.hasOwnProperty("text"))
                    this.success_text=elem.text;
            }
            else if(elem!=null)
            {
                this.success_text=elem;
            }
            this.modal.show();
            if (action == "stop_hide") {
                setTimeout(() => {
                    this.div_succses = false;
                    this.modal._config.backdrop = this.keyboard;
                    this.modal._config.keyboard = this.keyboard;
                }, 2000)

            }
            else setTimeout(() => this.hide_simple(), 2000)

        },
        error(err) {
            this.title_error = "Ошибка"
            var action = null;
            this.modal._config.backdrop = "static";
            this.modal._config.keyboard = false;

            if (err == undefined) this.error_text = "Ошибка программы";
            else if (typeof err === 'string') {
                this.error_text = err;
            }
            else if (typeof err == 'object' && err != null) {

                if (err.hasOwnProperty("action")) {
                    action = err.action;
                    err = err.err;
                }
                if (err.hasOwnProperty('err')) err = err.err;
                if (err.hasOwnProperty('response')) {

                    var status = err.response.status;
                    var data = err.response.data;
                    if (status) {
                        if (data instanceof Object) {
                            if (data.hasOwnProperty('title')) this.title_error = data.title;
                            if (data.hasOwnProperty('detail')) this.error_text = data.detail;
                        }
                        else if (typeof data === 'string' && data != "") {
                            this.error_text = data;
                        }
                        else {
                            this.error_text = err;
                        }
                    }
                    else { this.error_text = err; }

                }
                else if (err?.code == "ERR_NETWORK") { this.error_text = "Сервер недоступен" }
                else { this.error_text = err; }

            } this.div_error = true;

            if (action == "stop_hide") {
                this.modal.show();

                setTimeout(() => {
                    this.div_error = false;
                    this.modal._config.backdrop = this.keyboard;
                    this.modal._config.keyboard = this.keyboard;
                }, 2000);
            }
            else {
                this.modal.show();
                ; setTimeout(() => this.hide(), 2000)
            }
        }
    },
    mounted() {
        feather.replace();
        this.modal = new bootstrap.Modal(document.getElementById(this.id))
        document.getElementById(this.id).addEventListener('show.bs.modal', event => {
            this.value=true;
            this.$nextTick(()=>this.$emit("show"));//  this.shown();
        })
        document.getElementById(this.id).addEventListener('shown.bs.modal', event => {
             this.$nextTick(()=>this.$emit("shown"));//  this.shown();
        })
     
        document.getElementById(this.id).addEventListener('hidden.bs.modal', event => {
            this.value=false;
             this.$nextTick(()=>this.$emit("hidden"));//  this.shown();
        })

    },
    props: {
        /* shown: {
             default: () => { }, type: Function
         }
         ,*/
        width: { required: false, default: null },
        modalLg: { default: false, type: Boolean },
        noCloseButton: {
            default: false,
            type: Boolean,
        },
        id: {
            type: String,
            required: true
        },
        blur: {
            default: false,
            type: Boolean
        },
        title: {
            default: "",
            required: false,
            type: String
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        centered: {
            type: Boolean,
            default: false,
        },
        staticModal: {
            type: Boolean,
            default: false
        },
        haveLoading: { type: Boolean, default: false },
        noDefaultTitle: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        modalDialogScrollable: { type: Boolean, default: false },
        modalFullscreen: { type: Boolean, default: false },
    },

    watch: {
      
        loading() {
            if (this.loading) {
                this.modal._config.backdrop = "static";
                this.modal._config.keyboard = false;
            } else {
                this.modal._config.backdrop = this.backdrop;
                this.modal._config.keyboard = this.keyboard;
            }
        }
    },
    data() {
        return {
            value: false,
            loading_spinner: false,
            title_error: "Ошика",
            error_text: "",
            date: new Date().toLocaleDateString(),
            modal: null,
            keyboard: null,
            backdrop: null,
            div_succses: false,
            success_text:"Сохранено",
            default_success_text:"Сохранено",

            div_error: false,
            elem: null,
        }
    },
}
</script>
<style>
.modal.fade-scale:not(.show) .modal-dialog {
    transform: scale(0);
}

.modal.fade-scale .modal-dialog {
    transform: scale(1);
}

.modal-blur-backdrop {
    backdrop-filter: blur(2px);
    background-color: #9bacb770;
}
</style>