<template>
    <section>
        <div class="row">
            <div class="col-sm-auto col-12">
                <h3 class="my-1">
                    <div class="btn-group">
                        <button style="min-height: 24px;" type="button" title="Обновить" class="btn btn-default btn-smx" :disabled="isBusyDrivers"
                            @click="RefreshDrivers()"><i class="nav-icon fas fa-sync-alt"></i></button>
                       <!-- <button class="btn btn-outline-success btn-add   btn-smx" @click="ShowCreateDriverModal()"
                            title="Добавить водителя">
                            <i class="nav-icon fa fa-plus me-1" aria-hidden="true"></i>Добавить водителя
                        </button>-->
                    </div>
                </h3>
                <div class="card card-layout">
                    <div class="card-body p-0">
                        <div class="d-flex flex-wrap align-items-baseline justify-content-between p-2" style="gap:5px">
                            <div class="form-check form-switch ">
                                <input class="form-check-input" v-model="show_removed_drivers" name="removed"
                                    type="checkbox" id="removed_drivers">
                                <i class="form-check-label"><b> &laquo;удалённые&raquo; </b> водители</i>
                            </div>
                            <data-table-filter v-model="filterDrivers"></data-table-filter>
                        </div>
                        <b-overlay :show="isBusyDrivers" :opacity="0.5">
                            <b-table striped hover head-variant="light" id="table_drivers" class="m-0"
                                @filtered="onFilteredDrivers" :filter="filterDrivers" :filter-included-fields="['name']"
                                :filter-function="filterTableDrivers" :currentPage="currentPageDrivers"
                                :perPage="perPageDrivers" show-empty stacked="sm" :fields="fieldsDrivers"
                                :items="Drivers" :busy="isBusyDrivers && Drivers.length == 0">
                                <template #row-details="data">
                                    <b-card>
                                        <b>Описание:</b> <span 
                                            v-if="data.item.description == null"> -
                                        </span>
                                        <span v-else>
                                            {{ data.item.description }}
                                        </span>
                                    </b-card>
                                </template>
                                <template #empty>
                                    <div v-if="!DriversGetHaveError" class="text-center my-2">{{
                                        emptyTextDrivers }}
                                    </div>
                                    <div v-else class="text-center my-2" style="color:red">Ошибка загрузки</div>
                                </template>

                                <template #cell(pin)="data">
                                    <span class="d-flex align-items-center justify-content-center"
                                        v-if="data.item.pin == null">
                                        -
                                    </span>
                                    <span v-else>
                                        {{ data.item.pin }}
                                    </span>
                                </template>


                                <template #cell(number)="data">

                                    <span class="d-flex align-items-center justify-content-center"
                                        v-if="data.item.number == null">
                                        -
                                    </span><div class="d-flex" v-else style="gap:5px" >
                                         {{ NumberSpace(data.item.number) }} 
                                    <div v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                        title="Карта ожидает активации">
                                        <b-iconstack
                                            v-if="data.item?.isCardActivating ?? false"
                                            font-scale="1.2" title="Карта ожидает активации">
                                            <b-icon icon="credit-card2-front" scale="0.6" variant="warning"
                                                stacked></b-icon>
                                            <b-icon stacked icon="circle" variant="warning"></b-icon>
                                        </b-iconstack>
                                    </div>
                                </div>
                                 </template>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <strong>Загрузка...</strong>
                                    </div>
                                </template>
                                <template #cell(name)="data">
                                    <b-icon @click="data.toggleDetails" style="cursor: pointer;"
                                        :icon="data.detailsShowing ? 'chevron-up' : 'chevron-down'">
                                    </b-icon>

                                    <span
                                        :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }">  {{
                                             data.item.name
                                        }}</span>
                                </template>

                                <template #cell(change)="data">
                                    <div class="actions justify-content-center">
                                        <button class="btn edit" @click="ShowEditDriverModal(data.item)"
                                            title="Свойства подразделения"><i class="fa fa-pencil fa-1x"></i></button>
                                    </div>
                                </template>
                                <template #emptyfiltered>
                                    <div class=" text-center my-2">{{ emptyFilteredText }}</div>
                                </template>
                                <template #cell(delete)="data">
                                    <div class="actions justify-content-center">
                                        <button class="btn delete" v-if="!data.item.isRemoved"
                                            title="Удаление подразделения" @click="ShowDeleteDriverModal(data.item)"><i
                                                class="fa fa-trash fa-1x"></i></button>
                                    </div>
                                </template>
                            </b-table>
                            <pagination :total-rows="rowsDrivers" :perPage.sync="perPageDrivers"
                                :currentPage.sync="currentPageDrivers" aria-controls="table_drivers"></pagination>

                        </b-overlay>
                    </div>
                </div>
            </div>

        </div>
        <!-- #region modals-->
        <bs-modal centered id="CreateEditDriverModal" :loading="loading" ref="CreateEditDriverModal"
            :title="edit ? 'Свойства водителя' : 'Новый водитель'">
            <div class="col-12 mb-6">
                <form ref="CreateEditDriverForm" id="CreateEditDriverForm" @submit.prevent="CreateOrEditDriver">
                    <div class="row mb-n7">
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">ФИО</label>
                            </div>
                            <div class="col-sm-8 col-12 align-self-center"><input type="text" :disabled="loading"
                                    v-model="Driver.name" placeholder='Иванов Иван Иванович' class="form-control"
                                    required />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Описание</label>
                            </div>
                            <div class="col-sm-8 col-12 align-self-center"><textarea class="form-control" rows="5"
                                    :disabled="loading" v-model="Driver.description"></textarea>
                            </div>
                        </div>
                        <!--    <div class="row mb-2 align-items-center">
                            <div class="col-4"><label class="form-label font-weight-bold">Код/Номер карты</label>
                            </div>
                            <div class="col-8 align-self-center">
                                <div class="form-check form-switch">
                                    <input class="form-check-input " v-model="useNumber" type="checkbox"
                                        id="switchUsingNumber">
                                </div>
                            </div>
                        </div>-->
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Код</label>
                            </div>
                            <div class="col-sm-8 col-12 align-self-center"><input type="text" :disabled="loading"
                                    class="form-control" v-model="Driver.pin" @keypress="onlyNumber" pattern="[0-9]{4}"
                                    title="4 цифры" maxlength="4" placeholder="xxxx"
                                    :required="Driver.number == null || (Driver.pin?.length < 4 ?? false)"
                                    :class="{ 'is-invalid': pinError }">
                                <div style="color: #DC3545;" v-show="pinError">
                                    Пароль должен быть уникален
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Номер
                                    карты</label>
                            </div>
                            <div class="col-sm-8 col-12 align-self-center"><input type="text" :disabled="true"
                                    class="form-control" v-model="Driver.number" @keypress="onlyNumber" 
                                    @focusout="HandleFocusoutCardNumber" placeholder="xxxx xxxx xxxx xxxx"
                                    maxlength="19" title="xxxx xxxx xxxx xxxx" :class="{ 'is-invalid': numberError }"
                                    pattern="^[0-9]{4} {1}[0-9]{4} {1}[0-9]{4} {1}[0-9]{4}$"
                                    :required="Driver.pin == null || (Driver.number?.length < 19 ?? false)">
                                <div style="color: #DC3545;" v-show="numberError">
                                    Номер карты должен быть уникален
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button form="CreateEditDriverForm" :disabled="loading || DriversError" type="submit"
                        class="btn btn-sm btn-primary text-center align-items-center">
                        <span v-if="!loading">Cохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <bs-modal centered id="DeleteDriverModal" :loading="loading" ref="DeleteDriverModal" title="Удаление водителя">
            <form ref="DeleteDriverForm" id="DeleteDriverForm" @submit.prevent="DeleteDriver()">
                <p class="px-5 py-3 h4" style="text-align: center;">Вы действительно хотите удалить
                    водителя
                    <br> <strong>"{{ Driver.name }}"</strong>?
                </p>
            </form>
            <template #footer>
                <div class="col-12 d-flex gap-1">
                    <button :disabled="loading" form="DeleteDriverForm" type="submit"
                        class="btn btn-sm btn-danger text-center align-items-center">
                        <div v-if="!loading"> <i class="fa fa-trash me-1"></i>
                            <span>да</span>
                        </div>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                    <button :disabled="loading" form="DeleteCardForm" type="button"
                        @click="$refs.DeleteDriverModal.hide()"
                        class="btn me-1 btn-primary btn-sm text-center align-items-center">
                        <span>Нет</span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!-- #endregion modals-->
    </section>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import {
    mapActions
} from 'vuex'
import {
    textC_alignM,
    textL_alignM
} from '@/helpers/const.js'
import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => {
    window[name] = exported;
});
import {
    eventBus
} from '@/main.js'
import {
    mapGetters
} from 'vuex'
import {
    feather
} from '@/main.js';
export default {
    data() {
        return {
            /*region_drivers*/
            pinError: true,
            numberError: true,
            useNumber: false,
            show_removed_drivers: false,
            filterDrivers: "",
            filtered_itemsDrivers: [],
            emptyTextDrivers: "Нет водителей",
            perPageDrivers: 10,
            currentPageDrivers: 1,
            emptyTextDrivers: "Нет водителей",
            fieldsDrivers: [{
                key: 'name',
                label: "ФИО",
                sortable: true,
                thClass: textC_alignM,
                tdСlass: textL_alignM,
                thStyle:{'max-width':'50px'}
            },
           /* {
                key: 'description',
                sortable: false,
                label: "Описание",
                thClass: textC_alignM,
                tdСlass: textL_alignM
            },*/
            {
                key: 'pin',
                sortable: true,
                label: "Код",

                tdClass: textC_alignM
            },
            {
                key: 'number',
                sortable: false,
                label: "Номер карты",
                thClass: textC_alignM,

                tdClass: textC_alignM
            },
            {
                key: 'change',
                sortable: false,
                label: "",
                thStyle: {
                    width: "5%"
                },
                tdClass: textC_alignM
            },
            {
                key: 'delete',
                sortable: false,
                label: "",
                thStyle: {
                    width: "5%"
                },
                tdClass: textC_alignM
            },
            ],
            defaultDriver: {
                "pin": null,
                "number": null,
                "name": null,
                "description": null,
            },
            Driver: {},

            /*----------------------*/





            action: {
                type: null,
                method: null,
            },
            edit: false,
            dismissSecs: 5,
            dismissCountDown: 0,

        }
    },
    computed: {
        loading() {
            switch (this.action.method) {

                case 'post':
                    if (this.action.type === 'organizations') return this.OrganizationsPostAreLoading;
                    if (this.action.type === 'drivers') return this.DriversPostAreLoading;


                case 'put':
                    if (this.action.type === 'organizations') return this.OrganizationsPutAreLoading;
                    if (this.action.type === 'drivers') return this.DriversPutAreLoading;
                case 'delete':
                    if (this.action.type === 'organizations') return this.OrganizationsDeleteAreLoading;
                    if (this.action.type === 'drivers') return this.DriversDeleteAreLoading;
                default:
                    return false
            }
        },

        // #region drivers
        DriversError() { return this.pinError || this.numberError },
        rowsDrivers() {
            if (this.filtered_itemsDrivers.length == 0 && this.filterDrivers == "")
                return this.Drivers.length
            else {
                return this.filtered_itemsDepartment.length;
            }
        },

        Drivers() {
            if (this.show_removed_drivers) return this.AllDrivers.map(a => Object.assign({}, a, {
                _showDetails: false
            }))
            else return this.AllDrivers.filter(a => !a.isRemoved).map(a => Object.assign({}, a, {
                _showDetails: false
            }))
        },
        isBusyDrivers() {
            return (this.DriversGetAreLoading) ? true : false;
        },


        // #endregion


        ...mapGetters([
            'AllOrganizations',
            'OrganizationByGUID',
            'OrganizationsOnceLoaded',
            'OrganizationsGetAreLoading',
            'OrganizationsGetHaveError',
            'OrganizationsPostAreLoading',
            'OrganizationsPostHaveError',
            'OrganizationsPutAreLoading',
            'OrganizationsPutHaveError',
            'OrganizationsDeleteAreLoading',
            'OrganizationsDeleteHaveError',
            'CardsOnceLoaded',
            'CardFindByOrganizationGUID',


            'AllDrivers',
            'DriverByGUID',
            'DriversOnceLoaded',
            'DriversGetAreLoading',
            'DriversGetHaveError',
            'DriversPostAreLoading',
            'DriversPostHaveError',
            'DriversPutAreLoading',
            'DriversPutHaveError',
            'DriversDeleteAreLoading',
            'DriversDeleteHaveError',
        ]),

    },
    mounted() {
        feather.replace();
    },
    async created() {

    },
    watch: {
        'Driver.pin': function (new_val) {
            if (new_val != null && new_val.length == 4) {
                if (this.AllDrivers?.filter(a => !a.isRemoved && a.id != this.Driver.id)?.find(b => b.pin == new_val) != undefined)
                    this.pinError = true;
                else this.pinError = false
            }
            else this.pinError = false

        },
        'Driver.number': function (new_val) {

            if (new_val != null && new_val.length > 16) {

                if (this.AllDrivers?.filter(a => !a.isRemoved && a.id != this.Driver.id)?.find(b => b.number == new_val.split(" ").join("")) != undefined)
                    this.numberError = true;
                else this.numberError = false
            }
            else this.numberError = false
            if (this.Driver.number != null) {
                let realNumber = this.Driver.number.replace(/\s/gi, '')
                // Generate dashed number
                let dashedNumber = realNumber.match(/.{1,4}/g)
                // Replace the dashed number with the real one
                if (dashedNumber != null) this.Driver.number = dashedNumber.join(' ')
            }
        },

    },
    methods: {

        // #region Drivers
        NumberSpace(card_number) {
            if (card_number != undefined) return card_number.match(/.{1,4}/g).join(' ')
        },
        HandleFocusoutCardNumber(e) {
            if (this.Driver.number != null) {
                if (this.Driver.number.split(" ").join("").length < 16) {
                    var temp = '0'.repeat(16 - this.Driver.number.split(" ").join("").length) + this.Driver.number.split(" ").join("");
                    this.Driver.number = this.NumberSpace(temp)
                }
            }
            else {
                var temp = '0'.repeat(16);
                this.Driver.number = this.NumberSpace(temp)
            }
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                $event.preventDefault();
            }
        },
        async CreateOrEditDriver() {
            var number = null;
            if (this.Driver.number != null) {
                number = this.Driver.number.split(' ').join('');
            }
            else this.Driver.number = null;
            if (this.edit) {
                let driver = JSON.parse(JSON.stringify(this.DriverByGUID(this.Driver.id)));
                driver.name = this.Driver.name;
                driver.description = this.Driver.description;
                driver.pin = this.Driver.pin;
                driver.number = number
                this.action =
                {
                    method: 'put',
                    type: 'drivers',
                }
                await this.$store.dispatch(DRIVERS_PUT_REQUEST, driver).then(() => {
                    this.$refs['CreateEditDriverModal'].success();
                })
                    .catch(err => {
                        this.$refs['CreateEditDriverModal'].error(err);
                    })

            } else {
                this.action =
                {
                    method: 'post',
                    type: 'drivers',
                }

                await this.$store.dispatch(DRIVERS_POST_REQUEST, {
                    name: this.Driver.name,
                    description: this.Driver.description,
                    pin: this.Driver.pin,
                    number: number
                }).then(() => {
                    this.$refs['CreateEditDriverModal'].success();
                })
                    .catch(err => {
                        this.$refs['CreateEditDriverModal'].error(err);
                    })
            }
        },
        ClearErrors() {
            this.numberError = false;
            this.pinError = false;
        },
        ShowEditDriverModal(element) {
            this.ClearErrors();

            this.edit = true;
            if (element.pin == null) this.useNumber = true
            else this.useNumber = false;
            this.Driver = Object.assign({}, element)
            this.$refs['CreateEditDriverModal'].show();
        },

        ShowCreateDriverModal() {
            this.ClearErrors();

            this.edit = false;
            this.useNumber = false
            this.Driver = Object.assign({}, this.defaultDriver)
            this.$refs['CreateEditDriverModal'].show();
        },
        ShowDeleteDriverModal(element) {
            this.Driver = Object.assign({}, element);
            this.$refs['DeleteDriverModal'].show();
        },
        async DeleteDriver() {
            this.action =
            {
                method: 'delete',
                type: 'drivers',
            }
            await this.$store.dispatch('DRIVERS_DELETE_REQUEST', this.Driver.id).then(
                () => {
                    this.$refs['DeleteDriverModal'].success();
                }
            ).catch(
                err => {
                    this.$refs['DeleteDriverModal'].error(err);
                }
            )
        },

        RefreshDrivers() {

            this.$store.dispatch(DRIVERS_GET_REQUEST);
        },
        onFilteredDrivers(filteredItems) {
            this.filtered_itemsDrivers = filteredItems;
            this.currentPageDrivers = 1;
        },
        clearFilterDrivers() {
            this.filterDrivers = "";
        },
        filterTableDrivers(row, filter) {
            if (filter.length == 0 || filter.length == 1) return true
            if (row.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 && row.number.toLowerCase()[0] == filter.toLowerCase()[0]) return true;
            else return false
        },
        // #endregion  
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },

    },
}
</script>
