<template>
  <div class="d-flex align-items-center dataTables-filter">
    <div class="input-group dataTables-filter__content" style="height: 25px !important;">
      <div class="input-group-text" style="height: 25px !important"> <i class="fa-solid fa-magnifying-glass"></i></div>
      <input :placeholder="'Поиск...'" type="search" class="form-control " @input="UpdateValue" :value="value">
      <button @click="clear" v-if="value.length != 0" class="clear-input-button  ">
        <i class="fa fa-times"></i>
      </button>

    </div>
  </div>
</template>
<script>
export default {
  props: ['value'],
  methods: {
    UpdateValue(event) {
      this.$emit('input', event.target.value)
    },
    clear() {
      this.$emit('input', "")
    }
  }
}
</script>
<style>
input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
@media screen and (max-height: 767px) {
  .dataTables-filter{ width: 100%;}
}
 
.dataTables-filter__content {
  height: 25px !important;
}
.dataTables-filter{width: auto;;};
.dataTables-filter__content .input-group-text {
  height: 25px !important;
  background: white;
}

.dataTables-filter__content input {

  height: 25px !important;
  padding: 0 25px 0 0.5em;
}

.dataTables-filter__content button {
  right: 0;
  height: 25px;
  padding: 0 10px;
}

.dataTables-filter__content input:focus {
  z-index: 0 !important;
}

.clear-input-button {
  /* button position */
  position: absolute;
  /* button appearane */
  justify-content: center;
  align-items: center;
width: 25px;
  appearance: none;
  border: none;
  background: rgba(202, 202, 202, 0.87);
  color: white;
  font-size: 10px;
  cursor: pointer;

  /* hide the button initially */
}

.clear-input-button:hover {
  background: darkgray;
}
</style>