import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/*!!!!!!!!!!!!!!!!!!!!Импортируем пользовательские компоненты страницы!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
import VueApexCharts from 'vue-apexcharts'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'

/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/

/*!!!!!!!!!!!!!!!!!!!!Импортируем пользовательские компоненты страницы!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
import DataRange from '@/components/DataRangeComponent.vue'
import PageHeading from '@/components/page_heading.vue'
import BsModal from '@/components/ModalComponent.vue'
import Preloader from '@/components/PreloaderComponent.vue'
import HeaderPanel from '@/components/HeaderComponent.vue'
import SidenavPanel from '@/components/SideNavComponent.vue'
import BsSelect from '@/components/SelectComponent.vue'
import Select2 from '@/components/SelectV2Component.vue'
import DataTableFilter from '@/components/DataTableFilterComponent.vue'
import Signal from '@/components/Signal.vue'
import PaginationTable from '@/components/PaginationComponent.vue'

/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/


/*!!!!!!!!!!!!!!!!!!!!Экспортируем константы!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
export const eventBus = new Vue()
export const feather = require('feather-icons')
/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/

/*!!!!!!!!!!!!!!!!!!!!Импортируем стили страницы!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
//Adtron_Template+components+bootstrap//
import '../public/assets/scss/style.scss'
 
//fontawesome//
import '@fortawesome/fontawesome-free/css/all.css'

//Leaflet//
import "leaflet/dist/leaflet.css"
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
//bootstrap-vue.css//
import "../public/assets/css/vendor/bootstrap-vue.min.css"
//import "bootstrap-vue/dist/bootstrap-vue.css"
//Toastr.css//
import "../public/assets/css/plugins/toastr.min.css"
import "@vueform/multiselect/themes/default.css"
import "../public/assets/css/vendor/vueform/multiselect.css"

//import "../public/assets/css/plugins/multiselect.css"


import '../public/assets/css/plugins/sweet_alert_anim.css'
import '../public/assets/css/plugins/daterangepicker.css' 
//main.css//
import '../public/assets/css/Main.css'
Vue.config.productionTip = false



/*89ab4a56-f23e-4c9a-8693-d8c72aba0483*/
/*7541cb5c-b82d-41fb-97cf-36a5d34e92a6*/

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueApexCharts)


Vue.component('pagination', PaginationTable)

Vue.component('apexchart', VueApexCharts)
Vue.component('bs-datarange', DataRange)
Vue.component('multiselect', Multiselect)
Vue.component('data-table-filter', DataTableFilter)
Vue.component('page-heading', PageHeading)
Vue.component('bs-modal', BsModal)
Vue.component('preloader', Preloader)
Vue.component('header-panel', HeaderPanel)
Vue.component('sidenav-panel', SidenavPanel)
Vue.component('bs-select', BsSelect)
Vue.component('select2', Select2)
Vue.component('signal', Signal)


// Optionally install the BootstrapVue icon components plugin

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

