<template>
<div class="main-wrapper">
    <!-- Шапка-->
    <header-panel></header-panel>
    <!-- Шапка (конец) -->
    <!-- Боковая навигация -->
    <sidenav-panel></sidenav-panel>
    <!-- Боковая навигация -->
    <!-- Content Body Start -->
    <preloader v-if="show_preloader && first_show" />
    <transition mode="out-in">
        <router-view></router-view>
    </transition>
    <!-- Content Body End -->
    <!-- Footer Section Start -->
    <div class="footer-section bg-white py-1 mt-auto">
        <div class="container-fluid">
            <div class="footer-copyright text-center">
                <p class="text-muted">&copy; <a href="http://car-glonass.ru/about/">ООО "АМС" Калуга</a>&nbsp;
                    2009-{{ year_now }}</p>
            </div>
        </div>
    </div>
    <!-- Footer Section End -->
</div>
</template>
<script>
import {
    textC_alignM,
    textL_alignM
} from '@/helpers/const.js'
import * as exp from '@/helpers/actions.js';
import {
    mapGetters
} from 'vuex';
Object.entries(exp).forEach(([name, exported]) => {
    window[name] = exported;
});
export default {
    data() {
        return {
            first_show: true,
        }
    },
    computed: {
        year_now() {
            return moment().year()
        },
        show_preloader() {
            switch (this.$route.path) {
                case "/admin/clients":
                    if ((this.Admin_clientsGetAreLoaded || this.Admin_clientsGetHaveError)) {
                        this.first_show = false;
                        return false;
                    } else return true;
                case "/admin/cards":
                    if ((this.Admin_clientsGetAreLoaded || this.Admin_clientsGetHaveError) && (this.Admin_cardsGetAreLoaded || this.Admin_cardsGetHaveError)) {
                        this.first_show = false;
                        return false;
                    } else return true;
            }
        },
        ...mapGetters(['Admin_clientsGetAreLoaded', 'this.Admin_clientsGetHaveError', 'Admin_cardsGetAreLoaded', 'Admin_cardsGetHaveError'])
    },
    created() {
    },
    watch: {
    },
    methods: {
    },
}
</script>
