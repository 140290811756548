<template>
    <section>
        <div class="row">
            <div class="col-sm-auto col-12">
                <h3 class="my-1">
                    <div class="btn-group">
                        <button type="button" title="Обновить" class="btn btn-default btn-smx"
                            :disabled="isBusyDepartments" @click="RefreshDepartments()"><i
                                class="nav-icon fas fa-sync-alt"></i></button>
                        <button class="btn btn-outline-success btn-add   btn-smx" @click="ShowCreateOrganizationModal()"
                            title="Добавить подразделение">
                            <i class="nav-icon fa fa-plus me-1" aria-hidden="true"></i>Добавить подразделение
                        </button>
                    </div>
                </h3>
                <div class="card card-layout">
                    <div class="card-body p-0">
                        <div class="d-flex flex-wrap align-items-baseline justify-content-between p-2" style="gap:5px">
                            <div class="form-check form-switch">
                                <input class="form-check-input " v-model="show_removed_departments" name="removed"
                                    type="checkbox" id="removed_organization">
                                <i><b> &laquo;удалённые&raquo; </b> подразделения</i>
                            </div>
                            <data-table-filter v-model="filterDepartments"></data-table-filter>
                        </div>
                        <b-overlay :show="isBusyDepartments" :opacity="0.5">
                            <b-table striped hover head-variant="light" id="table_organizations" class="m-0"
                                @filtered="onFilteredDepartments" responsive :filter="filterDepartments"
                                :filter-included-fields="['name']" :filter-function="filterTableDepartments"
                                :currentPage="currentPageDepartments" :perPage="perPageDepartments" show-empty
                                :fields="fieldsDepartments" :items="Organizations"
                                :busy="isBusyDepartments && Organizations.length == 0">
                                <template #empty>
                                    <div v-if="!OrganizationsGetHaveError" class="text-center my-2">{{
                                        emptyTextDepartments }}
                                    </div>
                                    <div v-else class="text-center my-2" style="color:red">Ошибка загрузки</div>
                                </template>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <strong>Загрузка...</strong>
                                    </div>
                                </template>
                                <template #cell(name)="data">
                                    <span
                                        :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }">{{
                                            data.item.name
                                        }}</span>
                                </template>
                                <template #cell(change)="data">
                                    <div class="actions justify-content-center">
                                        <button class="btn edit" @click="ShowEditOrganizationModal(data.item)"
                                            title="Свойства подразделения"><i class="fa fa-pencil fa-1x"></i></button>
                                    </div>
                                </template>
                                <template #emptyfiltered>
                                    <div class=" text-center my-2">{{ emptyFilteredText }}</div>
                                </template>
                                <template #cell(delete)="data">
                                    <div class="actions justify-content-center">
                                        <button class="btn delete" v-if="!data.item.isRemoved"
                                            title="Удаление подразделения" @click="ShowDeleteModal(data.item)"><i
                                                class="fa fa-trash fa-1x"></i></button>
                                    </div>
                                </template>
                            </b-table>
                            <pagination :total-rows="rowsDepartments" :perPage.sync="perPageDepartments"
                                :currentPage.sync="currentPageDepartments" aria-controls="table_organizations">
                            </pagination>

                        </b-overlay>
                    </div>

                </div>
            </div>
        </div>

        <!-- #region modals -->
        <bs-modal centered id="CreateEditModalOrganization" :loading="loading" ref="CreateEditModalOrganization"
            :title="edit ? 'Свойства подразделения' : 'Новое подразделение'">
            <div class="col-12 mb-6">
                <form ref="CreateEditModalOrganizationForm" id="CreateEditModalOrganizationForm"
                    @submit.prevent="CreateOrEditOrganization">
                    <div class="row mb-n7 align-items-center">
                        <div class="col-12 ">
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Наименование</label></div>
                                <div class="col-sm-8 col-12 align-self-center"><input type="text" :disabled="loading"
                                        v-model="name" placeholder='ООО "Новая Организация"' class="form-control"
                                        required />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button form="CreateEditModalOrganizationForm" :disabled="loading" type="submit"
                        class="btn btn-sm btn-primary text-center align-items-center">
                        <span v-if="!loading">Cохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <bs-modal centered id="DeleteOrganizationModal" :loading="loading" ref="DeleteOrganizationModal"
            title="Удаление подразделения">
            <div class="col-12 mb-6">
                <form ref="DeleteOrganizationForm" id="DeleteOrganizationForm" @submit.prevent="Delete()">
                    <div class="row mb-n7 align-items-center">
                        <div class="col-12 ">
                            <p class="px-5 py-3 h4" style="text-align: center;">Вы действительно хотите удалить
                                подразделение
                                <br> <strong>"{{ name }}"</strong>?
                            </p>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 d-flex gap-1">
                    <button :disabled="loading" form="DeleteOrganizationForm" type="submit"
                        class="btn btn-sm btn-danger text-center align-items-center">
                        <div v-if="!loading"> <i class="fa fa-trash me-1"></i>
                            <span>да</span>
                        </div>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                    <button :disabled="loading" form="DeleteCardForm" type="button"
                        @click="$refs.DeleteOrganizationModal.hide()"
                        class="btn me-1 btn-primary btn-sm text-center align-items-center">
                        <span>Нет</span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!-- #endregion -->
    </section>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import {
    mapActions
} from 'vuex'
import {
    textC_alignM,
    textL_alignM
} from '@/helpers/const.js'
import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => {
    window[name] = exported;
});
import {
    eventBus
} from '@/main.js'
import {
    mapGetters
} from 'vuex'
import {
    feather
} from '@/main.js';
export default {
    data() {
        return {
            /*region_departments*/
            show_removed_departments: false,
            filterDepartments: "",
            emptyFilteredText: "Не найдено",
            filtered_itemsDepartment: [],
            emptyTextDepartments: "Нет подразделений",
            fieldsDepartments: [{
                key: 'name',
                label: "Наименование",
                sortable: true,
                thClass: textC_alignM,
                tdСlass: textL_alignM
            },
            {
                key: 'change',
                sortable: false,
                label: "",
                thStyle: {
                    width: "5%"
                },
                tdClass: textC_alignM
            },
            {
                key: 'delete',
                sortable: false,
                label: "",
                thStyle: {
                    width: "5%"
                },
                tdClass: textC_alignM
            }
            ],
            id: null,
            name: null,
            perPageDepartments: 10,
            currentPageDepartments: 1,
            /*----------------------*/
            action: {
                type: null,
                method: null,
            },
            edit: false,
            dismissSecs: 5,
            dismissCountDown: 0,

        }
    },
    computed: {
        loading() {
            switch (this.action.method) {

                case 'post':
                    if (this.action.type === 'organizations') return this.OrganizationsPostAreLoading;
                    if (this.action.type === 'drivers') return this.DriversPostAreLoading;


                case 'put':
                    if (this.action.type === 'organizations') return this.OrganizationsPutAreLoading;
                    if (this.action.type === 'drivers') return this.DriversPutAreLoading;
                case 'delete':
                    if (this.action.type === 'organizations') return this.OrganizationsDeleteAreLoading;
                    if (this.action.type === 'drivers') return this.DriversDeleteAreLoading;
                default:
                    return false
            }
        },
        // #region departments
        Organizations() {
            if (this.show_removed_departments) return this.AllOrganizations
            else return this.AllOrganizations.filter(a => !a.isRemoved)
        },
        rowsDepartments() {
            if (this.filtered_itemsDepartment.length == 0 && this.filterDepartments == "")
                return this.Organizations.length
            else {
                return this.filtered_itemsDepartment.length;
            }
        },
        isBusyDepartments() {
            return (this.OrganizationsGetAreLoading) ? true : false;
        },
        // #endregion
        ...mapGetters([
            'AllOrganizations',
            'OrganizationByGUID',
            'OrganizationsOnceLoaded',
            'OrganizationsGetAreLoading',
            'OrganizationsGetHaveError',
            'OrganizationsPostAreLoading',
            'OrganizationsPostHaveError',
            'OrganizationsPutAreLoading',
            'OrganizationsPutHaveError',
            'OrganizationsDeleteAreLoading',
            'OrganizationsDeleteHaveError',
            'CardsOnceLoaded',
            'CardFindByOrganizationGUID',


            'AllDrivers',
            'DriverByGUID',
            'DriversOnceLoaded',
            'DriversGetAreLoading',
            'DriversGetHaveError',
            'DriversPostAreLoading',
            'DriversPostHaveError',
            'DriversPutAreLoading',
            'DriversPutHaveError',
            'DriversDeleteAreLoading',
            'DriversDeleteHaveError',
        ]),

    },
    mounted() {
        this.$nextTick(() => { feather.replace() });
    },
    async created() {

    },

    methods: {

        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        // #region departments
       async RefreshDepartments() {
            if (!this.CardsOnceLoaded)
            await this.$store.dispatch(CARDS_GET_REQUEST);
            this.$store.dispatch(ORGANIZATIONS_GET_REQUEST);
        },
        onFilteredDepartments(filteredItems) {
            this.filtered_itemsDepartment = filteredItems;
            this.currentPageDepartments = 1;
        },
        clearFilterDepartments() {
            this.filterDepartments = "";
        },
        filterTableDepartments(row, filter) {
            if (filter.length == 0 || filter.length == 1) return true
            if (row.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 && row.number.toLowerCase()[0] == filter.toLowerCase()[0]) return true;
            // if (row.organizationID.toLowerCase().indexOf(filter.toLowerCase()) > -1 && row.imei.toLowerCase()[0] == filter.toLowerCase()[0]) return true;
            else return false
        },


        ShowEditOrganizationModal(element) {
            this.edit = true;
            this.name = element.name;
            this.id = element.id;
            this.$refs['CreateEditModalOrganization'].show();
        },
        async CreateOrEditOrganization() {
            if (this.edit) {
                let organization = JSON.parse(JSON.stringify(this.OrganizationByGUID(this.id)));
                if (organization.name != this.name) {
                    organization.name = this.name;
                    this.action =
                    {
                        method: 'put',
                        type: 'organizations',
                    }
                    await this.$store.dispatch('ORGANIZATIONS_PUT_REQUEST', organization).then(() => {
                        this.$refs['CreateEditModalOrganization'].success();
                        this.name = null;
                    })
                        .catch(err => {
                            this.$refs['CreateEditModalOrganization'].error(err);
                            this.name = null;
                        })
                } else {
                    this.$refs['CreateEditModalOrganization'].error('Наименование не изменено');
                    this.name = null;
                }
            } else {
                this.action =
                {
                    method: 'post',
                    type: 'organizations',
                }

                //this.$refs['CreateEditModalOrganization'].hide();
                await this.$store.dispatch('ORGANIZATIONS_POST_REQUEST', {
                    name: this.name
                }).then(() => {
                    this.$refs['CreateEditModalOrganization'].success();
                    this.name = null;
                })
                    .catch(err => {
                        this.$refs['CreateEditModalOrganization'].error(err);
                        this.name = null;
                    })
            }
        },
        ShowCreateOrganizationModal() {
            this.edit = false;
            this.name = null;
            this.id = null;
            this.$refs['CreateEditModalOrganization'].show();
        },
        ShowDeleteModal(element) {
            this.id = element.id;
            this.name = element.name;
            this.$refs['DeleteOrganizationModal'].show();
        },
        async Delete() {
            if (this.CardFindByOrganizationGUID(this.id) != undefined) {
                this.$refs['DeleteOrganizationModal'].error("Подразделение привязано к карте");
                //    this.dismissCountDown = this.dismissSecs
            } else {
                this.action =
                {
                    method: 'delete',
                    type: 'organizations',
                }
                // let organization = JSON.parse(JSON.stringify(this.OrganizationByGUID(this.id)));
                //  organization.isRemoved = true;
                await this.$store.dispatch('ORGANIZATIONS_DELETE_REQUEST', this.id).then(
                    () => {
                        //   organization.isRemoved = "true";
                        this.$refs['DeleteOrganizationModal'].success();
                    }
                ).catch(
                    err => {
                        this.$refs['DeleteOrganizationModal'].error(err);
                    }
                )
            }
        },
        // #endregion
    },
}
</script>
