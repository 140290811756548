<template>
    <div class="content-body">
        <div class="d-flex flex-grow-1">
            <div class="DirectotyTabs-prev d-flex d-sm-none">
                <b-icon icon="chevron-left" font-scale="2"></b-icon>
            </div>
            <b-tabs class="DirectotyTabs" v-model="tabIndex" @input="changeTab">

                <b-tab>
                    <template #title>
                        <h3><i data-feather="home" class="nav-icon" />
                            <span class="titlePage"> Подразделения </span>
                        </h3>
                    </template>

                </b-tab>
                <b-tab>
                    <template #title>
                        <h3><i class="fa-regular fa-id-card nav-icon" />
                            <span class="titlePage"> Водители </span>
                        </h3>

                    </template>

                </b-tab>
                <b-tab title="Продукты"><template #title>
                        <h3>
                            <i data-feather="shopping-bag" class="nav-icon"></i>
                            <span class="titlePage"> Продукты </span>
                        </h3>
                    </template>
                </b-tab>


            </b-tabs>
            <div class="DirectotyTabs-end  d-flex d-sm-none"> <b-icon font-scale="2" icon="chevron-right"></b-icon>
            </div>
        </div>
        <router-view></router-view>

    </div>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import {
    mapActions
} from 'vuex'
import {
    textC_alignM,
    textL_alignM
} from '@/helpers/const.js'
import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => {
    window[name] = exported;
});
import {
    eventBus
} from '@/main.js'
import {
    mapGetters
} from 'vuex'
import {
    feather
} from '@/main.js';
export default {
    data() {
        return {
            tabIndex: 0,
            names: ['Departments', 'Drivers', 'Products'],
        }
    },
    methods: {
        changeTab(event) {
            if (this.$route.name != this.names[event])
                this.$router.push({ name: this.names[event] })
        },

    },
    watch: {
        '$route.name': function (new_val) {
            this.$nextTick(() => this.tabIndex = this.names.findIndex(name => name === new_val))
        }
    },
    computed: {
        ...mapGetters([
            'OrganizationsOnceLoaded',
            'CardsOnceLoaded',
            'ProductsOnceLoaded',
            'WalletsOnceLoaded',
            'DriversOnceLoaded',
        ])
    },
    mounted() {

        this.$nextTick(() => { feather.replace(); this.tabIndex = this.names.findIndex(name => name === this.$route.name) });
    },
    async created() {
        if (!this.OrganizationsOnceLoaded)
            await this.$store.dispatch(ORGANIZATIONS_GET_REQUEST);
        if (!this.CardsOnceLoaded)
            await this.$store.dispatch(CARDS_GET_REQUEST);
        if (!this.WalletsOnceLoaded)
            await this.$store.dispatch(WALLETS_GET_REQUEST);
        if (!this.DriversOnceLoaded)
            await this.$store.dispatch(DRIVERS_GET_REQUEST);
    },

}
</script>
<style>
.DirectotyTabs-end,
.DirectotyTabs-prev {
    align-items: center;
    justify-content: center;
    min-width: 32px;
    flex: 0 0 32px;
}

.DirectotyTabs {
    overflow-y: hidden;
    overflow-x: auto;

    ::-webkit-scrollbar {
        width: 0;
        /* Remove scrollbar space */
        background: transparent;
        /* Optional: just make scrollbar invisible */
    }

    /* Optional: show position indicator in red */
    ::-webkit-scrollbar-thumb {
        background: #FF0000;
    }

}

.DirectotyTabs .nav-tabs {
    border: none;
    flex-wrap: nowrap;
    flex: 0 0 auto;
}

.DirectotyTabs>div {
    display: flex;
}
</style>
