<template>
    <div class="input-group">
        <span class="input-group-text"><b-icon icon="calendar2-date" /></span>
        <input type="text" :id="id" :disabled="disabled" class="form-control rdp-range-date" autocomplete="off" placeholder="Выберите дату"
            @keydown="$event.preventDefault()" />
    </div>
</template>
<script>
import { feather } from '@/main.js';
export default {
    props:
    {
        id: {
            type: String,
            required: true,
            default: "data-range"
        },
        name: {
            type: String,
            required: false,
            default: "datetimes"
        },
        disabled:{default:false, type:Boolean},
        start: {default:()=>moment().subtract(6, 'days').startOf("day")},
        end: {default:()=>moment().endOf("day"),}
    },
    mounted() {
        feather.replace();
        var self = this;
        $('#' + this.id).daterangepicker({
            opens: 'left',
            showDropdowns: true,
            drops: "down",
            cancelButtonClasses: "btn-danger",
            timePicker: true,
            startDate: this.start,
            endDate: this.end,
           // minDate: moment().subtract(20, 'years'),
            maxDate: moment().endOf("day"),
            timePicker24Hour: true,
            timePickerSeconds: true,
            autoUpdateInput: true,
            ranges: {
                'Сегодня': [moment().startOf("day"), moment().endOf("day")],
                'Вчера': [moment().subtract(1, 'days').startOf("day"), moment().subtract(1, 'days').endOf("day")],
                'Последние 7 дней': [moment().subtract(6, 'days').startOf("day"), moment().endOf("day")],
                'Последние 30 дней': [moment().subtract(29, 'days').startOf("day"), moment().endOf("day")],
                'Текущий месяц': [moment().startOf('month'), moment().endOf('month')],
                'Предыдущий месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            linkedCalendars: false,
            locale: {
                format: "DD.MM.YYYY HH:mm:ss",
                separator: " -> ",
                applyLabel: "Выбрать",
                cancelLabel: "Отменить",
                fromLabel: "С",
                toLabel: "По",
                customRangeLabel: "Выбрать интервал",
                weekLabel: "W",
                daysOfWeek: [
                    "Вс",
                    "Пн",
                    "Вт",
                    "Ср",
                    "Чт",
                    "Пт",
                    "Сб",
                    
                ],
                monthNames: [
                    "Январь",
                    "Февраль",
                    "Март",
                    "Апрель",
                    "Май",
                    "Июнь",
                    "Июль",
                    "Август",
                    "Сентябрь",
                    "Октябрь",
                    "Ноябрь",
                    "Декабрь"
                ],
                firstDay: 1
            },
        }).on("apply.daterangepicker", function (e) {
            var picker = $(e.target).data('daterangepicker');
            picker.element.val(picker.startDate.format(picker.locale.format) + ' -> ' + picker.endDate.format(picker.locale.format));
            self.updateTime(
                {
                    maxDate: moment().endOf("day"),
                    startDTime: picker.startDate,
                    endDTime: picker.endDate
                }
            )
        });;
        $('#id').trigger('apply.daterangepicker')
    },
    methods: {
        updateTime(e) { this.$emit('UpdateDT', e) }
    },
    /*
        destroyed: function () {
            $('#' + this.id)
                .off()
                .daterangepicker("destroy");
        }*/
}
</script>
