<template>
    <transition name="fade-preloader">
        <!-- <div v-if="true" class="preloader">-->
        <div v-if="show_preloader && first_show" class="preloader">
            <div class="preloader_animation"></div>
            <p>Загрузка данных. Пожалуйста, подождите....</p>
        </div>
    </transition>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    computed: {

        show_preloader() {
            var path = this.$route.path.split('/');
            if (path[1] == 'home') {
                switch (path[2]) {
                    case "vehicles":
                    if ((this.CardsGetAreLoaded || this.CardsGetHaveError) && (this.WalletsGetAreLoaded || this.WalletsGetHaveError) && (this.OrganizationsGetAreLoaded || this.OrganizationsGetHaveError) && (this.ProductsGetAreLoaded || this.ProductsGetHaveError) && (this.TerminalsGetAreLoaded || this.TerminalsGetHaveError)) {
                        this.first_show = false;
                        return false;
                    }
                    else return true;
                case 'issuedCards':
                 if ((this.IssuedCardsGetAreLoaded || this.IssuedCardsGetHaveError) && (this.OrganizationsGetAreLoaded || this.OrganizationsGetHaveError) && (this.ProductsGetAreLoaded || this.ProductsGetHaveError)) {
                    this.first_show = false;
                        return false;
                }
                case "terminals":
                    if ((this.CardsGetAreLoaded || this.CardsGetHaveError) && (this.OrganizationsGetAreLoaded || this.OrganizationsGetHaveError) && (this.ProductsGetAreLoaded || this.ProductsGetHaveError) && (this.TerminalsGetAreLoaded || this.TerminalsGetHaveError)) {
                        this.first_show = false;
                        return false;
                    }
                    else return true;
                case "terminals_map":
                    if ((this.CardsGetAreLoaded || this.CardsGetHaveError) && (this.OrganizationsGetAreLoaded || this.OrganizationsGetHaveError) && (this.ProductsGetAreLoaded || this.ProductsGetHaveError) && (this.TerminalsGetAreLoaded || this.TerminalsGetHaveError)) {
                        this.first_show = false;
                        return false;
                    }
                    else return true;
                case "products":
                    if ((this.ProductsGetAreLoaded || this.ProductsGetHaveError) && (this.WalletsGetAreLoaded || this.WalletsGetHaveError)) {
                        this.first_show = false;
                        return false;
                    }
                    else return true;
                case "directory":
                    if ((this.OrganizationsGetAreLoaded || this.OrganizationsGetHaveError) && (this.CardsGetAreLoaded || this.CardsGetHaveError) && (this.DriversGetAreLoaded || this.DriversGetHaveError)) {
                        this.first_show = false;
                        return false;
                    }
                    else return true;
                case "transactions":
                    if ((this.CardsGetAreLoaded || this.CardsGetHaveError) && (this.WalletsGetAreLoaded || this.WalletsGetHaveError) && (this.OrganizationsGetAreLoaded || this.OrganizationsGetHaveError) && (this.ProductsGetAreLoaded || this.ProductsGetHaveError) && (this.UsersGetAreLoaded || this.UsersGetHaveError)  && (this.DriversGetAreLoaded || this.DriversGetHaveError)) {
                        this.first_show = false;
                        return false;
                    }
                    else return true;
                }

            }
            else {
                 switch (path[2]) {
                    case "clients":
                        if ((this.Admin_clientsGetAreLoaded || this.Admin_clientsGetHaveError)) {
                             this.first_show = false;
                            return false;
                        }
                        else return true;
                    case "cards":
                        if ((this.Admin_cardsGetAreLoaded || this.Admin_cardsGetHaveError) && (this.Admin_clientsGetAreLoaded || this.Admin_clientsGetHaveError)) {
                            this.first_show = false;
                            return false;
                        }
                        else return true;
                }
            }
     
        },
        ...mapGetters([
        'IssuedCardsGetHaveError','IssuedCardsGetAreLoaded',
            'Admin_cardsGetHaveError',
            'Admin_cardsGetAreLoaded',
            'Admin_clientsGetAreLoaded',
            'Admin_clientsGetHaveError',
            'CardsGetAreLoaded',
            'WalletsGetAreLoaded',
            'OrganizationsGetAreLoaded',
            'OrganizationsGetHaveError',
            'DriversGetAreLoaded',
            'DriversGetHaveError',
            'ProductsGetAreLoaded',
            'UsersGetAreLoaded',
            'TerminalsGetAreLoaded',
            'CardsGetHaveError',
            'WalletsGetHaveError',
            'ProductsGetHaveError',
            'UsersGetHaveError',
            'TerminalsGetHaveError',
        ]),
    },
    data: function () {
        return {
            // show_preloader: true,
            first_show: true,
        }
    },
    async created() {
        this.first_show = true;
    }



}

</script>
<style scoped>
.fade-preloader-enter-active,
.fade-preloader-leave-active {
    transition: opacity 0.5s linear;
}

.fade-preloader-enter-from,
.fade-preloader-leave-to {
    opacity: 0;
}

.preloader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #e9ecef;
    z-index: 9999999;
}

.preloader p {
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    color: (--bs-primary);
}

.preloader_animation {
    display: block;
    position: relative;
    width: 150px;
    height: 150px;
    margin: 30px auto;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--bs-primary);
    animation: preloader_animation-spin 2s linear infinite;
}

.preloader_animation:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #464455;
    animation: preloader_animation-spin 3s linear infinite;
}

.preloader_animation:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9f9cbb;
    animation: preloader_animation-spin 1.5s linear infinite;
}

@keyframes preloader_animation-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>