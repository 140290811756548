export default {
    MAKE_PDF(data, header, name, footer = null) {
        data.unshift(header);
        if (footer != null)
            data.push(footer)
        var docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [
                { text: `${name}`, style: 'header' },

                {

                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
 
                        //                    widths: ['auto', 'auto', 'auto', 'auto'],

                        body: data
                    }
                },
            ],
            styles: {
                header: {
                    fontSize: 16,
                    bold: true
                },
            }
        }
        pdfMake.createPdf(docDefinition).download(name + '.pdf');
        return true;
    },

    MAKE_сsv(data, header, name, footer = null) {
        data.unshift(header);
        if (footer != null)
            data.push(footer);


        data = data.map(elem => {


            let temp = [];
            for (var i = 0; i < elem.length; i++) {
                if (i == 0) { temp.push(elem[i]) }
                else (temp.push('"' + elem[i] + '"'))
            }
            return temp.join(';') + "\r\n"

        })
        data = data.join('');
        data = this.win1251(data);

        saveAs(new Blob([data], { type: 'text/csv;' }), name + '.csv');
        return true;


    },
    win1251(data) {
        var uint8 = new Uint8Array(data.length);
        for (var i = 0; i < data.length; i++) {
            var x = data.charCodeAt(i);
            if (x >= 1040 && x <= 1103) { // Символы А..Я а..я
                x -= 848;
            } else if (x == 1025) {   // Символ Ё
                x = 168;
            } else if (x == 1105) {   // Символ ё
                x = 184;
            }
            uint8[i] = x;
        }
        return uint8;
    },
    MAKE_xlsx(data, headers, name, footer = null) {
        let excel = $JExcel.new("Calibri 12 #000000");
        excel.set({ sheet: 0, value: "Отчёт" });

        let title_Style = excel.addStyle({
            border: "none, none, none, none",
            font: "Calibri 12 #000000 B",
            align: "C C W"
        }
        )
        let title = name;
        excel.set(0, 0, 0, title, title_Style, headers.length)
        excel.set({ sheet: 0, row: 0, value: 50 })
        let current_Row = 2;
        let header_Style = excel.addStyle({
            border: "medium #000000,medium #000000,medium #000000,medium #000000",
            font: "Calibri 12 #000000 B",
            fill: "#F0F0F0",
            align: "C C W"
        });
        for (var i = 0; i < headers.length; i++) {
            excel.set(0, i, undefined, "auto");
            excel.set(0, i, current_Row, headers[i], header_Style);

        }

        current_Row += 1;

        let defaultcells_Style = excel.addStyle({
            border: "thin #000000,thin #000000,thin #000000,thin #000000",
            font: "Calibri 12 #000000",
            format: $JExcel.formats[0],
            align: "C C W"
        });

        for (var i = current_Row; i < data.length + current_Row; i++) {
            let a = data[i - current_Row];

            for (var j = 0; j < headers.length; j++) {
                excel.set(0, j, i, a[j] != undefined ? a[j] : null, defaultcells_Style);
            }
        }
        if (footer != null) {
            current_Row = data.length + current_Row;
            let footer_Style = excel.addStyle({
                font: "Calibri 12 #000000 B",
                fill: "#F0F0F0",
                align: "L C W"
            });
            for (var j = 0; j < footer.length; j++) {
                excel.set(0, j, current_Row, footer[j] != undefined ? footer[j] : null, footer_Style);
            }

        }
        title = title.replace("\n", " ");
        excel.generate(title + ".xlsx");
        return true;

    }
}