/*Vue.component('page-heading', {*/
<template>
    <div class="row justify-content-between align-items-center mb-4">
        <!-- Page Heading Start -->
        <div class="col-auto  mb-2">
            <h3 class="mb-0">{{ heading }}</h3>
        </div>
        <!-- Page Heading End -->
        <!-- Page Button Group Start -->
        <div class=" col-auto mb-2">
            <!-- <span>{{date}}</span>-->
            <div class="col actions" style="justify-content: end !important">
                <button class="btn add" @click="ShowCreateModalCard()"
                    style="width:40px; border-radius:12px !important; height:40px; padding:0;">
                    <i class="fa fa-plus fa-1x" aria-hidden="true"></i>
                </button>
            </div>
        </div>
        <!-- Page Button Group End -->
    </div>
</template>
<script>
import { feather } from '@/main.js';
export default {
    props: {
        heading: {
            type: String,
            required: true,
            default:"SDSA"
        },
    },
    data() {
        return {
            date: new Date().toLocaleDateString(),
        }
    },
}
</script>