<template>
    <div class="content-body">
        <!-- Page Headings Start -->
        <div class="row align-items-center justify-content-between mb-0">
            <h3><i data-feather="users" class="nav-icon"></i>
                <span class="titlePage"> Клиенты </span>
                <div class="btn-group">
                    <button type="button" title="Обновить" class="btn btn-default btn-smx" :disabled="isBusy"
                        v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" @click="Refresh()"><i
                            class="nav-icon fas fa-sync-alt"></i></button>
                    <button class="btn btn-outline-success btn-add   btn-smx" @click="ShowCreateClientModal()"
                        v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" title="Добавить клиента">
                        <i class="nav-icon fa fa-plus me-1" aria-hidden="true"></i> Добавить клиента
                    </button>
                </div>
            </h3>
        </div>
        <!-- Page Headings End -->

        <div class="card card-layout">
            <div class="card-body p-0">
                <div class="d-flex flex-wrap align-items-baseline justify-content-between p-2" style="gap:5px">
                    <div class="form-check form-switch">
                        <input class="form-check-input " v-model="show_removed" name="removed" type="checkbox"
                            id="removed_clients">
                        <i><b> &laquo;удалённые&raquo; </b>клиенты</i>
                    </div>
                    <data-table-filter v-model="filter"></data-table-filter>
                </div>
                <b-overlay :show="isBusy" :opacity="0.5">
                    <b-table ref="table_admin_clients" :filter="filter" striped hover head-variant="light" sticky-header
                        style="max-height: 60vh" :per-page="perPage" :current-page="currentPage"
                        id="table_admin_clients" stacked="md" :fields="fields" responsive :items="Clients"
                        :busy="isBusy && Clients.length == 0" show-empty primary-key="id">

                        <template #table-busy>
                            <div class="text-center my-2">
                                <strong>Загрузка...</strong>
                            </div>
                        </template>
                        <template #empty>
                            <div v-if="!Admin_clientsGetHaveError && !TerminalsGetHaveError && !UsersGetHaveError"
                                class="text-center my-2">{{ emptyText }}</div>
                            <div v-else class="text-center my-2" style="color:red">Ошибка загрузки</div>
                        </template>
                        <template #emptyfiltered>
                            <div class="text-center my-2">{{ emptyFilteredText }}</div>
                        </template>
                        <template #cell(block)="data">
                            <div class="actions justify-content-center text-center" v-if="data.item.block"
                                style=" display: inline !important; cursor:pointer">
                                <button class="btn edit" @click="ShowBlockClientModal(data.item)"
                                    title="Разблокировать">
                                    <b-icon icon="lock" font-scale="1"></b-icon>
                                </button>
                            </div>
                            <div class="actions justify-content-center text-center" v-else
                                style=" display: inline !important; cursor:pointer">
                                <button @click="ShowBlockClientModal(data.item)" class="btn edit" title="Заблокировать">
                                    <b-icon icon="unlock" font-scale="1"></b-icon>
                                </button>
                            </div>
                        </template>
                        <template #cell(reason_block_date)="data">
                            <div class="d-flex flex-column" v-if="data.item.block">
                                {{ get_date_string(data.item.blockDate) }}<div v-if="data.item.blockReason">{{
                                    data.item.blockReason }}</div>
                            </div>
                            <div class="d-flex justify-content-center" v-else>
                                -
                            </div>
                        </template>
                        <template #cell(name)="data">
                            <div class="d-flex justify-content-between align-items-center" style="gap:5px">
                                <span style="
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;    max-width: 250px;
    ">{{ data.item.name }}<div class="fst-italic" v-if="data.item.email">
                                        {{ data.item.email }}</div></span>
                                <div class="actions justify-content-center"
                                    style=" display: inline !important; cursor:pointer">
                                    <button @click="ShowEditClientModal(data.item)" class="btn edit"
                                        v-if="!data.item.isRemoved" style="float:right"><i class="fa fa-pencil fa-1x"
                                            title="Параметры клиента"></i></button>
                                </div>
                            </div>
                        </template>
                        <template #cell(auth)="data">
                            <div class="actions justify-content-center text-center"
                                style=" display: inline !important; cursor:pointer">
                                <button class="btn edit" title="Авторизоваться под">
                                    <i class="fa fa-solid fa-right-to-bracket fa-1x"></i>
                                </button>
                            </div>
                        </template>
                        <template #cell(delete)="data">
                            <div class="actions justify-content-center text-center"
                                style=" display: inline !important; cursor:pointer">
                                <button class="btn edit" title="Удаление клиента">
                                    <i class="fa fa-trash fa-1x"></i>
                                </button>
                            </div>
                        </template>
                        <template #cell(keys)="data">
                            <b>A:</b> {{ data.item.keys_A }}<br>
                            <b>B:</b> {{ data.item.keys_B }}
                        </template>
                        <template #cell(users)="data">
                            <div class="d-flex justify-content-between align-items-center " style="gap:5px">
                                <div class="d-none d-md-block">
                                    <span :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }"
                                        v-if="data.item.users?.length == 0 || data.item.users == null">Нет
                                        пользователей</span>
                                    <span :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }"
                                        v-if="data.item.users?.length == 1"><span
                                            class="badge pill bg-light text-dark">{{
                                                data.item.users[0]?.name
                                            }}</span></span>
                                    <span :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }"
                                        v-if="data.item.users != null && data.item.users?.length > 1"><span
                                            class="badge pill bg-light text-dark">{{ data.item.users[0]?.name }}</span>
                                        и {{ (Number(data.item.users.length) - 1) }} ещё </span>
                                </div>
                                <div class="d-block d-md-none">
                                    <span
                                        :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }">{{
                                            data.item.users?.length }}</span>
                                </div>
                                <div class="actions justify-content-center"
                                    style=" display: inline !important; cursor:pointer">
                                    <button @click="ShowUsersModal(data.item)" class="btn edit"
                                        v-if="!data.item.isRemoved" style="float:right"><i class="fa fa-eye fa-1x"
                                            title="Список пользователей"></i></button>
                                </div>
                            </div>
                        </template>
                        <template #cell(terminals)="data">
                            <div class="d-flex justify-content-between align-items-center " style="gap:5px">
                                <div class="d-none d-md-block">
                                    <span :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }"
                                        v-if="data.item.terminals?.length == 0 || data.item.terminals == null">Нет
                                        терминалов</span>
                                    <span :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }"
                                        class=" d-inline-flex align-items-center gap-1"
                                        v-if="data.item.terminals?.length == 1"><span
                                            class="badge d-inline-flex align-items-center gap-1 pill bg-light text-dark">
                                            <div>
                                                <b-icon v-if="!data.item.online" icon="circle-fill"
                                                    style="color: #4F4F4F;" font-scale="1.5" title="Не активен">
                                                </b-icon>
                                                <b-icon v-else icon="circle-fill" style="color: #15891B;"
                                                    font-scale="1.2" title="Активен"></b-icon>
                                            </div>
                                            {{ data.item.terminals[0]?.name
                                                + ' (' + data.item.terminals[0]?.imei + ')' }}
                                        </span></span>
                                    <span :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }"
                                        class=" d-inline-flex align-items-center gap-1"
                                        v-if="data.item.terminals?.length > 1">
                                        <span
                                            class="badge d-inline-flex align-items-center gap-1 pill bg-light text-dark">
                                            <div>
                                                <b-icon v-if="!data.item.online" icon="circle-fill"
                                                    style="color: #4F4F4F;" font-scale="1.5" title="Не активен">
                                                </b-icon>
                                                <b-icon v-else icon="circle-fill" style="color: #15891B;"
                                                    font-scale="1.2" title="Активен"></b-icon>
                                            </div>
                                            {{ data.item.terminals[0]?.name + ' (' + data.item.terminals[0]?.imei + ')'
                                            }}
                                        </span> и {{ (Number(data.item.terminals.length) - 1) }} ещё </span>
                                </div>
                                <div class="d-block d-md-none">
                                    <span
                                        :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }">{{
                                            data.item.terminals?.length }}</span>
                                </div>
                                <div class="actions justify-content-center" style=" cursor:pointer">
                                    <button @click="ShowTerminalsModal(data.item)" class="btn edit"
                                        v-if="!data.item.isRemoved" style="float:right"><i class="fa fa-eye fa-1x"
                                            title="Список терминалов"></i></button>
                                </div>
                            </div>
                        </template>
                    </b-table>
                    <!--  <div class="d-flex flex-column flex-md-row text-start align-items-baseline m-2"
                        :class="{ 'justify-content-end': rows <= perPage, 'justify-content-between': rows > perPage }">
                       <b-pagination v-if="rows > perPage" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="table_admin_clients" size="sm">
                        </b-pagination>
                        <div>
                            <div v-html="CaptionText" />
                            <div class="d-flex align-items-center"> Показывать 
                                <bs-select v-model="perPage" style="width:75px; height:28px !important; "
                                    class=" mx-1 p-0 " :options="rows_to_display_computed"
                                    @input="SaveToLocalStorage" />
                                записей
                            </div>
                        </div>
                    </div>-->
                    <pagination :total-rows="rows" :perPage.sync="perPage" :currentPage.sync="currentPage"
                        aria-controls="table_admin_clients"></pagination>


                </b-overlay>
            </div>
        </div>
        <!-- #region modals -->
        <!--********************************************************** Создание / Изменение клиента   *******************************************-->
        <bs-modal centered id="CreateEditClientModal" :loading="loading" ref="CreateEditClientModal"
            :title="edit ? 'Свойства клиента' : 'Новый клиент'">
            <div class="col-12 mb-6">
                <form ref="CreateEditClientForm" id="CreateEditClientForm" @submit.prevent="CreateEditClient">
                    <div class="row mb-n7 align-items-center">
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Наименование</label></div>
                            <div class="col-sm-8 col-12 align-self-center"><input type="text" :disabled="loading"
                                    v-model="client_name" class="form-control" placeholder="Техноген" required /></div>
                            <!--pattern="^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$"-->
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">E-mail</label></div>
                            <div class="col-sm-8 col-12 align-self-center"><input type="email" :disabled="loading"
                                    v-model="e_mail" class="form-control" placeholder="username@domain.com" />
                            </div>
                            <!--pattern="^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$"-->
                        </div>
                        <fieldset v-if="!edit">
                            <legend>Пользователь</legend>
                            <div class="mb-2 d-flex flex-column gap-1">
                                <b-card style="border:1px solid black" no-body border-variant="dark">
                                    <template #header>
                                        <b-row class="align-items-center">
                                            <div class="col-12" style="cursor:pointer">
                                                <input type="text" v-model="name" placeholder="Имя пользователя"
                                                    :disabled="loading" class="form-control" required />
                                            </div>
                                        </b-row>
                                    </template>
                                    <b-card-body>
                                        <div class="d-flex flex-column">
                                            <div class="row mb-2">
                                                <div class="col-sm-4 col-12 align-self-center"><label
                                                        class="form-label font-weight-bold">Логин:</label></div>
                                                <div class="col-sm-8 col-12 align-self-center">
                                                    <input type="text" v-model="login" :disabled="loading"
                                                        placeholder="login" class="form-control" required />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-sm-4 col-12 align-self-center"><label
                                                        class="form-label font-weight-bold">Пароль:</label></div>
                                                <div class="col-sm-8 col-12 align-self-center">
                                                    <input type="text" v-model="password" placeholder="password"
                                                        :disabled="loading" class="form-control" required />
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </div>
                        </fieldset>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button form="CreateEditClientForm" :disabled="loading" type="submit"
                        class="btn btn-sm btn-primary text-center align-items-center">
                        <span v-if="!loading">Сохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--********************************************************** Добавление нового пользователя  *******************************************-->
        <bs-modal centered id="CreateUserModal" :loading="loading" ref="CreateUserModal" style="z-index: 10001;"
            title='Новый пользователь'>
            <div class="col-12 mb-6">
                <form ref="CreateUserForm" id="CreateUserForm" @submit.prevent="CreateEditUsers">
                    <div class="row mb-n7 align-items-center">
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Имя
                                    пользователя:</label></div>
                            <div class="col-sm-8 col-12 align-self-center">
                                <input type="text" v-model="name" :disabled="loading" placeholder="Имя пользователя"
                                    class="form-control" required />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Логин:</label></div>
                            <div class="col-sm-8 col-12 align-self-center">
                                <input type="text" v-model="login" :disabled="loading" placeholder="login"
                                    class="form-control" required />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Пароль:</label></div>
                            <div class="col-sm-8 col-12 align-self-center">
                                <input type="text" v-model="password" placeholder="password" :disabled="loading"
                                    class="form-control" required />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button form="CreateUserForm" :disabled="loading" type="submit"
                        class="btn btn-sm btn-primary text-center align-items-center">
                        <span v-if="!loading">Сохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--********************************************************** Модальное  пользователей системы-->
        <bs-modal centered id="UsersModal" :loading="loading" ref="UsersModal" modal-fullscreen hide-footer
            noDefaultTitle :title="'Cписок пользователей'">

            <template #title>
                <div class="modal-title me-1">
                    <h5> Cписок пользователей</h5>
                    <div>Клиент: {{ ClientName }}</div>
                </div>
            </template>


            <template #headerActions>
                <button class="btn btn-outline-success btn-sm btn-add" @click="ShowCreateUserModal()"
                    title="Новый пользователь">
                    <i class="nav-icon fa fa-plus me-1" aria-hidden="true"></i>Новый пользователь
                </button>



            </template>
            <div class="container">
                <div class="row mb-n7 align-items-center">
                    <b-input-group>
                        <b-form-input type="search" v-model="filter_modal" placeholder="Поиск..."
                            :disabled="users.length == 0"></b-form-input>
                        <b-input-group-append>
                            <b-input-group-text style="border-radius:0px">
                                <b-icon icon="search" />
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                    <div v-if="UsersFilter?.length == 0" class="col-12 p-2 text-center" style="font-size:20px">
                        <span v-if="users.length == 0">Список
                            пользователей
                            пуст</span>
                        <span>Не найдено</span>
                    </div>

                    <div v-if="UsersFilter?.length > 0" class="col-lg-4 col-md-6 col-12 " v-for="elem in UsersFilter "
                        :key="elem.id">
                        <form :id="'EditUserForm-' + elem.id" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;"
                            class="p-2 m-2 border rounded" @submit.prevent="CreateEditUsers">
                            <fieldset>
                                <legend>
                                    <input type="text" v-model="name" v-if="edit && user_id === elem.id"
                                        :disabled="loading" class="form-control" required />
                                    <span v-else> {{ elem?.name }} </span>
                                </legend>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Логин:</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <input type="text" v-model="login" v-if="edit && user_id === elem.id"
                                            :disabled="loading" class="form-control" required />
                                        <span v-else>{{ elem?.login }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Пароль:</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <input type="text" v-model="password" v-if="edit && user_id === elem.id"
                                            :disabled="loading" class="form-control" required />
                                        <span v-else>{{ elem?.password }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Дата последнего подключения:</label>
                                    </div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        {{ get_date_string(elem?.lastLoginUTC) }}
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-12 align-self-center ">
                                        <div class="col actions justify-content-start py-2" style="gap:1em">
                                            <!--     <button v-if="!edit || user_id!==elem.id" type="button" class="btn edit" @click=ShowBlockUserModal(elem); title="Заблокировать пользователя"> 
                                        <b-icon icon="unlock" font-scale="2"></b-icon>
                                    </button>-->

                                            <button v-if="!edit || user_id !== elem.id" @click="LoginLikeUser(elem.id)"
                                                class="btn edit" type="button" title="Авторизоваться под">
                                                <i class="fa fa-solid fa-right-to-bracket fa-1x"></i>
                                            </button>


                                            <button v-if="!edit || user_id !== elem.id" type="button" class="btn edit"
                                                @click="ShowEditUsersModal(elem)" title="Изменить пользователя">
                                                <i class="fa fa-pencil fa-1x" aria-hidden="true"></i>
                                            </button>
                                            <button v-if="!edit || user_id !== elem.id" type="button" class="btn delete"
                                                @click=ShowDeleteUserModal(elem); title="Удаление пользователя">
                                                <i class="fa fa-trash fa-1x" aria-hidden="true"></i>
                                            </button>
                                            <button v-if="edit && user_id === elem.id" type="submit" class="btn add"
                                                title="Cохранить" @click=" permission_is_available = true">
                                                <i class="fa  fa-check fa-1x" aria-hidden="true"></i>
                                            </button>
                                            <button v-if="edit && user_id === elem.id" type="button" class="btn delete"
                                                @click=" edit = false; user_id = null" title="Отменить">
                                                <i class="fa fa-times fa-1x" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </bs-modal>
        <!--********************************************************** Модальное  терминалов клиента-->
        <bs-modal centered id="TerminalsModal" modal-fullscreen :loading="loading" ref="TerminalsModal" hide-footer
            noDefaultTitle>
            <template #title>
                <div class="modal-title me-1">
                    <h5> Cписок терминалов</h5>
                    <div>Клиент: {{ ClientName }}</div>
                </div>
            </template>
            <div class="container">
                <div class="row mb-n7 align-items-center">


                    <b-input-group>
                        <b-form-input type="search" v-model="filter_modal" placeholder="Поиск..."
                            :disabled="terminals.length == 0"></b-form-input>
                        <b-input-group-append>
                            <b-input-group-text style="border-radius:0px">
                                <b-icon icon="search" />
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                    <div v-if="TerminalsFilter?.length == 0" class="col-12 p-2 text-center" style="font-size:20px">
                        <span v-if="terminals.length == 0">Список терминалов клиент пуст</span>
                        <span v-else>Не найдено</span>
                    </div>



                    <div v-if="TerminalsFilter?.length > 0" class="col-lg-4 col-md-6 col-12 "
                        v-for="elem in TerminalsFilter" :key="elem.imei">
                        <form :id="'EditTerminalForm-' + elem.imei" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;"
                            class="p-2 m-2  rounded  border" :class="{ 'border-danger': elem?.isRemoved }">
                            <fieldset>
                                <legend>
                                    {{ elem?.name }}
                                </legend>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">IMEI:</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <span>{{ elem?.imei ?? 'Не задан' }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Версия ПО:</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <span>{{ elem?.softwareVersion > 0 ? elem?.softwareVersion : 'Не определена' ??
                                            `Не
                                            определена` }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Статус:</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <span :class="{ 'text-success': elem?.online }">{{ elem?.online ? 'Активен' :
                                            `Не
                                            активен` }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Дата
                                            подключения:</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <span>{{ get_date_string(elem?.lastConnectionDateTime) }}</span>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">
                                            Cостояние:</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <span :class="{ 'text-danger': elem?.isRemoved }">{{ elem?.isRemoved ? 'Удалён'
                                            :
                                            'Не удалён' }}</span>

                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-12 align-self-center ">
                                        <div class="col actions justify-content-start py-2"
                                            style="gap:1em; min-height: 34px;">
                                            <!--     <button v-if="!edit || user_id!==elem.id" type="button" class="btn edit" @click=ShowBlockUserModal(elem); title="Заблокировать пользователя"> 
                                        <b-icon icon="unlock" font-scale="2"></b-icon>
                                    </button>-->

                                            <button v-if="!elem?.isRemoved"
                                                @click="ShowChangeTerminalClientModal(elem.id)" class="btn edit"
                                                type="button" title="Передача терминала другому клиенту">
                                                <i class="fa-sharp fa-regular fa-user fa21x"></i>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>

                </div>
            </div>
        </bs-modal>

        <bs-modal centered id="ChangeTerminalClientModal" :loading="loading" ref="ChangeTerminalClientModal"
            noDefaultTitle style="z-index: 10001;">
            <template #title>
                <div class="modal-title">
                    <h5> Перенос терминала</h5>
                    <div>{{ TerminalName }}</div>
                    <div>{{ 'IMEI: ' + TerminalIMEI }}</div>
                </div>

            </template>
            <div class="col-12 mb-6">
                <form ref="ChangeTerminalClientForm" id="ChangeTerminalClientForm"
                    @submit.prevent="ChangeTerminalClient()">
                    <div class="row mb-n7 align-items-center">
                        <fieldset>
                            <legend>Клиент</legend>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Текущий клиент:</label></div>
                                <div class="col-sm-8 col-12 align-self-center">
                                    <input type="text" :value="ClientName" readonly disabled
                                        placeholder="Имя пользователя" class="form-control" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Новый
                                        клиент:</label>
                                </div>
                                <div class="col-sm-8 col-12 align-self-center">
                                    <multiselect trackBy="id" valueProp="value" :searchable="true" locale="ru"
                                        :allowEmpty="true" placeholder="Выберите клиента" id="CLients"
                                        :close-on-select="false" mode="single" :hideSelected="false" label="text"
                                        required v-model="new_client_id" :options="ClientsChangeSelect"
                                        :disabled="loading" @change="changeClient">
                                        <template v-slot:nooptions>
                                            <div class="d-flex w-100 align-items-center justify-content-center my-2">Нет
                                                элементов</div>
                                        </template>
                                        <template v-slot:noresults>
                                            <div class="d-flex w-100 align-items-center justify-content-center my-2"> Не
                                                найдено</div>
                                        </template>
                                    </multiselect>


                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Организации</legend>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Организации:</label>
                                </div>
                                <div class="col-sm-8 col-12 align-self-center">
                                    <multiselect trackBy="id" valueProp="value" :searchable="true" locale="ru"
                                        :allowEmpty="true" placeholder="Выберите организации" id="TerminalOrganization"
                                        mode="tags" :hideSelected="false" label="text" required
                                        v-model="terminalOrganizations" :options="AllOrganizationSelect"
                                        :disabled="new_client_id == null || loading">
                                        <template v-slot:nooptions>
                                            <div class="d-flex w-100 align-items-center justify-content-center my-2">Нет
                                                элементов</div>
                                        </template>
                                        <template v-slot:noresults>
                                            <div class="d-flex w-100 align-items-center justify-content-center my-2"> Не
                                                найдено</div>
                                        </template>
                                    </multiselect>
                                </div>

                                <div class="w-100 d-flex align-itemes-center justify-content-end py-1">
                                    <button type="button" title="Новый продукт"
                                        class="btn btn-outline-success btn-sm btn-add"
                                        @click="ShowCreateOrganizationModal()"
                                        :disabled="new_client_id == null || loading">
                                        Новая
                                        организация
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Тип ГСМ</legend>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">ГСМ:</label>
                                </div>
                                <div class="col-sm-8 col-12 align-self-center">
                                    <multiselect trackBy="id" valueProp="value" :searchable="true" locale="ru"
                                        :allowEmpty="true" placeholder="Выберите тип ГСМ" id="CLients"
                                        :close-on-select="false" mode="single" :hideSelected="false" label="text"
                                        required v-model="terminalProduct" :options="AllProductsSelect"
                                        :disabled="new_client_id == null || loading">
                                        <template v-slot:nooptions>
                                            <div class="d-flex w-100 align-items-center justify-content-center my-2">Нет
                                                элементов</div>
                                        </template>
                                        <template v-slot:noresults>
                                            <div class="d-flex w-100 align-items-center justify-content-center my-2"> Не
                                                найдено
                                            </div>
                                        </template>
                                    </multiselect>
                                </div>
                                <div class="w-100 d-flex align-itemes-center justify-content-end py-1">
                                    <button type="button" title="Новая организация"
                                        class="btn btn-outline-success btn-sm btn-add"
                                        @click="ShowCreateOrganizationModal()"
                                        :disabled="new_client_id == null || loading"> Новый продукт </button>
                                </div>
                            </div>

                        </fieldset>

                        <div class="row mb-2">
                            <div class="col-12 align-self-center">
                                <ul class="m-0">
                                    <li> При переносе терминала тип ГСМ на всех датчиках и колонках будет изменён на
                                        выбранный!
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button form="ChangeTerminalClientForm" :disabled="loading" type="submit"
                        class="btn btn-sm btn-primary text-center align-items-center">
                        <span v-if="!loading">Сохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--********************************************************** Модальное  блокировки клиента-->

        <bs-modal centered id="BlockClientModal" ref="BlockClientModal" :title="!block_status ? 'Блокировка клиента'
            :
            'Разблокировка клиента'">
            <div class="col-12 mb-6">
                <form ref="BlockClientModalForm" id="BlockClientModalForm" @submit.prevent="BlockClient">
                    <div class="row mb-n7 align-items-center">
                        <div class="row mb-2">
                            <div class="col-12 gap-1">
                                <p class=" h4 text-start">Вы действительно хотите {{ !block_status ? `заблокировать
                                    клиента`: `разблокировать клиента` }} <br>
                                    <strong>"{{ Admin_clientByGUID(client_id)?.name }}"{{
                                        Admin_clientByGUID(client_id)?.email ?
                                            `(${Admin_clientByGUID(client_id)?.email})` :
                                            ''
                                    }}</strong>?
                                </p>
                            </div>
                        </div>
                        <div class="row mb-2 " v-if="!block_status">
                            <div class="col-12 align-self-center mb-1"><label
                                    class="form-label font-weight-bold">Причина
                                    блокировки:</label></div>
                            <div class="col-12 align-self-center">
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                    v-model="reason" required></textarea>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12  ">
                    <button :disabled="loading" form="BlockClientModalForm" type="submit"
                        class="btn btn-sm btn-primary text-center align-items-center">
                        <span>Подтвердить</span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--********************************************************** Модальное  блокировки пользователя-->

        <bs-modal centered id="BlockUserModal" ref="BlockUserModal" no-close-button noClose staticModal hide-footer
            title="Блокировка пользователя">
            <div class="col-12 mb-6">
                <form ref="form" @submit.prevent="BlockUser">
                    <div class="row mb-n7 align-items-center">
                        <div class="row ">
                            <div class="col-12">
                                <p class=" h4" style="text-align: center;">Вы
                                    действительно хотите {{ !block_status ? 'заблокировать пользователя' :
                                        `разблокировать
                                    пользователя`}} <br>
                                    <strong>"{{ name + ' (' + login + ')' }}"</strong>?
                                </p>
                            </div>
                        </div>
                        <div class="col-12 mb-2 d-flex" style="justify-content: space-evenly;">
                            <button :disabled="loading" type="submit"
                                class="btn btn-primary text-center align-items-center">
                                <span>Да</span>
                            </button>
                            <button :disabled="loading" type="button" @click=' $refs["BlockUserModal"].hide();'
                                class="btn btn-primary text-center align-items-center">
                                <span>Нет</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </bs-modal>
        <!--********************************************************** Модальное  удаления пользователя-->

        <bs-modal centered id="DeleteUserModal" ref="DeleteUserModal" no-close-button noClose staticModal hide-footer
            title="Удаление пользователя">
            <div class="col-12 mb-6">
                <form ref="form" @submit.prevent="DeleteUser">
                    <div class="row mb-n7 align-items-center">
                        <div class="row ">
                            <div class="col-12">
                                <p class=" h4" style="text-align: center;">Вы
                                    действительно хотите удалить пользователя <br>
                                    <strong>"{{ name + '( ' + login + ' )' }}"</strong>?
                                </p>
                            </div>
                        </div>
                        <div class="col-12 mb-2 d-flex" style="justify-content: space-evenly;">
                            <button :disabled="loading" type="submit"
                                class="btn btn-primary text-center align-items-center">
                                <span>Да</span>
                            </button>
                            <button :disabled="loading" type="button" @click=' $refs["DeleteUserModal"].hide();'
                                class="btn btn-primary text-center align-items-center">
                                <span>Нет</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </bs-modal>

        <!--Модалка организации-->
        <bs-modal centered id="CreateOrganizationModal" :loading="loading" ref="CreateOrganizationModal"
            :title="'Новая организация'">
            <div class="col-12 mb-6">
                <form ref="CreateOrganizationModalForm" id="CreateOrganizationModalForm"
                    @submit.prevent="CreateOrganization()">
                    <div class="row mb-n7 align-items-center">
                        <div class="col-12 ">
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Наименование</label></div>
                                <div class="col-sm-8 col-12 align-self-center"><input type="text" :disabled="loading"
                                        v-model="organization.name" placeholder='ООО "Новая Организация"'
                                        class="form-control" required /></div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button form="CreateEditModalOrganizationForm" :disabled="loading" type="submit"
                        class="btn btn-sm btn-primary text-center align-items-center">
                        <span v-if="!loading">Cохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--Модалка продуктов-->
        <bs-modal centered id="CreateProducttModal" :loading="loading" ref="CreateProductModal"
            :title="'Новый продукт'">
            <div class="col-12 mb-6">
                <form ref="CreateProductForm" id="CreateProductForm" @submit.prevent="CreateProduct()">
                    <div class="row mb-n7 align-items-center">
                        <div class="col-12 ">
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Наименование</label></div>
                                <div class="col-sm-8 col-12 align-self-center"><input type="text"
                                        placeholder="Дизельное топливо" :disabled="loading" v-model="product.name"
                                        class="form-control" required /></div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Сокр.
                                        наим. &nbsp; <b-icon icon="question-circle"
                                            v-b-tooltip="{ id: 'svgCircleQuestion', title: 'Значение отображается на экране терминала', placement: 'top', variant: 'info', customClass: 'my-tooltip-class' }"
                                            style="cursor:pointer"></b-icon> </label>
                                </div>
                                <div class="col-sm-8 col-12 align-self-center"><input type="text" placeholder="ДТ"
                                        :disabled="loading" v-model="product.hardwareName" class="form-control"
                                        required />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button form="CreateOrEditeProductForm" :disabled="loading" type="submit"
                        class="btn btn-sm btn-primary  btn-sm text-center align-items-center">
                        <span v-if="!loading">Сохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!-- #endregion modals -->

    </div>
</template>
<script>
import {
    textC_alignM,
    textL_alignM
} from '@/helpers/const'
import {
    mapGetters
} from 'vuex';
import * as exp from '@/helpers/actions.js';
import {
    feather
} from '@/main.js';
import indDB from '@/helpers/IndexedDB'
Object.entries(exp).forEach(([name, exported]) => {
    window[name] = exported;
});
export default {
    data() {
        return {
            emptyFilteredText: "Не найдено",

            emptyText: "Нет клиентов",
            rows_to_display: [{
                text: "1",
                value: 1
            },
            {
                text: "25",
                value: 25
            },
            {
                text: "50",
                value: 50
            },
            {
                text: "100",
                value: 100
            },
            {
                text: 'ALL',

                value: -1
            }
            ],
            show_removed: false,
            filter: "",
            action: {
                type: null,
                method: null,
            },
            terminal_id: null,
            client_id: null,
            new_client_id: null,
            e_mail: null,
            reason: null,
            block_status: false,
            edit: false,
            user_id: null,
            client_name: null,
            name: null,
            filter_modal: "",
            login: null,
            password: null,
            terminals: [],
            permission_is_available: false,
            empty_text: "Нет клиентов",
            perPage: 100,
            currentPage: 1,
            users: [],
            fields: [
                {
                    key: 'name', label: "Имя",
                    sortable: true,
                    thClass: textC_alignM,
                    class: textL_alignM,

                },
                {
                    key: 'users',
                    label: "Пользователи",
                    sortable: false,
                    thClass: textC_alignM,
                    class: textL_alignM,
                },
                {
                    key: 'keys',
                    label: "Ключи",
                    sortable: false,
                    thClass: textC_alignM,
                    class: textL_alignM,
                },
                // { key: 'blockReason', label: "Причина", sortable: true, thClass: textC_alignM, tdСlass: textL_alignM, },
                // { key: 'name', label: "Наименование", sortable: true, thClass: textC_alignM, tdСlass: textL_alignM, },
                //{ key: 'keys_B', label: "Ключ Б", sortable: true, thClass: textC_alignM, tdСlass: textL_alignM, },
                {
                    key: 'terminals',
                    label: "Терминалы",
                    sortable: false,
                    thClass: textC_alignM,
                    class: textL_alignM,
                },
                {
                    key: 'reason_block_date',
                    label: "Дата/причина блокировки",
                    sortable: false,
                    thClass: textC_alignM,
                    class: textL_alignM,
                },
                /* {
                     key: 'auth',
                     label: "",
                     sortable: false,
                     thClass: textC_alignM,
                     class: textC_alignM,
                 },*/
                {
                    key: 'block',
                    label: "",
                    sortable: false,
                    thClass: textC_alignM,
                    class: textC_alignM,
                },
                {
                    key: 'delete',
                    label: "",
                    sortable: false,
                    thClass: textC_alignM,
                    class: textC_alignM,
                },
            ],
            action: {
                type: null,
                method: null,
            },
            organization: {
                name: null,
            },
            defaultOrganization: {
                name: null,
            },
            product: {
                name: null,
                hardwareName: null,
            },
            defaultProduct: {
                name: null,
                hardwareName: null,
            },
            terminalOrganizations: [],
            terminalProduct: null,
        }
    },
    computed: {
        rows_to_display_computed() { return this.rows_to_display.concat([{ text: "Все", value: this.rows }]) },

        CaptionText() {
            return `Показано с ${this.currentPage * this.perPage - this.perPage + 1} по ${this.currentPage * this.perPage <= this.rows ? this.currentPage * this.perPage : this.rows} из ${this.rows} записей`
        },
        Clients() {
            if (this.show_removed) return this.AllAdmin_clients
            else return this.AllAdmin_clients.filter(a => !a.isRemoved)
        },
        AllOrganizationSelect() {
            return this.AllOrganizations?.filter(t => t.isRemoved == false)?.map(element => {
                return {
                    text: element.name,
                    value: element.id,
                }
            })
        },
        AllProductsSelect() {
            return this.AllProducts?.filter(t => t.isRemoved == false)?.map(element => {
                return {
                    text: element.hardwareName,
                    value: element.hardwareID,
                }
            }) ?? []
        },
        TerminalsFilter() {
            var filtered_items = []
            if (this.filter_modal == "") return this.terminals;
            else {
                this.terminals?.forEach(element => {
                    if (element.imei.indexOf(this.filter_modal) >= 0) filtered_items.push(element);
                    else if (element.name.toLowerCase().indexOf(this.filter_modal.toLowerCase()) >= 0) filtered_items.push(element);
                });
                return filtered_items;
            }
        },
        UsersFilter() {
            var filtered_items = []
            if (this.filter_modal == "") return this.users;
            else {
                this.users?.forEach(element => {
                    if (element.name.toLowerCase().indexOf(this.filter_modal.toLowerCase()) >= 0) filtered_items.push(element);
                    else if (element.login.toLowerCase().indexOf(this.filter_modal.toLowerCase()) >= 0) filtered_items.push(element);
                });
                return filtered_items;
            }
        },
        ClientsChangeSelect() {

            return this.AllAdmin_clients.filter(item => item.id != this.client_id).map(element => {
                var name = this.Admin_clientByGUID(element.id)?.name ?? "";
                var e_mail = this.Admin_clientByGUID(element.id)?.email ? ` (${this.Admin_clientByGUID(element.id)?.email})` : "";
                return {
                    text: name + e_mail,
                    value: element.id,
                }
            })


        },
        ClientName() {
            var name = this.Admin_clientByGUID(this.client_id)?.name ?? "";
            var e_mail = this.Admin_clientByGUID(this.client_id)?.email ? ` (${this.Admin_clientByGUID(this.client_id)?.email})` : "";

            return name + e_mail
        },
        TerminalName() { return this.terminals.find(a => a.id == this.terminal_id)?.name ?? null },
        TerminalIMEI() { return this.terminals.find(a => a.id == this.terminal_id)?.imei ?? null },
        loading() {
            switch (this.action.method) {
                case 'get':
                    if (this.action.type === 'admin_clients') return this.Admin_clientsGetAreLoading;
                    if (this.action.type === 'users') return this.UsersGetAreLoading;
                case 'post':
                    if (this.action.type === 'admin_clients') return this.Admin_clientsPotsAreLoading;
                    if (this.action.type === 'users') return this.UsersPotsAreLoading;
                case 'put':
                    if (this.action.type === 'terminal_transfer') return this.TerminalsPutAreLoading
                    if (this.action.type === 'admin_clients') return this.Admin_clientsPutAreLoading;
                    if (this.action.type === 'users') return this.UsersPutAreLoading;
                case 'delete':
                    if (this.action.type === 'admin_clients') return this.Admin_clientsDeletereLoading;
                    if (this.action.type === 'users') return this.UsersDeleteAreLoading;
                default:
                    return null
            }
        },
        rows() {
            return this.AllAdmin_clients.length
        },
        isBusy() {
            return this.Admin_clientsGetAreLoading ? true : false;

        },
        ...mapGetters([

            'AllAdmin_clients',
            'Admin_clientByGUID',
            'Admin_clientsOnceLoaded',
            'Admin_clientsGetAreLoading',
            'Admin_clientsGetHaveError',
            'Admin_clientsPostAreLoading',
            'Admin_clientsPostHaveError',
            'Admin_clientsPutAreLoading',
            'Admin_clientsPutHaveError',
            'Admin_clientsDeleteAreLoading',
            'Admin_clientsDeleteHaveError',
            'AllTerminals',
            'AllProducts',
            'AllOrganizations',
            'TerminalByGUID',
            'TerminalsOnceLoaded',
            'TerminalsGetAreLoading',
            'TerminalsGetHaveError',
            'TerminalsPostAreLoading',
            'TerminalsPostHaveError',
            'TerminalsPutAreLoading',
            'TerminalsPutHaveError',
            'TerminalsDeleteAreLoading',
            'TerminalsDeleteHaveError',
            'AllUsers',
            'UserByGUID',
            'UsersOnceLoaded',
            'UsersGetAreLoading',
            'UsersGetHaveError',
            'UsersPostAreLoading',
            'UsersPostHaveError',
            'UsersPutAreLoading',
            'UsersPutHaveError',
            'UsersDeleteAreLoading',
            'UsersDeleteHaveError',
        ])
    },
    mounted() {
        feather.replace();
    },
    async created() {
        if (!this.Admin_clientsOnceLoaded) {
            await this.$store.dispatch(ADMIN_CLIENTS_GET_REQUEST)
        }
        /*     if (!this.UsersOnceLoaded) {
                this.$store.dispatch(USERS_GET_REQUEST)
            }
            if (!this.TerminalsOnceLoaded) {
                this.$store.dispatch(TERMINALS_GET_REQUEST)
            } */
    },

    methods: {
        Refresh() {
            this.$store.dispatch(ADMIN_CLIENTS_GET_REQUEST)
        },
        changeClient(event) {
            this.terminalProduct = null;
            this.terminalOrganizations = [];
            this.product = this.defaultProduct;
            this.organizacardtions = this.defaultOrganization;
            this.$store.dispatch(ADMIN_ORGANIZATIONS_GET_REQUEST, event);
            this.$store.dispatch(ADMIN_PRODUCTS_GET_REQUEST, event);


        },
        ShowCreateOrganizationModal() {
            this.organization = this.defaultOrganization;
            this.$refs.CreateOrganizationModal.show();
            setTimeout(() => {
                document.querySelectorAll('.modal-backdrop')[0].style.zIndex = "10000"
            }, 0);
        },
        CreateOrganization() {
            this.$store.dispatch(ADMIN_ORGANIZATIONS_POST_REQUEST, { _client_id: this.new_client_id, org: this.organization }).then(() => {
                this.$refs.CreateOrganizationModal.success();
            }).catch((err) => {
                this.$refs.CreateOrganizationModal.error(err);
            });
        },

        ShowCreateProductModal() {
            this.product = this.defaultProduct;
            this.$refs.CreateProductModal.show();
            setTimeout(() => {
                document.querySelectorAll('.modal-backdrop')[0].style.zIndex = "10000"
            }, 0);
        },
        CreateProduct() {
            this.$store.dispatch(ADMIN_PRODUCTS_POST_REQUEST, { _client_id: this.new_client_id, product: this.organization }).then(() => {
                this.$refs.CreateProductModal.success();
            }).catch((err) => {
                this.$refs.CreateProductModal.error(err);
            });
        },
        filterTable(row, filter) {
            /*if (filter.length == 0 || filter.length == 1) return true*/
            // if (row.number.split(' ').join('').toLowerCase().indexOf(filter.split(' ').join('').toLowerCase()) > -1 /*&& row.number.toLowerCase()[0] == filter.toLowerCase()[0]*/) return true;
            // else return false
        },
        Clear() {
            this.filter_modal = "";
        },
        ShowChangeTerminalClientModal(id) {
            this.terminal_id = id;
            this.new_client_id = null;
            this.terminalProduct = null;
            this.terminalOrganizations = [];
            this.$refs['ChangeTerminalClientModal'].show();
            setTimeout(() => {
                document.querySelectorAll('.modal-backdrop')[1].style.zIndex = "5000"
            }, 0);

        },
        async ChangeTerminalClient() {
            this.action = {
                type: 'terminal_transfer',
                method: 'put'
            }
            var terminal = JSON.parse(JSON.stringify(this.terminals.find(a => a.id == this.terminal_id)));
            if (terminal != null) {
                var transfer =
                {
                    terminal: terminal,
                    productHardwareID: this.terminalProduct,
                    organizations: this.terminalOrganizations,

                }
                await this.$store.dispatch(TRASFER_TERMINAL_PUT_REQUEST, { transfer, clientID: this.new_client_id, oldClientID: this.client_id }).then(resp => {

                    this.$refs.ChangeTerminalClientModal.success();
                })
                    .catch(err => {
                        this.$refs['ChangeTerminalClientModal'].error(err);
                    })
            }
        },
        async LoginLikeUser(user_id) {
            await indDB.deleteMagicToken().then(async () => {
                await this.$store.dispatch(AUTH_GET_MAGIC_TOKEN_REQUEST, user_id).then(async (response) => {
                    await indDB.addToMagicTokens(response.data.jwt, response.data.userName, response.data.tokenID, response.data.role, response.data.name).then(() => {
                        const routeData = this.$router.resolve({
                            name: 'Cards'
                        });
                        //window.open(routeData.href, '_blank');
                        window.location.replace(routeData.href)
                    })
                })
            })
        },
        get_date_string(elem) {
            return moment(elem).locale('ru').format('DD.MM.YYYY/HH:mm:ss')
        },
        ShowCreateClientModal() {
            this.e_mail = null;
            this.login = null;
            this.name = null;
            this.client_name = null;
            this.password = null;
            this.edit = false;
            this.$refs['CreateEditClientModal'].show();
        },
        ShowEditClientModal(client) {
            this.client_id = client.id;
            this.client_name = client.name;
            this.e_mail = client.email;
            this.edit = true;
            this.$refs['CreateEditClientModal'].show();
        },
        async CreateEditClient() {
            if (this.edit) {
                this.action = {
                    type: 'admin_client',
                    method: 'put'
                }

                let client = JSON.parse(JSON.stringify(this.Admin_clientByGUID(this.client_id)));
                client.email = this.e_mail;
                client.name = this.client_name;
                await this.$store.dispatch(ADMIN_CLIENTS_PUT_REQUEST, client).then(
                    () => {
                        this.$refs['CreateEditClientModal'].success();
                    }
                )
                    .catch(
                        err => {
                            this.$refs['CreateEditClientModal'].error({ action: "stop_hide", err });
                        }
                    )
            } else {
                this.action = {
                    type: 'admin_client',
                    method: 'post'
                }
                {
                    this.$store.dispatch(ADMIN_CLIENTS_POST_REQUEST, {
                        block: false,
                        name: this.client_name,
                        email: this.e_mail,
                        users: [{
                            name: this.name,
                            login: this.login,
                            password: this.password,
                        }],
                        terminals: []
                    })
                        .then(() => {
                            this.$refs['CreateEditClientModal'].success();
                        })
                        .catch(err => {
                            this.$refs['CreateEditClientModal'].error({ action: "stop_hide", err });
                        })
                }
            }
        },
        ShowDeleteUserModal(element) {
            this.$refs['UsersModal'].hide();
            this.name = element.name;
            this.login = element.login;
            this.user_id = element.id;
            this.block_status = element.blocked;
            this.$refs['DeleteUserModal'].show({
                vm: this,
                name: "UsersModal"
            })
        },
        async DeleteUser() {
            this.action = {
                type: 'admin_client',
                method: 'delete'
            }
            await this.$store.dispatch(USERS_DELETE_REQUEST, this.user_id).then(
                () => {
                    this.$refs['DeleteUserModal'].success();
                }
            )
                .catch(
                    err => {
                        this.$refs['DeleteUserModal'].error(err);
                    }
                )
        },
        ShowBlockUserModal(element) {
            this.$refs['UsersModal'].hide();
            this.name = element.name;
            this.login = element.login;
            this.e_mail = element.login;
            this.user_id = element.id;
            this.block_status = element.blocked;
            this.$refs['BlockUserModal'].show({
                vm: this,
                name: "UsersModal"
            })
        },
        async BlockUser() {
            this.action = {
                type: 'users',
                method: 'put'
            }
            let user = JSON.parse(JSON.stringify(this.users.find(el => el.id == this.user_id)));
            user.blocked = !this.block_status;
            await this.$store.dispatch(USERS_PUT_REQUEST, user).then(
                () => {
                    this.$refs['BlockUserModal'].success();
                }
            )
                .catch(
                    (err) => {
                        this.$refs['BlockUserModal'].error(err);
                    }
                )
        },
        ShowBlockClientModal(element) {
            this.client_id = element.id;
            this.reason = null;
            this.block_status = element.block;
            this.e_mail = element.email;
            this.$refs['BlockClientModal'].show();
        },
        async BlockClient() {
            this.action = {
                type: 'admin_clients',
                method: 'put'
            }
            let client = JSON.parse(JSON.stringify(this.Admin_clientByGUID(this.client_id)));
            client.block = !this.block_status;
            client.blockReason = this.reason;
            await this.$store.dispatch(ADMIN_CLIENTS_PUT_REQUEST, client).then(
                () => {
                    this.$refs['BlockClientModal'].success();
                }
            )
                .catch(
                    (err) => {
                        this.$refs['BlockClientModal'].error(err);
                    }
                )
        },
        ShowTerminalsModal(element) {
            this.client_id = element.id
            this.filter_modal = "";
            this.terminals = element.terminals;
            this.$refs['TerminalsModal'].show()
        },
        ShowUsersModal(element) {
            this.filter_modal = "";
            this.client_id = element.id
            this.users = element.users;
            this.email = element.email
            this.edit = false;
            this.user_id = null
            this.$refs['UsersModal'].show()
        },
        ShowCreateUserModal() {
            this.login = null;
            this.name = null;
            this.password = null;
            this.edit = false;
            this.$refs['CreateUserModal'].show();
            setTimeout(() => {
                document.querySelectorAll('.modal-backdrop')[1].style.zIndex = "10000"
            }, 0);
        },
        ShowEditUsersModal(elem) {
            this.permission_is_available = false;
            this.name = elem.name;
            this.login = elem.login;
            this.password = elem.password;
            this.edit = true;
            this.user_id = elem.id;
        },
        async CreateEditUsers() {
            if (this.edit) {
                if (this.permission_is_available) {
                    this.action = {
                        type: 'users',
                        method: 'put'
                    }
                    let user = JSON.parse(JSON.stringify(this.users.find(el => el.id == this.user_id)));
                    user.name = this.name;
                    user.password = this.password;
                    user.login = this.login;
                    await this.$store.dispatch(USERS_PUT_REQUEST, user).then(
                        () => {
                            this.$refs['CreateUserModal'].show()
                            setTimeout(() => {
                                document.querySelectorAll('.modal-backdrop')[1].style.zIndex = "10000"
                            }, 0)
                            this.$refs['CreateUserModal'].success();
                            this.user_id = null;
                            this.edit = false;
                        }
                    )
                        .catch(
                            err => {
                                this.$refs['CreateUserModal'].show()
                                setTimeout(() => {
                                    document.querySelectorAll('.modal-backdrop')[1].style.zIndex = "10000"
                                }, 0)
                                this.$refs['CreateUserModal'].error({ action: "stop_hide", err });

                            }
                        )
                }
            }
            else {
                this.action = {
                    type: 'users',
                    method: 'post'
                }
                await this.$store.dispatch(USERS_POST_REQUEST, { name: this.name, password: this.password, login: this.login, clientID: this.client_id }).then(
                    () => {
                        this.$refs['CreateUserModal'].success();
                    }
                )
                    .catch(
                        err => {
                            this.$refs['CreateUserModal'].error({ action: "stop_hide", err });
                        }
                    )
            }
        },
    },
}
</script>
