<template>
  <div id="app">
    <router-view v-if="isJavascriptEnabled"> </router-view>
    <div v-else class="alert alert-danger" role="alert" style="font-size: 45px;">
      Приложение не работает без включенного JavaScript!!!!
    </div>

  </div>
</template>
<script>
import { feather } from '@/main.js';
export default {
  data() {
    return {
      isJavascriptEnabled: false
    }
  }
  , created() {
    this.isJavascriptEnabled =  !document.querySelector('html').classList.contains('no-js');
  },
  updated() {
    feather.replace();
  },
}
</script>
