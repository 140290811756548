<template>
    <div v-if="totalRows != 0" class="d-flex flex-wrap flex-row text-start align-items-center p-1" style="gap:5px; font-size: 0.875rem;"
        :class="{ 'justify-content-end': (totalRows <= perPage || perPage == -1), 'justify-content-between': (totalRows > perPage && (perPage != -1)) }">
        <b-pagination v-bind="$attrs" v-on="$listeners" v-if="totalRows > perPage && perPage != -1"
            v-model.number="currentPageComputed" :total-rows="totalRows" :per-page="perPage" :limit="limit"
            :aria-controls="ariaControls"  size="sm">

            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope" />
            </template>
        </b-pagination>
        <div>
           
            <div class="d-flex align-items-center">
                <bs-select v-model.number="perPageComputed" style="width:60px; height:25px !important; "
                    class=" mx-1 p-0 " :options="itemsPerPageOptions" @input="SaveToLocalStorage" /> <span v-html="CaptionText" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created() {
        if (this.perPageOptions != null && this.perPageOptions != undefined)
            this.itemsPerPageOptions = this.perPageOptions

    },
    data() {
        return {
            itemsPerPageOptions: [{
                text: "10",
                value: 10
            },
            {
                text: "100",
                value: 100
            },
            {
                text: "1000",
                value: 1000
            },
            {
                text: "Все",
                value: -1
            },],

        }
    },

    props: {
        perPage: {
            required: true,
            default: 1,
        },
        currentPage: {
            required: true,
            default: 1,
        },
        totalRows: {
            type: Number,
            required: true,
            default: 0,
        },
        needSaveLS: {
            type: Boolean,
            default: false,
        },
        perPageOptions: {
            type: Array,
        },
        ariaControls: {
            type: String,
            default: "",
        },

        limit: {
            type: Number,
            default: 2
        }

    },
    computed:
    {
        currentPageComputed: {
            get() {
                return this.currentPage == null ? 1 : this.currentPage;
            },
            set(val) {
                this.$emit('update:currentPage', val);
            }
        },
        perPageComputed: {
            get() {
                return this.perPage == null ? 1 : this.perPage;
            },
            set(val) {
                this.$emit('update:perPage', val);
                this.$emit('update:currentPage', 1);

            }
        },
        CaptionText() {
            var perPage = this.perPage == -1 ? this.totalRows : this.perPage
            return `${this.currentPage * perPage - perPage + 1} - ${this.currentPage * perPage <= this.totalRows ? this.currentPage * perPage : this.totalRows} из ${this.totalRows};`
        },
    },
    methods:
    {
        SaveToLocalStorage(e) {
            if (this.needSaveLS) {
                var a = this.itemsPerPageOptions.find(a => a.value == e);
                if (a != undefined && this.ariaControls != "") localStorage.setItem(this.ariaControls, a.value);

            }
        },
    }

}
</script>