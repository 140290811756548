var render, staticRenderFns
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./SelectV2Component.vue?vue&type=custom&index=0&blockType=select&%3Aid=id&%3Amultiple=multiple&ref=formselect"
if (typeof block0 === 'function') block0(component)

export default component.exports