<template>
  <div class="main-wrapper">
    <!-- Шапка-->
    <header-panel></header-panel>
    <!-- Шапка (конец) -->
    <!-- Боковая навигация -->
    <sidenav-panel></sidenav-panel>
    <!-- Боковая навигация -->
    <!-- Content Body Start -->
    <preloader />
    <transition name="fadeln" mode="out-in">
      <router-view></router-view>
    </transition>
    <!-- Content Body End -->
    <!-- Footer Section Start -->
    <div class="footer-section bg-white py-1 mt-auto">
      <div class="container-fluid">
        <div class="footer-copyright text-center">
          <p class="text-muted">&copy; <a href="https://amsy.ru/index.html#about-us">ООО "АМС" Калуга</a>&nbsp;
            2009-{{ year_now }}</p>
        </div>
      </div>
    </div>
    <!-- Footer Section End -->
  </div>
</template>
<script>
import { textC_alignM, textL_alignM } from '@/helpers/const.js'
import * as exp from '@/helpers/actions.js'
import { mapActions } from 'vuex'
import { eventBus } from '@/main.js'
import { mapGetters } from 'vuex'
import { feather } from '@/main.js';
import indDB from '@/helpers/IndexedDB'

export default {
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval)
  },
  computed: {
    year_now() {
      return moment().year()
    },

    ...mapGetters([
      'ProductsOnceLoaded',
      'IssuedCardsOnceLoaded',
      'CardByGUID',
      'OrganizationsOnceLoaded',
      'CardsOnceLoaded',
      'WalletsOnceLoaded',
      'OrganizationsOnceLoaded',
      'ProductsOnceLoaded',
      'UsersOnceLoaded',
      'TerminalsOnceLoaded',
      'CardsGetAreLoading',
      'CardsGetHaveError',
      'CardsPostAreLoading',
      'CardsPostHaveError',
      'CardsPutAreLoading',
      'CardsPutHaveError',
      'CardsDeleteAreLoading',
      'WalletFindByProdictGUID',
      'WalletsOnceLoaded',
      'AllWallets',
      'AllCards',
      'AllProducts',
      'ProductByGUID',
      'WalletsByCardGUID',
      'WalletByGUID',
      'WalletsGetAreLoading',
      'WalletsGetHaveError',
      'WalletsPostAreLoading',
      'WalletsPostHaveError',
      'WalletsPutAreLoading',
      'WalletsPutHaveError',
      'WalletsDeleteAreLoading',
      'TerminalByGUID',
      'CardsGetAreLoaded',
      'WalletsGetAreLoaded',
      'OrganizationsGetAreLoaded',
      'ProductsGetAreLoaded',
      'UsersGetAreLoaded',
      'TerminalsGetAreLoaded',
      'CardsGetHaveError',
      'WalletsGetHaveError',
      'OrganizationsGetHaveError',
      'ProductsGetHaveError',
      'UsersGetHaveError',
      'TerminalsGetHaveError',

    ]),
  },
  data: function () {
    return {
      debug_socket: false,
      socket: null,
      tryopencount: 0,
      checksocketTimeout: null,
      socketLastMsgTime: new Date(0), // время приема последних данных с сокета
      interval: null,
    }
  },

  beforeDestroy() {
    if (this.socket != null) {
      this.socket.close();
    }
    eventBus.$off('New_message')
  },
  async created() {
    if (!this.IssuedCardsOnceLoaded) {
      await this.$store.dispatch(ISSUEDCARDS_GET_REQUEST);
    }
    if (!this.ProductsOnceLoaded) {
      await this.$store.dispatch(PRODUCTS_GET_REQUEST);
    }

    this.interval = setInterval(() => {
      this.$store.dispatch(ISSUEDCARDS_GET_REQUEST);
    }, 60000 * 2);
  },
  async mounted() {

    feather.replace();
    await eventBus.$on('New_message', async (message) => {
      let msg;
      let title = ``;

      if (message.MessageType === "CardCommandResponse") {

        let card = null;
        let wallet = null;
        let driver = null;
        if (message.WalletID != null) {
          await this.$store.dispatch(WALLETS_GET_BY_GUID_REQUEST, message.WalletID).then(resp => wallet = resp.data.length > 0 ? resp.data[0] : null).catch(() => wallet = null)
        }
        if (message.CardID != null) {
          await this.$store.dispatch(CARDS_GET_BY_GUID_REQUEST, message.CardID).then(resp => card = resp.data.length > 0 ? resp.data[0] : null).catch(() => card = null)

        }

        if (message.DriverID != null) {
          await this.$store.dispatch(exp.DRIVERS_GET_BY_GUID_REQUEST, message.DriverID).then(resp => driver = resp.data.length > 0 ? resp.data[0] : null).catch(() => driver = null)
        }
        let wallet_name = ``;
        if (wallet != null) {

          let product_name = this.ProductByGUID(wallet?.productID)?.hardwareName;
          if (product_name != null && product_name != undefined)
            wallet_name = product_name + `(${wallet.name})`
          else
            wallet_name = `(${wallet.name})`;
        }
        else wallet_name = 'Неизвестный кошелёк';
        if (message.Error == null) {

          switch (message.CardCommandCode) {
            case 1:
              msg = `Кошелёк: ${wallet?.name} (${this.ProductByGUID(wallet?.productID)?.hardwareName}) был удалён`
              this.$store.commit('WALLETS_DELETE_SUCCESS_TRUE', message.WalletID)
              break;
            case 2:
              msg = "Пароль был изменён"
              break;
            case 3:
              msg = `Кошелёк: ${wallet_name} был создан`;
              break;
            case 4:
              msg = `Кошелёк: ${wallet_name} был пополнен`
              break;
            case 5:
              msg = `Кошелёк: ${wallet_name} - были изменены лимиты`
              break;
            case 6:
              msg = "Подразделение было изменено"
              break;
            case 7:
              msg = `Кредитный кошелёк: ${wallet_name} был создан`;
              break;
            case 8:
              msg = `Кошелёк: ${wallet_name} - возврат литров`
              break;
            case 9:
              msg = `Карта водителя успешно активирована`
              break;

          }
          if (message.CardCommandCode == 9) {
            title = `Карта: ${this.NumberSpace(driver?.number)}`
          }
          else {
            title = `Карта: ${this.NumberSpace(card?.number)}<br>Держатель: ${card?.holderName}`
          }
          toastr["info"](msg, title)
          // eventBus.$emit("Complite_command", card.commandQueue)
        }
        else {
          switch (message.CardCommandCode) {
            case 1:
              msg = `Кошелёк: ${wallet_name} не был удалён<br>Код ошибки: ${message.Error}`
              // this.$store.commit('WALLETS_DELETE_SUCCESS_TRUE', message.WalletID)
              break;
            case 2:
              msg = `Пароль не изменён<br>Код ошибки: ${message.Error}`
              break;
            case 3:
              msg = `Кошелёк: ${wallet_name} не был создан<br>Код ошибки: ${message.Error}`;
              break;
            case 4:
              msg = `Кошелёк: ${wallet_name} не был пополнен<br>Код ошибки: ${message.Error}`
              break;
            case 5:
              msg = `Кошелёк: ${wallet_name} - не были изменены лимиты<br>Код ошибки: ${message.Error}`
              break;
            case 6:
              msg = `Подразделение не было изменено<br>Код ошибки: ${message.Error}`
              break;
            case 7:
              msg = `Кредитный кошелёк: ${wallet_name} не был создан<br>Код ошибки: ${message.Error}`;
              break;
            case 8:
              msg = `Кошелёк: ${wallet_name} - возврат средств не успешен<br>Код ошибки: ${message.Error}`;
              break;
            case 9:
              msg = `Карта водителя не была активирована<br>Код ошибки: ${message.Error}`
              break;

          }
          if (message.CardCommandCode == 9) {
            title = `Карта: ${this.NumberSpace(driver?.number)}`
          }
          else {
            title = `Карта: ${this.NumberSpace(card?.number)}<br>Держатель: ${card?.holderName}`
          }
          toastr["error"](msg, title)
        }
      }
      if (message.MessageType === "TerminalCommandResponse") {
        let title = ``;
        let terminal = null;

        await this.$store.dispatch(exp.TERMINALS_GET_BY_GUID_REQUEST, message.TerminalID).then(resp => terminal = resp.data.length > 0 ? resp.data[0] : null).catch(() => terminal = null)
        let terminalInfo=``;
        if(terminal!=null)
        {
          terminalInfo=`Терминал: ${terminal.name} <br>IMEI: ${terminal.imei!=null? terminal.imei :'-'}`
        }
        title=terminalInfo;
        if (message.Error == null) {
          switch (message.TerminalCommandsCode) {
            case 1:
              msg = "Изменена конфигурация терминала"
              break;
            case 2:
              msg = "Изменён список подразделений"
              break;
            case 3:
              msg = "Изменён чёрный список карт"
              break;
            case 4:
              msg = "Изменён список водителей"
              break;
            case 5:
              msg = "Перезапуск оборудования"
              break;
          }
          toastr["info"](msg, title)

        }
        else {
          switch (message.TerminalCommandsCode) {
            case 1:
              msg = `Ошибка изменения конфигурации ТРК<br>Код ошибки: ${message.Error} `
              break;
            case 2:
              msg = `Ошибка изменения списка подразделений<br>Код ошибки: ${message.Error}`
              break;
            case 3:
              msg = `Ошибка изменения чёрного списка карт<br>Код ошибки: ${message.Error}`
              break;
            case 4:
              msg = `Ошибка запроса состояния оборудования<br>Код ошибки: ${message.Error}`
              break;
            case 5:
              msg = `Ошибка перезапуска оборудования<br>Код ошибки: ${message.Error}`
              break;
          }
          toastr["error"](msg, `Терминал ${terminal.name}<br>IMEI: ${terminal.imei}`)
        }
      }
      if (message.MessageType === "Status") {
        await this.$store.dispatch(TERMINALS_GET_BY_GUID_REQUEST, message.TerminalID);

      }
      if(message.MessageType === "GetConfiguration")
      {
        let title = ``;
        let terminal = null;

        await this.$store.dispatch(exp.TERMINALS_GET_BY_GUID_REQUEST, message.TerminalID).then(resp => terminal = resp.data.length > 0 ? resp.data[0] : null).catch(() => terminal = null)
        let terminalInfo=``;
        if(terminal!=null)
        {
          terminalInfo=`Терминал: ${terminal.name} <br>IMEI: ${terminal.imei!=null? terminal.imei :'-'}`
        }
        title=terminalInfo;
        toastr["info"]("Запрошена конфигурация", title)

      }

      if (message.MessageType === "Transaction") {
        let wallet = null;
        let card = null;
        if (message.WalletID != null) {
          await this.$store.dispatch(WALLETS_GET_BY_GUID_REQUEST, message.WalletID).then(resp => wallet = resp.data.length > 0 ? resp.data[0] : null).catch(() => wallet = null)
        }
        if (message.CardID != null) {
          await this.$store.dispatch(CARDS_GET_BY_GUID_REQUEST, message.CardID).then(resp => card = resp.data.length > 0 ? resp.data[0] : null).catch(() => card = null)

        }

        let user = null;
        if (message.UserID != null) {
          await this.$store.dispatch(USERS_BY_GUID_GET_REQUEST, message.UserID).then(resp => user = resp.data.length > 0 ? resp.data[0] : null).catch(() => user = null)
        }
        let transaction_type, transaction_quantity, date_start, date_end;
        let RequestQuantity = (parseFloat(message.RequestQuantity) / 100).toString();
        let Quantity = (parseFloat(message.Quantity) / 100).toString();
        date_start = moment(message.StartUTC).locale('ru').format('DD.MM.YYYY/HH:mm:ss');
        date_end = moment(message.EndUTC).locale('ru').format('DD.MM.YYYY/HH:mm:ss');

        let wallet_name = ``;
        if (wallet != null) {

          let product_name = this.ProductByGUID(wallet?.productID)?.hardwareName;
          if (product_name != null && product_name != undefined)
            wallet_name = product_name + `(${wallet.name})`
          else
            wallet_name = `(${wallet.name})`;
        }
        else wallet_name = 'Неизвестный кошелёк';

        if (message.TransactionType == 0) {

          transaction_type = "Списание"; transaction_quantity = `Запрошено: ${RequestQuantity} л.<br> Отпущено: ${Quantity} л.`
          title = `Тип транзакции: ${transaction_type}<br>${transaction_quantity}<br>Кошелёк: ${wallet_name}`
          msg = `Карта: ${this.NumberSpace(card?.number)}<br>Держатель: ${card?.holderName}<br>Начало транзакции: ${date_start}<br>Окончание транзакции: ${date_end}`

        }
        else if (message.TransactionType == 1) {
          if (message.ReplenishWallet) {
            transaction_type = "Возврат"; transaction_quantity = `Возвращено: ${Quantity} л.`;

          }
          else {
            transaction_type = "Пополнение"; transaction_quantity = `Пополнено: ${Quantity} л.`
          }
          title = `Тип транзакции: ${transaction_type}<br>${transaction_quantity}<br>Кошелёк: ${wallet_name}`
          msg = `Карта: ${this.NumberSpace(card?.number)}<br>Держатель: ${card?.holderName}<br>Начало транзакции: ${date_start}<br>Окончание транзакции: ${date_end}`

        }
        else if (message.TransactionType == 2) {
          await this.$store.dispatch(exp.ISSUEDCARDS_GET_REQUEST);
          transaction_type = "Выпуск карты";
          title = `Тип транзакции: ${transaction_type}`
          msg = `Карта: ${this.NumberSpace(message.CardNumber)}<br>Начало транзакции: ${date_start}<br>Окончание транзакции: ${date_end}`

        }
        else {
          transaction_type = "Неизвестный тип транзакции"
          title = `Тип транзакции: ${transaction_type}`
          msg = ``;
        }

        if (message.Error == null) {

          toastr["info"](msg, title)
          eventBus.$emit("New_Transaction", message)
        }
        else {
          msg = `Транзакция не выполнена<br>Ошибка: ${message?.Error}`
          toastr["error"](msg, title)
        }
      }


      if (message.MessageType === "CancelCommand") {
        toastr["error"](msg, `Команда отменена`)
      }
    })
    toastr.options = {
      "closeButton": true,
      "debug": false,
      "newestOnTop": false,
      "progressBar": false,
      "positionClass": "toast-bottom-right",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "10000",
      "extendedTimeOut": "5000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut",
      "Prevent Duplicates": true,
    }
    document.body.addEventListener('click', function (e) {
      if (e.target !== '.sidenav-toggle' && !e.target.closest('.sidenav-toggle, .sidenav-section')) {
        document.querySelector(".main-wrapper").classList.remove('sidenav-open')
      }
    })
    //if (develop == true) return;	
    this.initializesocket();
  },
  updated() {
    feather.replace()
  },
  methods: {
    NumberSpace(card_number) {
      if (card_number != undefined && card_number != null) return card_number.match(/.{1,4}/g).join(' ')
      else return ``;
    },

    initializesocket() {
      var vm = this;
      this.tryopencount++;

      var location = window.location;
      let connection_string = process.env.NODE_ENV === 'development' ? 'http://localhost:5001' : window.origin;

      if (location.protocol == "http:") {
        connection_string = connection_string.replace("http", "ws") + "/ws"
      }
      if (location.protocol == "https:") {
        connection_string = connection_string.replace("https", "wss") + "/ws"
      }
      //  connection_string = 'ws://petrol.amsy.ru/ws'
      //UpdateSystemStatus( CG_DICTIONARY.UI.statusbar_connecting, true );
      this.socket = new WebSocket(connection_string);
      this.socket.onopen = async function (e) {
        var tokenID;

        var user_role;
        await indDB.getUserToken().then(async resp => {
          if (resp) {
            user_role = resp.role
            tokenID = resp.tokenID;
          }
        });
        if (user_role == "admin") {
          if (vm.$route.fullPath.split('/')[1] == "home") {
            await indDB.getMagicToken().then(async resp => {
              if (resp) {
                tokenID = resp.tokenID;
              }
            })

          }
        }

        vm.socket.send(tokenID); // "5162f761-90e8-4cfc-aa9c-5b732d2ac8d3"
        // vm.tryopencount = 0;
        vm.checkSocketAlive();
      };
      this.socket.onmessage = function (event) {
        vm.socketLastMsgTime = new Date();
        if (this.debug_socket)
          console.log(`[message] Data received: ${event.data} <- server`);
        //
        var message = JSON.parse(event.data);
        // 
        if (message != undefined && message != '') {
          //  switch (message.MessageType) {
          // Transaction
          //TerminalCommandResponse
          //CardCommandResponse
          eventBus.$emit('New_message', message);
        }
      };
      this.socket.onclose = function (event) {
        if (this.debug_socket)
          console.log("Сокет закрывается")
        clearTimeout(vm.checksocketTimeout); // отключаем проверку сокета
        var msg;
        if (event.wasClean)
          msg = `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`;
        else
          msg = '[close] Connection died';
        if (vm.debug_socket == true)
          console.log('' + msg);
        /*if (vm.tryopencount > 2) {
          console.log(`[trying connect count] ${vm.tryopencount}`);
        }
        else {
          setTimeout(vm.initializesocket, 1000 * 5); // попытка переподключения
        }*/
      };
      this.socket.onerror = function (error) {
        if (vm.debug_socket == true)
          console.log(`[error] ${error.message}`);
      };
    },
    checkSocketAlive() {
      try {
        this.socket.send("");
        this.checksocketTimeout = setTimeout(this.checkSocketAlive, 1000 * 5);
      }
      catch {
      }
    }
  },
}
</script>
<style>
.fadeln-enter-active,
.fadeln-leave-active {
  transition: opacity .3s linear;
}

.fadeln-enter,
.fadeln-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>