<template>
    <div class="content-body">
        <!-- #region PageHeadingsStart -->
        <div class="row align-items-center justify-content-between mb-0">
            <h3><i class="nav-icon fa-solid fa-car me-1"></i>
                <span class="title"> Транспортные средства </span>
                <div class="btn-group">
                    <button type="button" title="Обновить" class="btn btn-default btn-smx" :disabled="isBusy"
                        v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" @click="Refresh()"><i
                            class="nav-icon fas fa-sync-alt"></i></button>
                    <button class="btn btn-outline-success btn-add   btn-smx" @click="ShowCreateCardModal()"
                        title="Добавить топливную карту"
                        v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }">
                        <i class="nav-icon fa fa-plus me-1" aria-hidden="true"></i>Добавить топливную карту
                    </button>
                </div>
            </h3>
        </div>
        <!-- #endregion Page Headings End -->
        <!-- #region DefaultDataTableStart-->

        <div class="card card-layout">
            <div class="card-body p-0">
                <div class="d-flex flex-wrap align-items-baseline justify-content-between p-2" style="gap:5px">
                    <div class="form-check form-switch">
                        <input class="form-check-input " v-model="show_removed" name="removed" type="checkbox"
                            id="removed_card">
                        <i><b> &laquo;удалённые&raquo; </b> топливные карты</i>
                    </div>
                    <data-table-filter v-model="filter"></data-table-filter>
                </div>
                <b-overlay :show="isBusy" :opacity="0.5">
                    <b-table @filtered="onFiltered" :filter="filter" sticky-header
                        :filter-included-fields="['number', 'holderName', 'organization']"
                        class="table-sticky-header-layout m-0" :filter-function="filterTable" ref="table_cards" hover
                        head-variant="light" :per-page="perPage" :current-page="currentPage" id="table_cards"
                        :fields="fields" :items="Cards" :busy="isBusy && Cards.length == 0" show-empty primary-key="id">
                        <template #emptyfiltered>
                            <div class="text-center my-2">{{ emptyFilteredText }}</div>
                        </template>
                        <template #table-busy>
                            <div class="text-center my-2">
                                <strong>Загрузка...</strong>
                            </div>
                        </template>
                        <template #empty>
                            <div v-if="!CardsGetHaveError && !WalletsGetHaveError && !OrganizationsGetHaveError && !ProductsGetHaveError"
                                class="text-center my-2">{{ emptyText }}</div>
                            <div v-else class="text-center my-2" style="color:red">Ошибка загрузки</div>
                        </template>
                        <template #cell(number)="data">
                            <div class="d-flex justify-content-between text-start" style="flex-wrap:wrap;">
                                <span style=" flex: 1 0 auto;" class="text-center"
                                    :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }">{{
                                        NumberSpace(data.item.number) }}</span>
                                <div class="d-flex" style="gap:5px">
                                    <div v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                        title="Параметры карты изменются">
                                        <b-iconstack
                                            v-if="data.item?.commandQueue?.find(t => (t.commandCode == 2 || t.commandCode == 6) && t.status == 0) != null ?? false"
                                            font-scale="1.2" title="Параметры карты изменются">
                                            <b-icon icon="credit-card2-front" scale="0.6" variant="warning"
                                                stacked></b-icon>
                                            <b-icon stacked icon="circle" variant="warning"></b-icon>
                                        </b-iconstack>
                                    </div>
                                    <div class="actions" style="cursor:pointer; cursor: pointer;">
                                        <button @click="ShowEditModalCard(data.item)" title="Свойства карты"
                                            v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                            class="btn change" v-if="!data.item.isRemoved" style="float:right"><i
                                                class="fa fa-cog fa-1x"></i></button>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template #cell(holderName)="data">
                            <span :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }">{{
                                data.item.holderName }}</span>
                        </template>
                        <template #cell(organizationName)="data">
                            <span :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }">{{
                                data.item.organizationName }}</span>
                        </template>
                        <template #cell(wallet)="data">
                            <b-skeleton-img animation="wave" width="100%" height="60px" no-aspect
                                v-if="WalletsByCardGUID(data.item.id)?.length == 0 && WalletsGetAreLoading"></b-skeleton-img>
                            <span v-else-if="WalletsByCardGUID(data.item.id)?.length == 0">Нет доступных
                                кошельков</span>
                            <b-card no-body v-for="(elem, index) in WalletsByCardGUID(data.item.id)" class="my-1 border"
                                :class="{
                                    'border-dark': (!elem.isReplenishing && !elem.isAdding && !elem.isModifying && !elem.isRemoving) || data.item.isRemoved,
                                    'border-success': elem.isAdding && !data.item.isRemoved,
                                    'border-info': elem.isReplenishing && !data.item.isRemoved,
                                    'border-danger': elem.isRemoving && !data.item.isRemoved,
                                    'border-warning': elem.isModifying && !data.item.isRemoved,
                                }" :key="index">
                                <template #header>

                                    <b-row class="align-items-center" style="gap:5px 0px" v-b-toggle="elem.id">

                                        <b-col cols="10"
                                            class="text-xxl-start text-wrap text-center order-xxl-2 col-xxl-5 order-2"><b>{{
                                                ProductByGUID(elem.productID)?.name ??
                                                '' }}</b>
                                            <div v-if="!data.item.isRemoved" class="d-flex align-items-center ">

                                                <div v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                                    title="Кошелёк создаётся">
                                                    <b-iconstack v-if="elem.isAdding && !elem.isRemoving"
                                                        font-scale="1.5">
                                                        <b-icon icon="check" stacked variant="success"></b-icon>
                                                        <b-icon stacked icon="circle" variant="success"></b-icon>
                                                    </b-iconstack>
                                                </div>
                                                <div v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                                    title="Кошелёк изменяется">
                                                    <b-iconstack v-if="elem.isModifying" font-scale="1.5"
                                                        title="Кошелёк изменяется">
                                                        <b-icon icon="pencil" scale="0.6" variant="warning"
                                                            stacked></b-icon>
                                                        <b-icon stacked icon="circle" variant="warning"></b-icon>
                                                    </b-iconstack>
                                                </div>
                                                <div v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                                    :title="summ_fuel(elem.cardID)">
                                                    <b-iconstack v-if="elem.isReplenishing" font-scale="1.5">
                                                        <b-icon stacked scale="0.6" icon="arrow-up-right"
                                                            variant="info"></b-icon>
                                                        <b-icon stacked icon="circle" variant="info"></b-icon>
                                                    </b-iconstack>
                                                </div>
                                                <div v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                                    title="Кошелёк удаляется">
                                                    <b-iconstack v-if="elem.isRemoving" font-scale="1.5">
                                                        <b-icon icon="trash" scale="0.6" stacked
                                                            variant="danger"></b-icon>
                                                        <b-icon stacked icon="circle" variant="danger"></b-icon>
                                                    </b-iconstack>
                                                </div>

                                            </div>
                                        </b-col>
                                        <b-col cols="12"
                                            class="d-inline-flex align-items-center order-xxl-3 order-4 col-xxl-6"
                                            style="justify-content: space-between;"><span class="text-start"> Баланс:


                                                <i v-if="elem.creditWallet" class="fa-solid fa-infinity"></i>

                                                <span v-else>{{ elem.quantity }} л.</span> <br
                                                    v-if="elem.isReplenishing"><span class="fst-italic"
                                                    v-if="elem.isReplenishing">{{
                                                        summ_fuel1(elem.cardID) }}</span>
                                            </span>
                                            <div v-if="!elem.creditWallet && !data.item.isRemoved">
                                                <button class="btn edit align-items-center" title="Пополнение кошелька"
                                                    v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                                    style="padding:0 !important;" :class="{ 'd-none': elem.isRemoving }"
                                                    :disabled="elem.isRemoving"
                                                    @click.stop="ShowWalletPaymentModal(elem)">
                                                    <b-icon icon="droplet-half" style="cursor:pointer" color="#0079db"
                                                        font-scale="1.5"></b-icon>
                                                </button>
                                            </div>
                                        </b-col>
                                        <b-col cols="2" class="order-xxl-5 order-3  col-xl-1"
                                            :class="{ 'd-none': not_translation }" style="cursor:pointer"><i
                                                class="fa fa-sort-desc fa-6  when-closed"></i>
                                            <i class="fa fa-sort-asc fa-6 when-open"></i>
                                        </b-col>
                                    </b-row>
                                    <b-row>

                                    </b-row>
                                </template>
                                <b-collapse appear :id="elem.id" :accordion="elem.cardID">
                                    <b-card-body>
                                        <b-row class=align-items-center>
                                            <b-col cols="12" class="text-start"><b>Наименование: </b>
                                                {{ elem?.name }} </b-col>
                                        </b-row>
                                        <b-row v-if="WalletsByCardGUID(elem.cardID).length <= 0"
                                            class=align-items-center>
                                            <b-col cols="12" class="text-start"><b>Доза автоналива: </b>
                                                {{ elem.defaultLimit }} л. </b-col>
                                        </b-row>
                                        <hr>
                                        <b-row class="mt-2">
                                            <b-col class="h6 text-start"><b>Лимиты по топливу:</b></b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="12"
                                                class="text-xxl-center text-start col-xxl-4 d-flex flex-wrap">
                                                Суточный:<span> <!--{{ elem.dailyLimitCurrent }}/--><i> {{
                                                    elem.dailyLimitValue != 0 ? `${elem.dailyLimitValue} л.` : `не
                                                        задан`
                                                }}</i></span>
                                            </b-col>
                                            <b-col cols="12"
                                                class="text-xxl-center text-start col-xxl-4 d-flex flex-wrap">
                                                Недельный: <span> <!--{{ elem.weekLimitCurrent }}/--> <i> {{
                                                    elem.weekLimitValue != 0 ? `${elem.weekLimitValue} л.` : `не
                                                        задан`
                                                }}</i></span>
                                            </b-col>
                                            <b-col cols="12"
                                                class="text-xxl-center text-start col-xxl-4 d-flex flex-wrap">
                                                Месячный: <span> <!--{{ elem.monthLimitCurrent }}/--> <i> {{
                                                    elem.monthLimitValue != 0 ? `${elem.monthLimitValue} л.` : `не
                                                        задан`
                                                }}</i></span>
                                            </b-col>
                                        </b-row>
                                        <hr>
                                        <b-row class="mt-2">
                                            <b-col class="h6 text-start"><b>Лимиты по операциям:</b></b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="4" class="text-xxl-center text-start d-flex flex-wrap">
                                                Cуточный: <span>
                                                    <!--{{ elem.operationLimitCurrent }}/--><i> {{
                                                        elem.operationLimitValue != 0 ? `${elem.operationLimitValue}
                                                        шт.` :
                                                            `не
                                                        задан` }}</i></span>
                                            </b-col>
                                        </b-row>
                                        <hr>
                                        <b-row class="mt-2">
                                            <b-col cols="12" class="text-center" v-if="!data.item.isRemoved">
                                                <div class="actions" style="justify-content: center;     gap: 1em;">
                                                    <button class="btn edit" @click="ShowEditModalWallet(elem)"
                                                        v-if="!elem.isRemoving" title="Свойства кошелька"
                                                        v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"><i
                                                            class="fa fa-pencil fa-1x"></i></button>
                                                    <button class="btn delete"
                                                        v-if="!elem.isRemoved && !elem.isRemoving && !elem.isReplenishing"
                                                        v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                                        title="Удаление кошелька"
                                                        @click="delete_cancel_wallet = false; ShowDeleteModalWallet(elem)"><i
                                                            class="fa fa-trash fa-1x"></i></button><!--&& !elem.isAdding -->
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </template>
                        <template #cell(actpls)="data">
                            <div class="actions justify-content-center" title="Новый кошелёк"
                                v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" style="cursor:pounter"
                                v-if="WalletsByCardGUID(data.item.id).length <= 3 && !data.item.isRemoved">
                                <b-iconstack font-scale="1.5" class="btn add bg-success"
                                    @click=" ShowCreateModalWallet(data.item)">
                                    <b-icon stacked icon="wallet2"></b-icon>
                                    <b-icon stacked icon="plus" scale="0.5"></b-icon>
                                </b-iconstack>
                            </div>
                        </template>
                        <template #cell(actdel)="data">
                            <div class="actions" style="justify-content: center !important;">
                                <button v-if="!data.item.isRemoved" class="btn delete" title="Удаление карты"
                                    v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                    @click=" ShowDeleteModalCard(data.item)"><i class="fa fa-trash fa-1x"></i></button>
                                <button v-else class="btn change" title="Восстановление карты"
                                    v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"
                                    @click=" ShowDeleteModalCard(data.item)"><i
                                        class="fa fa-rotate-left fa-1x"></i></button>
                            </div>
                        </template>
                    </b-table>
                    <!--<div class="d-flex flex-column flex-md-row text-start align-items-baseline m-2"
                        :class="{ 'justify-content-end': rows <= perPage, 'justify-content-between': rows > perPage }">
                        <b-pagination v-if="rows > perPage" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="table_cards" size="sm">
                        </b-pagination>
                        <div>
                            <div v-html="CaptionText" />
                            <div class="d-flex align-items-center"> Показывать
                                <bs-select v-model="perPage" style="width:75px; height:28px !important; "
                                    class=" mx-1 p-0 " :options="rows_to_display_computed"
                                    @input="SaveToLocalStorage" />
                                записей
                            </div>
                        </div>
                    </div>-->
                    <pagination :total-rows="rows" :perPage.sync="perPage" :currentPage.sync="currentPage"
                        aria-controls="table_cards">
                    </pagination>
                </b-overlay>
            </div>
        </div>
        <!-- #endregion -->
        <!-- #region modals -->
        <!--********************************************************** Создание / Изменение карты   *******************************************-->
        <bs-modal centered @shown="() => $refs.CardNumberMain?.focus()" :loading="loading" ref="CreateEditCardModal"
            id="CreateEditCardModal" modalDialogScrollable
            :title="edit ? 'Свойства топливной карты' : 'Новая топливная карта'">
            <div class="col-12 mb-6">
                <form ref="CreateEditCardForm" id="CreateEditCardForm" @submit.prevent="CreateEditCard">
                    <div class="row mb-n7 align-items-center">
                        <fieldset>
                            <legend>Общие параметры карты</legend>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Номер
                                        карты</label></div>
                                <div class="col-sm-8 col-12 align-self-center"><input type="text"
                                        @focusout="HandleFocusout" ref="CardNumberMain" :disabled="loading || edit"
                                        @keypress="onlyNumber" v-model="number" class="form-control"
                                        placeholder="xxxx xxxx xxxx xxxx" maxlength="19" title="xxxx xxxx xxxx xxxx"
                                        pattern="^[0-9]{4} {1}[0-9]{4} {1}[0-9]{4} {1}[0-9]{4}$" required />
                                </div>
                            </div>
                            <div class="row mb-2" v-if="edit">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Табельный номер</label></div>
                                <div class="col-sm-8 col-12 align-self-center"><input type="text"
                                        placeholder="0000000000000000" disabled readonly ref="serviceNumber"
                                        v-model="serviceNumber" class="form-control" @input="ServiceNumberChange" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Пин-код</label></div>
                                <div class="col-sm-8 col-12 align-self-center">
                                    <div class="input-group" v-if="!PinChanged">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text" style="border-radius: 3px 0px 0px 3px;">
                                                <input class="form-check-input" type="checkbox" v-model="checked_pin"
                                                    @change="ChangeChecked($event, 'Pin')">
                                            </div>
                                        </div>
                                        <input type="text" :disabled="loading || !checked_pin" v-model="pin" ref="Pin"
                                            class="form-control" @keypress="onlyNumber" pattern="[0-9]{4}"
                                            title="4 цифры" maxlength="4" placeholder="xxxx" :required="checked_pin" />
                                    </div>
                                    <div v-else class="input-group">
                                        <input type="text" :disabled="PinChanged" v-model="pin" class="form-control"
                                            @keypress="onlyNumber" pattern="[0-9]{4}" title="4 цифры" maxlength="4"
                                            placeholder="xxxx" required />
                                        <div class="input-group-append" style="cursor:pointer">
                                            <span class="input-group-text" style="border-radius: 0px 3px 3px 0px"
                                                @click=" CommandCancel(id, commandQueue.find(t => t.commandCode == 2 && t.status == 0)?.id)">x</span>
                                        </div>
                                    </div>
                                    <!--<small v-if="CardByGUID(id)?.pin==='0000'">0000 - пароль не задан<br></small>-->
                                    <small v-if="PinChanged" class="form-text text-muted">Новый пароль:
                                        {{ changeable_pin }}</small>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Подразделение</label></div>
                                <div class="col-sm-8 col-12 align-self-center">
                                    <multiselect autocomplete="off" trackBy="text" valueProp="value" v-if="!OrgChanged"
                                        :disabled="loading" :searchable="true" locale="ru" :allowEmpty="true"
                                        placeholder="Выберите подразделение" id="Organization" :close-on-select="true"
                                        label="text" required v-model="organizationID"
                                        :options="AllOrganizationsSelect()">
                                        <template v-slot:nooptions>
                                            <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                                Нет элементов</div>
                                        </template>
                                        <template v-slot:noresults>
                                            <div class="d-flex w-100 align-items-center justify-content-center my-2">
                                                Не найдено</div>
                                        </template>
                                    </multiselect>
                                    <div v-else class="input-group">
                                        <bs-select :disabled="OrgChanged" v-model="organizationID"
                                            :options="AllOrganizationsSelect()" required />
                                        <div class="input-group-append" style="cursor:pointer">
                                            <span class="input-group-text" style="border-radius: 0px 3px 3px 0px"
                                                @click=" CommandCancel(id, commandQueue.find(t => t.commandCode == 6 && t.status == 0)?.id)">x</span>
                                        </div>
                                    </div>
                                    <small v-if="OrgChanged" class="form-text text-muted">Новое подразделение:
                                        {{ OrganizationByGUID(changeable_organizationID)?.name }}</small>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Номер
                                        ТС/Держатель</label></div>
                                <div class="col-sm-8 col-12 align-self-center"><input type="text"
                                        placeholder="Номер ТС/Имя держателя" :disabled="loading" v-model="holderName"
                                        class="form-control" required />
                                </div>
                            </div>
                            <div class="row mb-2" v-if="!edit">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Создание кошельков</label></div>
                                <div class="col-sm-8 col-12 align-self-center">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" v-model="needWallets" :disabled="edit"
                                            type="checkbox" id="flexSwitchCheckDefault">
                                    </div>
                                </div>
                            </div>
                            <b-tabs class="card-tabs" v-if="needWallets" content-class="px-2" v-model="activeWallet"  
                                @activate-tab="onTabActivate" >
                                <template #tabs-end>
                                    <b-nav-item role="presentation" @click.prevent="NewWalletTab" href="#"
                                        v-if="Wallets.length < 4"><b>+</b></b-nav-item>
                                </template>
                                <b-tab v-for="(wallet, index)  in Wallets" :key="index">
                                    <template #title>{{ index == activeWallet ? `Кошелёк №${index + 1}` : `№${index +
                                        1}` }}</template>
                                    <div class="text-end py-1"><button type="button"
                                            class="btn btn-outline-danger btn-sm btn-delete" v-if="Wallets.length > 1"
                                            @click="DeleteWalletTab(index)">Удалить</button>
                                    </div>
                                     <form :id="'WalletForm' + index" :ref="'WalletForm' + index">
                                        <fieldset>
                                            <legend>Общие параметры кошелька</legend>
                                            <div class="row mb-2">
                                                <div class="col-sm-4 col-12 align-self-center"><label
                                                        class="form-label font-weight-bold">Наименование</label></div>
                                                <div class="col-sm-8 col-12 align-self-center"><input type="text"
                                                        placeholder="Имя кошелька" :disabled="loading"
                                                        v-model="wallet.wallet_name" ref="WalletName"
                                                        class="form-control" required />
                                                </div>
                                            </div>
                                            <div class="row mb-2" v-if="!edit">
                                                <div class="col-sm-4 col-12 align-self-center"><label
                                                        class="form-label font-weight-bold">Продукт</label></div>
                                                <div class="col-sm-8 col-12 align-self-center">
                                                    <multiselect autocomplete="off" trackBy="text" valueProp="value"
                                                        :disabled="loading" :searchable="true" locale="ru"
                                                        :allowEmpty="true" placeholder="Выберите
                         продукт" :close-on-select="true" label="text" required v-model="wallet.wallet_productID"
                                                        :options="AllProductsSelect()">
                                                        <template v-slot:nooptions>
                                                            <div
                                                                class="d-flex w-100 align-items-center justify-content-center my-2">
                                                                Нет элементов</div>
                                                        </template>
                                                        <template v-slot:noresults>
                                                            <div
                                                                class="d-flex w-100 align-items-center justify-content-center my-2">
                                                                Не найдено</div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-sm-4 col-12 align-self-center">
                                                    <label class="form-label font-weight-bold">Доза по умолчанию
                                                        (л.)</label>
                                                </div>
                                                <div class="col-sm-8 col-12 align-self-center">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text"
                                                                style="border-radius: 3px 0px 0px 3px;">
                                                                <input class="form-check-input" type="checkbox"
                                                                    v-model="wallet.checked_defaultLimit"
                                                                    @change="ChangeChecked($event, 'defaultLimit')">
                                                            </div>
                                                        </div>
                                                        <input min="1" type="number"
                                                            :disabled="loading || !wallet.checked_defaultLimit"
                                                            ref="defaultLimit" @keypress="onlyNumber"
                                                            v-model.number="wallet.defaultLimit" class="form-control"
                                                            :required="wallet.checked_defaultLimit" />
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-sm-4 col-12 align-self-center"><label
                                                        class="form-label font-weight-bold">Кредитный</label></div>
                                                <div class="col-sm-8 col-12 align-self-center">
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" v-model="wallet.creditWallet"
                                                            :disabled="edit" type="checkbox"
                                                            id="flexSwitchCheckDefault">
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <legend>Лимиты по топливу</legend>
                                            <div class="row mb-2">
                                                <div class="col-sm-4 col-12 align-self-center"><label
                                                        class="form-label font-weight-bold">Cуточный лимит (л.)</label>
                                                </div>
                                                <div class="col-sm-8 col-12 align-self-center">
                                                    <div class="input-group" v-if="!WalletChanged">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text"
                                                                style="border-radius: 3px 0px 0px 3px;">
                                                                <input class="form-check-input" type="checkbox"
                                                                    v-model="wallet.checked_dailyLimitValue"
                                                                    @change="ChangeChecked($event, 'dailyLimit')">
                                                            </div>
                                                        </div>
                                                        <input type="number" min="1"
                                                            :disabled="loading || !wallet.checked_dailyLimitValue"
                                                            ref="dailyLimit" @keypress="onlyNumber"
                                                            v-model.number="wallet.dailyLimitValue" class="form-control"
                                                            :required="wallet.checked_dailyLimitValue" />
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-sm-4 col-12 align-self-center"><label
                                                        class="form-label font-weight-bold">Недельный лимит (л.)</label>
                                                </div>
                                                <div class="col-sm-8 col-12 align-self-center">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text"
                                                                style="border-radius: 3px 0px 0px 3px;">
                                                                <input class="form-check-input" type="checkbox"
                                                                    v-model="wallet.checked_weekLimitValue"
                                                                    @change="ChangeChecked($event, 'weekLimit')">
                                                            </div>
                                                        </div>
                                                        <input type="number" min="1"
                                                            :disabled="loading || !wallet.checked_weekLimitValue"
                                                            ref="weekLimit" @keypress="onlyNumber"
                                                            v-model.number="wallet.weekLimitValue" class="form-control"
                                                            :class="{ 'is-invalid': wallet.invalid_weekLimit }"
                                                            :required="wallet.checked_weekLimitValue" />
                                                        <div class="invalid-feedback d-block mt-0"
                                                            v-if="wallet.invalid_weekLimit">
                                                            {{ wallet.invalid_weekLimit_text }}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-sm-4 col-12 align-self-center"><label
                                                        class="form-label font-weight-bold">Месячный лимит (л.)</label>
                                                </div>
                                                <div class="col-sm-8 col-12 align-self-center">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text"
                                                                style="border-radius: 3px 0px 0px 3px;">
                                                                <input class="form-check-input" type="checkbox"
                                                                    v-model="wallet.checked_monthLimitValue"
                                                                    @change="ChangeChecked($event, 'monthLimit')">
                                                            </div>
                                                        </div>
                                                        <input type="number" min="1"
                                                            :disabled="loading || !wallet.checked_monthLimitValue"
                                                            ref="monthLimit" @keypress="onlyNumber"
                                                            v-model.number="wallet.monthLimitValue" class="form-control"
                                                            :class="{ 'is-invalid': wallet.invalid_monthLimit }"
                                                            :required="wallet.checked_monthLimitValue" />
                                                        <div class="invalid-feedback d-block"
                                                            v-if="wallet.invalid_monthLimit">
                                                            {{ wallet.invalid_monthLimit_text }}
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <legend>Лимиты по операциям</legend>
                                            <div class="row mb-2">
                                                <div class="col-sm-4 col-12 align-self-center"><label
                                                        class="form-label font-weight-bold">Дневной лимит (шт.)</label>
                                                </div>
                                                <div class="col-sm-8 col-12 align-self-center">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text"
                                                                style="border-radius: 3px 0px 0px 3px;">
                                                                <input class="form-check-input" type="checkbox"
                                                                    v-model="wallet.checked_operationLimitValue"
                                                                    @change="ChangeChecked($event, 'operationLimit')">
                                                            </div>
                                                        </div>
                                                        <input type="number" min="1"
                                                            :disabled="loading || !wallet.checked_operationLimitValue"
                                                            @keypress="onlyNumber" ref="operationLimit"
                                                            v-model.number="wallet.operationLimitValue"
                                                            class="form-control"
                                                            :required="wallet.checked_operationLimitValue" />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </b-tab>
                            </b-tabs>
                            <div class="row mb-2" v-if="edit">
                                <div class="w-100 d-flex align-itemes-center justify-content-start">
                                    <button type="button" @click="ShowReissueCardModal()"
                                        class="btn  btn-smx btn-information " title="Заменить карту"
                                        v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }">
                                        Замена карты
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset v-if="edit">
                            <legend>Параметры блокировки</legend>
                            <div class="row mb-2">
                                <div class="col-12 align-self-center">
                                    <div class="w-100 d-flex align-itemes-center justify-content-start">
                                        <button type="button" :disabled="blockOnTerminal.length == 0"
                                            @click="ShowUnBlockCardModal()" class="btn  btn-smx btn-add me-1"
                                            title="Разблокировать карту на всех терминалах"
                                            v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }">
                                            Разблокировать карту
                                        </button>
                                        <button type="button" @click="ShowBlockCardModal()"
                                            class="btn  btn-outline-danger btn-smx"
                                            title="Заблокировать карту на всех терминалах"
                                            v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }">
                                            Заблокировать карту
                                        </button>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between my-2">
                                        Терминалы с блокировкой карты:
                                        <i v-if="blockOnTerminal.length > 0" class="fa fa-bars" style="cursor: pointer;"
                                            :visible.sync="VisibleBlockOnTerminal" v-b-toggle.blockOnTerminal />

                                        <div class="fst-italic" v-else>Нет</div>
                                    </div>

                                    <b-collapse class="mb-2" id="blockOnTerminal">
                                        <b-card class="text-center" no-body v-if="blockOnTerminal.length > 0">
                                            <b-card-body class="border">
                                                <ul class="m-0  ">
                                                    <li class="text-start" v-for="elem in blockOnTerminal" :key="elem">
                                                        {{
                                                            GetTerminalInfo(elem) }}
                                                    </li>
                                                </ul>
                                            </b-card-body>
                                        </b-card>
                                    </b-collapse>

                                </div>
                            </div>
                        </fieldset>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button :disabled="loading" form="CreateEditCardForm" type="submit"
                        class="btn btn-primary btn-sm text-center align-items-center">
                        <span v-if="!loading">Сохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--********************************************************** Замена карты   *******************************************-->
        <bs-modal centered @shown="() => $refs?.CardNumber?.focus()" :loading="loading" ref="ReissueCardModal"
            id="ReissueCardModal" title="Замена карты">
            <div class="col-12 mb-6">
                <form ref="ReissueCardForm" id="ReissueCardForm" @submit.prevent="">
                    <div class="row mb-n7 align-items-center">
                        <fieldset v-if="!ReissueQuestion">
                            <legend>Общие параметры</legend>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Номер
                                        старой карты</label></div>
                                <div class="col-sm-8 col-12 align-self-center"><input type="text" ref="CardNumberOld"
                                        disabled readonly @keypress="onlyNumber" v-model="number" class="form-control"
                                        placeholder="xxxx xxxx xxxx xxxx" maxlength="19" title="xxxx xxxx xxxx xxxx"
                                        pattern="^[0-9]{4} {1}[0-9]{4} {1}[0-9]{4} {1}[0-9]{4}$" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-4 col-12 align-self-center"><label
                                        class="form-label font-weight-bold">Номер
                                        новой карты</label></div>
                                <div class="col-sm-8 col-12 align-self-center"><input type="text"
                                        @focusout="HandleFocusoutReissue" ref="CardNumber" :disabled="loading"
                                        @keypress="onlyNumber" v-model="numberReissue" class="form-control"
                                        placeholder="xxxx xxxx xxxx xxxx" maxlength="19" title="xxxx xxxx xxxx xxxx"
                                        pattern="^[0-9]{4} {1}[0-9]{4} {1}[0-9]{4} {1}[0-9]{4}$" required />
                                </div>
                            </div>
                        </fieldset>
                        <div class="col-12 " v-if="ReissueQuestion">
                            <p class="px-5 py-3 h4" style="text-align: center;">Вы действительно хотите заменить
                                карту <br>
                                <strong>"{{ NumberSpace(CardByGUID(id)?.number) }}"</strong> на
                                <strong>"{{ numberReissue }}"</strong> ?
                            </p>
                            <ul>
                                <li> При замене на новую карту будут перенесены кошельки. </li>
                                <li> Старая карта будет добавлена в чёрный список на всех терминалах. </li>
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button :disabled="loading" v-if="ReissueQuestion" type="button" @click="ReissueCard"
                        class="btn btn-danger btn-sm text-center align-items-center me-1">
                        <span v-if="!loading">Да</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                    <button @click="ReissueQuestion = !ReissueQuestion" v-if="ReissueQuestion" type="button"
                        class="btn btn-primary btn-sm text-center align-items-center">
                        <span v-if="!loading">Нет</span>
                    </button>
                    <button @click="AskReissueCardModal()" v-if="!ReissueQuestion" type="button"
                        :disabled="number == numberReissue"
                        class="btn btn-danger btn-sm text-center align-items-center">
                        <span v-if="!loading">Заменить</span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--********************************************************** Пополнение кошелька  *******************************************-->
        <bs-modal @shown="() => { if (!this?.VolumeChanged) this?.$refs?.payManyWalet?.focus() }" centered
            noDefaultTitle id="WalletPaymentModal" :loading="loading" ref="WalletPaymentModal" title="Пополнение">
            <template #title>
                <div class="modal-title">
                    <div class="h5">Пополнение</div>
                    <div class="fst-italic">Кошелёк: {{ WalletByGUID(wallet_id)?.name }} ({{
                        ProductByGUID(WalletByGUID(wallet_id)?.productID)?.name }})</div>
                    <div class="fst-italic">Карта: {{ NumberSpace(CardByGUID(wallet_cardID)?.number) }}</div>
                </div>
            </template>
            <div class="col-12 mb-6">
                <form ref="PaymenyWalletForm" id="PaymenyWalletForm" @submit.prevent="PaymenyWallet">
                    <div class="row mb-n7 align-items-center">
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Объём
                                    (л.)</label></div>
                            <div class="col-sm-8 col-12 align-self-center">
                                <input type="number" min="1" :disabled="loading || VolumeChanged"
                                    placeholder="Введите дозу..." ref="payManyWalet" @keypress="onlyNumber"
                                    v-model.number="wallet_quantity" class="form-control" required />
                                <div class="py-1 d-flex justify-content-between"
                                    v-if="VolumeChanged && (!WalletByGUID(wallet_id)?.isRemoved ?? false) && changeable_quantity != null">
                                    <small class="form-text text-muted">На
                                        пополнении:
                                        {{ changeable_quantity }} л.</small>
                                    <button class="btn btn-outline-danger btn-sm btn-delete" type="button"
                                        @click="CommandCancel(id, commandQueue.find(t => t.commandCode == 4 && t.status == 0)?.id)"
                                        title="Отмена пополнения"> Отмена</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12   text-start">
                    <button :disabled="loading || VolumeChanged" type="submit" form="PaymenyWalletForm"
                        class="btn btn-primary btn-sm text-center align-items-center">
                        <span v-if="!loading">Пополнить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--********************************************************** Создание / Изменение кошелька   *******************************************-->
        <bs-modal modalDialogScrollable centered @shown="() => $refs.WalletName[0]?.focus()" id="CreateEditWalletModal"
            :loading="loading" ref="CreateEditWalletModal" noDefaultTitle
            :title="edit ? 'Свойства кошелька' : ' Новый кошелёк'">
            <template #title>
                <div class="modal-title">
                    <div class="h5">{{ edit ? 'Свойства кошелька' : 'Новый кошелёк' }}</div>
                    <div v-if="edit" class="fst-italic">Кошелёк: {{ WalletByGUID(CurrentWallet?.wallet_id)?.name }} ({{
                        ProductByGUID(WalletByGUID(CurrentWallet?.wallet_id)?.productID)?.name }})</div>
                    <div class="fst-italic">Карта: {{ NumberSpace(CardByGUID(id)?.number) }}
                    </div>
                </div>
            </template>
            <div class="col-12 mb-6">
                <form ref="CreateEditWalletForm" id="CreateEditWalletForm" @submit.prevent="CreateEditWallet">
                    <div v-for="(wallet, index) in Wallets" :key="index">
                        <div class="row mb-n7 align-items-center">
                            <fieldset>
                                <legend>Общие параметры кошелька</legend>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Наименование</label></div>
                                    <div class="col-sm-8 col-12 align-self-center"><input type="text"
                                            placeholder="Имя кошелька" :disabled="loading" v-model="wallet.wallet_name"
                                            ref="WalletName" class="form-control" required />
                                    </div>
                                </div>
                                <div class="row mb-2" v-if="!edit">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Продукт</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <!-- <bs-select :disabled="loading" v-model="wallet_productID" :options="AllProductsSelect()" required>
                             <option selected disabled v-if="!edit" value="" class="d-none">Выберите
                                 продукт</option>
                         </bs-select>-->
                                        <multiselect autocomplete="off" trackBy="text" valueProp="value"
                                            :disabled="loading" :searchable="true" locale="ru" :allowEmpty="true"
                                            placeholder="Выберите
                         продукт" id="time_zone" :close-on-select="true" label="text" required
                                            v-model="wallet.wallet_productID" :options="AllProductsSelect()">
                                            <template v-slot:nooptions>
                                                <div
                                                    class="d-flex w-100 align-items-center justify-content-center my-2">
                                                    Нет элементов</div>
                                            </template>
                                            <template v-slot:noresults>
                                                <div
                                                    class="d-flex w-100 align-items-center justify-content-center my-2">
                                                    Не найдено</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="row mb-2" v-if="WalletsByCardGUID(wallet.wallet_cardID).length <= 1">
                                    <div class="col-sm-4 col-12 align-self-center">
                                        <label class="form-label font-weight-bold">Доза по умолчанию (л.)</label>
                                    </div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <div class="input-group" v-if="!WalletChanged">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text" style="border-radius: 3px 0px 0px 3px;">
                                                    <input class="form-check-input" type="checkbox" v-model="wallet.checked_defaultLimit"
                                                        @change="ChangeChecked($event, 'defaultLimit')">
                                                </div>
                                            </div>
                                            <input min="1" type="number"
                                                :disabled="loading || !wallet.checked_defaultLimit" ref="defaultLimit"
                                                @keypress="onlyNumber" v-model.number="wallet.defaultLimit"
                                                class="form-control" :required="wallet.checked_defaultLimit" />
                                        </div>
                                        <div v-else class="input-group">
                                            <input type="number" :disabled="WalletChanged" @keypress="onlyNumber"
                                                v-model.number="wallet.defaultLimit" class="form-control" required />
                                            <div class="input-group-append" style="cursor:pointer"
                                                v-if="changeable_defaultLimit != null && !wallet.isAdding">
                                                <span class="input-group-text" style="border-radius: 0px 3px 3px 0px"
                                                    @click=" CommandCancel(id, commandQueue.find(t => t.commandCode == 5 && t.status == 0)?.id)">x</span>
                                            </div>
                                        </div>
                                        <small v-if="WalletChanged && changeable_operationLimitValue != null"
                                            class="form-text text-muted">Новая доза по умолчанию:
                                            {{ changeable_defaultLimit }}</small>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Кредитный</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" v-model="wallet.creditWallet"
                                                :disabled="edit" type="checkbox" id="flexSwitchCheckDefault">
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend>Лимиты по топливу</legend>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Cуточный лимит (л.)</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <div class="input-group" v-if="!WalletChanged">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text" style="border-radius: 3px 0px 0px 3px;">
                                                    <input class="form-check-input" type="checkbox" v-model="wallet.checked_dailyLimitValue"
                                                        @change="ChangeChecked($event, 'dailyLimit')">
                                                </div>
                                            </div>
                                            <input type="number" min="1"
                                                :disabled="loading || !wallet.checked_dailyLimitValue" ref="dailyLimit"
                                                @keypress="onlyNumber" v-model.number="wallet.dailyLimitValue"
                                                class="form-control" :required="wallet.checked_dailyLimitValue" />
                                        </div>
                                        <div v-else class="input-group">
                                            <input type="number" :disabled="WalletChanged" @keypress="onlyNumber"
                                                v-model.number="wallet.dailyLimitValue" class="form-control" required />
                                            <div class="input-group-append" style="cursor:pointer"
                                                v-if="changeable_dailyLimitValue != null && !wallet.isAdding">
                                                <span class="input-group-text" style="border-radius: 0px 3px 3px 0px"
                                                    @click="CommandCancel(id, commandQueue.find(t => t.commandCode == 5 && t.status == 0)?.id)">x</span>
                                            </div>
                                        </div>
                                        <small v-if="WalletChanged && changeable_dailyLimitValue != null"
                                            class="form-text text-muted">Новый суточный лимит:
                                            {{ changeable_dailyLimitValue }} л.</small>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Недельный лимит (л.)</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <div class="input-group" v-if="!WalletChanged">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text" style="border-radius: 3px 0px 0px 3px;">
                                                    <input class="form-check-input" type="checkbox" v-model="wallet.checked_weekLimitValue"
                                                        @change="ChangeChecked($event, 'weekLimit')">
                                                </div>
                                            </div>
                                            <input type="number" min="1"
                                                :disabled="loading || !wallet.checked_weekLimitValue" ref="weekLimit"
                                                @keypress="onlyNumber" v-model.number="wallet.weekLimitValue"
                                                class="form-control" :class="{ 'is-invalid': wallet.invalid_weekLimit }"
                                                :required="checked_weekLimitValue" />
                                            <div class="invalid-feedback d-block mt-0" v-if="wallet.invalid_weekLimit">
                                                {{ wallet.invalid_weekLimit_text }}
                                            </div>
                                        </div>
                                        <div v-else class="input-group">
                                            <input type="number" :disabled="WalletChanged" @keypress="onlyNumber"
                                                v-model.number="wallet.weekLimitValue" class="form-control " required />
                                            <div class="input-group-append" style="cursor:pointer"
                                                v-if="changeable_weekLimitValue != null && !wallet.isAdding">
                                                <span class="input-group-text" style="border-radius: 0px 3px 3px 0px"
                                                    @click=" CommandCancel(id, commandQueue.find(t => t.commandCode == 5 && t.status == 0)?.id)">x</span>
                                            </div>
                                        </div>
                                        <small v-if="WalletChanged && changeable_weekLimitValue != null"
                                            class="form-text text-muted">Новый недельный лимит:
                                            {{ changeable_weekLimitValue }} л.</small>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Месячный лимит (л.)</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <div class="input-group" v-if="!WalletChanged">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text" style="border-radius: 3px 0px 0px 3px;">
                                                    <input class="form-check-input" type="checkbox" v-model="wallet.checked_monthLimitValue"
                                                        @change="ChangeChecked($event, 'monthLimit')">
                                                </div>
                                            </div>
                                            <input type="number" min="1"
                                                :disabled="loading || !wallet.checked_monthLimitValue" ref="monthLimit"
                                                @keypress="onlyNumber" v-model.number="wallet.monthLimitValue"
                                                class="form-control"
                                                :class="{ 'is-invalid': wallet.invalid_monthLimit }"
                                                :required="wallet.checked_monthLimitValue" />
                                            <div class="invalid-feedback d-block" v-if="wallet.invalid_monthLimit">
                                                {{ wallet.invalid_monthLimit_text }}
                                            </div>
                                        </div>
                                        <div v-else class="input-group">
                                            <input type="number" :disabled="WalletChanged" @keypress="onlyNumber"
                                                v-model.number="wallet.monthLimitValue" class="form-control" required />
                                            <div v-if="changeable_monthLimitValue != null && !wallet.isAdding"
                                                class="input-group-append" style="cursor:pointer">
                                                <span class="input-group-text" style="border-radius: 0px 3px 3px 0px"
                                                    @click=" CommandCancel(id, commandQueue.find(t => t.commandCode == 5 && t.status == 0)?.id)">x</span>
                                            </div>
                                        </div>
                                        <small v-if="WalletChanged && changeable_monthLimitValue != null"
                                            class="form-text text-muted">Новый месячный лимит:
                                            {{ changeable_monthLimitValue }} л.</small>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend>Лимиты по операциям</legend>
                                <div class="row mb-2">
                                    <div class="col-sm-4 col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Дневной лимит (шт.)</label></div>
                                    <div class="col-sm-8 col-12 align-self-center">
                                        <div class="input-group" v-if="!WalletChanged">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text" style="border-radius: 3px 0px 0px 3px;">
                                                    <input class="form-check-input" type="checkbox" v-model="wallet.checked_operationLimitValue"
                                                        @change="ChangeChecked($event, 'operationLimit')">
                                                </div>
                                            </div>
                                            <input type="number" min="1"
                                                :disabled="loading || !wallet.checked_operationLimitValue"
                                                @keypress="onlyNumber" ref="operationLimit"
                                                v-model.number="wallet.operationLimitValue" class="form-control"
                                                :required="wallet.checked_operationLimitValue" />
                                        </div>
                                        <div v-else class="input-group">
                                            <input type="number" :disabled="WalletChanged" @keypress="onlyNumber"
                                                v-model.number="wallet.operationLimitValue" class="form-control"
                                                required />
                                            <div class="input-group-append" style="cursor:pointer"
                                                v-if="changeable_operationLimitValue != null && !wallet.isAdding">
                                                <span class="input-group-text" style="border-radius: 0px 3px 3px 0px"
                                                    @click=" CommandCancel(id, commandQueue.find(t => t.commandCode == 5 && t.status == 0)?.id)">x</span>
                                            </div>
                                        </div>
                                        <small v-if="WalletChanged && changeable_operationLimitValue != null"
                                            class="form-text text-muted">Новый лимит операций:
                                            {{ changeable_operationLimitValue }} шт.
                                        </small>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12  text-start">
                    <button
                        :disabled="loading || CurrentWallet.invalid_monthLimit || CurrentWallet.invalid_weekLimit || WalletChanged"
                        type="submit" form="CreateEditWalletForm"
                        class="btn btn-primary btn-sm text-center align-items-center">
                        <span v-if="!loading">Сохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--********************************************************** Удаление карты   *******************************************-->
        <bs-modal centered id="DeleteCardModal" ref="DeleteCardModal"
            :title="CardByGUID(id)?.isRemoved ?? false ? 'Восстановление карты' : 'Удаление карты'">
            <div class="col-12 mb-6">
                <form ref="DeleteCardForm" @submit.prevent=" DeleteCard()">
                    <div class="row mb-n7 align-items-center">
                        <div class="col-12 ">
                            <p class="px-5 py-3 h4" style="text-align: center;">Вы действительно хотите
                                {{ CardByGUID(id)?.isRemoved ?? false ? 'восстановить' : 'удалить' }}
                                карту <br>
                                <strong>"{{ NumberSpace(CardByGUID(id)?.number) }}"</strong>?
                            </p>
                            <ul v-if="!CardByGUID(id)?.isRemoved ?? false">
                                <li> Изменение свойств данных карты будет заблокировано. </li>
                                <li> Изменение/пополнение кошельков карты будет недоступно. </li>
                                <li> Добавление новых кошельков на карту будет недоступно. </li>
                                <li> <b>Карта будет добавлена в чёрный список на всех терминалах.</b> </li>
                            </ul>
                            <ul v-else>
                                <li> Карта станет доступной для изменения. </li>
                                <li> Карта будет удалена из чёрного списка всех терминалах. </li>
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button :disabled="loading" form="DeleteCardForm" type="submit" @click=" DeleteCard()"
                        class="btn me-1 btn-danger btn-sm text-center align-items-center">
                        <div v-if="!loading"> <i class="fa fa-trash me-1"></i>
                            <span>да</span>
                        </div>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                    <button :disabled="loading" form="DeleteCardForm" type="button"
                        @click="$refs.DeleteCardModal.hide()"
                        class="btn me-1 btn-primary btn-sm text-center align-items-center">
                        <span>Нет</span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--********************************************************** Блокировка карты на терминалах*******************************************-->
        <bs-modal centered id="BlockCardModal" ref="BlockCardModal" title="Блокировка карты" blur>
            <div class="col-12 mb-6">
                <form ref="BlockCardForm" @submit.prevent="BlockCard()">
                    <div class="row mb-n7 align-items-center">
                        <div class="col-12 ">
                            <p class="px-5 py-3 h4" style="text-align: center;">Вы действительно хотите
                                {{ unblock ? 'разблокировать' : 'заблокировать' }} карту <br>
                                <strong>"{{ NumberSpace(CardByGUID(id)?.number) }}"</strong> на всех терминалах?
                            </p>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button :disabled="loading" form="BlockCardForm" type="submit" @click="BlockCard()"
                        class="btn me-1 btn-sm text-center align-items-center"
                        :class="{ 'btn-danger': !unblock, 'btn-success': unblock }">
                        <div v-if="!loading"> <!--<i class="fa fa-trash me-1"></i>-->
                            <span>да</span>
                        </div>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                    <button :disabled="loading" form="BlockCardForm" type="button" @click="$refs.BlockCardModal.hide()"
                        class="btn btn-primary me-1 btn-sm text-center align-items-center">
                        <div v-if="!loading"> <!--<i class="fa fa-trash me-1"></i>-->
                            <span>Нет</span>
                        </div>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!--********************************************************** Удаление кошелька *******************************************-->
        <bs-modal centered id="DeleteWalletModal" ref="DeleteWalletModal" noCloseButton staticModal
            :title="delete_cancel_wallet ? cancel_action == 1 ? 'Отмена создания кошелька' : 'Отмена удаления кошелька' : 'Удаление кошелька'">
            <div class="col-12 mb-6">
                <form ref="DeleteWalletForm" id="DeleteWalletForm" @submit.prevent="DeleteWallet">
                    <div class="row mb-n7 align-items-center">
                        <div class="col-12 ">
                            <p class="px-5 py-3 h4" style="text-justify: inter-word;">Вы действительно хотите
                                <span v-if="!delete_cancel_wallet"> удалить
                                    кошелёк</span>
                                <span v-if="delete_cancel_wallet && cancel_action == 1">отменить создание
                                    кошелька</span>
                                <span v-if="delete_cancel_wallet && cancel_action == 2">отменить удаление
                                    кошелька</span>
                                <strong>"{{ wallet_name }}"</strong>?
                            </p>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12 text-start">
                    <button :disabled="loading" form="DeleteWalletForm" type="submit"
                        class="btn me-1 btn-danger btn-sm text-center align-items-center">
                        <div v-if="!loading"> <i class="fa fa-trash me-1"></i>
                            <span>да</span>
                        </div>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                    <button :disabled="loading" form="DeleteWalletForm" type="button"
                        @click="$refs.DeleteWalletModal.hide()"
                        class="btn me-1 btn-primary btn-sm text-center align-items-center">
                        <span>Нет</span>
                    </button>
                </div>
            </template>
        </bs-modal>
        <!-- #endregion-->
    </div>
</template>

<script>
import {
    textC_alignM,
    textL_alignM,
} from '@/helpers/const'
import {
    feather
} from '@/main.js';
import {
    eventBus
} from '@/main.js'
import Vue from 'vue';
import {
    mapGetters
} from 'vuex'
import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => {
    window[name] = exported;
});
export default {
    data() {
        return {
            VisibleBlockOnTerminal: false,
            serviceNumber: null,
            unblock: false,
            blockOnTerminal: [],
            rows_to_display: [{
                text: "10",
                value: 10
            },
            {
                text: "25",
                value: 25
            },
            {
                text: "50",
                value: 50
            },
            {
                text: "100",
                value: 100
            },
            ],
            emptyText: "Нет топливных карт",
            filter: "",
            emptyFilteredText: "Не найдено",
            not_translation: false,
            checked_pin: true,
            cancel_action: "1",
            cardCommandsText: ["", ""],
            dismissSecs: 5,
            dismissCountDown: 0,
            fields: [

                {
                    key: 'number',
                    label: "Номер топливной карты",
                    sortable: true,
                    thClass: textC_alignM,
                    class: textC_alignM,
                    thStyle: {
                        width: "20%"
                    }
                },
                {
                    key: 'holderName',
                    label: "Номер ТС/Держатель",
                    sortable: true,
                    thClass: textC_alignM,
                    class: textL_alignM,
                    thStyle: {
                        width: "15%"
                    }
                },
                {
                    key: 'organizationName',
                    label: "Подразделение",
                    sortable: true,
                    thClass: textC_alignM,
                    class: textL_alignM,
                    thStyle: {
                        width: "20%"
                    }
                },

                {
                    key: 'wallet',
                    label: "Кошельки",
                    sortable: false,
                    class: textC_alignM,
                    thStyle: {
                        width: "35%"
                    },
                },
                {
                    key: 'actpls',
                    sortable: false,
                    label: "",
                    class: textC_alignM,
                    thStyle: {
                        width: "5%"
                    }
                },
                { key: 'actdel', sortable: false, label: "", class: textC_alignM, thStyle: { width: "5%" } }
            ],
            show_removed: false, //Отображение карт на удалении
            perPage: 10,
            currentPage: 1,
            edit: null,
            empty_text: "Нет карт",
            //Для карты//
            numberReissue: null,//Номер перевыпускаемой карты
            ReissueQuestion: false,///Вопрос про замену

            number: null, //Номер карты
            id: null, //GUID карты
            pin: null, //Пин-код
            needWallets: false,
            organizationID: null, //GUID подразделения
            holderName: null, //Имя держателя
            commandQueue: [], //Очередь команд
            changeable_pin: null,
            changeable_organizationID: null,
            //*******//

            //Для кошелька//
            creditWallet: false,
            wallet_name: null,
            wallet_id: null,
            wallet_quantity: null,
            changeable_wallet_quantity: null,
            wallet_productID: null,
            wallet_cardID: null,

            ///Дневной лимит по топливу
            dailyLimitValue: null,
            dailyLimitCurrent: null,
            checked_dailyLimitValue: false,

            ///Недельный лимит по топливу
            weekLimitValue: null,
            weekLimitCurrent: null,
            checked_weekLimitValue: false,
            invalid_weekLimit: true,
            invalid_weekLimit_text: "",

            ///Месячный лимит по топливу
            monthLimitValue: null,
            monthLimitCurrent: null,
            checked_monthLimitValue: false,
            invalid_monthLimit: true,

            invalid_monthLimit_text: "",

            ///Дневной лимит по операциям 
            operationLimitValue: null,
            operationLimitCurrent: null,
            checked_operationLimitValue: false,



            defaultLimit: null,
            checked_defaultLimit: false,
            collapseId: null,
            delete_cancel_wallet: false,
            changeable_quantity: null,
            changeable_productID: null,
            changeable_dailyLimitValue: null,
            changeable_weekLimitValue: null,
            changeable_monthLimitValue: null,
            changeable_operationLimitValue: null,
            changeable_defaultLimit: null,
            filtered_items: [],
            //*******//
            action: {
                type: null,
                method: null,
            },
            ///Дефолтный кошелёк
            defaultWallet: {
                //Для кошелька//
                creditWallet: false,
                wallet_name: null,
                wallet_id: null,
                wallet_productID: null,
                ///Дневной лимит по топливу
                dailyLimitValue: 0,
                checked_dailyLimitValue: false,
                ///Недельный лимит по топливу
                weekLimitValue: 0,
                checked_weekLimitValue: false,
                invalid_weekLimit: false,
                invalid_weekLimit_text: "",
                ///Месячный лимит по топливу
                monthLimitValue: 0,
                checked_monthLimitValue: false,
                invalid_monthLimit: false,
                invalid_monthLimit_text: "",
                ///Дневной лимит по операциям 
                operationLimitValue: 0,
                checked_operationLimitValue: false,
                ///Лимит по умолчанию
                defaultLimit: 0,
                checked_defaultLimit: false,
                wallet_cardID: null,

            },
            Wallets: [],
            activeWallet: 0,
        }
    },
    beforeDestroy() {

        // eventBus.$off('Complite_command')
    },
    destroyed() {

    },
    computed: {

        CurrentWallet() {
            if (this.Wallets.length == 0) {
                return null;
            }
            else return this.Wallets[this.activeWallet];
        },
        dailyLimitValues() { return this.Wallets.map(item => item.dailyLimitValue) },
        weekLimitValues() { return this.Wallets.map(item => item.weekLimitValue) },
        monthLimitValues() { return this.Wallets.map(item => item.monthLimitValue) },

        CaptionText() {
            return `Показано с ${this.currentPage * this.perPage - this.perPage + 1} по ${this.currentPage * this.perPage <= this.rows ? this.currentPage * this.perPage : this.rows} из ${this.rows} записей`
        },
        CardsWithOrganizations() {
            return this.AllCards.map(card => {
                return Object.assign(card, { organizationName: this.OrganizationByGUID(card.organizationID)?.name ?? null })
            })
        },
        Cards() {
            if (this.show_removed) return this.CardsWithOrganizations;
            else return this.CardsWithOrganizations.filter(a => !a.isRemoved);
        },
        rows_to_display_computed() { return this.rows_to_display.concat([{ text: "Все", value: this.rows }]) },
        rows() {
            if (this.filtered_items.length == 0 && this.filter == "")
                return this.Cards.length
            else {
                return this.filtered_items.length;
            }
        },
        ...mapGetters([
            'AllOrganizationsSelect',
            'AllProductsSelect',
            'ErrorApiResponseText',
            'AllCards',
            'CardByGUID',
            'CardsOnceLoaded',
            'CardsGetHaveError',
            'CardsGetAreLoading',
            'CardsPostHaveError',
            'CardsPostAreLoading',
            'CardsPutHaveError',
            'CardsPutAreLoading',
            'CardsDeleteAreLoading',
            'AllOrganizations',
            'OrganizationByGUID',
            'OrganizationsOnceLoaded',
            'OrganizationsGetAreLoading',
            'OrganizationsGetHaveError',
            'AllProducts',
            'ProductByGUID',
            'ProductsOnceLoaded',
            'ProductsGetAreLoading',

            'ProductsGetHaveError',
            'AllWallets',
            'WalletByGUID',
            'WalletsByCardGUID',
            'WalletFindByProdictGUID',
            'WalletsOnceLoaded',
            'WalletsGetAreLoading',
            'WalletsGetHaveError',
            'WalletsPostAreLoading',
            'WalletsPostHaveError',
            'WalletsPutAreLoading',
            'WalletsPutHaveError',
            'WalletsDeleteAreLoading',
            'WalletsDeleteHaveError',
            'TerminalByGUID',
            'TerminalsGetHaveError',
            'TerminalsGetAreLoading',
            'TerminalsOnceLoaded'
        ]),
        isBusy() {
            return (this.CardsGetAreLoading || this.WalletsGetAreLoading || this.OrganizationsGetAreLoading && this.ProductsGetAreLoading && this.TerminalsGetAreLoading) ? true : false;
        },
        loading() {
            switch (this.action.method) {
                case 'get':
                    if (this.action.type === 'card') return this.CardsGetAreLoading;
                    if (this.action.type === 'wallet') return this.WalletsGetAreLoading;
                case 'post':
                    if (this.action.type === 'card') return this.CardsPostAreLoading;
                    if (this.action.type === 'wallet') return this.WalletsPostAreLoading;
                case 'put':
                    if (this.action.type === 'card') return this.CardsPutAreLoading;
                    if (this.action.type === 'wallet') return this.WalletsPutAreLoading;
                case 'delete':
                    if (this.action.type === 'card') return this.CardsDeleteAreLoading;
                    if (this.action.type === "card_block") return this.CardsBlockAreLoading;
                    if (this.action.type === 'wallet') return this.WalletsDeleteAreLoading;
                default:
                    return null
            }
        },


        //Изменено подразделение
        OrgChanged() {
            let i = this.commandQueue.find(t => t.commandCode == 6 && t.status == 0);
            this.changeable_organizationID = i != undefined ? i.details.organizationID : null;
            return i == undefined ? false : true;
        },
        //Пин-код сменён
        PinChanged() {
            let i = this.commandQueue.find(t => t.commandCode == 2 && t.status == 0)
            this.changeable_pin = i != undefined ? i.details.pin : null;
            return i == undefined ? false : true;
        },

        WalletChanged() {
            let i = this.commandQueue.find(t => t.commandCode == 5 && t.status == 0 && t.details.walletID == this.CurrentWallet?.wallet_id)
            this.changeable_dailyLimitValue = i != undefined && i.details.dailyLimitValue != this.dailyLimitValue ? i.details.dailyLimitValue : null;
            this.changeable_weekLimitValue = i != undefined && i.details.weekLimitValue != this.weekLimitValue ? i.details.weekLimitValue : null;
            this.changeable_monthLimitValue = i != undefined && i.details.monthLimitValue != this.monthLimitValue ? i.details.monthLimitValue : null;
            this.changeable_operationLimitValue = i != undefined && i.details.operationLimitValue != this.operationLimitValue ? i.details.operationLimitValue : null;
            this.changeable_defaultLimit = i != undefined && i.details.defaultLimit != this.defaultLimit ? i.details.defaultLimit : null;
            return i == undefined ? false : true;
        },
        VolumeChanged() {
            let i = this.commandQueue.find(t => t.commandCode == 4 && t.status == 0 && t.details.walletID == this.wallet_id)
            this.changeable_quantity = i != undefined ? i.details.volume : null;
            return i == undefined ? false : true;
        },
    },
    mounted() {
        var text = localStorage.getItem("UserCard_Select")
        if (text) {
            this.perPage = this.rows_to_display_computed.find(a => a.text == text).value;
        }
        else this.perPage = 100//this.rows_to_display_computed[0].value

        feather.replace();
        /* eventBus.$on('Complite_command', (commandQueue) => {
             this.commandQueue = commandQueue
             this.OrgChanged;
             this.WalletChanged;
             this.PinChanged;
             this.VolumeChanged;
         })*/


        //  setTimeout(()=>{this.$refs['table_cards'].$refs['item-rows'].forEach(element => { element.$slots.default[3].data.attrs['data-label']="Сука";})},5000)
    },
    async created() {
        if (!this.CardsOnceLoaded) {
            await this.$store.dispatch(CARDS_GET_REQUEST);
        }
        if (!this.WalletsOnceLoaded) {
            await this.$store.dispatch(WALLETS_GET_REQUEST);
        }
        if (!this.TerminalsOnceLoaded) {
            await this.$store.dispatch(TERMINALS_GET_REQUEST);
        }
        if (!this.OrganizationsOnceLoaded) {
            await this.$store.dispatch(ORGANIZATIONS_GET_REQUEST);
        }
        if (!this.ProductsOnceLoaded) {
            await this.$store.dispatch(PRODUCTS_GET_REQUEST);
        }
    },
    watch: {
        pin(new_val) {
            if (new_val == "0000") {
                this.checked_pin = false;
                this.pin = null;
            }
        },
        needWallets(new_val) {
            if (new_val) {
                this.Wallets.push(Object.assign({}, JSON.parse(JSON.stringify(this.defaultWallet))));
            }
            else this.Wallets = [];
        },
        AllCards: {
            deep: true,
            handler: function (new_val, old_val) {
                if (this.id != null && new_val != null && new_val.length > 0) {
                    var card = new_val?.find(a => a.id == this.id) ?? null;
                    if (card != null) {
                        // this.blockOnTerminal = card.blockOnTerminal ?? [];
                        this.commandQueue = card.commandQueue;
                    }
                }
            }
        },
        number: function () {
            if (this.number != null) {
                let realNumber = this.number.replace(/\s/gi, '')
                // Generate dashed number
                let dashedNumber = realNumber.match(/.{1,4}/g)
                // Replace the dashed number with the real one
                if (dashedNumber != null) this.number = dashedNumber.join(' ')
            }

        },
        numberReissue: function () {
            if (this.numberReissue != null) {
                let realNumber = this.numberReissue.replace(/\s/gi, '')
                // Generate dashed number
                let dashedNumber = realNumber.match(/.{1,4}/g)
                // Replace the dashed number with the real one
                if (dashedNumber != null) this.numberReissue = dashedNumber.join(' ')
            }
        },

        rows(new_val) {
            if (new_val != 0 && localStorage.getItem("UserCard_Select") == "Все") this.perPage = this.rows_to_display_computed.find(a => a.text == "Все").value
        },


        dailyLimitValues(new_val) {
            if (new_val.length > 0) {
                if (this.activeWallet == new_val.length) return;

                var dailyLimitValueNew = new_val[this.activeWallet];
                this.Wallets[this.activeWallet].invalid_weekLimit = false;
                this.Wallets[this.activeWallet].invalid_monthLimit = false;

                if (dailyLimitValueNew) {
                    if (this.Wallets[this.activeWallet].checked_weekLimitValue && this.Wallets[this.activeWallet].weekLimitValue) {
                        if (dailyLimitValueNew >= this.Wallets[this.activeWallet].weekLimitValue) {
                            this.Wallets[this.activeWallet].invalid_weekLimit = true;
                            this.Wallets[this.activeWallet].invalid_weekLimit_text = "недельный лимит должен быть больше дневного"
                        }
                    }
                    else if (this.Wallets[this.activeWallet].checked_monthLimitValue && this.Wallets[this.activeWallet].monthLimitValue) {
                        if (dailyLimitValueNew >= this.Wallets[this.activeWallet].monthLimitValue) {
                            this.Wallets[this.activeWallet].invalid_monthLimit = true;
                            this.Wallets[this.activeWallet].invalid_monthLimit = "месячный лимит должен быть больше дневного"
                        }

                    }


                }

            }
        },
        weekLimitValues(new_val) {
            if (new_val.length > 0) {
                if (this.activeWallet == new_val.length) return;
                var weekLimitValueNew = new_val[this.activeWallet];

                this.Wallets[this.activeWallet].invalid_weekLimit = false;
                this.Wallets[this.activeWallet].invalid_monthLimit = false;
                if (weekLimitValueNew) {
                    if (this.Wallets[this.activeWallet].checked_dailyLimitValue && this.Wallets[this.activeWallet].dailyLimitValue) {
                        if (weekLimitValueNew <= this.Wallets[this.activeWallet].dailyLimitValue) {
                            this.Wallets[this.activeWallet].invalid_weekLimit = true;
                            this.Wallets[this.activeWallet].invalid_weekLimit_text = "недельный лимит должен быть больше дневного"
                        }
                    }
                    if (this.Wallets[this.activeWallet].checked_monthLimitValue && this.Wallets[this.activeWallet].monthLimitValue) {
                        if (weekLimitValueNew >= this.Wallets[this.activeWallet].monthLimitValue) {
                            this.Wallets[this.activeWallet].invalid_monthLimit = true;
                            this.Wallets[this.activeWallet].invalid_monthLimit_text = "месячный лимит должен быть больше недельного"
                        }
                    }

                }

            }
        },
        monthLimitValues(new_val) {
            if (new_val.length > 0) {
                if (this.activeWallet == new_val.length) return;

                var monthLimitValueNew = new_val[this.activeWallet];

                this.Wallets[this.activeWallet].invalid_monthLimit = false;
                if (monthLimitValueNew) {
                    if (this.Wallets[this.activeWallet].checked_weekLimitValue && this.Wallets[this.activeWallet].weekLimitValue) {
                        if (monthLimitValueNew <= this.Wallets[this.activeWallet].weekLimitValue) {
                            this.Wallets[this.activeWallet].invalid_monthLimit = true;
                            this.Wallets[this.activeWallet].invalid_monthLimit_text = "месячный лимит должен быть больше недельного"
                        }

                    }
                    else if (this.Wallets[this.activeWallet].checked_dailyLimitValue && this.Wallets[this.activeWallet].dailyLimitValue) {
                        if (monthLimitValueNew <= this.Wallets[this.activeWallet].dailyLimitValue) {
                            this.Wallets[this.activeWallet].invalid_monthLimit = true;
                            this.Wallets[this.activeWallet].invalid_monthLimit_text = "месячный лимит должен быть больше дневного"
                        }
                    }

                }
            }
        },

        dailyLimitValue(new_val) {
            this.invalid_weekLimit = false;
            this.invalid_monthLimit = false;

            if (new_val) {
                if (this.checked_weekLimitValue && this.weekLimitValue) {
                    if (new_val >= this.weekLimitValue) {
                        this.invalid_weekLimit = true;
                        this.invalid_weekLimit_text = "недельный лимит должен быть больше дневного"
                    }
                }
                else if (this.checked_monthLimitValue && this.monthLimitValue) {
                    if (new_val >= this.monthLimitValue) {
                        this.invalid_monthLimit = true;
                        this.invalid_monthLimit = "месячный лимит должен быть больше дневного"
                    }

                }


            }

        },
        weekLimitValue(new_val) {
            this.invalid_weekLimit = false;
            this.invalid_monthLimit = false;
            if (new_val) {
                if (this.checked_dailyLimitValue && this.dailyLimitValue) {
                    if (new_val <= this.dailyLimitValue) {
                        this.invalid_weekLimit = true;
                        this.invalid_weekLimit_text = "недельный лимит должен быть больше дневного"
                    }
                }
                if (this.checked_monthLimitValue && this.monthLimitValue) {
                    if (new_val >= this.monthLimitValue) {
                        this.invalid_monthLimit = true;
                        this.invalid_monthLimit_text = "месячный лимит должен быть больше недельного"
                    }
                }

            }


        },
        monthLimitValue(new_val) {
            this.invalid_monthLimit = false;
            if (new_val) {
                if (this.checked_weekLimitValue && this.weekLimitValue) {
                    if (new_val <= this.weekLimitValue) {
                        this.invalid_monthLimit = true;
                        this.invalid_monthLimit_text = "месячный лимит должен быть больше недельного"
                    }

                }
                else if (this.checked_dailyLimitValue && this.dailyLimitValue) {
                    if (new_val <= this.dailyLimitValue) {
                        this.invalid_monthLimit = true;
                        this.invalid_monthLimit_text = "месячный лимит должен быть больше дневного"
                    }
                }

            }

        }
    },
    methods: {
    
        onTabActivate(_, prevTabIndex, bvEvent) {
            let ref = 'WalletForm' + prevTabIndex;
            if (!this.$refs[ref][0].checkValidity()) {
                this.$refs[ref][0].reportValidity();
                bvEvent.preventDefault();
            }
            else if (this.Wallets[prevTabIndex].invalid_monthLimit || this.Wallets[prevTabIndex].invalid_weekLimit) {
               bvEvent.preventDefault();
            }
        },
        DeleteWalletTab(index) {
            this.Wallets.splice(index, 1);
        },
        NewWalletTab() {
            let ref = 'WalletForm' + this.activeWallet;
            if (!this.$refs[ref][0].checkValidity()) this.$refs[ref][0].reportValidity();
            else if (this.Wallets[this.activeWallet].invalid_monthLimit || this.Wallets[this.activeWallet].invalid_weekLimit) return;
            else {
                this.Wallets.push(Object.assign({}, JSON.parse(JSON.stringify(this.defaultWallet))));



                setTimeout(()=>this.activeWallet=this.Wallets.length-1,100) 
            }
        },
        async Refresh() {


            if (!this.TerminalsOnceLoaded) {
                await this.$store.dispatch(TERMINALS_GET_REQUEST);
            }
            if (!this.OrganizationsOnceLoaded) {
                await this.$store.dispatch(ORGANIZATIONS_GET_REQUEST);
            }
            if (!this.ProductsOnceLoaded) {
                await this.$store.dispatch(PRODUCTS_GET_REQUEST);
            }
            await this.$store.dispatch(CARDS_GET_REQUEST);
            await this.$store.dispatch(WALLETS_GET_REQUEST);
        },
        ReissueCard() {
            var card = { number: this.numberReissue.split(' ').join('') };
            this.$store.dispatch(CARDS_REISSUE_PUT_REQUEST, { card, oldCardId: this.id }).then(() => {
                this.$store.dispatch(TERMINALS_GET_REQUEST);
                this.$store.dispatch(WALLETS_GET_REQUEST);

                this.$refs['ReissueCardModal'].success();
            })
                .catch(err => {
                    /* if (err.response) {
                         err = this.ErrorApiResponseText(err);
                     }*/
                    this.$refs['ReissueCardModal'].error({ action: "stop_hide", err });
                    this.ReissueQuestion = false;
                })




        },
        AskReissueCardModal() {
            if (this.$refs.ReissueCardForm.checkValidity())
                this.ReissueQuestion = true;
            else this.$refs.ReissueCardForm.reportValidity();
        },

        ShowReissueCardModal() {
            this.ReissueQuestion = false,
                this.numberReissue = null;
            this.$refs["CreateEditCardModal"].hide();
            this.$refs["ReissueCardModal"].show({ vm: this, name: "CreateEditCardModal" });
        },
        ServiceNumberChange(e) {
            if (e.target.value == this.number) {
                this.serviceNumber = e.target.value.split(" ").join("");
            }
        },
        GetTerminalInfo(guid) {
            var terminal = this.TerminalByGUID(guid);
            return terminal?.name + `(${terminal.imei})`;
        },
        SaveToLocalStorage(e) {
            var a = this.rows_to_display_computed.find(a => a.value == e);
            if (a != undefined) localStorage.setItem("UserCard_Select", a.text);
        },
        HandleFocusout(e) {
            if (this.number != null) {
                if (this.number.split(" ").join("").length < 16) {
                    var temp = '0'.repeat(16 - this.number.split(" ").join("").length) + this.number.split(" ").join("");
                    this.number = this.NumberSpace(temp)
                }
            }
            else {
                var temp = '0'.repeat(16);
                this.number = this.NumberSpace(temp)
            }
        },
        HandleFocusoutReissue(e) {
            if (this.numberReissue != null) {
                if (this.numberReissue.split(" ").join("").length < 16) {
                    var temp = '0'.repeat(16 - this.numberReissue.split(" ").join("").length) + this.numberReissue.split(" ").join("");
                    this.numberReissue = this.NumberSpace(temp)
                }
            }
            else {
                var temp = '0'.repeat(16);
                this.numberReissue = this.NumberSpace(temp)
            }
        },
        onFiltered(filteredItems) {
            this.filtered_items = filteredItems;
            this.currentPage = 1;
        },
        clear() {
            this.filter = "";
        },
        filterTable(row, filter) {
            /*if (filter.length == 0 || filter.length == 1) return true*/
            if (row.holderName.toLowerCase().indexOf(filter.toLowerCase()) > -1 && row.holderName.toLowerCase()[0] == filter.toLowerCase()[0]) return true;
            if (row.number.split(' ').join('').toLowerCase().indexOf(filter.split(' ').join('').toLowerCase()) > -1 /*&& row.number.toLowerCase()[0] == filter.toLowerCase()[0]*/) return true;
            if (this.OrganizationByGUID(row.organizationID).name.toLowerCase().indexOf(filter.toLowerCase()) > -1 && this.OrganizationByGUID(row.organizationID).name.toLowerCase()[0] == filter.toLowerCase()[0]) return true;
            else return false
        },
        change_checked_pin(e) {
            if (this.checked_pin) {
                this.pin = "";
            }
        },

        ChangeChecked(e, type) {
            switch (type) {
                case 'Pin':
                    this.pin = null;
                    if (this.checked_pin) {
                        this.$nextTick(() => {
                            this.$refs.Pin.focus();
                        })
                    }

                    break;
                case 'operationLimit':
                    if (this.CurrentWallet?.checked_operationLimitValue) {
                        this.Wallets[this.activeWallet].operationLimitValue = null;
                        this.$nextTick(() => {
                            this.$refs.operationLimit[this.activeWallet].focus()
                        });
                    }
                    else this.Wallets[this.activeWallet].operationLimitValue = 0;
                    break;
                case 'dailyLimit':
                    if (this.CurrentWallet?.checked_dailyLimitValue) {
                        this.Wallets[this.activeWallet].dailyLimitValue = null;
                        this.$nextTick(() => {
                            this.$refs.dailyLimit[this.activeWallet].focus()
                        });

                    } else this.Wallets[this.activeWallet].dailyLimitValue = 0;
                    break;
                case 'weekLimit':
                    if (this.CurrentWallet?.checked_weekLimitValue) {
                        this.Wallets[this.activeWallet].weekLimitValue = null;
                        this.$nextTick(() => {
                            this.$refs.weekLimit[this.activeWallet].focus()
                        });

                    } else {
                        this.Wallets[this.activeWallet].weekLimitValue = 0;
                    }
                    break;
                case 'monthLimit':
                    if (this.CurrentWallet?.checked_monthLimitValue) {
                        this.Wallets[this.activeWallet].monthLimitValue = null;
                        this.$nextTick(() => {
                            this.$refs.monthLimit[this.activeWallet].focus()
                        });


                    } else {
                        this.Wallets[this.activeWallet].monthLimitValue = 0;
                    }
                    break;
                case 'defaultLimit':
                    if (this.CurrentWallet?.checked_defaultLimit) {
                        this.Wallets[this.activeWallet].defaultLimit = null;
                        this.$nextTick(() => {
                            this.$refs.defaultLimit[this.activeWallet].focus()
                        });
                    }
                    else this.Wallets[this.activeWallet].defaultLimit = 0;
                    break;
            }

        },
        //Отмена команды
        CommandCancel(card_id, command_id) {
            this.$store.dispatch(COMMANDCANCEL_DELETE_REQUEST, {
                card_id: card_id,
                command_id: command_id
            }).then(
                () => {
                    eventBus.$emit("New_message", { MessageType: "CancelCommand" })

                    if (this.id != null) {
                        this.$store.dispatch(CARDS_GET_BY_GUID_REQUEST, this.id)

                    }
                    if (this.wallet_id != null) {
                        this.$store.dispatch(WALLETS_GET_BY_GUID_REQUEST, this.wallet_id)
                    }
                }
            )
                .catch(err => {
                    console.log(`Отменить команду не удалось, код ошибки: ${err.code}`);
                    console.log(err)
                });
        },
        NumberSpace(card_number) {
            if (card_number != undefined) return card_number.match(/.{1,4}/g).join(' ')
        },

        //Только номер 
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                $event.preventDefault();
            }
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        summ_fuel(card) {
            var count = 0;
            var arr = this.CardByGUID(card)?.commandQueue.filter(element => element.commandCode === 4 || element.commandCode == 8 );
            for (var i = 0; i < arr.length; i++) {
                count += parseFloat(arr[i]?.details?.volume ?? 0)  ;
            }
            return 'Кошелёк пополняется. На пополнении ' + count.toString() + ' л.';
        },
        summ_fuel1(card) {
            var count = 0;
            var arr = this.CardByGUID(card)?.commandQueue.filter(element => element.commandCode === 4 || element.commandCode == 8);
            for (var i = 0; i < arr.length; i++) {
                count += parseFloat(arr[i]?.details?.volume?? 0) ;
            }
            return 'На пополнении ' + count.toString() + ' л.';
        },
        //////Действия с картой
        ShowCreateCardModal() {


            if (this.AllOrganizationsSelect().length == 0) {
                this.$refs['CreateEditCardModal'].error("Список подразделений пуст");
            }
            else {
                this.activeWallet = 0;
                this.Wallets = [];
                this.needWallets = false;
                this.commandQueue = [];
                this.blockOnTerminal = [];
                this.edit = false;
                this.checked_pin = true;
                this.serviceNumber = null;
                this.number = null;
                this.pin = null;
                this.holderName = null;
                this.organizationID = '';
                this.id = null;
                this.$refs['CreateEditCardModal'].show();
            }
        },
        ShowEditModalCard(element) {
            this.VisibleBlockOnTerminal = false;
            if (this.AllOrganizationsSelect().length == 0) {
                this.$refs['CreateEditCardModal'].error("Список подразделений пуст");
            }
            else {
                this.Wallets=[];
                this.needWallets=false;
                this.edit = true;
                this.id = element.id;

                this.$store.dispatch(CARDS_BOT_GET_REQUEST, this.id).then(resp => this.blockOnTerminal = resp?.data ?? []).catch(err => this.blockOnTerminal = []);
                this.numberReissue = null;
                this.ReissueQuestion = false;
                // this.blockOnTerminal = element.blockOnTerminal != null ? element.blockOnTerminal : [];
                this.commandQueue = element.commandQueue;
                if (element.pin == "0000") {
                    this.pin = "";
                    this.checked_pin = false
                } else {
                    this.checked_pin = true;
                    this.pin = element.pin;
                }
                this.serviceNumber = element.serviceNumber
                this.number = element.number;
                this.holderName = element.holderName;
                this.organizationID = element.organizationID;
                this.$refs['CreateEditCardModal'].show();
            }
        },
        CheckCardForm() {
            if (this.$refs.CreateEditCardForm.checkValidity()) {
                if (this.needWallets) {
                    for (let index = 0; index < this.Wallets.length; index++) {
                        let ref = 'WalletForm' + index;
                        if (this.$refs[ref][0].checkValidity() && !this.Wallets[index].invalid_monthLimit && !this.Wallets[index].invalid_weekLimit) continue;
                        else {

                            this.activeWallet = index; this.$nextTick(() => { this.$refs[ref][0].reportValidity(); return false; })
                        }

                    }
                    return true;
                }
                else return true;
            }
            else { this.$refs.CreateEditCardForm.reportValidity(); return false; }
        },
        async CreateEditCard() {
            if (this.edit) {
                this.action = {
                    type: 'card',
                    method: 'put'
                }
                let card = JSON.parse(JSON.stringify(this.CardByGUID(this.id)));
                if (this.checked_pin) {
                    card.pin = this.pin;
                } else {
                    card.pin = "0000"
                }
                card.organizationID = this.organizationID;
                card.number = this.number.split(' ').join('');
                if (this.serviceNumber == null || this.serviceNumber == "") {
                    card.serviceNumber = this.number.split(' ').join('')
                }
                else { card.serviceNumber = this.serviceNumber }

                card.holderName = this.holderName;
                await this.$store.dispatch(CARDS_PUT_REQUEST, card).then(
                    () => {
                        this.$refs['CreateEditCardModal'].success();
                    }
                )
                    .catch(
                        err => {
                            this.$refs['CreateEditCardModal'].error({ action: "stop_hide", err });
                        }
                    )
            } else {
                if (!this.CheckCardForm()) return;
                this.action = {
                    type: 'card',
                    method: 'post'
                }
                if (this.serviceNumber == null || this.serviceNumber == "") { this.serviceNumber = this.number.split(' ').join('') }

                var pin
                if (this.checked_pin) {
                    pin = this.pin;
                } else {
                    pin = "0000"
                }
                this.$store.dispatch('CARDS_POST_REQUEST', {
                    Card: {
                        pin,
                        serviceNumber: this.serviceNumber,
                        organizationID: this.organizationID,
                        number: this.number.split(' ').join(''),
                        holderName: this.holderName
                    }, Wallets: this.Wallets.map(element => {
                        return {
                            productID: element.wallet_productID,
                            name: element.wallet_name,
                            defaultLimit: element.defaultLimit,
                            dailyLimitValue: element.dailyLimitValue,
                            weekLimitValue: element.weekLimitValue,
                            monthLimitValue: element.monthLimitValue,
                            creditWallet: element.creditWallet,
                            operationLimitValue: element.operationLimitValue,
                        }
                    })
                }
                )
                    .then(() => {
                        this.$refs['CreateEditCardModal'].success();
                    })
                    .catch(err => {
                        /*if (err.response) {
                            err = this.ErrorApiResponseText(err.response);
                        }*/
                        this.$refs['CreateEditCardModal'].error({ err, action: "stop_hide" });
                    })
            }
        },
        ShowBlockCardModal() {
            this.$refs['BlockCardModal'].show();
            this.unblock = false;
        },
        ShowUnBlockCardModal() {
            this.$refs['BlockCardModal'].show();
            this.unblock = true;

        },
        async BlockCard() {
            this.action = {
                type: 'card',
                method: 'put'
            }
            if (this.CardByGUID(this.id) != undefined) {
                this.$store.dispatch(this.unblock ? CARDS_UNBLOCK_PUT_REQUEST : CARDS_BLOCK_PUT_REQUEST, this.id).then(() => {
                    this.$store.dispatch(CARDS_BOT_GET_REQUEST, this.id).then(resp => this.blockOnTerminal = resp?.data ?? []).catch(err => this.blockOnTerminal = []);
                    var card = this.CardByGUID(this.id);

                    //  this.blockOnTerminal = card?.blockOnTerminal != null ? card.blockOnTerminal : [];
                    this.commandQueue = card.commandQueue;
                    this.$store.dispatch(TERMINALS_GET_REQUEST);
                    this.$refs['BlockCardModal'].success();
                })
                    .catch(err => {
                        /*if (err.response) {
                            err = this.ErrorApiResponseText(err);
                        }*/
                        this.$refs['BlockCardModal'].error(err);
                    })
            }
        },
        ShowDeleteModalCard(element) {
            this.id = element.id;
            this.pin = element.pin;
            this.$refs['DeleteCardModal'].show();
        },
        async DeleteCard() {
            this.action = {
                type: 'card',
                method: 'delete'
            }
            if (this.CardByGUID(this.id) != undefined) {
                this.$store.dispatch(CARDS_DELETE_REQUEST, this.id).then(() => {
                    this.$refs['DeleteCardModal'].success();
                })
                    .catch(err => {
                        /*  if (err.response) {
                              err = this.ErrorApiResponseText(err);
                          }*/
                        this.$refs['DeleteCardModal'].error(err);
                    })
            }
        },
        //////Действия с кошельком
        ShowCreateModalWallet(element) {
            this.activeWallet = 0;

            if (this.AllProductsSelect().length == 0) {
                this.$refs['CreateEditWalletModal'].error("Список продуктов пуст");
            }

            else {
                this.id = element.id;
                this.edit = false;
                this.Wallets = [];
                this.Wallets.push(Object.assign({}, JSON.parse(JSON.stringify(this.defaultWallet))));
                /*this.creditWallet = false;
                this.wallet_name = null;
                this.wallet_id = null;
                this.wallet_quantity = null;
                this.wallet_productID = null;
                this.checked_weekLimitValue = false;
                this.weekLimitValue = 0;
                this.checked_dailyLimitValue = false;
                this.dailyLimitValue = 0;
                this.checked_monthLimitValue = false;
                this.monthLimitValue = 0;
                this.checked_operationLimitValue = false;
                this.operationLimitValue = 0;
                this.checked_defaultLimit = false;
                this.defaultLimit = 0;
                this.wallet_cardID = element.id;*/
                this.$refs['CreateEditWalletModal'].show();
            }
        },
        ShowEditModalWallet(element) {
            this.activeWallet = 0;

            if (this.AllProductsSelect().length == 0) {
                this.$refs['CreateEditWalletModal'].error("Список продуктов пуст");
            }
            else {
                this.Wallets = [];
                this.activeWallet=0;
                this.edit = true;
                this.id = element.cardID;

                this.Wallets.push({
                    creditWallet: element.creditWallet,
                    wallet_id: element.id,
                    wallet_name: element.name,
                    wallet_productID: element.productID,
                    dailyLimitValue: element.dailyLimitValue,
                    weekLimitValue: element.weekLimitValue,
                    monthLimitValue: element.monthLimitValue,
                    operationLimitValue: element.operationLimitValue,
                    defaultLimit: element.defaultLimit,
                    wallet_cardID: element.cardID,
                    isAdding: element.isAdding,
                })


                if (element.dailyLimitValue == 0) this.Wallets[this.activeWallet].checked_dailyLimitValue = false;
                else this.Wallets[this.activeWallet].checked_dailyLimitValue = true;

                if (element.weekLimitValue == 0) this.Wallets[this.activeWallet].checked_weekLimitValue = false
                else this.Wallets[this.activeWallet].checked_weekLimitValue = true;

                if (element.monthLimitValue == 0) this.Wallets[this.activeWallet].checked_monthLimitValue = false
                else this.Wallets[this.activeWallet].checked_monthLimitValue = true;

                if (element.operationLimitValue == 0) this.Wallets[this.activeWallet].checked_operationLimitValue = false;
                else this.Wallets[this.activeWallet].checked_operationLimitValue = true;

                if (element.defaultLimit == 0) this.Wallets[this.activeWallet].checked_defaultLimit = false;
                else this.Wallets[this.activeWallet].checked_defaultLimit = true;


                //CurrentWallet = element.cardID;
                this.commandQueue = this.CardByGUID(this.id).commandQueue
                this.$refs['CreateEditWalletModal'].show();
            }
        },
        CheckWalletForm() {
            if (this.$refs.CreateEditCardForm.checkValidity() && !this.invalid_monthLimit && !this.invalid_weekLimit) {
                return false;
            }
            else { this.$refs.CreateEditCardForm.reportValidity() }

        },
        async CreateEditWallet() {

            if (this.edit) {
                this.method = {
                    type: 'wallet',
                    method: 'put'
                };
                let wallet = JSON.parse(JSON.stringify(this.WalletByGUID(this.CurrentWallet.wallet_id)));
                wallet.name = this.CurrentWallet.wallet_name;
                wallet.defaultLimit = this.CurrentWallet.defaultLimit;
                wallet.dailyLimitValue = this.CurrentWallet.dailyLimitValue;
                wallet.weekLimitValue = this.CurrentWallet.weekLimitValue;
                wallet.monthLimitValue = this.CurrentWallet.monthLimitValue;
                wallet.operationLimitValue = this.CurrentWallet.operationLimitValue;
                await this.$store.dispatch('WALLETS_PUT_REQUEST', wallet).then(
                    () => {
                        this.$refs['CreateEditWalletModal'].success();

                    }
                )
                    .catch(
                        err => {

                            this.$refs['CreateEditWalletModal'].error(err);
                        }
                    )
            } else {
                this.method = {
                    type: 'wallet',
                    method: 'post'
                };
                this.$store.dispatch('WALLETS_POST_REQUEST', {
                    cardID: this.id,
                    productID: this.CurrentWallet.wallet_productID,
                    name: this.CurrentWallet.wallet_name,
                    defaultLimit: this.CurrentWallet.defaultLimit,
                    dailyLimitValue: this.CurrentWallet.dailyLimitValue,
                    weekLimitValue: this.CurrentWallet.weekLimitValue,
                    monthLimitValue: this.CurrentWallet.monthLimitValue,
                    creditWallet: this.CurrentWallet.creditWallet,
                    operationLimitValue: this.CurrentWallet.operationLimitValue,
                })
                    .then(() => {
                        this.$refs['CreateEditWalletModal'].success();
                    })
                    .catch(err => {
                        this.$refs['CreateEditWalletModal'].error(err);
                    })
            }

        },
        ///////////Пополнение кошелька
        ShowWalletPaymentModal(element) {
            this.edit = true;
            this.id = element.cardID;
            this.wallet_cardID = element.cardID;
            this.commandQueue = this.CardByGUID(this.wallet_cardID).commandQueue
            this.wallet_id = element.id
            this.wallet_quantity = null;
            this.$refs['WalletPaymentModal'].show();
        },
        async PaymenyWallet() {
            this.method = {
                type: 'wallet',
                method: 'put'
            };
            let wallet = JSON.parse(JSON.stringify(this.WalletByGUID(this.wallet_id)));
            wallet.quantity = this.wallet_quantity;
            await this.$store.dispatch('WALLETS_GET_PAYMENT_REQUEST', {
                wallet_id: this.wallet_id,
                wallet_quantity: this.wallet_quantity
            }).then(
                () => {
                    this.$store.dispatch(WALLETS_GET_BY_GUID_REQUEST, this.wallet_id);
                    this.$store.dispatch(CARDS_GET_BY_GUID_REQUEST, wallet.cardID);
                    this.$refs['WalletPaymentModal'].success({ action: "stop_hide" });
                }
            )
                .catch(
                    err => {
                        console.log(err)
                        this.$refs['WalletPaymentModal'].error(err);
                    }
                )
        },
        ShowDeleteModalWallet(elem) {
            this.wallet_id = elem.id;
            this.wallet_cardID = elem.cardID;
            this.wallet_name = elem.name;
            this.$refs['DeleteWalletModal'].show();
        },
        async DeleteWallet() {
            this.action = {
                type: 'wallet',
                method: 'delete'
            }
            let wallet = JSON.parse(JSON.stringify(this.WalletByGUID(this.wallet_id)))
            this.$store.dispatch('WALLETS_DELETE_REQUEST', this.wallet_id).then(() => {
                this.$refs['DeleteWalletModal'].success();
            })
                .catch(err => {
                    this.$refs['DeleteWalletModal'].error(err);
                })
        },
    },
}
</script>
<style>
.skeleton-wallet {
    height: skeleton-wallet;
}

.card-wallet+.card-wallet {}

.card-body-cards {
    overflow-y: auto;
}

.card-tabs .tab-content {
    border: 1px solid #DEE2E6;
    border-top: none
}
</style>
