<template>
    <div class="position-relative">
        <b-icon icon="x" v-if="currentValue == 0" class="position-absolute" style="top:10px; left: -5px;"
            variant="danger"></b-icon>
        <ul class="signal-strength" :style="{ 'height': (quantity * defaultHeight) + 'px' }">
            <li v-for="n in quantity" :style="{ 'padding-top': (defaultHeight * quantity - defaultHeight * n) + 'px' }"
                :key="n" :ref="'key' + n">
                <div></div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: {
        currentValue: { type: Number, default: 0 },
        quantity: { type: Number, default: 4 },
        maxQuality: { type: Number, default: 33 }
    },
    watch: {
        currentValue(new_val) {
            this.ChangeBack(new_val)

        }
    },
    mounted() {

        this.ChangeBack(this.currentValue)
    },
    methods: {
        ChangeBack(val) {


            if (val == 0) {

                for (var i = 1; i <= this.quantity; i++) {
                    var refName = `key${i}`
                    var parent = this.$refs[refName][0];
                    var firstChild = parent.querySelector(':first-child');

                    // Если первый вложенный элемент существует
                    if (firstChild) {
                        // Изменяем его стиль
                        firstChild.style.backgroundColor = '#d3d3d3';
                        //#9FD1A7
                    }
                }
            }
            else {
                var elemsInGroup = Math.floor(this.maxQuality / this.quantity)
                var k = 1;
                while ((this.currentValue > parseInt(k * elemsInGroup)) && k < this.quantity) {
                    k += 1;
                }
                for (var i = 1; i <= k; i++) {
                    var refName = `key${i}`
                    var parent = this.$refs[refName][0];
                    var firstChild = parent.querySelector(':first-child');
                    if (firstChild) {
                        // Изменяем его стиль
                        firstChild.style.backgroundColor = '#13A92C';
                        //#9FD1A7
                    }
                }
                for (var i = k + 1; i <= this.quantity; i++) {
                    var refName = `key${i}`
                    var parent = this.$refs[refName][0];
                    var firstChild = parent.querySelector(':first-child');

                    if (firstChild) {
                        // Изменяем его стиль
                        firstChild.style.backgroundColor = '#d3d3d3';

                        //#9FD1A7
                    }
                }

            }


        }
    },
    data() {
        return {
            defaultHeight: 5,

        }
    }
}
</script>

<style>
.signal-strength {
    float: left;
    list-style: none;
    overflow: hidden;
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
}

.signal-strength li {
    display: inline-block;
    width: 5px;
    float: left;
    height: 100%;
    margin-right: 1px;
}

.signal-strength li+li {
    margin-top: 0px !important;
}

.signal-strength li div {
    height: 100%;
    background-color: #13A92C;
}
</style>