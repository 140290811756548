<template>
    <div class="content-body">
        <div class="row">
            <div class="col-md-auto col-12">
                <!-- Page Headings Start -->
                <!--<page-heading heading=" "></page-heading>-->
                <div class="row align-items-center justify-content-between mb-0">
                    <h3><i data-feather="home" class="nav-icon"></i>
                        <span class="titlePage"> История выпуска карт </span>
                        <div class="btn-group">
                            <button type="button" title="Обновить" class="btn btn-default btn-smx" :disabled="isBusy"
                                v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" @click="Refresh()"><i
                                    class="nav-icon fas fa-sync-alt"></i></button>
                        </div>
                    </h3>
                </div>

                <!-- Page Headings End -->
                <!--Default Data Table Start-->


                <div class="card card-layout">

                    <div class="card-body p-0">
                        <div class="d-flex flex-wrap align-items-baseline justify-content-end p-2" style="gap:5px">

                            <data-table-filter v-model="filter"></data-table-filter>
                        </div>
                        <b-overlay :show="isBusy" :opacity="0.5">
                            <b-table ref="table_admin_cards" striped hover head-variant="light" :per-page="perPage"
                                sticky-header style="max-height: 60vh" :filter="filter" :filter-function="filterTable"
                                :current-page="currentPage" id="table_admin_cards" :fields="fields" responsive
                                :items="AllAdmin_cards" :busy="isBusy && AllAdmin_cards.length == 0" show-empty
                                primary-key="id">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template #emptyfiltered>
                                    <div class=" text-center my-2">{{ emptyFilteredText }}</div>
                                </template>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <strong>Загрузка...</strong>
                                    </div>
                                </template>
                                <template #empty>
                                    <div v-if="!Admin_cardsGetHaveError && !Admin_clientsGetHaveError"
                                        class="text-center my-2">
                                        {{ empty_text }}</div>
                                    <div v-else class="text-center my-2" style="color:red">Ошибка загрузки</div>
                                </template>
                                <template #cell(number)="data">
                                    <span
                                        :class="{ 'text-decoration-line-through text-danger': data.item.isRemoved }">{{
                                            NumberSpace(data.item.number) }}</span>
                                </template>
                                <!--  <template #table-caption v-if="rows != 0">
                                <div class="d-flex flex-column flex-md-row text-start  align-items-center px-2"
                                    :class="{ 'justify-content-end': rows <= perPage, 'justify-content-between': rows > perPage }">
                                    <b-pagination v-if="rows > perPage" :limit="3" v-model="currentPage"
                                        :total-rows="rows" :per-page="perPage" class="order-md-first order-last"
                                        aria-controls="table_cards" size="sm"></b-pagination>
                                    Записи с {{ currentPage * perPage - perPage + 1 }} до {{ currentPage * perPage
                                        <= rows ? currentPage * perPage : rows }} из {{ rows }} записей </div>
                            </template>-->
                            </b-table>
                            <pagination :total-rows="rows" :perPage.sync="perPage" :currentPage.sync="currentPage"
                                aria-controls="table_admin_cards"></pagination>
                        </b-overlay>

                    </div>
                </div>
            </div>
        </div>
        <bs-modal centered id="CreateEditCardModal" :loading="loading" ref="CreateEditCardModal" title="Новая карта">
            <div class="col-12 mb-6">
                <form ref="CreateEditCardForm" id="CreateEditCardForm" @submit.prevent="CreateEditCard">
                    <div class="row mb-n7 align-items-center">
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Номер
                                    карты</label></div>
                            <div class="col-sm-8 col-12 align-self-center"><input type="text" :disabled="loading"
                                    @keypress="onlyNumber" v-model="number" class="form-control"
                                    placeholder="xxxx xxxx xxxx xxxx" maxlength="19" title="xxxx xxxx xxxx xxxx"
                                    pattern="^[0-9]{4} {1}[0-9]{4} {1}[0-9]{4} {1}[0-9]{4}$" required /></div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 col-12 align-self-center"><label
                                    class="form-label font-weight-bold">Клиент</label></div>
                            <div class="col-sm-8 col-12 align-self-center">
                                <multiselect trackBy="text" :searchable="true" locale="ru" :allowEmpty="true"
                                    placeholder="Выберите клиента" id="time_zone" :close-on-select="true" label="text"
                                    :disabled="loading" v-model="clientID" :options="AllAdmin_clientsSelect">
                                    <template v-slot:nooptions>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2">Нет
                                            элементов</div>
                                    </template>
                                    <template v-slot:noresults>
                                        <div class="d-flex w-100 align-items-center justify-content-center my-2"> Не
                                            найдено</div>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <template #footer>
                <div class="col-12  text-start">
                    <button form="CreateEditCardForm" :disabled="loading" type="submit"
                        class="btn btn-primary btn-sm text-center align-items-center">
                        <span v-if="!loading">Сохранить</span>
                        <span v-else>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span>&nbsp; Загрузка...</span>
                        </span>
                    </button>
                </div>
            </template>
        </bs-modal>
    </div>
</template>
<script>
import { textC_alignM, textL_alignM, textR_alignM } from '@/helpers/const'
import { eventBus } from '@/main.js'
import { mapGetters } from 'vuex';
import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => { window[name] = exported; });
import { feather } from '@/main.js';
export default {
    data() {
        return {
            emptyFilteredText: "Не найдено",
            filtered_items: [],
            filter: "",
            fields: [
                {
                    key: 'index',
                    label: "№",
                    sortable: false,
                    thClass: textC_alignM,
                    tdClass: textR_alignM,
                    thStyle: {},
                    disabled: true,
                },
                {
                    key: 'number', label: "Номер карты", sortable: true, thClass: textC_alignM, class: textC_alignM, thStyle: { 'width': '60%' }
                },
                {
                    key: 'clientID', label: "Клиент", sortable: true, thClass: textC_alignM, class: textL_alignM, formatter: value => {
                        return `${this.Admin_clientByGUID(value)?.name} ${this.Admin_clientByGUID(value)?.email ? '(' + this.Admin_clientByGUID(value)?.email + ')' : ''}`;
                    }
                },
                {
                    key: 'emissionDate', label: "Дата выпуска", sortable: true, thClass: textC_alignM, class: textC_alignM, formatter: value => {
                        return moment(value).locale('ru').format('DD.MM.YYYY/HH:mm:ss');
                    }
                },
            ],
            action: { type: null, method: null, },
            number: null,
            clientID: null,
            permission_is_available: false,
            empty_text: "Нет карт",
            perPage: 10,
            currentPage: 1,
        }
    },
    mounted() {
        feather.replace();
    },
    computed: {
        rows() {
            return this.$refs?.table_admin_cards?.filteredItems.length ?? this.AllAdmin_cards.length
        },
        loading() {
            switch (this.action.method) {
                case 'get':
                    if (this.action.type === 'admin_cards') return this.Admin_cardsGetAreLoading;
                case 'post':
                    if (this.action.type === 'admin_cards') return this.Admin_cardsPostAreLoading;
                case 'put':
                    if (this.action.type === 'admin_cards') return this.Admin_cardsPutAreLoading;
                case 'delete':
                    if (this.action.type === 'admin_cards') return this.Admin_cardsDeleteAreLoading;
                default:
                    return null
            }
        },
        AllAdmin_clientsSelect() {
            return this.AllAdmin_clients?.filter(t => t.isRemoved == false)?.map(element => {
                return {
                    text: element.email,
                    value: element.id,
                }
            }) ?? []
        },
        isBusy() {
            return (this.Admin_clientsGetAreLoading || this.Admin_clientsGetAreLoading) ? true : false;

            if (this.Admin_cardsHaveError || this.Admin_clientsHaveError) {
                return false;
            }
            else {
                return (this.Admin_cardsOnceLoaded && this.Admin_clientsOnceLoaded) ? false : true;
            }
        },
        ...mapGetters([
            'AllAdmin_cards',
            'Admin_cardsByGUID',
            'Admin_cardsOnceLoaded',
            'Admin_cardsGetAreLoading',
            'Admin_cardsGetHaveError',
            'Admin_cardsPostAreLoading',
            'Admin_cardsPostHaveError',
            'Admin_cardsPutAreLoading',
            'Admin_cardsPutHaveError',
            'Admin_cardsDeleteAreLoading',
            'Admin_cardseleteHaveError',
            'Admin_clientsGetAreLoading',
            'Admin_clientsOnceLoaded',
            'AllAdmin_clients',
            'AllAdmin_clients',
            'Admin_clientByGUID',
            'Admin_clientsGetHaveError',
        ])
    },
    async created() {
        if (!this.Admin_cardsOnceLoaded) {
            await this.$store.dispatch(ADMIN_CARDS_GET_REQUEST)
        }
        if (!this.Admin_clientsOnceLoaded) {
            await this.$store.dispatch(ADMIN_CLIENTS_GET_REQUEST)
        }
    },
    watch: {
        number: function () {
            if (this.number != null) {
                let realNumber = this.number.replace(/\s/gi, '')
                // Generate dashed number
                let dashedNumber = realNumber.match(/.{1,4}/g)
                // Replace the dashed number with the real one
                if (dashedNumber != null) this.number = dashedNumber.join(' ')
            }
        },
    },
    methods: {
        async Refresh() {
            await this.$store.dispatch(ADMIN_CARDS_GET_REQUEST)
            await this.$store.dispatch(ADMIN_CLIENTS_GET_REQUEST)
        },
        clear() { this.filter = "" },
        filterTable(row, filter) {

            if (row.number.toLowerCase().indexOf(filter.toLowerCase()) > -1) return true;
            if (row.number.toLowerCase().indexOf(filter.toLowerCase().split(" ").join("")) > -1) return true;
            if (this.Admin_clientByGUID(row.clientID).name.toLowerCase().indexOf(filter.toLowerCase()) > -1) return true;
            return false
        },
        //Только номер 
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                $event.preventDefault();
            }
        },
        NumberSpace(card_number) {
            if (card_number != undefined) return card_number.match(/.{1,4}/g).join(' ')
        },
        ShowCreateCardModal() {
            this.number = null;
            this.clientID = null
            this.$refs['CreateEditCardModal'].show();
        },
        async CreateEditCard() {
            this.action = { type: 'admin_cards', method: 'post' }
            this.$store.dispatch(ADMIN_CARDS_POST_REQUEST, { number: this.number.split(' ').join(''), clientID: this.clientID, })
                .then(() => {
                    this.$refs['CreateEditCardModal'].success();
                })
                .catch(err => {
                    this.$refs['CreateEditCardModal'].error(err);
                })
        },
    },
}
</script>