import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import axios from 'axios';
import * as exp from '@/helpers/actions.js';
import indDB from '@/helpers/IndexedDB';
Object.entries(exp).forEach(([name, exported]) => { window[name] = exported; });
Vue.use(Vuex)
export default new Vuex.Store({
    state: {

        issuedCards: [],
        issuedCards_once_loaded: false,
        issuedCards_get_status: "",
        issuedCards_post_status: "",

        users: [],
        users_once_loaded: false,
        users_get_status: "",
        users_post_status: "",
        users_put_status: "",
        users_delete_status: "",
        products: [],
        products_once_loaded: false,
        products_get_status: "",
        products_post_status: "",
        products_put_status: "",
        products_delete_status: "",
        organizations: [],
        organizations_once_loaded: false,
        organizations_get_status: "",
        organizations_post_status: "",
        organizations_put_status: "",
        organizations_delete_status: "",



        drivers: [],
        drivers_once_loaded: false,
        drivers_get_status: "",
        drivers_post_status: "",
        drivers_put_status: "",
        drivers_delete_status: "",



        cards: [],
        cards_once_loaded: false,
        cards_get_status: "",
        cards_post_status: "",
        cards_put_status: "",
        cards_delete_status: "",
        wallets: [],
        wallets_once_loaded: false,
        wallets_get_status: "",
        wallets_post_status: "",
        wallets_put_status: "",
        wallets_delete_status: "",
        terminals: [],
        terminals_once_loaded: false,
        terminals_get_status: "",
        terminals_post_status: "",
        terminals_put_status: "",
        terminals_delete_status: "",
        admin_clients: [],
        admin_clients_once_loaded: false,
        admin_clients_get_status: "",
        admin_clients_post_status: "",
        admin_clients_put_status: "",
        admin_clients_delete_status: "",
        admin_cards: [],
        admin_cards_once_loaded: false,
        admin_cards_get_status: "",
        admin_cards_post_status: "",
        admin_cards_put_status: "",
        admin_cards_delete_status: "",
        http_status_401: false,
        http_status_401_message: "",
    },
    mutations: {
        call_401(state) {
            state.once_call = true;
        },
        // USERS
        [USERS_GET_REQUEST](state) {
            Vue.nextTick(() => {
                state.users_get_status = "loading";
            })
        },
        [USERS_GET_SUCCESS](state, data) {
            Vue.set(state, "users", data);
            Vue.nextTick(() => {
                state.users_once_loaded = true;
                state.users_get_status = "success";
            })
        },
        [USERS_GET_ERROR](state) {
            state.users_get_status = "error";
        },
        [USERS_POST_REQUEST](state) {
            Vue.nextTick(() => {
                state.users_post_status = "loading";
            })
        },
        [USERS_POST_ERROR](state) {
            Vue.nextTick(() => {
                state.users_post_status = "error";
            })
        },
        [USERS_POST_SUCCESS](state, data) {
            state.users.push(data);
            var i = state.admin_clients?.findIndex(p => p.id == data.clientID) ?? -1;
            if (i > -1)
                state.admin_clients[i].users?.push(data);
            Vue.nextTick(() => {
                state.users_post_status = "success";
            })
        },
        [USERS_PUT_REQUEST](state) {
            Vue.nextTick(() => {
                state.users_put_status = "loading";
            })
        },
        [USERS_PUT_ERROR](state) {
            Vue.nextTick(() => {
                state.users_put_status = "error";
            })
        },
        [USERS_PUT_SUCCESS](state, data) {
            let i = state.users?.findIndex(p => p.id == data.id) ?? -1;
            if (i > -1)
                state.users.splice(i, 1, data);

            i = state.admin_clients?.findIndex(p => p.id == data.clientID) ?? -1;
            if (i > -1) {
                var j = state.admin_clients[i]?.users.findIndex(p => p.id == data.id) ?? -1;
                if (j > -1) {
                    state.admin_clients[i].users.splice(j, 1, data);
                }
            }
            Vue.nextTick(() => { state.users_put_status = "success"; })
        },
        [USERS_DELETE_REQUEST](state) {
            Vue.nextTick(() => { state.users_delete_status = "loading"; })
        },
        [USERS_DELETE_SUCCESS](state, data) {
            let i = state.users?.findIndex(p => p.guid == data) ?? -1;
            if (i > -1)
                state.users[i].isRemoved = true;
            Vue.nextTick(() => { state.users_delete_status = "success"; })
        },
        [USERS_DELETE_ERROR](state) {
            Vue.nextTick(() => {
                state.users_delete_status = "error";
            })
        },
        [COMMANDCANCEL_DELETE_SUCCESS](state, data) {
            let i = state.cards.find(t => t.id == data.card_id)?.commandQueue.findIndex(p => p.id == data.command_id) ?? -1;
            if (i > -1)
                state.cards.find(t => t.id == data.card_id).commandQueue.splice(i, 1);
        },
        // PRODUCTS
        [PRODUCTS_GET_REQUEST](state) {
            Vue.nextTick(() => {
                state.products_get_status = "loading";
            })
        },
        [PRODUCTS_GET_ERROR](state) {
            Vue.nextTick(() => {
                state.products_get_status = "error";
            })
        },
        [PRODUCTS_GET_SUCCESS](state, data) {
            Vue.set(state, "products", data);
            Vue.nextTick(() => {
                state.products_once_loaded = true;
                state.products_get_status = "success";
            })
        },
        [PRODUCTS_POST_REQUEST](state) {
            Vue.nextTick(() => {
                state.products_post_status = "loading";
            })
        },
        [PRODUCTS_POST_ERROR](state) {
            Vue.nextTick(() => {
                state.products_post_status = "error";
            })
        },
        [PRODUCTS_POST_SUCCESS](state, data) {
            state.products.push(data);
            Vue.nextTick(() => {
                state.products_post_status = "success";
            })
        },
        [PRODUCTS_PUT_REQUEST](state) {
            Vue.nextTick(() => {
                state.products_put_status = "loading";
            })
        },
        [PRODUCTS_PUT_ERROR](state) {
            Vue.nextTick(() => {
                state.products_put_status = "error";
            })
        },
        [PRODUCTS_PUT_SUCCESS](state, data) {
            let i = state.products?.findIndex(p => p.id == data.id) ?? -1;
            if (i > -1)
                state.products.splice(i, 1, data);
            Vue.nextTick(() => {
                state.products_put_status = "success";
            })
        },
        [PRODUCTS_DELETE_REQUEST](state) {
            Vue.nextTick(() => {
                state.products_delete_status = "loading";
            })
        },
        [PRODUCTS_DELETE_SUCCESS](state, data) {
            let i = state.products?.findIndex(p => p.id == data.id) ?? -1;
            if (i > -1)
                state.products.splice(i, 1, data);

            // state.products[i].isRemoved = true;
            Vue.nextTick(() => {
                state.products_delete_status = "success";
            })
        },
        [PRODUCTS_DELETE_ERROR](state) {
            Vue.nextTick(() => {
                state.products_delete_status = "error";
            })
        },
        // ORGANIZATIONS
        [ORGANIZATIONS_GET_REQUEST](state) {
            Vue.nextTick(() => {
                state.organizations_get_status = "loading";
            })
        },
        [ORGANIZATIONS_GET_ERROR](state) {
            Vue.nextTick(() => {
                state.organizations_get_status = "error";
            })
        },
        [ORGANIZATIONS_GET_SUCCESS](state, data) {
            Vue.set(state, "organizations", data);
            Vue.nextTick(() => {
                state.organizations_once_loaded = true;
                state.organizations_get_status = "success";
            })
        },
        [ORGANIZATIONS_POST_REQUEST](state) {
            Vue.nextTick(() => {
                state.organizations_post_status = "loading";
            })
        },
        [ORGANIZATIONS_POST_ERROR](state) {
            Vue.nextTick(() => {
                state.organizations_post_status = "error";
            })
        },
        [ORGANIZATIONS_POST_SUCCESS](state, data) {
            state.organizations.push(data);
            Vue.nextTick(() => {
                state.organizations_post_status = "success";
            })
        },
        [ORGANIZATIONS_PUT_REQUEST](state) {
            Vue.nextTick(() => {
                state.organizations_put_status = "loading";
            })
        },
        [ORGANIZATIONS_PUT_ERROR](state) {
            Vue.nextTick(() => {
                state.organizations_put_status = "error";
            })
        },
        [ORGANIZATIONS_PUT_SUCCESS](state, data) {
            let i = state.organizations.findIndex(p => p.id == data.id) ?? -1;
            if (i > -1)
                state.organizations.splice(i, 1, data);
            Vue.nextTick(() => {
                state.organizations_put_status = "success";
            })
        },
        [ORGANIZATIONS_DELETE_REQUEST](state) {
            Vue.nextTick(() => {
                state.organizations_delete_status = "loading";
            })
        },
        [ORGANIZATIONS_DELETE_SUCCESS](state, data) {
            let i = state.organizations?.findIndex(p => p.guid == data.id) ?? -1;
            if (i > -1)
                state.organizations.splice(i, 1, data);
            Vue.nextTick(() => {
                state.organizations_delete_status = "success";
            })
        },
        [ORGANIZATIONS_DELETE_ERROR](state) {
            Vue.nextTick(() => {
                state.organizations_delete_status = "error";
            })
        },

        // #region DrriversMutations
        [DRIVERS_GET_REQUEST](state) {
            Vue.nextTick(() => {
                state.drivers_get_status = "loading";
            })
        },
        [DRIVERS_GET_ERROR](state) {
            Vue.nextTick(() => {
                state.drivers_get_status = "error";
            })
        },
        [DRIVERS_GET_SUCCESS](state, data) {
            Vue.set(state, "drivers", data);
            Vue.nextTick(() => {
                state.drivers_once_loaded = true;
                state.drivers_get_status = "success";
            })
        },
        [DRIVERS_GET_BY_GUID_SUCCESS](state, data) {
            let i = state.drivers.findIndex(p => p.id == data[0].id) ?? -1;
            if (i > -1)
                state.drivers.splice(i, 1, data[0]);
            else state.drivers.push(data[0])
            Vue.nextTick(() => { })
        },

        [DRIVERS_POST_REQUEST](state) {
            Vue.nextTick(() => {
                state.drivers_post_status = "loading";
            })
        },
        [DRIVERS_POST_ERROR](state) {
            Vue.nextTick(() => {
                state.drivers_post_status = "error";
            })
        },
        [DRIVERS_POST_SUCCESS](state, data) {
            state.drivers.push(data);
            Vue.nextTick(() => {
                state.drivers_post_status = "success";
            })
        },
        [DRIVERS_PUT_REQUEST](state) {
            Vue.nextTick(() => {
                state.drivers_put_status = "loading";
            })
        },
        [DRIVERS_PUT_ERROR](state) {
            Vue.nextTick(() => {
                state.drivers_put_status = "error";
            })
        },
        [DRIVERS_PUT_SUCCESS](state, data) {
            let i = state.drivers.findIndex(p => p.id == data.id) ?? -1;
            if (i > -1)
                state.drivers.splice(i, 1, data);
            Vue.nextTick(() => {
                state.drivers_put_status = "success";
            })
        },
        [DRIVERS_DELETE_REQUEST](state) {
            Vue.nextTick(() => {
                state.drivers_delete_status = "loading";
            })
        },
        [DRIVERS_DELETE_SUCCESS](state, data) {
            let i = state.drivers?.findIndex(p => p.guid == data.id) ?? -1;
            if (i > -1)
                state.drivers.splice(i, 1, data);
            Vue.nextTick(() => {
                state.drivers_delete_status = "success";
            })
        },
        [DRIVERS_DELETE_ERROR](state) {
            Vue.nextTick(() => {
                state.drivers_delete_status = "error";
            })
        },

        // #endregion driversMutations

        // #region IssuedCardsMutations
        [ISSUEDCARDS_GET_REQUEST](state) {
            Vue.nextTick(() => {
                state.issuedCards_get_status = "loading";
            })
        },
        [ISSUEDCARDS_GET_ERROR](state) {
            Vue.nextTick(() => {
                state.issuedCards_get_status = "error";
            })
        },
        [ISSUEDCARDS_GET_SUCCESS](state, data) {
            Vue.set(state, "issuedCards", data);
            Vue.nextTick(() => {
                state.issuedCards_once_loaded = true;
                state.issuedCards_get_status = "success";
            })
        },
        [ISSUEDCARDS_POST_REQUEST](state) {
            Vue.nextTick(() => {
                state.issuedCards_post_status = "loading";
            })
        },
        [ISSUEDCARDS_POST_ERROR](state) {
            Vue.nextTick(() => {
                state.issuedCards_post_status = "error";
            })
        },
        [ISSUEDCARDS_POST_SUCCESS](state, data) {
            if (data != null && data instanceof Object) {
                var arrayData = [];
                var type = null
                if (data.hasOwnProperty('type'))
                    type = data.type;
                if (data.hasOwnProperty('data'))
                    var arrayData = data.data;

                if (type != null && type == 'fuelCard') {
                    for (var i = 0; i < arrayData.length; i++) {
                        if (arrayData[i].hasOwnProperty('wallets')) {
                            arrayData[i].wallets.forEach(a => state.wallets.push(a))
                            delete arrayData[i]['wallets'];
                        }
                        state.cards.push(arrayData[i]);
                    }
                }
                if (type != null && type == 'driverCard') {
                    for (var i = 0; i < arrayData.length; i++) {

                        state.drivers.push(arrayData[i])
                    }
                }
            }

            Vue.nextTick(() => {
                state.issuedCards_once_loaded = true;
                state.issuedCards_post_status = "success";
            })
        },

        // #endregion IssuedCardsMutations
        // CARDS
        [CARDS_GET_REQUEST](state) {
            Vue.nextTick(() => {
                state.cards_get_status = "loading";
            })
        },
        [CARDS_GET_ERROR](state) {
            Vue.nextTick(() => {
                state.cards_get_status = "error";
            })
        },
        [CARDS_GET_SUCCESS](state, data) {
            Vue.set(state, "cards", data);
            Vue.nextTick(() => {
                state.cards_once_loaded = true;
                state.cards_get_status = "success";
            })
        },
        [CARDS_GET_BY_GUID_SUCCESS](state, data) {
            let i = state.cards.findIndex(p => p.id == data[0].id) ?? -1;
            if (i > -1)
                state.cards.splice(i, 1, data[0]);
            else state.cards.push(data[0])
            Vue.nextTick(() => { })
        },
        [CARDS_POST_REQUEST](state) {
            Vue.nextTick(() => {
                state.cards_post_status = "loading";
            })
        },
        [CARDS_POST_ERROR](state) {
            Vue.nextTick(() => {
                state.cards_post_status = "error";
            })
        },
        [CARDS_POST_SUCCESS](state, data) {
            if (data.hasOwnProperty('wallets')) {
                data.wallets.forEach(a => state.wallets.push(a))
                delete data['wallets'];
            }
            state.cards.push(data);

            Vue.nextTick(() => {
                state.cards_post_status = "success";
            })
        },
        [CARDS_PUT_REQUEST](state) {
            Vue.nextTick(() => {
                state.cards_put_status = "loading";
            })
        },
        [CARDS_PUT_ERROR](state) {
            Vue.nextTick(() => {
                state.cards_put_status = "error";
            })
        },
        [CARDS_PUT_SUCCESS](state, data) {

            if (data instanceof Array) {

                for (var m = 0; m < data.length; m++) {
                    let i = state.cards.findIndex(p => p.id == data[m].id) ?? -1;
                    if (i > -1)
                        state.cards.splice(i, 1, data[m]);
                    else
                        state.cards.push(data[m]);
                }
                Vue.nextTick(() => {
                    state.cards_put_status = "success";
                })
            } else if (data instanceof Object) {
                let i = state.cards.findIndex(p => p.id == data.id) ?? -1;
                if (i > -1)
                    state.cards.splice(i, 1, data);
                Vue.nextTick(() => {
                    state.cards_put_status = "success";
                })
            }
        },
        [CARDS_DELETE_REQUEST](state) {
            Vue.nextTick(() => {
                state.cards_delete_status = "loading";
            })
        },
        [CARDS_DELETE_SUCCESS](state, data) {
            let i = state.cards?.findIndex(p => p.id == data.id) ?? -1;
            if (i > -1)
                state.cards.splice(i, 1, data);
            Vue.nextTick(() => { state.cards_delete_status = "success"; })
        },
        [CARDS_DELETE_ERROR](state) {
            Vue.nextTick(() => {
                state.cards_delete_status = "error";
            })
        },
        // WALLETS
        [WALLETS_GET_REQUEST](state) {
            Vue.nextTick(() => {
                state.wallets_get_status = "loading";
            })
        },
        [WALLETS_GET_ERROR](state) {
            Vue.nextTick(() => {
                state.wallets_get_status = "error";
            })
        },
        [WALLETS_GET_SUCCESS](state, data) {
            data.forEach(element => {
                element.toggle = false;
            });
            Vue.set(state, "wallets", data);
            Vue.nextTick(() => {
                state.wallets_once_loaded = true;
                state.wallets_get_status = "success";
            })
        },
        [WALLETS_GET_BY_GUID_SUCCESS](state, data) {
            let i = state.wallets.findIndex(p => p.id == data[0].id) ?? -1;
            if (i > -1)
                state.wallets.splice(i, 1, data[0]);
            else state.wallets.push(data[0]);
            Vue.nextTick(() => { })


        },
        [WALLETS_POST_REQUEST](state) {
            Vue.nextTick(() => {
                state.wallets_post_status = "loading";
            })
        },
        [WALLETS_POST_ERROR](state) {
            Vue.nextTick(() => {
                state.wallets_post_status = "error";
            })
        },
        [WALLETS_POST_SUCCESS](state, data) {
            data.toggle = false
            state.wallets.push(data);
            Vue.nextTick(() => {
                state.wallets_post_status = "success";
            })
        },
        [WALLETS_PUT_REQUEST](state) {
            Vue.nextTick(() => {
                state.wallets_put_status = "loading";
            })
        },
        [WALLETS_PUT_ERROR](state) {
            Vue.nextTick(() => {
                state.wallets_put_status = "error";
            })
        },
        [WALLETS_PUT_SUCCESS](state, data, toggle) {
            // data.toggle = toggle;
            let i = state.wallets.findIndex(p => p.id == data.id) ?? -1;
            if (i > -1)
                state.wallets.splice(i, 1, data);
            Vue.nextTick(() => {
                state.wallets_put_status = "success";
            })
        },
        [WALLETS_DELETE_REQUEST](state) {
            Vue.nextTick(() => { state.wallets_delete_status = "loading"; })
        },
        [WALLETS_DELETE_SUCCESS](state, data) {
            let i = state.wallets?.findIndex(p => p.id == data) ?? -1;
            if (i > -1)
                state.wallets[i].isRemoving = true;
            Vue.nextTick(() => {
                state.wallets_delete_status = "success";
            })
        },
        [WALLETS_DELETE_SUCCESS_TRUE](state, data) {
            let i = state.wallets?.findIndex(p => p.id == data) ?? -1;
            if (i > -1)
                state.wallets.splice(i, 1, data);
        },
        [WALLETS_DELETE_ERROR](state) {
            Vue.nextTick(() => {
                state.wallets_delete_status = "error";
            })
        },
        // TERMINALS
        [TERMINALS_GET_REQUEST](state) {
            Vue.nextTick(() => {
                state.terminals_get_status = "loading";
            })
        },
        [TERMINALS_GET_ERROR](state) {
            Vue.nextTick(() => {
                state.terminals_get_status = "error";
            })
        },
        [TERMINALS_GET_SUCCESS](state, data) {

            if (state.terminals_once_loaded) {
                //   state.terminals = data;

                for (var i = 0; i < data.length; i++) {
                    var ind = state.terminals.findIndex(a => a.id == data[i].id) ?? -1;
                    if (ind == -1) { data[i].active_tab = 0; state.terminals.push(data[i]) }
                    else {
                        data[i].active_tab = state.terminals[ind]?.active_tab ?? 0;
                        state.terminals.splice(ind, 1, data[i])
                    }
                }
            }
            else {
                data.forEach(element => { element.active_tab = 0 })

                Vue.set(state, "terminals", data);
            }
            Vue.nextTick(() => {
                state.terminals_once_loaded = true;
                state.terminals_get_status = "success";
            })
        },
        [TERMINALS_GET_BY_GUID_SUCCESS](state, data) {
            let i = state.terminals.findIndex(p => p.id == data[0].id) ?? -1;
            if (i > -1)
                state.terminals.splice(i, 1, data[0]);
            else state.terminals.push(data[0])
        },
        [TERMINALS_POST_REQUEST](state) {
            Vue.nextTick(() => {
                state.terminals_post_status = "loading";
            })
        },
        [TERMINALS_POST_ERROR](state) {
            Vue.nextTick(() => {
                state.terminals_post_status = "error";
            })
        },
        [TERMINALS_POST_SUCCESS](state, data) {
            data.active_tab = 0;
            state.terminals.push(data);
            Vue.nextTick(() => {
                state.terminals_post_status = "success";
            })
        },
        [TERMINALS_PUT_REQUEST](state) {
            Vue.nextTick(() => {
                state.terminals_put_status = "loading";
            })
        },
        [TERMINALS_PUT_ERROR](state) {
            Vue.nextTick(() => {
                state.terminals_put_status = "error";
            })
        },
        [TERMINALS_PUT_SUCCESS](state, data) {

            if (data?.oldClientID != null && data?.clientID != null) {
                var i = state.admin_clients?.findIndex(p => data.clientID == p.id) ?? -1;
                if (i > -1) {
                    var new_term = data.terminals.find(a => a.imei != null) ?? null;
                    if (new_term != null) {
                        state.admin_clients[i]?.terminals?.push(new_term);
                        i = state.admin_clients?.findIndex(p => data.oldClientID == p.id) ?? -1;
                        if (i > -1) {
                            var old_terminal = data.terminals.find(a => a.imei == null) ?? null;
                            if (old_terminal != null) {
                                var j = state.admin_clients[i].terminals?.findIndex(a => a.id == old_terminal.id) ?? -1
                                if (j > -1) {
                                    state.admin_clients[i]?.terminals.splice(j, 1, old_terminal)
                                }
                            }
                        }
                    }
                }

            }
            else {
                i = state.terminals.findIndex(p => p.id == data.terminal.id) ?? -1;
                if (i > -1) {
                    var active_tab = state.terminals[i].active_tab ?? 0
                    data.terminal.active_tab = active_tab;
                    state.terminals.splice(i, 1, data.terminal);
                }
            }

            Vue.nextTick(() => {
                state.terminals_put_status = "success";
            })
        },
        [TERMINALS_DELETE_REQUEST](state) {
            Vue.nextTick(() => {
                state.terminals_delete_status = "loading";
            })
        },
        [TERMINALS_DELETE_SUCCESS](state, data) {
            /*            let i = state.terminals?.findIndex(p => p.guid == data) ?? -1;
                    if (i > -1)
                        state.terminals.splice(i, 1); */
            Vue.nextTick(() => {
                state.terminals_delete_status = "success";
            })
        },
        [TERMINALS_DELETE_ERROR](state) {
            Vue.nextTick(() => {
                state.terminals_delete_status = "error";
            })
        },
        //Admin_clients
        [ADMIN_CLIENTS_GET_REQUEST](state) {
            Vue.nextTick(() => {
                state.admin_clients_get_status = "loading";
            })
        },
        [ADMIN_CLIENTS_GET_ERROR](state) {
            Vue.nextTick(() => {
                state.admin_clients_get_status = "error";
            })
        },
        [ADMIN_CLIENTS_GET_SUCCESS](state, data) {
            Vue.set(state, "admin_clients", data);
            Vue.nextTick(() => {
                state.admin_clients_once_loaded = true;
                state.admin_clients_get_status = "success";
            })
        },
        [ADMIN_CLIENTS_GET_BY_GUID_SUCCESS](state, data) {
            let i = state.admin_clients.findIndex(p => p.id == data[0].id) ?? -1;
            if (i > -1)
                state.admin_clients.splice(i, 1, data[0]);
            else state.admin_clients.push(data[0])
        },
        [ADMIN_CLIENTS_POST_REQUEST](state) {
            Vue.nextTick(() => {
                state.admin_clients_post_status = "loading";
            })
        },
        [ADMIN_CLIENTS_POST_ERROR](state) {
            Vue.nextTick(() => {
                state.admin_clients_post_status = "error";
            })
        },
        [ADMIN_CLIENTS_POST_SUCCESS](state, data) {
            state.admin_clients.push(data);
            Vue.nextTick(() => {
                state.admin_clients_post_status = "success";
            })
        },
        [ADMIN_CLIENTS_PUT_REQUEST](state) {
            Vue.nextTick(() => {
                state.admin_clients_put_status = "loading";
            })
        },
        [ADMIN_CLIENTS_PUT_ERROR](state) {
            Vue.nextTick(() => {
                state.admin_clients_put_status = "error";
            })
        },
        [ADMIN_CLIENTS_PUT_SUCCESS](state, data) {
            let i = state.admin_clients.findIndex(p => p.id == data.id) ?? -1;
            if (i > -1)
                state.admin_clients.splice(i, 1, data);
            Vue.nextTick(() => {
                state.admin_clients_put_status = "success";
            })
        },
        [ADMIN_CLIENTS_DELETE_REQUEST](state) {
            Vue.nextTick(() => {
                state.admin_clients_delete_status = "loading";
            })
        },
        [ADMIN_CLIENTS_DELETE_SUCCESS](state, data) {
            Vue.nextTick(() => {
                state.admin_clients_delete_status = "success";
            })
            /*            let i = state.admin_clients?.findIndex(p => p.guid == data) ?? -1;
                    if (i > -1)
                        state.admin_clients.splice(i, 1); */
        },
        [ADMIN_CLIENTS_DELETE_ERROR](state) {
            Vue.nextTick(() => {
                state.admin_clients_delete_status = "error";
            })
        },
        //admin_cards
        [ADMIN_CARDS_GET_REQUEST](state) {
            Vue.nextTick(() => {
                state.admin_cards_get_status = "loading";
            })
        },
        [ADMIN_CARDS_GET_ERROR](state) {
            Vue.nextTick(() => {
                state.admin_cards_get_status = "error";
            })
        },
        [ADMIN_CARDS_GET_SUCCESS](state, data) {
            Vue.set(state, "admin_cards", data);
            Vue.nextTick(() => {
                state.admin_cards_once_loaded = true;
                state.admin_cards_get_status = "success";
            })
        },
        [ADMIN_CARDS_GET_BY_GUID_SUCCESS](state, data) {
            let i = state.admin_cards.findIndex(p => p.id == data[0].id) ?? -1;
            if (i > -1)
                state.admin_cards.splice(i, 1, data[0]);
            else state.admin_cards.push(data[0])
        },
        [ADMIN_CARDS_POST_REQUEST](state) {
            Vue.nextTick(() => {
                state.admin_cards_post_status = "loading";
            })
        },
        [ADMIN_CARDS_POST_ERROR](state) {
            Vue.nextTick(() => {
                state.admin_cards_post_status = "error";
            })
        },
        [ADMIN_CARDS_POST_SUCCESS](state, data) {
            state.admin_cards.push(data);
            Vue.nextTick(() => {
                state.admin_cards_post_status = "success";
            })
        },
        [ADMIN_CARDS_PUT_REQUEST](state) {
            Vue.nextTick(() => {
                state.admin_cards_put_status = "loading";
            })
        },
        [ADMIN_CARDS_PUT_ERROR](state) {
            Vue.nextTick(() => {
                state.admin_cards_put_status = "error";
            })
        },
        [ADMIN_CARDS_PUT_SUCCESS](state, data) {
            let i = state.admin_cards.findIndex(p => p.id == data.id) ?? -1;
            if (i > -1)
                state.admin_cards.splice(i, 1, data);
            Vue.nextTick(() => {
                state.admin_cards_put_status = "success";
            })
        },
        [ADMIN_CARDS_DELETE_REQUEST](state) {
            Vue.nextTick(() => {
                state.admin_cards_delete_status = "loading";
            })
        },
        [ADMIN_CARDS_DELETE_SUCCESS](state, data) {
            /*            let i = state.admin_cards?.findIndex(p => p.guid == data) ?? -1;
                    if (i > -1)
                        state.admin_cards.splice(i, 1,data); */
            Vue.nextTick(() => {
                state.admin_cards_delete_status = "success";
            })
        },
        [ADMIN_CARDS_DELETE_ERROR](state) {
            Vue.nextTick(() => {
                state.admin_cards_delete_status = "error";
            })
        },
        [AUTH_LOGIN_SUCCESS](state) {
            state.http_status_401 = false;
        },
        [SET_HTTP_401_STATUS](state, message) {
            state.http_status_401 = true;
            if (message == null || message == '')
                message = "Время авторизационной сессии истекло"
            state.http_status_401_message = message
        },
        [CLEAR_FLAGS](state) {
            state.cards_get_status = "";
            state.users_get_status = "";
            state.terminals_get_status = "";
            state.products_get_status = "";
            state.organizations_get_status = "";
            state.drivers_get_status = "";
            state.wallets_get_status = "";
            state.users_once_loaded = false;
            state.products_once_loaded = false;
            state.organizations_once_loaded = false;
            state.drivers_once_loaded = false
            state.cards_once_loaded = false;
            state.wallets_once_loaded = false;
            state.terminals_once_loaded = false;
            state.admin_cards_once_loaded = false;
            state.admin_clients_once_loaded = false;
            state.admin_cards = [];
            state.admin_clients = [];
            state.users = [];
            state.products = [];
            state.organizations = [];
            state.drivers = [];
            state.cards = [];
            state.wallets = [];
            state.terminals = [];

            state.issuedCards = [],
                state.issuedCards_once_loaded = false;
            state.issuedCards_get_status = "";
            state.issuedCards_post_status = "";
        }
    },
    actions: {
        //HELPERS
        [CHECK_HTTP_ERROR]: async ({ dispatch, commit, getters }, err) => {
            if (err.status === 401 || err.response?.status === 401 && !getters.HttpStatus401) {
                commit('SET_HTTP_401_STATUS', err?.response?.data)
                dispatch(AUTH_LOGOUT);
            }
        },
        //AUTH
        [AUTH_LOGIN]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                //commit(AUTH_LOGIN);
                API({ url: "Account", data, method: "POST" })
                    .then(resp => {
                        commit(AUTH_LOGIN_SUCCESS);
                        resolve(resp);
                    })
                    .catch(err => {
                        //commit(AUTH_LOGIN_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [AUTH_GET_MAGIC_TOKEN_REQUEST]: ({ commit, dispatch }, user_id) => {
            return new Promise((resolve, reject) => {
                API({ url: `Account/magictoken/${user_id}`, method: "GET" })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [AUTH_LOGOUT]: async ({ commit, dispatch, }) => {

            if (router?.history._startLocation.includes('admin')) {
                await indDB.deleteUserToken();
                await indDB.deleteMagicToken()
            }
            else {
                var role;
                await indDB.getUserToken().then(resp => role = resp?.role)
                if (role == "admin") {
                    await indDB.deleteMagicToken()
                    if (router?.history.current.path.includes('admin')) {
                        await indDB.deleteUserToken();
                    }
                }
                else {
                    await indDB.deleteUserToken();
                }

            }

            commit(CLEAR_FLAGS);
            router.push({ name: "Login" })
        },
        //USERS
        [USERS_GET_REQUEST]: ({ commit, dispatch }) => {
            commit(USERS_GET_REQUEST);
            API({ url: "user" })
                .then(resp => {
                    commit(USERS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(USERS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [USERS_BY_GUID_GET_REQUEST]: ({ commit, dispatch }, id) => {
            return new Promise((resolve, reject) => {
                API({ url: `user/${id}` })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [USERS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(USERS_PUT_REQUEST);
                API({ url: "user", data, method: "PUT" })
                    .then(resp => {
                        commit(USERS_PUT_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(USERS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [USERS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(USERS_POST_REQUEST);
                API({ url: "user", data, method: "POST" })
                    .then(resp => {
                        commit(USERS_POST_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(USERS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [USERS_DELETE_REQUEST]: ({ commit, dispatch }, id) => {
            return new Promise((resolve, reject) => {
                commit(USERS_DELETE_REQUEST);
                API({ url: `user/${id}`, data, method: "DELETE" })
                    .then(resp => {
                        commit(USERS_DELETE_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(USERS_DELETE_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [COMMANDCANCEL_DELETE_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                API({ url: `CardCommand/${data.command_id}`, method: "DELETE" })
                    .then(resp => {
                        commit(COMMANDCANCEL_DELETE_SUCCESS, data);
                        resolve(resp)
                    })
                    .catch(err => {
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    })
            });
        },
        //PRODUCTS
        [PRODUCTS_GET_REQUEST]: ({ commit, dispatch }) => {
            commit(PRODUCTS_GET_REQUEST);
            API({ url: "product" })
                .then(resp => {
                    commit(PRODUCTS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(PRODUCTS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [ADMIN_PRODUCTS_GET_REQUEST]: ({ commit, dispatch }, guid) => {
            commit(PRODUCTS_GET_REQUEST);
            API({ url: `product/admin/${guid}` })
                .then(resp => {
                    commit(PRODUCTS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(PRODUCTS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [PRODUCTS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(PRODUCTS_POST_REQUEST);
                API({ url: "product", data, method: "POST" })
                    .then(resp => {
                        commit(PRODUCTS_POST_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(PRODUCTS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [ADMIN_PRODUCTS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(PRODUCTS_POST_REQUEST);
                API({ url: `product/admin/${data._client_id}`, data: data.product, method: "POST" })
                    .then(resp => {
                        commit(PRODUCTS_POST_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(PRODUCTS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [PRODUCTS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(PRODUCTS_PUT_REQUEST);
                API({ url: "product", data, method: "PUT" })
                    .then(resp => {
                        commit(PRODUCTS_PUT_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(PRODUCTS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [PRODUCTS_DELETE_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                commit(PRODUCTS_PUT_REQUEST);
                API({ url: `product/${guid}/`, method: "DELETE" })
                    .then(resp => {
                        commit(PRODUCTS_DELETE_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(PRODUCTS_DELETE_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        //ORGANIZATIONS
        [ORGANIZATIONS_GET_REQUEST]: ({ commit, dispatch }) => {
            commit(ORGANIZATIONS_GET_REQUEST);
            API({ url: "organization" })
                .then(resp => {
                    commit(ORGANIZATIONS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(ORGANIZATIONS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [ADMIN_ORGANIZATIONS_GET_REQUEST]: ({ commit, dispatch }, guid) => {
            commit(ORGANIZATIONS_GET_REQUEST);
            API({ url: `organization/admin/${guid}` })
                .then(resp => {
                    commit(ORGANIZATIONS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(ORGANIZATIONS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [ADMIN_ORGANIZATIONS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(ORGANIZATIONS_POST_REQUEST);
                API({ url: `organization/admin/${data._client_id}`, data: data.org, method: "POST" })
                    .then(resp => {
                        commit(ORGANIZATIONS_POST_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ORGANIZATIONS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [ORGANIZATIONS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(ORGANIZATIONS_POST_REQUEST);
                API({ url: "organization", data, method: "POST" })
                    .then(resp => {
                        commit(ORGANIZATIONS_POST_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ORGANIZATIONS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [ORGANIZATIONS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(ORGANIZATIONS_PUT_REQUEST);
                API({ url: "organization", data, method: "PUT" })
                    .then(resp => {
                        commit(ORGANIZATIONS_PUT_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ORGANIZATIONS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [ORGANIZATIONS_DELETE_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                commit(ORGANIZATIONS_PUT_REQUEST);
                API({ url: `organization/${guid}/`, method: "DELETE" })
                    .then(resp => {
                        commit(ORGANIZATIONS_DELETE_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ORGANIZATIONS_DELETE_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },

        // #region IssuedCardsActions
        [ISSUEDCARDS_GET_REQUEST]: ({ commit, dispatch }) => {
            commit(ISSUEDCARDS_GET_REQUEST);
            API({ url: "IssuedCard" })
                .then(resp => {
                    commit(ISSUEDCARDS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(ISSUEDCARDS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [ISSUEDCARDS_FUEL_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {

                commit(ISSUEDCARDS_POST_REQUEST);
                API({ url: "IssuedCard/ActivateFuelCard", method: "POST", data })
                    .then(resp => {
                        commit(ISSUEDCARDS_POST_SUCCESS, { data: resp.data, type: 'fuelCard' });
                        dispatch(exp.ISSUEDCARDS_GET_REQUEST);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ISSUEDCARDS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        dispatch(exp.ISSUEDCARDS_GET_REQUEST);

                        reject(err);

                    });
            });
        },

        [ISSUEDCARDS_DRIVER_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(ISSUEDCARDS_POST_REQUEST);
                API({ url: "IssuedCard/ActivateDriverCard", method: "POST", data })
                    .then(resp => {
                        commit(ISSUEDCARDS_POST_SUCCESS, { data: resp.data, type: 'driverCard' });
                        dispatch(exp.ISSUEDCARDS_GET_REQUEST);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ISSUEDCARDS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        dispatch(ISSUEDCARDS_GET_REQUEST);

                        reject(err);
                    });
            });
        },
        // #endregion
        // #region DriversActions
        [DRIVERS_GET_REQUEST]: ({ commit, dispatch }) => {
            commit(DRIVERS_GET_REQUEST);
            API({ url: "driver" })
                .then(resp => {
                    commit(DRIVERS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(DRIVERS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [DRIVERS_GET_BY_GUID_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                API({ url: `driver/${guid}` })
                    .then(resp => {
                        commit(DRIVERS_GET_BY_GUID_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err)
                    });
            })
        },


        [ADMIN_DRIVERS_GET_REQUEST]: ({ commit, dispatch }, guid) => {
            commit(DRIVERS_GET_REQUEST);
            API({ url: `driver/admin/${guid}` })
                .then(resp => {
                    commit(DRIVERS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(DRIVERS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [ADMIN_DRIVERS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(DRIVERS_POST_REQUEST);
                API({ url: `driver/admin/${data._client_id}`, data: data.org, method: "POST" })
                    .then(resp => {
                        commit(DRIVERS_POST_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(DRIVERS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [DRIVERS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(DRIVERS_POST_REQUEST);
                API({ url: "driver", data, method: "POST" })
                    .then(resp => {
                        commit(DRIVERS_POST_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(DRIVERS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [DRIVERS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(DRIVERS_PUT_REQUEST);
                API({ url: "driver", data, method: "PUT" })
                    .then(resp => {
                        commit(DRIVERS_PUT_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(DRIVERS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [DRIVERS_DELETE_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                commit(DRIVERS_PUT_REQUEST);
                API({ url: `driver/${guid}/`, method: "DELETE" })
                    .then(resp => {
                        commit(DRIVERS_DELETE_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(DRIVERS_DELETE_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },

        // #endregion DriversActions


        //CARDS
        [CARDS_GET_REQUEST]: ({ commit, dispatch }) => {
            commit(CARDS_GET_REQUEST);
            API({ url: "card" })
                .then(resp => {
                    commit(CARDS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(CARDS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [CARDS_BOT_GET_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                API({ url: `card/BlockOnTerminal/${guid}` })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        resolve(err);

                        dispatch(CHECK_HTTP_ERROR, err);
                    });
            })
        },
        [CARDS_GET_BY_GUID_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                //  commit(CARDS_GET_REQUEST);
                API({ url: `card/${guid}` })
                    .then(resp => {
                        commit(CARDS_GET_BY_GUID_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        // commit(CARDS_GET_BY_GUID_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err)
                    });
            })
        },
        [CARDS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(CARDS_POST_REQUEST);
                API({ url: "card", data, method: "POST" })
                    .then(resp => {
                        commit(CARDS_POST_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(CARDS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [CARDS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(CARDS_PUT_REQUEST);
                API({ url: "card", data, method: "PUT" })
                    .then(resp => {
                        commit(CARDS_PUT_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(CARDS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [CARDS_DELETE_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                commit(CARDS_DELETE_REQUEST);
                API({ url: `card/${guid}/`, method: "DELETE" })
                    .then(resp => {
                        commit(CARDS_DELETE_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(CARDS_DELETE_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [CARDS_REISSUE_PUT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(CARDS_PUT_REQUEST);
                API({ url: `card/Reissue/${data.oldCardId}/`, data: data.card, method: "Put" })
                    .then(resp => {
                        commit(CARDS_PUT_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(CARDS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [CARDS_BLOCK_PUT_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                commit(CARDS_PUT_REQUEST);
                API({ url: `card/Block/${guid}/`, method: "Put" })
                    .then(resp => {
                        commit(CARDS_PUT_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(CARDS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [CARDS_UNBLOCK_PUT_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                commit(CARDS_PUT_REQUEST);
                API({ url: `card/UnBlock/${guid}/`, method: "Put" })
                    .then(resp => {
                        commit(CARDS_PUT_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(CARDS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        //WALLETS
        [WALLETS_GET_REQUEST]: ({ commit, dispatch }) => {
            commit(WALLETS_GET_REQUEST);
            API({ url: "wallet" })
                .then(resp => {
                    commit(WALLETS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(WALLETS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [WALLETS_GET_BY_GUID_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                API({ url: `wallet/${guid}` })
                    .then(resp => {
                        commit(WALLETS_GET_BY_GUID_SUCCESS, resp.data);
                        resolve(resp);

                    })
                    .catch(err => {
                        //commit(WALLETS_GET_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);

                    });
            });
        },
        [WALLETS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(WALLETS_POST_REQUEST);
                API({ url: "wallet", data, method: "POST" })
                    .then(resp => {
                        commit(WALLETS_POST_SUCCESS, resp.data);
                        dispatch(CARDS_GET_BY_GUID_REQUEST, resp.data.cardID)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(WALLETS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [WALLETS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(WALLETS_PUT_REQUEST);
                API({ url: "wallet", data, method: "PUT" })
                    .then(resp => {
                        commit(WALLETS_PUT_SUCCESS, resp.data);
                        dispatch(CARDS_GET_BY_GUID_REQUEST, resp.data.cardID)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(WALLETS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [WALLETS_GET_PAYMENT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(WALLETS_PUT_REQUEST);
                API({ url: `wallet/replenish/${data.wallet_id}/${data.wallet_quantity}` })
                    .then(resp => {
                        //  commit(WALLETS_PUT_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        //   commit(WALLETS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [WALLETS_DELETE_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                commit(WALLETS_PUT_REQUEST);
                API({ url: `wallet/${guid}`, method: "DELETE" })
                    .then(resp => {
                        commit(WALLETS_DELETE_SUCCESS, guid);
                        dispatch(CARDS_GET_BY_GUID_REQUEST, resp.data)
                        //  dispatch(WALLETS_GET_BY_GUID_REQUEST,guid)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(WALLETS_DELETE_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        //TRANSACTION
        [TRANSACTIONS_GET_BY_DATETIME_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                API({ url: "transaction", params: data.time, data: data.body, method: "POST" })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [TRANSACTIONS_GET_BY_ID_REQUEST]: ({ commit, dispatch }, id) => {
            return new Promise((resolve, reject) => {
                API({ url: `transaction/${id}`, method: "GET" })
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        //TERMINALS
        [TERMINALS_GET_REQUEST]: ({ commit, dispatch }) => {
            commit(TERMINALS_GET_REQUEST);
            API({ url: "terminal" })
                .then(resp => {
                    commit(TERMINALS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(TERMINALS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [TANK_HISTORY_GET_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                API({
                    url: `terminal/tankhistory/${data.guid}`, params: {
                        _start: data.start,
                        _end: data.end,
                        index: data.index
                    }
                })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err)
                    });
            });
        },
        [TERMINALS_GET_BY_GUID_REQUEST]: ({ commit, dispatch }, guid) => {
            //  commit(CARDS_GET_REQUEST);
            return new Promise((resolve, reject) => {

            API({ url: `terminal/${guid}` })
                .then(resp => {
                    commit(TERMINALS_GET_BY_GUID_SUCCESS, resp.data);
                    resolve(resp)
                })
                .catch(err => {
                    // commit(CARDS_GET_BY_GUID_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                    reject(err)
                });
            });

        },
        [TERMINALS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(TERMINALS_POST_REQUEST);
                API({ url: "terminal", data, method: "POST" })
                    .then(resp => {
                        commit(TERMINALS_POST_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(TERMINALS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [TRASFER_TERMINAL_PUT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(TERMINALS_PUT_REQUEST);
                API({ url: `terminal/transfer`, params: { clientID: data.clientID }, data: data.transfer, method: "PUT" })
                    .then(resp => {
                        commit(TERMINALS_PUT_SUCCESS, { terminals: resp.data, oldClientID: data.oldClientID, clientID: data.clientID });
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(TERMINALS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },



        [TERMINALS_UPDATE_CONF_POST_REQUEST]: ({ commit, dispatch }, id) => {
            return new Promise((resolve, reject) => {
                commit(TERMINALS_PUT_REQUEST);
                API({ url: `terminal/SetUpdateConfig/${id}`, method: "POST" })
                    .then(resp => {
                        commit(TERMINALS_PUT_SUCCESS, { terminal: resp.data, oldClientID: null, clientID: null });
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(TERMINALS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },


        [TERMINALS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(TERMINALS_PUT_REQUEST);
                API({ url: "terminal", data, method: "PUT" })
                    .then(resp => {
                        commit(TERMINALS_PUT_SUCCESS, { terminal: resp.data, oldClientID: null, clientID: null });
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(TERMINALS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [TERMINALS_DELETE_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                commit(TERMINALS_DELETE_REQUEST);
                API({ url: `terminal/${guid}/`, method: "DELETE" })
                    .then(resp => {
                        commit(TERMINALS_DELETE_SUCCESS, guid);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(TERMINALS_DELETE_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        //ADMIN_CARDS
        [ADMIN_CARDS_GET_REQUEST]: ({ commit, dispatch }) => {
            commit(ADMIN_CARDS_GET_REQUEST);
            API({ url: "Client/card" })
                .then(resp => {
                    commit(ADMIN_CARDS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(ADMIN_CARDS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [ADMIN_CARDS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(ADMIN_CARDS_POST_REQUEST);
                API({ url: "Client/card", data, method: "POST" })
                    .then(resp => {
                        commit(ADMIN_CARDS_POST_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ADMIN_CARDS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [ADMIN_CARDS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(ADMIN_CARDS_PUT_REQUEST);
                API({ url: "Client/card", data, method: "PUT" })
                    .then(resp => {
                        commit(ADMIN_CARDS_PUT_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ADMIN_CARDS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [ADMIN_CARDS_DELETE_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                commit(ADMIN_CARDS_PUT_REQUEST);
                API({ url: `Client/card/${guid}/`, method: "DELETE" })
                    .then(resp => {
                        commit(ADMIN_CARDS_DELETE_SUCCESS, guid);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ADMIN_CARDS_DELETE_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        //ADMIN_CLIENTS
        [ADMIN_CLIENTS_GET_REQUEST]: ({ commit, dispatch }) => {
            commit(ADMIN_CLIENTS_GET_REQUEST);
            API({ url: "Client" })
                .then(resp => {
                    commit(ADMIN_CLIENTS_GET_SUCCESS, resp.data);
                })
                .catch(err => {
                    commit(ADMIN_CLIENTS_GET_ERROR);
                    dispatch(CHECK_HTTP_ERROR, err);
                });
        },
        [ADMIN_CLIENTS_POST_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(ADMIN_CLIENTS_POST_REQUEST);
                API({ url: "Client", data, method: "POST" })
                    .then(resp => {
                        commit(ADMIN_CLIENTS_POST_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ADMIN_CLIENTS_POST_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [ADMIN_CLIENTS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
            return new Promise((resolve, reject) => {
                commit(ADMIN_CLIENTS_PUT_REQUEST);
                API({ url: "Client", data, method: "PUT" })
                    .then(resp => {
                        commit(ADMIN_CLIENTS_PUT_SUCCESS, resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ADMIN_CLIENTS_PUT_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
        [ADMIN_CLIENTS_DELETE_REQUEST]: ({ commit, dispatch }, guid) => {
            return new Promise((resolve, reject) => {
                commit(ADMIN_CLIENTS_PUT_REQUEST);
                API({ url: `Client/${guid}/`, method: "DELETE" })
                    .then(resp => {
                        commit(ADMIN_CLIENTS_DELETE_SUCCESS, guid);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit(ADMIN_CLIENTS_DELETE_ERROR);
                        dispatch(CHECK_HTTP_ERROR, err);
                        reject(err);
                    });
            });
        },
    },
    getters: {
        HttpStatus401Message(state) {
            return state.http_status_401_message;
        },
        TimeZones() {
            return [
                {
                    text: "UTC-12",
                    value: -12 * 4,
                },
                {
                    text: "UTC-11",
                    value: -11 * 4,
                },
                {
                    text: "UTC-10",
                    value: -10 * 4,
                },
                {
                    text: "UTC-9",
                    value: -9 * 4,
                },
                {
                    text: "UTC-8",
                    value: -8 * 4,
                },
                {
                    text: "UTC-7",
                    value: -7 * 4,
                },
                {
                    text: "UTC-6",
                    value: -6 * 4,
                },
                {
                    text: "UTC-5",
                    value: -5 * 4,
                },
                {
                    text: "UTC-4",
                    value: -4 * 4,
                },
                {
                    text: "UTC-3",
                    value: -3 * 4,
                },
                {
                    text: "UTC-2",
                    value: -2 * 4,
                },
                {
                    text: "UTC-1",
                    value: -1 * 4,
                },
                {
                    text: "UTC+0",
                    value: 0 * 4,
                },
                {
                    text: "UTC+1",
                    value: 1 * 4,
                },
                {
                    text: "UTC+2",
                    value: 2 * 4,
                },
                {
                    text: "UTC+3",
                    value: 3 * 4,
                },
                {
                    text: "UTC+4",
                    value: 4 * 4,
                },
                {
                    text: "UTC+5",
                    value: 5 * 4,
                },
                {
                    text: "UTC+6",
                    value: 6 * 4,
                },
                {
                    text: "UTC+7",
                    value: 7 * 4,
                },
                {
                    text: "UTC+8",
                    value: 8 * 4,
                },
                {
                    text: "UTC+9",
                    value: 9 * 4,
                },
                {
                    text: "UTC+10",
                    value: 10 * 4,
                },
                {
                    text: "UTC+11",
                    value: 11 * 4,
                },
                {
                    text: "UTC+12",
                    value: 12 * 4,
                },
            ]
        },
        KeyboardRotate() {
            return [
                {
                    text: "0 градусов",
                    value: 0,
                },
                {
                    text: "90 градусов",
                    value: 1,
                },
                {
                    text: "180 градусов",
                    value: 2,
                },
                {
                    text: "270 градусов",
                    value: 3,
                },
            ]
        },
        SoftwareVersionCheck() {
            return {
                Error: 0,
                ParamVewrsion20: 20,
                ParamVewrsion25: 25,
            }
        },
        AllGaugeTypesSelect() {
            return [
                {
                    text: "Не задан",
                    value: 0,
                    have_gauge_addr: false,
                    have_calibration_table: false
                },
                {
                    text: "ДУТ LSS",
                    value: 1,
                    have_gauge_addr: false,
                    have_calibration_table: true,
                }
            ]
        },
        PumpsTypeByValue: (state, getters) => value => getters.AllPumpsTypesSelect?.find(t => t.value == value),
        PumpsTypeHaveAddr: (state, getters) => value => { return getters.PumpsTypeByValue(value)?.have_pump_addr ?? false },

        GaugeTypeByValue: (state, getters) => value => getters.AllGaugeTypesSelect?.find(t => t.value == value),
        GaugeHaveAddr: (state, getters) => value => { return getters.GaugeTypeByValue(value)?.have_gauge_addr ?? false },
        GaugeHaveCalibrationTable: (state, getters) => value => { return getters.GaugeTypeByValue(value)?.have_calibration_table ?? false },

        AllPumpsTypesSelect(state, getters) {
            return getters.AllPumpsTypes.filter(a => !a.not_use);
        },
        AllPumpsTypes() {
            return [
                {
                    text: 'АЗТ 2.0',
                    value: 0,
                    have_pump_addr: false,
                    not_use: false,
                },
                {
                    text: 'SANKI',
                    value: 1,
                    have_pump_addr: true,
                    not_use: true,

                },
                {
                    text: 'АЗТ 2.0 (высокопроизводительный)',
                    value: 2,
                    have_pump_addr: false,
                    not_use: false,
                },
                {
                    text: 'УЗСГ',
                    value: 3,
                    have_pump_addr: false,
                    not_use: false,
                },
            ]
        },
        //Users
        AllUsers: state => state.users,
        UserByGUID: (state, getters) => id => getters.AllUsers?.find(t => t.id == id),
        UsersOnceLoaded: state => state.users_once_loaded,
        UsersGetAreLoaded: state => state.users_get_status == "success",
        UsersGetAreLoading: state => state.users_get_status == "loading",
        UsersGetHaveError: state => state.users_get_status == "error",
        UsersPostAreLoading: state => state.users_post_status == "loading",
        UsersPostHaveError: state => state.users_post_status == "error",
        UsersPutAreLoading: state => state.users_put_status == "loading",
        UsersPutHaveError: state => state.users_put_status == "error",
        UsersDeleteAreLoading: state => state.users_delete_status == "loading",
        UsersDeleteHaveError: state => state.users_delete_status == "error",
        //Organizations
        AllOrganizations: state => state.organizations,
        OrganizationByGUID: (state, getters) => id => getters.AllOrganizations?.find(t => t.id == id),
        OrganizationsOnceLoaded: state => state.organizations_once_loaded,
        OrganizationsGetAreLoading: state => state.organizations_get_status == "loading",
        OrganizationsGetAreLoaded: state => state.organizations_get_status == "success",
        OrganizationsGetHaveError: state => state.organizations_get_status == "error",
        OrganizationsPostAreLoading: state => state.organizations_post_status == "loading",
        OrganizationsPostHaveError: state => state.organizations_post_status == "error",
        OrganizationsPutAreLoading: state => state.organizations_put_status == "loading",
        OrganizationsPutHaveError: state => state.organizations_put_status == "error",
        OrganizationsDeleteAreLoading: state => state.organizations_delete_status == "loading",
        OrganizationsDeleteHaveError: state => state.organizations_delete_status == "error",
        OrganizationsTerminals: (state, getters) => terminal_id => getters.TerminalByGUID(terminal_id)?.organizationIds?.map(el => {
            return {
                text: getters.OrganizationByGUID(el)?.name,
                value: el
            }
        }) ?? [],

        //#region DriversGetters
        AllDrivers: state => state.drivers,
        DriverByGUID: (state, getters) => id => getters.AllDrivers?.find(t => t.id == id),
        DriverByNumber: (state, getters) => number => getters.AllDrivers?.find(t => t.number == number),

        DriversOnceLoaded: state => state.drivers_once_loaded,
        DriversGetAreLoading: state => state.drivers_get_status == "loading",
        DriversGetAreLoaded: state => state.drivers_get_status == "success",
        DriversGetHaveError: state => state.drivers_get_status == "error",
        DriversPostAreLoading: state => state.drivers_post_status == "loading",
        DriversPostHaveError: state => state.drivers_post_status == "error",
        DriversPutAreLoading: state => state.drivers_put_status == "loading",
        DriversPutHaveError: state => state.drivers_put_status == "error",
        DriversDeleteAreLoading: state => state.drivers_delete_status == "loading",
        DriversDeleteHaveError: state => state.drivers_delete_status == "error",
        // #endregion DriversGetters


        //#region IssuedCardsGetters

        AllIssuedCards: state => state.issuedCards,
        IssuedCardsOnceLoaded: state => state.issuedCards_once_loaded,
        IssuedCardsPostAreLoaded: state => state.issuedCards_post_status == "success",
        IssuedCardsPostAreLoading: state => state.issuedCards_post_status == "loading",
        IssuedCardsPostHaveError: state => state.issuedCards_post_status == "error",
        IssuedCardsGetAreLoaded: state => state.issuedCards_get_status == "success",
        IssuedCardsGetAreLoading: state => state.issuedCards_get_status == "loading",
        IssuedCardsGetHaveError: state => state.issuedCards_get_status == "error",
        // #endregion IssuedCardsGetters


        HttpStatus401: state => state.http_status_401,
        //Products
        AllProducts: state => state.products,
        ProductByGUID: (state, getters) => id => getters.AllProducts?.find(t => t.id == id),
        ProductByhardwareID: (state, getters) => id => getters.AllProducts?.find(t => t.hardwareID == id),
        ProductsOnceLoaded: state => state.products_once_loaded,
        ProductsGetAreLoaded: state => state.products_get_status == "success",
        ProductsGetAreLoading: state => state.products_get_status == "loading",
        ProductsGetHaveError: state => state.products_get_status == "error",
        ProductsPostAreLoading: state => state.products_post_status == "loading",
        ProductsPostHaveError: state => state.products_post_status == "error",
        ProductsPutAreLoading: state => state.products_put_status == "loading",
        ProductsPutHaveError: state => state.products_put_status == "error",
        ProductsDeleteAreLoading: state => state.products_delete_status == "loading",
        ProductsDeleteHaveError: state => state.products_delete_status == "error",
        //Wallets
        AllWallets: state => state.wallets,
        WalletByGUID: (state, getters) => id => getters.AllWallets?.find(t => t.id == id) ?? null,
        WalletFindByProdictGUID: (state, getters) => product_id => getters.AllWallets?.find(t => t.productID == product_id),
        WalletsOnceLoaded: state => state.wallets_once_loaded,
        WalletsGetAreLoaded: state => state.wallets_get_status == "success",
        WalletsGetAreLoading: state => state.wallets_get_status == "loading",
        WalletsGetHaveError: state => state.wallets_get_status == "error",
        WalletsPostAreLoading: state => state.wallets_post_status == "loading",
        WalletsPostHaveError: state => state.wallets_post_status == "error",
        WalletsPutAreLoading: state => state.wallets_put_status == "loading",
        WalletsPutHaveError: state => state.wallets_put_status == "error",
        WalletsDeleteAreLoading: state => state.wallets_delete_status == "loading",
        WalletsDeleteHaveError: state => state.wallets_delete_status == "error",
        WalletsByCardGUID: (state, getters) => card_id => getters.AllWallets?.filter(t => t.cardID == card_id && !t.isRemoved),
        //Cards
        AllCards: state => state.cards,
        CardByGUID: (state, getters) => id => getters.AllCards?.find(t => t.id == id),
        CardFindByOrganizationGUID: (state, getters) => organization_id => getters.AllCards?.find(t => t.organizationID == organization_id),
        CardsOnceLoaded: state => state.cards_once_loaded,
        CardsGetAreLoaded: state => state.cards_get_status == "success",
        CardsGetAreLoading: state => state.cards_get_status == "loading",
        CardsGetHaveError: state => state.cards_get_status == "error",
        CardsPostAreLoading: state => state.cards_post_status == "loading",
        CardsPostHaveError: state => state.cards_post_status == "error",
        CardsPutAreLoading: state => state.cards_put_status == "loading",
        CardsPutHaveError: state => state.cards_put_status == "error",
        CardsDeleteAreLoading: state => state.cards_delete_status == "loading",
        CardsdDeleteHaveError: state => state.cards_delete_status == "error",
        BlackListCards: (state, getters) => terminal_id => getters.TerminalByGUID(terminal_id)?.blackList?.map(el => {
            var text = getters.CardByGUID(el)?.holderName + ' ' + '(' + getters.CardByGUID(el)?.number.match(/.{1,4}/g).join(' ') + ')' + `<b class="text-danger">${getters.CardByGUID(el)?.isRemoved ? ' - карта удалена' : ''}</b>`
            return {
                html: text,
                value: el,
                id: getters.CardByGUID(el)?.id,
                disabled: getters.CardByGUID(el)?.isRemoved ?? false,
                number: getters.CardByGUID(el)?.number,
                holderName: getters.CardByGUID(el)?.holderName
            }
        }) ?? [],
        //Terminals
        AllTerminals: state => state.terminals,
        //state.terminals
        TerminalByGUID: (state, getters) => id => getters.AllTerminals?.find(t => t.id == id),
        TerminalsOnceLoaded: state => state.terminals_once_loaded,
        TerminalsGetAreLoaded: state => state.terminals_get_status == "success",
        TerminalsGetAreLoading: state => state.terminals_get_status == "loading",
        TerminalsGetHaveError: state => state.terminals_get_status == "error",
        TerminalsPostAreLoading: state => state.terminals_post_status == "loading",
        TerminalsPostHaveError: state => state.terminals_post_status == "error",
        TerminalsPutAreLoading: state => state.terminals_put_status == "loading",
        TerminalsPutHaveError: state => state.terminals_put_status == "error",
        TerminalsDeleteAreLoading: state => state.terminals_delete_status == "loading",
        TerminalsDeleteHaveError: state => state.terminals_delete_status == "error",
        //Admin_cards
        AllAdmin_cards: state => state.admin_cards,
        //state.admin_cards
        Admin_cardByGUID: (state, getters) => id => getters.AllAdmin_cards?.find(t => t.id == id),
        Admin_cardsOnceLoaded: state => state.admin_cards_once_loaded,
        Admin_cardsGetAreLoaded: state => state.admin_cards_get_status == "success",
        Admin_cardsGetAreLoading: state => state.admin_cards_get_status == "loading",
        Admin_cardsGetHaveError: state => state.admin_cards_get_status == "error",
        Admin_cardsPostAreLoading: state => state.admin_cards_post_status == "loading",
        Admin_cardsPostHaveError: state => state.admin_cards_post_status == "error",
        Admin_cardsPutAreLoading: state => state.admin_cards_put_status == "loading",
        Admin_cardsPutHaveError: state => state.admin_cards_put_status == "error",
        Admin_cardsDeleteAreLoading: state => state.admin_cards_delete_status == "loading",
        Admin_cardsDeleteHaveError: state => state.admin_cards_delete_status == "error",
        //Admin_clients
        AllAdmin_clients: state => state.admin_clients,
        //state.admin_clients
        Admin_clientByGUID: (state, getters) => id => getters.AllAdmin_clients?.find(t => t.id == id),
        Admin_clientsOnceLoaded: state => state.admin_clients_once_loaded,
        Admin_clientsGetAreLoaded: state => state.admin_clients_get_status == "success",
        Admin_clientsGetAreLoading: state => state.admin_clients_get_status == "loading",
        Admin_clientsGetHaveError: state => state.admin_clients_get_status == "error",
        Admin_clientsPostAreLoading: state => state.admin_clients_post_status == "loading",
        Admin_clientsPostHaveError: state => state.admin_clients_post_status == "error",
        Admin_clientsPutAreLoading: state => state.admin_clients_put_status == "loading",
        Admin_clientsPutHaveError: state => state.admin_clients_put_status == "error",
        Admin_clientsDeleteAreLoading: state => state.admin_clients_delete_status == "loading",
        Admin_clientsDeleteHaveError: state => state.admin_clients_delete_status == "error",

        AllOrganizationsSelect: (state, getters) => (withRemoved = false, isHardware = false) => {
            if (withRemoved == undefined) withRemoved = false;
            if (isHardware == undefined) isHardware = false;
            return getters.AllOrganizations?.filter(t => withRemoved ? true : t.isRemoved == false)?.map(element => {
                return {
                    text: element.name,
                    value: isHardware ? element.hardwareID : element.id,
                }
            }) ?? []
        },
        AllProductsSelect: (state, getters) => (withRemoved = false, isHardware = false) => {
            if (withRemoved == undefined) withRemoved = false;
            if (isHardware == undefined) isHardware = false;
            return getters.AllProducts?.filter(t => withRemoved ? true : !t.isRemoved)?.map(element => {
                var text = element.name + ` (${element.hardwareName})`
                return {
                    text: text,
                    value: isHardware ? element.hardwareID : element.id,
                }
            }) ?? []

        },
        AllTransactionsTypeSelect: (state, getters) => {
            return [
                { text: 'Списание', value: 0 },
                { text: 'Пополнение', value: 1 },
                { text: 'Возврат', value: -1 },
                { text: 'Выпуск карты', value: 2 },

            ]
        },

        ErrorApiResponseText: () => err => {
            switch (err.status) {
                case 400:
                    return err.data
                default:
                    return err
            }
        },
    },
})
const API = axios.create({
    //baseURL: 'https://petrol.amsy.ru/api/',
    baseURL: '/api',
    timeout: 10000,
});
API.interceptors.request.use(
    async config => {
        /*  let token;*/
        let token = await indDB.getUserToken();
        // const token = sessionStorage.getItem('jwt');\

        if (token) {
            let href = window.location.href;
            if (token.role == "admin") {
                if (href.indexOf('admin') > -1) {
                    config.headers['Authorization'] = 'Bearer ' + token.jwt;
                }
                else {

                    let token_magic = await indDB.getMagicToken();
                    if (token_magic) {

                        config.headers['Authorization'] = 'Bearer ' + token_magic.jwt;
                    }

                }

            }
            else {
                config.headers['Authorization'] = 'Bearer ' + token.jwt;
            }
            config.headers['Content-Type'] = 'application / json';
        }
        return config;
    },
    error => {
        Promise.reject(error)
    })
/*
[message] Data received: {"ClientID":"2d7ebaf1-ff4a-434a-8a5d-fe8c660bb835","MessageType":"Transaction","CardID":"0d6d1c8b-92f8-49fc-9a8b-d7cae10baa0b","CardCommandCode":0,"TerminalCommandsCode":0,"WalletID":"d9fdf590-8aa2-493d-9a93-349d46589760","TerminalID":"721d6439-6dcf-4fb6-8da3-34f0c0d2b94d","CardCommandID":null,"TerminalCommandID":null,"TransactionID":513,"Error":null} <- server
*/
