<template>
    <div class="content-body">
        <!-- #region PageHeadingsStart -->
        <div class="row align-items-center justify-content-between mb-0">
            <h3><i class="nav-icon fa-solid fa-car me-1"></i>
                <span class="title"> Новые карты </span>
                <div class="btn-group">
                    <button type="button" title="Обновить" class="btn btn-default btn-smx" :disabled="isBusy"
                        v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }" @click="Refresh()"><i
                            class="nav-icon fas fa-sync-alt"></i></button>

                </div>
            </h3>
        </div>
        <!-- #endregion Page Headings End -->
        <!-- #region DefaultDataTableStart-->

        <div class="row" style="gap:20px">
 
            <div class="col-12 col-lg-5">
                <div class="h5">1) Выберите карты из списка</div>
                <div class="card card-layout">
                    <div class="card-body p-0">
                        <div class="d-flex flex-wrap align-items-baseline justify-content-end p-2" style="gap:5px">

                            <!---<data-table-filter v-model="filter"></data-table-filter>-->
                        </div>
                        <b-overlay :show="isBusy" :opacity="0.5">
                            <b-table @filtered="onFiltered" :filter="filter" sticky-header :select-mode="'multi'"
                                selectable @row-selected="onRowSelected" :filter-included-fields="['number']"
                                class="table-sticky-header-layout m-0" :filter-function="filterTable"
                                ref="table_issued_cards" hover head-variant="light" :per-page="perPage"
                                :current-page="currentPage" id="table_issued_cards" :fields="fields"
                                :items="AllIssuedCards" :busy="isBusy && AllIssuedCards.length == 0" show-empty
                                primary-key="id">
                                <template #emptyfiltered>
                                    <div class="text-center my-2">{{ emptyFilteredText }}</div>
                                </template>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <strong>Загрузка...</strong>
                                    </div>
                                </template>

                                <template #cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                        <span class="sr-only">Выбрано</span>
                                    </template>
                                    <template v-else>
                                        <span aria-hidden="true">&nbsp;</span>
                                        <span class="sr-only">Не выбрано</span>
                                    </template>
                                </template>
                                <template #empty>
                                    <div v-if="!IssuedCardsGetHaveError" class="text-center my-2">{{ emptyText }}</div>
                                    <div v-else class="text-center my-2" style="color:red">Ошибка загрузки</div>
                                </template>
                                <template #cell(number)="data">
                                    {{
                                        NumberSpace(data.item.number) }}

                                </template>



                            </b-table>

                            <pagination :total-rows="rows" :perPage.sync="perPage" :currentPage.sync="currentPage"
                                aria-controls="table_issued_cards">
                            </pagination>
                        </b-overlay>
                    </div>
                </div>
                <div class="my-2" v-if="selected.length > 0">
                    <div class="h5"> 2) Выберите тип активации</div>


                    <b-form-radio plain :disabled="selected.length == 0" v-model="issueType" name="IssueType" value="A"
                        size="lg">
                        Топливная
                        карта</b-form-radio>
                    <b-form-radio plain :disabled="selected.length == 0" v-model="issueType" name="IssueType" value="B"
                        size="lg"> Карта
                        водителя</b-form-radio>
                </div>
            </div>
            <div class="col-12 col-lg" v-if="issueType != null">
                <div class="h5"> 3) {{ (issueType == 'A') ? `Настройте параметры нажмите кнопку выпуска` : `Нажмите
                    кнопку
                    выпуска`}}
                </div>
                <div class="card card-layout" v-if="issueType == 'A'">
                    <div class="card-body">
                        <form ref="CreateEditCardForm" id="CreateEditCardForm" @submit.prevent="CreateEditCard">
                            <div class="row align-items-center">
                                <div class="col-12 сol-md-6">
                                    <fieldset>
                                        <legend>Общие параметры карты</legend>
                                        <div class="row mb-2">
                                            <div class="col-sm-4 col-12 align-self-center"><label
                                                    class="form-label font-weight-bold">Пин-код &nbsp; <b-icon
                                                        icon="question-circle"
                                                        v-b-tooltip="{ id: 'svgCircleQuestion', title: 'Если в поле стоит значение, оно будет заданно всем выбранным картам', placement: 'top', variant: 'info', customClass: 'my-tooltip-class' }"
                                                        style="cursor:pointer"></b-icon></label></div>
                                            <div class="col-sm-8 col-12 align-self-center">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text"
                                                            style="border-radius: 3px 0px 0px 3px;">
                                                            <input class="form-check-input" type="checkbox"
                                                                v-model="checked_pin"
                                                                @change="ChangeChecked($event, 'Pin')">
                                                        </div>
                                                    </div>
                                                    <input type="text"
                                                        :disabled="loading || !checked_pin || useDefaultPassword"
                                                        v-model="pin" ref="Pin" class="form-control"
                                                        @keypress="onlyNumber" pattern="[0-9]{4}" title="4 цифры"
                                                        maxlength="4" placeholder="xxxx" :required="checked_pin" />
                                                </div>
                                                <b-form-checkbox v-if="checked_pin" class="my-2" @change="ChangeChecked($event, 'Pin')"
                                                    plain v-model="useDefaultPassword">
                                                    Использовать последние 4 цифры карты </b-form-checkbox>


                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-4 col-12 align-self-center"><label
                                                    class="form-label font-weight-bold">Подразделение &nbsp; <b-icon
                                                        icon="question-circle"
                                                        v-b-tooltip="{ id: 'svgCircleQuestion', title: 'Подразделение будет назначено всем выбранным картам', placement: 'top', variant: 'info', customClass: 'my-tooltip-class' }"
                                                        style="cursor:pointer"></b-icon></label></div>
                                            <div class="col-sm-8 col-12 align-self-center">
                                                <multiselect trackBy="text" valueProp="value" :disabled="loading"
                                                    :searchable="true" locale="ru" :allowEmpty="true"
                                                    placeholder="Выберите подразделение" id="Organization"
                                                    :close-on-select="true" label="text" required
                                                    v-model="organizationID" :options="AllOrganizationsSelect()">
                                                    <template v-slot:nooptions>
                                                        <div
                                                            class="d-flex w-100 align-items-center justify-content-center my-2">
                                                            Нет элементов</div>
                                                    </template>
                                                    <template v-slot:noresults>
                                                        <div
                                                            class="d-flex w-100 align-items-center justify-content-center my-2">
                                                            Не найдено</div>
                                                    </template>
                                                </multiselect>


                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-4 col-12 align-self-center"><label
                                                    class="form-label font-weight-bold">Номер
                                                    ТС/Держатель &nbsp; <b-icon icon="question-circle"
                                                        v-b-tooltip="{ id: 'svgCircleQuestion', title: 'При создании к полю будет добавлено 4 последние цифры карты. Например, Иванов А.А._4985', placement: 'top', variant: 'info', customClass: 'my-tooltip-class' }"
                                                        style="cursor:pointer"></b-icon></label></div>
                                            <div class="col-sm-8 col-12 align-self-center"><input type="text"
                                                    placeholder="Номер ТС/Имя держателя" :disabled="loading"
                                                    v-model="holderName" class="form-control" required />
                                            </div>
                                        </div>
                                        <div class="row mb-2" v-if="!edit">
                                            <div class="col-sm-4 col-12 align-self-center"><label
                                                    class="form-label font-weight-bold">Создание кошельков &nbsp;
                                                    <b-icon icon="question-circle"
                                                        v-b-tooltip="{ id: 'svgCircleQuestion', title: 'Создаваемые кошельки будут добавлены всем картам', placement: 'top', variant: 'info', customClass: 'my-tooltip-class' }"
                                                        style="cursor:pointer"></b-icon></label></div>
                                            <div class="col-sm-8 col-12 align-self-center">
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" v-model="needWallets"
                                                        :disabled="edit" type="checkbox" id="flexSwitchCheckDefault">
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="col-12">
                                    <b-tabs class="card-tabs" v-if="needWallets" content-class="px-2"
                                        v-model="activeWallet" @activate-tab="onTabActivate">
                                        <template #tabs-end>
                                            <b-nav-item role="presentation" @click.prevent="NewWalletTab" href="#"
                                                v-if="Wallets.length < 4"><b>+</b></b-nav-item>
                                        </template>
                                        <b-tab v-for="(wallet, index)  in Wallets">
                                            <template #title>{{ index == activeWallet ? `Кошелёк №${index + 1}` :
                                                `№${index +
                                                1}` }}</template>
                                            <div class="text-end py-1"><button type="button"
                                                    class="btn btn-outline-danger btn-sm btn-delete"
                                                    v-if="Wallets.length > 1"
                                                    @click="DeleteWalletTab(index)">Удалить</button>
                                            </div>
                                            <form :id="'WalletForm' + index" :ref="'WalletForm' + index">
                                                <div class="row" style="gap: 10px;">
                                                    <div class="col-12 col-xl">
                                                        <fieldset>
                                                            <legend>Общие параметры кошелька</legend>
                                                            <div class="row mb-2">
                                                                <div class="col-12 align-self-center"><label
                                                                        class="form-label font-weight-bold">Наименование&nbsp;
                                                                        <b-icon icon="question-circle"
                                                                            v-b-tooltip="{ id: 'svgCircleQuestion', title: 'При создании к полю будет добавлено 4 последние цифры карты. Например, Кошелёк1_4985', placement: 'top', variant: 'info', customClass: 'my-tooltip-class' }"
                                                                            style="cursor:pointer"></b-icon></label>
                                                                </div>
                                                                <div class="col-12 align-self-center"><input type="text"
                                                                        placeholder="Имя кошелька" :disabled="loading"
                                                                        v-model="wallet.wallet_name" ref="WalletName"
                                                                        class="form-control" required />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2" v-if="!edit">
                                                                <div class="col-12 align-self-center"><label
                                                                        class="form-label font-weight-bold">Продукт</label>
                                                                </div>
                                                                <div class="col-12 align-self-center">
                                                                    <multiselect trackBy="text" valueProp="value"
                                                                        :disabled="loading" :searchable="true"
                                                                        locale="ru" :allowEmpty="true" placeholder="Выберите
                         продукт" :close-on-select="true" label="text" required v-model="wallet.wallet_productID"
                                                                        :options="AllProductsSelect()">
                                                                        <template v-slot:nooptions>
                                                                            <div
                                                                                class="d-flex w-100 align-items-center justify-content-center my-2">
                                                                                Нет элементов</div>
                                                                        </template>
                                                                        <template v-slot:noresults>
                                                                            <div
                                                                                class="d-flex w-100 align-items-center justify-content-center my-2">
                                                                                Не найдено</div>
                                                                        </template>
                                                                    </multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-12 align-self-center">
                                                                    <label class="form-label font-weight-bold">Доза по
                                                                        умолчанию
                                                                        (л.)</label>
                                                                </div>
                                                                <div class="col-12 align-self-center">
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <div class="input-group-text"
                                                                                style="border-radius: 3px 0px 0px 3px;">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    v-model="wallet.checked_defaultLimit"
                                                                                    @change="ChangeChecked($event, 'defaultLimit')">
                                                                            </div>
                                                                        </div>
                                                                        <input min="1" type="number"
                                                                            :disabled="loading || !wallet.checked_defaultLimit"
                                                                            ref="defaultLimit" @keypress="onlyNumber"
                                                                            v-model.number="wallet.defaultLimit"
                                                                            class="form-control"
                                                                            :required="wallet.checked_defaultLimit" />
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-12 align-self-center"><label
                                                                        class="form-label font-weight-bold">Кредитный</label>
                                                                </div>
                                                                <div class="col-12 align-self-center">
                                                                    <div class="form-check form-switch">
                                                                        <input class="form-check-input"
                                                                            v-model="wallet.creditWallet"
                                                                            :disabled="edit" type="checkbox"
                                                                            id="flexSwitchCheckDefault">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>

                                                    <div class="col-12 col-xl">
                                                        <fieldset>
                                                            <legend>Лимиты по топливу</legend>
                                                            <div class="row mb-2">
                                                                <div class="col-12 align-self-center"><label
                                                                        class="form-label font-weight-bold">Cуточный
                                                                        лимит
                                                                        (л.)</label>
                                                                </div>
                                                                <div class="col-12 align-self-center">
                                                                    <div class="input-group" v-if="!WalletChanged">
                                                                        <div class="input-group-prepend">
                                                                            <div class="input-group-text"
                                                                                style="border-radius: 3px 0px 0px 3px;">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    v-model="wallet.checked_dailyLimitValue"
                                                                                    @change="ChangeChecked($event, 'dailyLimit')">
                                                                            </div>
                                                                        </div>
                                                                        <input type="number" min="1"
                                                                            :disabled="loading || !wallet.checked_dailyLimitValue"
                                                                            ref="dailyLimit" @keypress="onlyNumber"
                                                                            v-model.number="wallet.dailyLimitValue"
                                                                            class="form-control"
                                                                            :required="wallet.checked_dailyLimitValue" />
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-12 align-self-center"><label
                                                                        class="form-label font-weight-bold">Недельный
                                                                        лимит
                                                                        (л.)</label>
                                                                </div>
                                                                <div class="col-12 align-self-center">
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <div class="input-group-text"
                                                                                style="border-radius: 3px 0px 0px 3px;">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    v-model="wallet.checked_weekLimitValue"
                                                                                    @change="ChangeChecked($event, 'weekLimit')">
                                                                            </div>
                                                                        </div>
                                                                        <input type="number" min="1"
                                                                            :disabled="loading || !wallet.checked_weekLimitValue"
                                                                            ref="weekLimit" @keypress="onlyNumber"
                                                                            v-model.number="wallet.weekLimitValue"
                                                                            class="form-control"
                                                                            :class="{ 'is-invalid': wallet.invalid_weekLimit }"
                                                                            :required="wallet.checked_weekLimitValue" />
                                                                        <div class="invalid-feedback d-block mt-0"
                                                                            v-if="wallet.invalid_weekLimit">
                                                                            {{ wallet.invalid_weekLimit_text }}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-12 align-self-center"><label
                                                                        class="form-label font-weight-bold">Месячный
                                                                        лимит
                                                                        (л.)</label>
                                                                </div>
                                                                <div class="col-12 align-self-center">
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <div class="input-group-text"
                                                                                style="border-radius: 3px 0px 0px 3px;">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    v-model="wallet.checked_monthLimitValue"
                                                                                    @change="ChangeChecked($event, 'monthLimit')">
                                                                            </div>
                                                                        </div>
                                                                        <input type="number" min="1"
                                                                            :disabled="loading || !wallet.checked_monthLimitValue"
                                                                            ref="monthLimit" @keypress="onlyNumber"
                                                                            v-model.number="wallet.monthLimitValue"
                                                                            class="form-control"
                                                                            :class="{ 'is-invalid': wallet.invalid_monthLimit }"
                                                                            :required="wallet.checked_monthLimitValue" />
                                                                        <div class="invalid-feedback d-block"
                                                                            v-if="wallet.invalid_monthLimit">
                                                                            {{ wallet.invalid_monthLimit_text }}
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                        <fieldset>
                                                            <legend>Лимиты по операциям</legend>
                                                            <div class="row mb-2">
                                                                <div class="col-12 align-self-center"><label
                                                                        class="form-label font-weight-bold">Дневной
                                                                        лимит
                                                                        (шт.)</label>
                                                                </div>
                                                                <div class="col-12 align-self-center">
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <div class="input-group-text"
                                                                                style="border-radius: 3px 0px 0px 3px;">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    v-model="wallet.checked_operationLimitValue"
                                                                                    @change="ChangeChecked($event, 'operationLimit')">
                                                                            </div>
                                                                        </div>
                                                                        <input type="number" min="1"
                                                                            :disabled="loading || !wallet.checked_operationLimitValue"
                                                                            @keypress="onlyNumber" ref="operationLimit"
                                                                            v-model.number="wallet.operationLimitValue"
                                                                            class="form-control"
                                                                            :required="wallet.checked_operationLimitValue" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </form>
                                        </b-tab>
                                    </b-tabs>
                                </div>
                                <div class="col-12">

                                    <ul class="m-0">
                                        <li>
                                            <h5 class="h5"><span class="text-danger">Важно!</span> Для изменения
                                                информации о
                                                топливной карте после активации необходимо перейти в интерфейс "<router-link class="text-info text-decoration-underline" :to="{ name: 'Cards'}">Транспортные средства</router-link>" </h5>
                                        </li>
                                        <li>
                                            <h5 class="h5"><span class="text-danger">Важно!</span> Для активации карту
                                                необходимо
                                                приложить к терминалу!</h5>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </form>


                    </div>

                </div>
                <div class="card card-layout" v-if="issueType == 'B'">
                    <div class="card-body">
                        <ul class="m-0">
                            <li>
                                <h5 class="h5">В качестве кода доступа будут установленны 4 последние цифры карты</h5>
                            </li>
                            <li>
                                <h5 class="h5">Наименование водителя после активации:"Водитель_xxxx". xxxx - 4 последние
                                    цифры карты
                                </h5>
                            </li>
                            <li>
                                <h5 class="h5"><span class="text-danger">Важно!</span> Для изменения информации о
                                    водителе после
                                    активации необходимо перейти в интерфейс  "<router-link class="text-info text-decoration-underline" :to="{ name: 'Drivers'}">Справочники/Водители</router-link>" </h5>
                            </li>
                            <li>
                                <h5 class="h5"><span class="text-danger">Важно!</span> Для активации карту необходимо
                                    приложить к
                                    терминалу!</h5>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="py-2">
                    <b-button variant="primary" @click="ActivateCard()">Активировать карты</b-button>
                </div>
            </div>
        </div>
        <!-- #endregion -->
        <bs-modal ref="EmptyModal" id="EmptyModalIssuedCards" centered></bs-modal>
    </div>
</template>

<script>
import axios from 'axios';
import {
    textC_alignM,
    textL_alignM,
} from '@/helpers/const'
import {
    feather
} from '@/main.js';
import {
    eventBus
} from '@/main.js'
import Vue from 'vue';
import {
    mapGetters
} from 'vuex'
import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => {
    window[name] = exported;
});
export default {
    data() {
        return {
            selected: [],
            issueType: null,
            VisibleBlockOnTerminal: false,
            serviceNumber: null,
            unblock: false,
            blockOnTerminal: [],
            rows_to_display: [{
                text: "10",
                value: 10
            },
            {
                text: "25",
                value: 25
            },
            {
                text: "50",
                value: 50
            },
            {
                text: "100",
                value: 100
            },
            ],
            emptyText: "Нет новых карт",
            filter: "",
            emptyFilteredText: "Не найдено",
            not_translation: false,
            cancel_action: "1",
            cardCommandsText: ["", ""],
            dismissSecs: 5,
            dismissCountDown: 0,
            fields: [
                {
                    key: 'selected',
                    label: "",
                    sortable: false,
                    thClass: textC_alignM,
                    class: textC_alignM,
                    thStyle: {
                        width: "5px"
                    }
                },
                {
                    key: 'number',
                    label: "Номер карты",
                    sortable: true,
                    thClass: textC_alignM,
                    class: textC_alignM,
                    thStyle: {

                    }
                },
                {
                    key: 'issueDate',
                    label: "Дата выпуска",
                    sortable: true,
                    thClass: textC_alignM,
                    class: textC_alignM,
                    formatter: value => {
                        return moment(value).locale('ru').format('DD.MM.YYYY/HH:mm:ss');
                    },
                    thStyle: {

                    }
                },


            ],
            show_removed: false, //Отображение карт на удалении
            perPage: 10,
            currentPage: 1,
            edit: null,
            empty_text: "Нет карт",
            //Для карты//
            numberReissue: null,//Номер перевыпускаемой карты
            ReissueQuestion: false,///Вопрос про замену

            number: null, //Номер карты
            id: null, //GUID карты
            pin: null, //Пин-код
            needWallets: false,
            useDefaultPassword: true,
            organizationID: null, //GUID подразделения
            holderName: null, //Имя держателя
            checked_pin: true,

            commandQueue: [], //Очередь команд
            changeable_pin: null,
            changeable_organizationID: null,
            //*******//

            //Для кошелька//
            creditWallet: false,
            wallet_name: null,
            wallet_id: null,
            wallet_quantity: null,
            changeable_wallet_quantity: null,
            wallet_productID: null,
            wallet_cardID: null,

            ///Дневной лимит по топливу
            dailyLimitValue: null,
            dailyLimitCurrent: null,
            checked_dailyLimitValue: false,

            ///Недельный лимит по топливу
            weekLimitValue: null,
            weekLimitCurrent: null,
            checked_weekLimitValue: false,
            invalid_weekLimit: true,
            invalid_weekLimit_text: "",

            ///Месячный лимит по топливу
            monthLimitValue: null,
            monthLimitCurrent: null,
            checked_monthLimitValue: false,
            invalid_monthLimit: true,

            invalid_monthLimit_text: "",

            ///Дневной лимит по операциям 
            operationLimitValue: null,
            operationLimitCurrent: null,
            checked_operationLimitValue: false,



            defaultLimit: null,
            checked_defaultLimit: false,
            collapseId: null,
            delete_cancel_wallet: false,
            changeable_quantity: null,
            changeable_productID: null,
            changeable_dailyLimitValue: null,
            changeable_weekLimitValue: null,
            changeable_monthLimitValue: null,
            changeable_operationLimitValue: null,
            changeable_defaultLimit: null,
            filtered_items: [],
            //*******//
            action: {
                type: null,
                method: null,
            },
            ///Дефолтный кошелёк
            defaultWallet: {
                //Для кошелька//
                creditWallet: false,
                wallet_name: null,
                wallet_id: null,
                wallet_productID: null,
                ///Дневной лимит по топливу
                dailyLimitValue: 0,
                checked_dailyLimitValue: false,
                ///Недельный лимит по топливу
                weekLimitValue: 0,
                checked_weekLimitValue: false,
                invalid_weekLimit: false,
                invalid_weekLimit_text: "",
                ///Месячный лимит по топливу
                monthLimitValue: 0,
                checked_monthLimitValue: false,
                invalid_monthLimit: false,
                invalid_monthLimit_text: "",
                ///Дневной лимит по операциям 
                operationLimitValue: 0,
                checked_operationLimitValue: false,
                ///Лимит по умолчанию
                defaultLimit: 0,
                checked_defaultLimit: false,
            },
            Wallets: [],
            activeWallet: 0,
        }
    },
    beforeDestroy() {

        // eventBus.$off('Complite_command')
    },
    destroyed() {

    },
    computed: {
        CurrentWallet() {
            if (this.Wallets.length == 0) {
                return null;
            }
            else return this.Wallets[this.activeWallet];
        },
        dailyLimitValues() { return this.Wallets.map(item => item.dailyLimitValue) },
        weekLimitValues() { return this.Wallets.map(item => item.weekLimitValue) },
        monthLimitValues() { return this.Wallets.map(item => item.monthLimitValue) },

        CaptionText() {
            return `Показано с ${this.currentPage * this.perPage - this.perPage + 1} по ${this.currentPage * this.perPage <= this.rows ? this.currentPage * this.perPage : this.rows} из ${this.rows} записей`
        },
        CardsWithOrganizations() {
            return this.AllCards.map(card => {
                return Object.assign(card, { organizationName: this.OrganizationByGUID(card.organizationID)?.name ?? null })
            })
        },
        Cards() {
            if (this.show_removed) return this.CardsWithOrganizations;
            else return this.CardsWithOrganizations.filter(a => !a.isRemoved);
        },
        rows_to_display_computed() { return this.rows_to_display.concat([{ text: "Все", value: this.rows }]) },
        rows() {
            if (this.filtered_items.length == 0 && this.filter == "")
                return this.AllIssuedCards.length
            else {
                return this.filtered_items.length;
            }
        },
        ...mapGetters([
            'IssuedCardsOnceLoaded',
            'AllIssuedCards',
            'IssuedCardsGetHaveError',
            'IssuedCardsGetAreLoading',


            'AllOrganizationsSelect',
            'AllProductsSelect',
            'ErrorApiResponseText',
            'AllCards',
            'CardByGUID',
            'CardsOnceLoaded',
            'CardsGetHaveError',
            'CardsGetAreLoading',
            'CardsPostHaveError',
            'CardsPostAreLoading',
            'CardsPutHaveError',
            'CardsPutAreLoading',
            'CardsDeleteAreLoading',
            'AllOrganizations',
            'OrganizationByGUID',
            'OrganizationsOnceLoaded',
            'OrganizationsGetAreLoading',
            'OrganizationsGetHaveError',
            'AllProducts',
            'ProductByGUID',
            'ProductsOnceLoaded',
            'ProductsGetAreLoading',

            'ProductsGetHaveError',
            'AllWallets',
            'WalletByGUID',
            'WalletsByCardGUID',
            'WalletFindByProdictGUID',
            'WalletsOnceLoaded',
            'WalletsGetAreLoading',
            'WalletsGetHaveError',
            'WalletsPostAreLoading',
            'WalletsPostHaveError',
            'WalletsPutAreLoading',
            'WalletsPutHaveError',
            'WalletsDeleteAreLoading',
            'WalletsDeleteHaveError',
            'TerminalByGUID',
            'TerminalsGetHaveError',
            'TerminalsGetAreLoading',
            'TerminalsOnceLoaded'
        ]),
        isBusy() {
            return (this.IssuedCardsGetAreLoading) ? true : false;
        },
        loading() {
            switch (this.action.method) {

                case 'post':
                    if (this.action.type === 'IssuedCard') return this.IssuedCardsPostAreLoading;
                default:
                    return false
            }
        },


        //Изменено подразделение
        OrgChanged() {
            let i = this.commandQueue.find(t => t.commandCode == 6 && t.status == 0);
            this.changeable_organizationID = i != undefined ? i.details.organizationID : null;
            return i == undefined ? false : true;
        },
        //Пин-код сменён
        PinChanged() {
            let i = this.commandQueue.find(t => t.commandCode == 2 && t.status == 0)
            this.changeable_pin = i != undefined ? i.details.pin : null;
            return i == undefined ? false : true;
        },

        WalletChanged() {
            let i = this.commandQueue.find(t => t.commandCode == 5 && t.status == 0 && t.details.walletID == this.wallet_id)
            this.changeable_dailyLimitValue = i != undefined && i.details.dailyLimitValue != this.dailyLimitValue ? i.details.dailyLimitValue : null;
            this.changeable_weekLimitValue = i != undefined && i.details.weekLimitValue != this.weekLimitValue ? i.details.weekLimitValue : null;
            this.changeable_monthLimitValue = i != undefined && i.details.monthLimitValue != this.monthLimitValue ? i.details.monthLimitValue : null;
            this.changeable_operationLimitValue = i != undefined && i.details.operationLimitValue != this.operationLimitValue ? i.details.operationLimitValue : null;
            this.changeable_defaultLimit = i != undefined && i.details.defaultLimit != this.defaultLimit ? i.details.defaultLimit : null;
            return i == undefined ? false : true;
        },
        VolumeChanged() {
            let i = this.commandQueue.find(t => t.commandCode == 4 && t.status == 0 && t.details.walletID == this.wallet_id)
            this.changeable_quantity = i != undefined ? i.details.volume : null;
            return i == undefined ? false : true;
        },
    },
    mounted() {
        var text = localStorage.getItem("UserCard_Select")
        if (text) {
            this.perPage = this.rows_to_display_computed.find(a => a.text == text).value;
        }
        else this.perPage = 100

        feather.replace();
    },
    async created() {

        if (!this.OrganizationsOnceLoaded) {
            await this.$store.dispatch(ORGANIZATIONS_GET_REQUEST)
        }
        if (!this.ProductsOnceLoaded) {
            await this.$store.dispatch(PRODUCTS_GET_REQUEST)
        }
    },
    watch: {
        pin(new_val) {
            if (new_val == "0000") {
                this.checked_pin = false;
                this.pin = null;
            }
        },
        selected(new_val) {
            if (new_val.length == 0)
                this.issueType = null;
        },
        issueType(new_val) {
            this.ClearFormCardWallets()

        },
        needWallets(new_val) {
            if (new_val) {
                this.Wallets.push(Object.assign({}, JSON.parse(JSON.stringify(this.defaultWallet))));
            }
            else this.Wallets = [];
        },



        rows(new_val) {
            if (new_val != 0 && localStorage.getItem("UserCard_Select") == "Все") this.perPage = this.rows_to_display_computed.find(a => a.text == "Все").value
        },


        dailyLimitValues(new_val) {
            if (new_val.length > 0) {
                var dailyLimitValueNew = new_val[this.activeWallet];
                this.Wallets[this.activeWallet].invalid_weekLimit = false;
                this.Wallets[this.activeWallet].invalid_monthLimit = false;

                if (dailyLimitValueNew) {
                    if (this.Wallets[this.activeWallet].checked_weekLimitValue && this.Wallets[this.activeWallet].weekLimitValue) {
                        if (dailyLimitValueNew >= this.Wallets[this.activeWallet].weekLimitValue) {
                            this.Wallets[this.activeWallet].invalid_weekLimit = true;
                            this.Wallets[this.activeWallet].invalid_weekLimit_text = "недельный лимит должен быть больше дневного"
                        }
                    }
                    else if (this.Wallets[this.activeWallet].checked_monthLimitValue && this.Wallets[this.activeWallet].monthLimitValue) {
                        if (dailyLimitValueNew >= this.Wallets[this.activeWallet].monthLimitValue) {
                            this.Wallets[this.activeWallet].invalid_monthLimit = true;
                            this.Wallets[this.activeWallet].invalid_monthLimit = "месячный лимит должен быть больше дневного"
                        }

                    }


                }

            }
        },
        weekLimitValues(new_val) {
            if (new_val.length > 0) {

                var weekLimitValueNew = new_val[this.activeWallet];

                this.Wallets[this.activeWallet].invalid_weekLimit = false;
                this.Wallets[this.activeWallet].invalid_monthLimit = false;
                if (weekLimitValueNew) {
                    if (this.Wallets[this.activeWallet].checked_dailyLimitValue && this.Wallets[this.activeWallet].dailyLimitValue) {
                        if (weekLimitValueNew <= this.Wallets[this.activeWallet].dailyLimitValue) {
                            this.Wallets[this.activeWallet].invalid_weekLimit = true;
                            this.Wallets[this.activeWallet].invalid_weekLimit_text = "недельный лимит должен быть больше дневного"
                        }
                    }
                    if (this.Wallets[this.activeWallet].checked_monthLimitValue && this.Wallets[this.activeWallet].monthLimitValue) {
                        if (weekLimitValueNew >= this.Wallets[this.activeWallet].monthLimitValue) {
                            this.Wallets[this.activeWallet].invalid_monthLimit = true;
                            this.Wallets[this.activeWallet].invalid_monthLimit_text = "месячный лимит должен быть больше недельного"
                        }
                    }

                }

            }
        },
        monthLimitValues(new_val) {
            if (new_val.length > 0) {

                var monthLimitValueNew = new_val[this.activeWallet];

                this.Wallets[this.activeWallet].invalid_monthLimit = false;
                if (monthLimitValueNew) {
                    if (this.Wallets[this.activeWallet].checked_weekLimitValue && this.Wallets[this.activeWallet].weekLimitValue) {
                        if (monthLimitValueNew <= this.Wallets[this.activeWallet].weekLimitValue) {
                            this.Wallets[this.activeWallet].invalid_monthLimit = true;
                            this.Wallets[this.activeWallet].invalid_monthLimit_text = "месячный лимит должен быть больше недельного"
                        }

                    }
                    else if (this.Wallets[this.activeWallet].checked_dailyLimitValue && this.Wallets[this.activeWallet].dailyLimitValue) {
                        if (monthLimitValueNew <= this.Wallets[this.activeWallet].dailyLimitValue) {
                            this.Wallets[this.activeWallet].invalid_monthLimit = true;
                            this.Wallets[this.activeWallet].invalid_monthLimit_text = "месячный лимит должен быть больше дневного"
                        }
                    }

                }
            }
        },

        dailyLimitValue(new_val) {
            this.invalid_weekLimit = false;
            this.invalid_monthLimit = false;

            if (new_val) {
                if (this.checked_weekLimitValue && this.weekLimitValue) {
                    if (new_val >= this.weekLimitValue) {
                        this.invalid_weekLimit = true;
                        this.invalid_weekLimit_text = "недельный лимит должен быть больше дневного"
                    }
                }
                else if (this.checked_monthLimitValue && this.monthLimitValue) {
                    if (new_val >= this.monthLimitValue) {
                        this.invalid_monthLimit = true;
                        this.invalid_monthLimit = "месячный лимит должен быть больше дневного"
                    }

                }


            }

        },
        weekLimitValue(new_val) {
            this.invalid_weekLimit = false;
            this.invalid_monthLimit = false;
            if (new_val) {
                if (this.checked_dailyLimitValue && this.dailyLimitValue) {
                    if (new_val <= this.dailyLimitValue) {
                        this.invalid_weekLimit = true;
                        this.invalid_weekLimit_text = "недельный лимит должен быть больше дневного"
                    }
                }
                if (this.checked_monthLimitValue && this.monthLimitValue) {
                    if (new_val >= this.monthLimitValue) {
                        this.invalid_monthLimit = true;
                        this.invalid_monthLimit_text = "месячный лимит должен быть больше недельного"
                    }
                }

            }


        },
        monthLimitValue(new_val) {
            this.invalid_monthLimit = false;
            if (new_val) {
                if (this.checked_weekLimitValue && this.weekLimitValue) {
                    if (new_val <= this.weekLimitValue) {
                        this.invalid_monthLimit = true;
                        this.invalid_monthLimit_text = "месячный лимит должен быть больше недельного"
                    }

                }
                else if (this.checked_dailyLimitValue && this.dailyLimitValue) {
                    if (new_val <= this.dailyLimitValue) {
                        this.invalid_monthLimit = true;
                        this.invalid_monthLimit_text = "месячный лимит должен быть больше дневного"
                    }
                }

            }

        }
    },
    methods: {
        TEST(){
            axios.get('/api/transaction/1')
        },
        ActivateCard() {
            if (this.issueType == 'A') this.CreateFuelCard();
            if (this.issueType == 'B') this.CreateDriverCard();
        },
        ClearFormCardWallets() {
            this.needWallets = false;
            this.holderName = null;
            this.organizationID = null;
            this.pin = null;
            this.checked_pin = true;
            this.useDefaultPassword = true;
            this.Wallets = [];
        },

        onRowSelected(items) {
            this.selected = items
        },
        onTabActivate(_, prevTabIndex, bvEvent) {
            let ref = 'WalletForm' + prevTabIndex;
            if (!this.$refs[ref][0].checkValidity()) {
                this.$refs[ref][0].reportValidity();
                bvEvent.preventDefault();
            }
            else if (this.Wallets[prevTabIndex].invalid_monthLimit || this.Wallets[prevTabIndex].invalid_weekLimit) {
                bvEvent.preventDefault();
            }
        },
        DeleteWalletTab(index) {
            this.Wallets.splice(index, 1);
        },
        NewWalletTab() {
            let ref = 'WalletForm' + this.activeWallet;
            if (!this.$refs[ref][0].checkValidity()) this.$refs[ref][0].reportValidity();
            else if (this.Wallets[this.activeWallet].invalid_monthLimit || this.Wallets[this.activeWallet].invalid_weekLimit) return;
            else {
                this.Wallets.push(Object.assign({}, JSON.parse(JSON.stringify(this.defaultWallet))));

                setTimeout(this.activeWallet = this.Wallets.length - 1, 100);
            }
        },
        Refresh() {
            this.$store.dispatch(ISSUEDCARDS_GET_REQUEST);
        },




        SaveToLocalStorage(e) {
            var a = this.rows_to_display_computed.find(a => a.value == e);
            if (a != undefined) localStorage.setItem("UserCard_Select", a.text);
        },

        onFiltered(filteredItems) {
            this.filtered_items = filteredItems;
            this.currentPage = 1;
        },
        clear() {
            this.filter = "";
        },
        filterTable(row, filter) {
            /*if (filter.length == 0 || filter.length == 1) return true*/
            if (row.number.split(' ').join('').toLowerCase().indexOf(filter.split(' ').join('').toLowerCase()) > -1 /*&& row.number.toLowerCase()[0] == filter.toLowerCase()[0]*/) return true;
            else return false
        },
        change_checked_pin(e) {
            if (this.checked_pin) {
                this.pin = "";
            }
        },

        ChangeChecked(e, type) {
            switch (type) {
                case 'Pin':
                    this.pin = null;
                    if (this.checked_pin && !this.useDefaultPassword) {
                        this.$nextTick(() => {
                            this.$refs.Pin.focus();
                        })
                    }

                    break;
                case 'operationLimit':
                    if (this.CurrentWallet?.checked_operationLimitValue) {
                        this.Wallets[this.activeWallet].operationLimitValue = null;
                        this.$nextTick(() => {
                            this.$refs.operationLimit[this.activeWallet].focus()
                        });
                    }
                    else this.Wallets[this.activeWallet].operationLimitValue = 0;
                    break;
                case 'dailyLimit':
                    if (this.CurrentWallet?.checked_dailyLimitValue) {
                        this.Wallets[this.activeWallet].dailyLimitValue = null;
                        this.$nextTick(() => {
                            this.$refs.dailyLimit[this.activeWallet].focus()
                        });

                    } else this.Wallets[this.activeWallet].dailyLimitValue = 0;
                    break;
                case 'weekLimit':
                    if (this.CurrentWallet?.checked_weekLimitValue) {
                        this.Wallets[this.activeWallet].weekLimitValue = null;
                        this.$nextTick(() => {
                            this.$refs.weekLimit[this.activeWallet].focus()
                        });

                    } else {
                        this.Wallets[this.activeWallet].weekLimitValue = 0;
                    }
                    break;
                case 'monthLimit':
                    if (this.CurrentWallet?.checked_monthLimitValue) {
                        this.Wallets[this.activeWallet].monthLimitValue = null;
                        this.$nextTick(() => {
                            this.$refs.monthLimit[this.activeWallet].focus()
                        });


                    } else {
                        this.Wallets[this.activeWallet].monthLimitValue = 0;
                    }
                    break;
                case 'defaultLimit':
                    if (this.CurrentWallet?.checked_defaultLimit) {
                        this.Wallets[this.activeWallet].defaultLimit = null;
                        this.$nextTick(() => {
                            this.$refs.defaultLimit[this.activeWallet].focus()
                        });
                    }
                    else this.Wallets[this.activeWallet].defaultLimit = 0;
                    break;
            }

        },

        NumberSpace(card_number) {
            if (card_number != undefined) return card_number.match(/.{1,4}/g).join(' ')
        },

        //Только номер 
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                $event.preventDefault();
            }
        },


        CheckCardForm() {
            if (this.$refs.CreateEditCardForm.checkValidity()) {
                if (this.needWallets) {
                    for (let index = 0; index < this.Wallets.length; index++) {
                        let ref = 'WalletForm' + index;
                        if (this.$refs[ref][0].checkValidity() && !this.Wallets[index].invalid_monthLimit && !this.Wallets[index].invalid_weekLimit) continue;
                        else { this.activeWallet = index; this.$nextTick(() => { this.$refs[ref][0].reportValidity(); return false; }) }

                    }
                    return true;
                }
                else return true;
            }
            else { this.$refs.CreateEditCardForm.reportValidity(); return false; }
        },
        async CreateDriverCard() {
            this.action = {
                type: 'IssuedCard',
                method: 'post'
            }
            let Drivers = [];
            for (var i = 0; i < this.selected.length; i++) {
                var last4 = this.selected[i].number.substr(-4, 4);
                Drivers.push({
                    pin: last4,
                    name: `Водитель_${last4}`,
                    number: this.selected[i].number,
                })
            }

            await this.$store.dispatch(ISSUEDCARDS_DRIVER_POST_REQUEST, Drivers)
                .then(() => { this.$refs.EmptyModal.success('Карты водителя успешно cозданы'); })
                .catch((err) => { this.$refs.EmptyModal.error(err) })



        },
        async CreateFuelCard() {
            {
                if (!this.CheckCardForm()) return;
                this.action = {
                    type: 'IssuedCard',
                    method: 'post'
                }

                let CardsWallets = [];

                for (var i = 0; i < this.selected.length; i++) {

                    var last4 = this.selected[i].number.substr(-4, 4);
                    CardsWallets.push
                        ({
                            Card: {
                                pin: this.checked_pin ? this.useDefaultPassword ? last4 : this.pin : '0000',
                                serviceNumber: this.selected[i].number,
                                organizationID: this.organizationID,
                                number: this.selected[i].number,
                                holderName: `${this.holderName}_${last4}`
                            }


                            , Wallets: this.Wallets.map(element => {
                                return {
                                    productID: element.wallet_productID,
                                    name: `${element.wallet_name}_${last4}`,
                                    defaultLimit: element.defaultLimit,
                                    dailyLimitValue: element.dailyLimitValue,
                                    weekLimitValue: element.weekLimitValue,
                                    monthLimitValue: element.monthLimitValue,
                                    creditWallet: element.creditWallet,
                                    operationLimitValue: element.operationLimitValue,
                                }
                            })

                        });
                }
                await this.$store.dispatch(exp.ISSUEDCARDS_FUEL_POST_REQUEST, CardsWallets).then(() => { this.$refs.EmptyModal.success('Топливные карты успешно cозданы') }).catch((err) => { this.$refs.EmptyModal.error(err) })
            }
        },








    },
}
</script>
<style>
.skeleton-wallet {
    height: skeleton-wallet;
}

.card-wallet+.card-wallet {}

.card-body-cards {
    overflow-y: auto;
}

.card-tabs .tab-content {
    border: 1px solid #DEE2E6;
    border-top: none
}
</style>
